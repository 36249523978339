import type { MutationTree } from 'vuex'
import type {GenderAndCity } from './state'

const mutations: MutationTree<GenderAndCity> = {
  updateGenders(state, gender: Array<{id: number, value1:string}>) {
    state.genders = gender;
  },

  updateCitys(state, citys:Array<{id: number, value2:string}>){
      state.citys = citys;
  }
}

export default mutations
