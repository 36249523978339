import type { Module } from "vuex";
import type { GenericState } from "./state";
import { buildUrlWithQueryOptions } from "@/lib/utils/stringUtils";
import { axiosInstance } from "@/lib/https/setting";
import type { AxiosInstance } from "axios";
import {
  PAGE_SIZE_DEFAULT
} from '@/models/https/pagination'

interface Identifiable {
  id: string;
}

const axios: AxiosInstance = axiosInstance

export const createGenericModule = <T extends Identifiable>(): Module<GenericState<T>, any> => ({
  namespaced: true,
  state: () => ({
    items: {
      data: [] as T[],
      pagination: {
        keyword: '',
        page: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        fullName: '',
        email: '',
        role: '',
        status: -1,
      }
    }
  }),
  mutations: {
    SET_ITEMS(state, items: { data: T[]; pagination: any }) {
      state.items = items;
    },
    ADD_ITEM(state, item: T) {
      state.items.data.push(item);
    },
    UPDATE_ITEM(state, updatedItem: T) {
      const index = state.items.data.findIndex(item => item.id === updatedItem.id);
      if (index !== -1) {
        state.items.data[index] = Object.assign({}, state.items.data[index], updatedItem);
      }
    },
    REMOVE_ITEM(state, id: string) {
      state.items.data = state.items.data.filter(item => item.id !== id);
      state.items.pagination.totalRecords -= 1;
    },
    UPDATE_PAGINATION(state, pagination: any) {
      state.items.pagination = Object.assign({}, state.items.pagination, pagination);
    },
    RESET_PAGINATION(state) {
      state.items.pagination = {
        keyword: '',
        page: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        orderBy: '',
        fullName: '',
        email: '',
        role: '',
        status: -1,
      }
    }
  },
  actions: {
    async refreshItems({ commit, state }, param: { apiUrl: string }) {
      try {
        const url = buildUrlWithQueryOptions(param.apiUrl, state.items.pagination)
        const response = await axios.get(url)
        const { data } = response;
        commit('SET_ITEMS', data);
      } catch (error) {
        throw error;
      }
    },
    async fetchItems({ commit }, param: { apiUrl: string, options: any }) {
      try {
        const url = buildUrlWithQueryOptions(param.apiUrl, param.options)
        const response = await axios.get(url)
        const { data } = response;
        commit('SET_ITEMS', data);
      } catch (error) {
        throw error;
      }
    },
    async updatePagination({ commit }, param: { apiUrl: string, pagination: any, moduleNamespace: string }) {
      try {
        commit('UPDATE_PAGINATION', param.pagination);
        await this.dispatch(param.moduleNamespace + '/fetchItems', { apiUrl: param.apiUrl, options: param.pagination })
      } catch (error) {
        throw error;
      }
    }
  },
  getters: {
    allItems(state): T[] {
      return state.items.data;
    },
    pagination(state): any {
      return state.items.pagination;
    },
    getItemById: (state) => (id: string) => {
      return state.items.data.find(item => item.id === id);
    },
  },
});
