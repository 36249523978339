<style setup scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import { TitleType } from '../parts/NameMeaning/GroupTitle.vue';
import GroupTitle from '../parts/NameMeaning/GroupTitle.vue';
import SimTableComponentSlider from '../parts/SimTableComponentSlider.vue';
import { computed, onMounted, ref } from 'vue';
import type { simItemDto } from '@/models/https/simResponse';
import type { IGetSimReq } from '@/models/https/simRequests';
import SimRepository from '@/lib/https/simRepository';
import { RepositoryFactory } from '@/lib/https';
import { getZodiacAsianWithIndex } from '@/lib/utils/common';
import { routerPaths } from '@/router/routes';
import SelectZodiacComponent from '../parts/SelectZodiacComponent.vue';

import { fiveElementIcons } from '@/lib/utils/imageUtils';

const { getSims } = RepositoryFactory.getRepository<SimRepository>(
   SimRepository
);

const currentZodiacSlect = ref<number>(1);
const zodiacName = computed(() => getZodiacAsianWithIndex(currentZodiacSlect.value));

const metalSimDatas = ref<Array<simItemDto>>([]);
const woodSimDatas = ref<Array<simItemDto>>([]);
const waterSimDatas = ref<Array<simItemDto>>([]);
const fireSimDatas = ref<Array<simItemDto>>([]);
const earthSimDatas = ref<Array<simItemDto>>([]);

const createDefaultRequest = () => {
   const data: IGetSimReq = {
      key_work: "",
      nha_mang_ds: null,
      gia_tien_ma_ds: null,
      ngu_hanh_ma_ds: "1",
      tuoi_ma_ds: currentZodiacSlect.value.toString(),
      sim_loai_ma_ds: null,
      page_name: "sotheotuoi",
      page_index: 1,
      page_size: 18,
      gia_tien_sort: 0,
      cai_van_sort: 0,
      dau_so: undefined
   };
   return data;
}

const fetchSimsWithZodiac = async (zodiac: string) => {
   const rq = createDefaultRequest();

   rq.ngu_hanh_ma_ds = "1";
   rq.tuoi_ma_ds = zodiac;
   const rpMetal = await getSims(rq);
   metalSimDatas.value = rpMetal?.data ?? [];

   rq.ngu_hanh_ma_ds = "2";
   const rpWood = await getSims(rq);
   woodSimDatas.value = rpWood?.data ?? [];

   rq.ngu_hanh_ma_ds = "3";
   const rpWater = await getSims(rq);
   waterSimDatas.value = rpWater?.data ?? [];

   rq.ngu_hanh_ma_ds = "4";
   const rpFire = await getSims(rq);
   fireSimDatas.value = rpFire?.data ?? [];

   rq.ngu_hanh_ma_ds = "5";
   const rpEarth = await getSims(rq);
   earthSimDatas.value = rpFire?.data ?? [];
}

onMounted(async () => {
   try {
      await fetchSimsWithZodiac("1");
   } catch (error) {

   }
})

const onchangeZodiac = (id: number) => {
   currentZodiacSlect.value = id;
   fetchSimsWithZodiac(currentZodiacSlect.value.toString());
}

</script>

<template>
   <section class="py-3 section-feng-shui">
      <div class="container-fluid">
         <div class="row align-items-center">
            <div class="col-12 col-lg-5 col-xl-3">
               <h1 class="title-section text-xl-start mb-3">Số theo tuổi</h1>
            </div>
            <div class="col-12 col-lg-7 col-xl-9 pe-0">
               <div class="slider-service">
                  <div>
                     <img src="@/assets/images/banner-tuoi.png" class="w-100 img-banner" alt="banner" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>

   <section class="section-page section-to-network" id="tuoi-list-phone-number">
      <SelectZodiacComponent @onChangeZodiac="onchangeZodiac"/>

      <GroupTitle :type="TitleType.Metal" :title="`Tuổi ${zodiacName} - Mệnh kim`" class-props=""
         v-bind:icon='fiveElementIcons.metalIcon'>
         <template #botSlot>
            <div class="text-end pe-1">
               <RouterLink :to="`${routerPaths.DetailPhoneNumber}/?element=1&zodiac=${currentZodiacSlect}&pageName=sotheotuoi&pageSize=36`" class="btn btn-sm btn-outline-danger border-0">Xem
                  thêm</RouterLink>
            </div>
         </template>
      </GroupTitle>
      <SimTableComponentSlider :simDatas="metalSimDatas" labelElement="Sim mệnh:"/>

      <GroupTitle :type="TitleType.Wood" :title="`Tuổi ${zodiacName} - Mệnh Mộc`" class-props=""
         v-bind:icon='fiveElementIcons.woodIcon'>
         <template #botSlot>
            <div class="text-end pe-1">
               <RouterLink :to="`${routerPaths.DetailPhoneNumber}/?element=2&zodiac=${currentZodiacSlect}&pageName=sotheotuoi&pageSize=36`" class="btn btn-sm btn-outline-danger border-0">Xem
                  thêm</RouterLink>
            </div>
         </template>
      </GroupTitle>
      <SimTableComponentSlider :simDatas="woodSimDatas" labelElement="Sim mệnh:"/>

      <GroupTitle :type="TitleType.Water" :title="`Tuổi ${zodiacName} - Mệnh Thủy`" class-props=""
         v-bind:icon='fiveElementIcons.woodIcon'>
         <template #botSlot>
            <div class="text-end pe-1">
               <RouterLink :to="`${routerPaths.DetailPhoneNumber}/?element=3&zodiac=${currentZodiacSlect}&pageName=sotheotuoi&pageSize=36`" class="btn btn-sm btn-outline-danger border-0">Xem
                  thêm</RouterLink>
            </div>
         </template>
      </GroupTitle>
      <SimTableComponentSlider :simDatas="waterSimDatas" labelElement="Sim mệnh:"/>

      <GroupTitle :type="TitleType.Fire" :title="`Tuổi ${zodiacName} - Mệnh Hỏa`" class-props=""
         v-bind:icon='fiveElementIcons.fireIcon'>
         <template #botSlot>
            <div class="text-end pe-1">
               <RouterLink :to="`${routerPaths.DetailPhoneNumber}/?element=4&zodiac=${currentZodiacSlect}&pageName=sotheotuoi&pageSize=36`" class="btn btn-sm btn-outline-danger border-0">Xem
                  thêm</RouterLink>
            </div>
         </template>
      </GroupTitle>
      <SimTableComponentSlider :simDatas="fireSimDatas" labelElement="Sim mệnh:"/>

      <GroupTitle :type="TitleType.Earth" :title="`Tuổi ${zodiacName} - Mệnh Thổ`" class-props=""
         v-bind:icon='fiveElementIcons.earthIcon'>
         <template #botSlot>
            <div class="text-end pe-1">
               <RouterLink :to="`${routerPaths.DetailPhoneNumber}/?element=5&zodiac=${currentZodiacSlect}&pageName=sotheotuoi&pageSize=36`" class="btn btn-sm btn-outline-danger border-0">Xem
                  thêm</RouterLink>
            </div>
         </template>
      </GroupTitle>
      <SimTableComponentSlider :simDatas="earthSimDatas" labelElement="Sim mệnh:"/>
   </section>
</template>