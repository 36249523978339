<script lang="ts" setup>
import VinaphoneIcon from '@/assets/images/img-vina.png';
import ViettelIcon from '@/assets/images/img-viettel.png';
import MobifoneIcon from '@/assets/images/img-mobi.png';

import { numberWithCommas } from '@/lib/utils/common';
import type { ISimPurchaseHistoryDetail } from '@/models/PurchaseHistory';

defineProps<{ detail: ISimPurchaseHistoryDetail; }>();


function getIcon(nha_mang: string) {
    switch (nha_mang) {
        case 'Vinaphone': return VinaphoneIcon;
        case 'Viettel': return ViettelIcon;
        case 'Mobifone': return MobifoneIcon;
        default:
            break;
    }
}


</script>

<template>
    <div class="item-cart">
        <img :src="getIcon(detail.nha_mang)" class="img-fluid" alt="hình" style='width:30px;'>
        <strong class="me-auto ms-2">{{ detail.sim }}</strong>
        <div class="text-end">
            {{ detail.so_luong }}x {{ numberWithCommas(detail.tien) }}
        </div>
    </div>
</template>