import type { MutationTree } from 'vuex'
import { ProductType, type Product, type ShoppingCart } from './state'

const mutations: MutationTree<ShoppingCart> = {
  addProductToCart(state, product: Product) {
    state.products.push(product);
  },

  updateQuatityProduct(state, product:Product)
  {
    const p = state.products.find(e => e.productId == product.productId);
    if(p)
    {
      p.qty += product.qty;
    }
   
  },

  removeProductToCart(state, productId:string){
    const ind = state.products.findIndex(p => p.productId === productId);
    if(ind >= 0)
    {
      state.products.splice(ind, 1);
    }
    
  }
}

export default mutations
