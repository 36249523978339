import type { ActionTree } from 'vuex'
import { ProductType, type Product, type ShoppingCart } from './state'
import type { RootState } from '../rootState';
import { AddProductResult } from '.';


const actions: ActionTree<ShoppingCart, RootState> = {
    UpdateProducts({ commit, state }, product: Product) {
        return new Promise((resolve, reject) => {
            const p = state.products.find(e => e.productId == product.productId);

            if (product) {
                if (p && p.type == ProductType.Durable) {
                    reject(AddProductResult.ExitsProductError);
                    return;
                }
                else if(p && p.type == ProductType.Consumable)
                {
                    commit('updateQuatityProduct', product);
                }
                else { 
                    commit('addProductToCart', product);
                }
                
                resolve(AddProductResult.AddProductSuccessed);
            } else {
                reject('Lỗi: Giá trị payload không hợp lệ.');
            }
        });
    }
}

export default actions;
