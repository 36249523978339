<style setup>
@import '@/assets/css/signin.css';
</style>
<script lang="ts" setup>
import { RepositoryFactory } from '@/lib/https';
import AccountRepository from '@/lib/https/accountRepository';
import router from '@/router';
import { routerPaths } from '@/router/routes';
import { ref } from 'vue';
</script>
<script lang="ts">
const { sendOTP } = RepositoryFactory.getRepository<AccountRepository>(
    AccountRepository
);

const otpResult = ref<{ msg?: string, code?: string }>({})

export default {
    data() {
        return {
            form: {
                txtPhone: ''
            }
        }
    },
    methods: {
        async submitOTP() {
            const otpRes = await sendOTP(this.form.txtPhone);
            otpResult.value = { msg: otpRes.result_msg, code: otpRes.result_code }
            if (otpRes.result_code == '000' && otpRes?.result_msg == 'ok') {
                router.push(`${routerPaths.ConfirmOTP}?mobile=${this.form.txtPhone}`)
            } else {
                alert(otpRes.result_msg)
            }
        }
    }
}
</script>
<template>
    <main class="main m-0 p-0">
        <div class="container-fluid">
            <div class="row align-items-center justify-content-center m-0 p-0">
                <div class="d-none d-md-flex col-md-6 col-xxl-5 px-0 left">
                    <h2 class="heading">THAY SỐ CẢI VẬN</h2>
                </div>
                <div class="col-md-6 col-xxl-5 right">
                    <div class="signin-block mt-auto mb-2">
                        <img src="@/assets/images/logoLUCTRUMENHLY.svg" class="logo mb-3" width="60" alt="logo LỤC TRỤ MỆNH LÝ">
                        <form @submit.prevent="submitOTP" class="form-signin">
                            <h1 class="title-page mt-2">ĐĂNG NHẬP NGAY</h1>
                            <div class="my-3">
                                <label for="txtPhone" class="form-label fw-normal">SỐ ĐIỆN THOẠI</label>
                                <input v-model="form.txtPhone" type="tel" class="form-control form-control-lg text-center" id="txtPhone" placeholder="Số điện thoại">
                            </div>
                            <button class="w-100 btn btn-lg btn-danger rounded-pill">ĐĂNG NHẬP</button>
                            <small v-if="otpResult.code != '000' && otpResult.msg != 'ok'" class="form-text text-danger">
                                {{ otpResult.msg }}
                            </small>
                            <div class="other-block gap-3 mt-5">
                                <p class="text-muted mb-2">
                                    <a type="button">Tặng 1 lượt xem cải vận trong 30 ngày đối với tài khoản đăng ký lần đầu tiên.</a>
                                </p>
                            </div>
                        </form>
                    </div>
                    <div class="app-block text-end mt-auto mb-3">
                        <a href="https://play.google.com/store/apps/details?id=askmom.sim.com.sim_app_mobile" target="_blank">
                            <img src="@/assets/images/app-ch-play.svg" height="30px" alt="CH Play">
                        </a>
                        <a href="https://apps.apple.com/us/app/sim-s%E1%BB%91-%C4%91%E1%BA%B9p/id1673781239" target="_blank">
                            <img src="@/assets/images/app-store.svg" height="30px" alt="App Store">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>