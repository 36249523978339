import type { MutationTree } from "vuex";
import type { PopupState } from "./state";
const mutations: MutationTree<PopupState> = {
	setPopup(state, key: keyof PopupState) {
		state[key] = true;
	},
	unsetPopup(state, key: keyof PopupState) {
		state[key] = false;
	},
};

export default mutations;

