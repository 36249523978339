import Repository from '@/lib/https/repository'
import { buildUrlWithQueryOptions } from '@/lib/utils/stringUtils'
import type {IDestinyAnalysisHistoryRes, IDrawNumberHistoryRes, IPerpetualCalendarHistoryRes } from '@/models/PurchaseHistory'
import type { IHistoriesParam } from '@/models/requestHistory'

export const historyDestinyAnalysisPacketUrl = '/api/donhang/get_order_dich_vu_by_cus';
export const historyDrawNumberPacketUrl = '/api/donhang/get_order_quay_so_by_cus';
export const historyPerpetualCalendarPacketUrl = '/api/donhang/get_order_lvn_by_cus';

export default class RequestHistoryRepository extends Repository {

  getHistoriesDestinyAnalysis = async (data:IHistoriesParam) => {
    const url = buildUrlWithQueryOptions(historyDestinyAnalysisPacketUrl, data)
    const response = await this.axios.post<IDestinyAnalysisHistoryRes>(url, {});
    if (response.data) return response.data;
    return null;
  } 

  getHistoriesDrawNumberPacket = async (data:IHistoriesParam) => {
    const url = buildUrlWithQueryOptions(historyDrawNumberPacketUrl, data)
    const response = await this.axios.post<IDrawNumberHistoryRes>(url, {});
    if (response.data) return response.data;
    return null;
  } 

  getHistoriesPerpetualCalendarPacket = async (data:IHistoriesParam) => {
    const url = buildUrlWithQueryOptions(historyPerpetualCalendarPacketUrl, data)
    const response = await this.axios.post<IPerpetualCalendarHistoryRes>(url, {});
    if (response.data) return response.data;
    return null;
  } 
}
