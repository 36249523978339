<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import { RepositoryFactory } from '@/lib/https';
import EconomyRepository from '@/lib/https/economyRepository';
import type { ISimPurchaseHistory } from '@/models/PurchaseHistory';
import PageComponent from '@/components/parts/PageComponent.vue';
import SimPurchaseHistoryTable from './SimPurchaseTable/SimPurchaseHistoryTable.vue';

const TABS = [
    'Tất cả',
    'Đang xử lý',
    'Đã lên đơn',
    'Đã giao',
    'Đã hủy',
    'Trả hàng'
];

const currentTab = ref<string>(TABS[0]);
const purchaseHistories = ref<ISimPurchaseHistory[]>([]);
const { getOrders } = RepositoryFactory.getRepository<EconomyRepository>(EconomyRepository);

const pagingData = ref<{ total_page: number; page_index: number; }>({
    total_page: 0,
    page_index: 0
});

onMounted(() => {
    getSimHistories('0');
});

const setTab = (tab: string) => {
    currentTab.value = tab;
    const idx = TABS.indexOf(tab);
    getSimHistories(idx.toString());
};

const onIndexChange = (index: number) => {
    const idx = TABS.indexOf(currentTab.value);
    getSimHistories(idx.toString(), index);
};

const getSimHistories = (idx: string, page_index = 1) =>
    getOrders(idx, page_index).then(({ data }) => {
        if (data?.length) {
            pagingData.value = {
                total_page: data[0].total_page,
                page_index: data[0].page_index
            };
        } else {
            pagingData.value = {
                total_page: 0,
                page_index: 0
            };
        }
        purchaseHistories.value = data || [];
    });

</script>

<template>
    <div>
        <ul class="nav justify-content-center tab-primary" id="myHistory" role="tablist">
            <li class="nav-item" role="presentation" v-for="tab in TABS">
                <button :class="`nav-link ${currentTab == tab ? 'active' : ''}`" @click="setTab(tab)" id="all-tab" data-bs-toggle="tab" type="button" role="tab" :aria-controls="tab" aria-selected="true">
                    {{ tab }}
                </button>
            </li>
        </ul>
        <SimPurchaseHistoryTable :data="purchaseHistories" />
        <PageComponent v-if="pagingData.total_page > 0" @onIndexChange="onIndexChange" :totalPages="pagingData.total_page" />
    </div>
</template>