export default {
  applicationList: "Application list",
  createApplication: "Create Application",
  registered: "Registered",
  underConfirmation: "Confirming",
  applicationName: "Application name",
  organizationName: "Organization name",
  representativeEmail: "Representative Email",
  creationDateAndTime: "Creation date and time",
  returnToApplicationList: "Return to application list",
  applicationManagement: "Application management",
  representativePhoneNumber: "Representative phone number",
  createApplicationButton: "Create application",
  applicationUpdate: "Application update",
  changeOrganizationName: "Change organization name",
  deleteApplication: "Delete application",
  desDeleteApplication: "Do you want to delete this application?",
  applicationUpdateCompleted: "Application update completed",
  applicationDeletionCompleted: "Application deletion completed",
  desEditLogo: "The optimal image size is 192x192 pixels. ",
  desApplicationDetail:
    "A registration application has been submitted with the following information. Please wait for a moment while we confirm the application contents.",
  changeRepresentativePhoneNumber: "Change representative phone number",
  changeRepresentativeEmail: "Change representative email",

  updateApplication: "Update application",
  updateApplicationName: "Update application name",
  updateApplicationLogo: "Update application logo",
  updateApplicationOrganizationName: "Update application organization name",
  updateApplicationRepresentativeEmail: "Update application representative email",
  updateApplicationRepresentativePhoneNumber: "Update application representative phone number",
  exportApplication: "Export application",
  createdDate: "Creation date",
  updatedDate: "Updated date",
  applicationDoesNotExist: "Application does not exist.",
  scope: "Scope",
  env: "Environment",
  authorClientNM: "Authorization client name",
  authorClientID: "Authorization client ID",
}
