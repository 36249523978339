import type { MutationTree } from "vuex";
import type { NotificationState } from "./state";
const mutations: MutationTree<NotificationState> = {
	showSuccess(state, { text, canNavigate }) {
		state.id = new Date().getTime().toString()
		state.isShow = true;
		state.canNavigate = canNavigate;
		state.notification = {
			type: 'success',
			content: text
		}
	},
	showError(state, { text, canNavigate }) {
		state.id = new Date().getTime().toString()
		state.isShow = true;
		state.canNavigate = canNavigate;
		state.notification = {
			type: 'error',
			content: text
		}
	},
	hideAll(state) {
		state.id = "";
		state.isShow = false;
		state.canNavigate = false;
		state.notification = {}
	}
};

export default mutations;
