<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script setup lang="ts">
import store from '@/store';
import type { Product } from '@/store/cart/state';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { getCitys, numberWithCommas } from "../../lib/utils/common"
import Swal from 'sweetalert2';
import ProductComponent from '../parts/ProductComponent.vue';
import { routerPaths } from '@/router/routes';
import { RepositoryFactory } from '@/lib/https';
import CitiesRepositiory from '@/lib/https/citiesRepository';
import { ErrorMessage } from '@/lib/utils/ErrorMessage';
import { UserProfileSate } from '@/store/userProfile/state';
import PaymentRepository from '@/lib/https/paymentRepository';
import type { ICreateOrderData } from '@/models/https/paymentRequests';
import router from '@/router';

const { getDictrics, getWards } = RepositoryFactory.getRepository<CitiesRepositiory>(CitiesRepositiory);
const { createOrder } = RepositoryFactory.getRepository<PaymentRepository>(PaymentRepository);

const products = computed(() => store.state.shoppingCart.products as Array<Product>);
const userProfile = computed(() => store.state.userProfile as UserProfileSate);
const cities = computed(() => getCitys());

const selectDistrict = ref();
const selectWard = ref();
const selectCity = ref();
const addressInput = ref();
const inputName = ref();
const phoneNumber = ref();
const noteInput = ref();
const districts = ref<Array<{ id: string, value1: number, value2: number }>>([]);
const wards = ref<Array<{ id: string, value1: number, value2: number }>>([]);

const isSetUpForm: boolean = false;

const validateInput = () => {
    let error: any = undefined;
    if (inputName.value.value === "") {
        error = {
            icon: "error",
            title: ErrorMessage.InputNameWrong,
        }
    }
    else if (phoneNumber.value.value === "") {
        error = {
            icon: "error",
            title: ErrorMessage.InputPhoneNumberWrong,
        }
    }
    else if (selectCity.value.value === "-1") {
        error = {
            icon: "error",
            title: ErrorMessage.InputCitiesWrong,
        }
    }
    else if (selectDistrict.value.value === "-1") {
        error = {
            icon: "error",
            title: ErrorMessage.InputDistrictWrong,
        }
    }
    else if (selectWard.value.value === "-1") {
        error = {
            icon: "error",
            title: ErrorMessage.InputWardWrong,
        }
    }
    else if (addressInput.value.value === "") {
        error = {
            icon: "error",
            title: ErrorMessage.InputAddressWrong,
        }
    }


    if (error) {
        Swal.fire(error);
        return { error: true };
    }

    return { error: false };
}

const onCityChange = () => {
    if (selectDistrict.value) {
        selectDistrict.value.value = "-1";
    }
    if (selectWard.value) {
        selectWard.value.value = "-1";
    }
    wards.value = [];
    districts.value = [];
    getDictrics({ master_type: 'district', master_child: selectCity.value.value }).then(rs => {
        districts.value = rs?.data ?? [];
    });

}

const onDistrictChange = () => {
    wards.value = [];
    if (selectWard.value) {
        selectWard.value.value = "-1";
    }
    getWards({ master_type: 'town', master_child: selectDistrict.value.value }).then(res => {
        wards.value = res?.data ?? []
    });
}

const onOrderClick = () => {
    if (validateInput().error) {
        return;
    }
    const items: Array<ICreateOrderData> = products.value.map(p => { return { sim: p.productId, tien: p.price } });
    const pr = {
        ho_ten: inputName.value.value,
        mobile: phoneNumber.value.value,
        dia_chi: addressInput.value.value,
        tong_tien: products.value.reduce((partialSum: number, a) => partialSum + (a.qty * a.price), 0),
        ghi_chu: noteInput.value.value ?? "",
        thanh_pho: selectCity.value.value,
        quan: selectDistrict.value.value,
        phuong: selectWard.value.value
    }
    createOrder(pr, items).then(rs => {
        if (rs?.result_code === "000") {
            router.replace(`${routerPaths.PaymentSuccessed}/?dhs_ma=${rs.data.dhs_ma}&tong_tien=${pr.tong_tien}`);
        }
        else {
            const error: any = {
                icon: "error",
                title: rs?.result_msg,
            }
            Swal.fire(error);
        }
    });
}

const setUpForm = () => {

    if (userProfile.value.id !== -1 && !isSetUpForm) {
        if (userProfile.value.cityOfResidence > 0) {
            selectCity.value.value = userProfile.value.cityOfResidence;
        }
        if (userProfile.value.districtOfResidence > 0) {
            getDictrics({ master_type: 'district', master_child: selectCity.value.value }).then(async rs => {
                districts.value = rs?.data ?? [];
                await nextTick();
                selectDistrict.value.value = userProfile.value.districtOfResidence;
            });

            if (userProfile.value.wardOfResidence > 0) {

                getWards({ master_type: 'town', master_child: userProfile.value.districtOfResidence.toString() }).then(async res => {
                    wards.value = res?.data ?? [];
                    await nextTick();
                    selectWard.value.value = userProfile.value.wardOfResidence;
                });
            }
        }

        inputName.value.value = userProfile.value.fullName;
        phoneNumber.value.value = userProfile.value.phoneNumber;
        addressInput.value.value = userProfile.value.placeOfResidence;
    }
}

watch(() => userProfile.value.id, () => {
    setUpForm();
});

onMounted(() => {
    setUpForm();
})

</script>

<template>
    <div class="header-page d-none">
        <a href="../gio-hang/" class="ic-back"></a>
        <h1 class="title-page">THANH TOÁN</h1>
    </div>
    <section class="section-page section-page-mobile section-page-payment" id="payment-section">
        <div class="container-fluid container-scroll">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="row">
                        <div class="col-md-6 col-lg-7 me-auto">
                            <div class="px-3 px-sm-0">
                                <div class="d-flex justify-content-between align-items-center mb-3">
                                    <h2 class="title-page text-center">Thông tin giao hàng</h2>
                                    <RouterLink :to="routerPaths.Login" class="btn btn-outline-danger rounded-pill"
                                        id="btn-signin">Đăng nhập</RouterLink>
                                </div>
                                <div class="row">
                                    <div class="col-6 mb-3">
                                        <label for="nameInput" class="form-label">Họ tên</label>
                                        <input type="text" class="form-control" ref="inputName" id="nameInput"
                                            placeholder="">
                                    </div>
                                    <div class="col-6 mb-3">
                                        <label for="phoneInput" class="form-label">Số điện thoại</label>
                                        <input type="phone" class="form-control" ref="phoneNumber" id="phoneInput"
                                            placeholder="">
                                    </div>
                                </div>
                            </div>
                            <div class="bg-light p-3">
                                <div class="row">
                                    <div class="col-sm-12 col-xl-4 mb-3">
                                        <label for="province" class="form-label">Tỉnh/TP</label>
                                        <select ref="selectCity" class="form-select" v-on:change="onCityChange">
                                            <option value="-1">-Chọn-</option>
                                            <option v-for="city in cities" :value="city.id">{{ city.value2 }}</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 col-xl-4 mb-3">
                                        <label for="city" class="form-label">Quận/Huyện</label>
                                        <select class="form-select" ref="selectDistrict" v-on:change="onDistrictChange">
                                            <option value="-1">-Chọn-</option>
                                            <option v-for="district in districts" :value="district.value1">{{
                                                district.value2 }}</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 col-xl-4 mb-3">
                                        <label for="city" class="form-label">Phường/Xã</label>
                                        <select class="form-select" ref="selectWard">
                                            <option value="-1">-Chọn-</option>
                                            <option v-for="ward in wards" :value="ward.value1">{{ ward.value2 }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-12 my-3">
                                        <label for="locationInput" class="form-label">Địa chỉ</label>
                                        <textarea ref="addressInput" class="form-control" id="locationInput" rows="1"
                                            placeholder="vd: 8 Lý Chính Thắng"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="px-3 px-sm-0">
                                <textarea class="form-control my-4" ref="noteInput" id="noteInput" placeholder="Ghi chú"
                                    rows="1"></textarea>
                                <RouterLink to="../" class="btn text-link text-primary h6 btn-back">
                                    <svg width="6" height="8" viewBox="0 0 6 8" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.51568 0.940976L4.57568 0.000976562L0.575684 4.00098L4.57568 8.00098L5.51568 7.06098L2.46235 4.00098L5.51568 0.940976Z"
                                            fill="#0D6EFD" />
                                    </svg>
                                    <span class="ms-1">Tiếp tục mua hàng</span>
                                </RouterLink>
                            </div>
                        </div>
                        <div class="col-md-5 col-lg-4">
                            <div class="px-3 px-sm-0" id="div_carts" v-if="products && products.length > 0">
                                <h2 class="title-page mb-4" id="id_title">Đơn hàng({{ products.length }})</h2>
                                <ProductComponent v-for="product in products" :product="product" />
                                <div class="total-cart d-flex align-items-center py-2 mt-3">
                                    <strong class="me-auto ms-2">Tổng tiền tạm
                                        tính</strong>
                                    <strong class="text-end text-danger h6 fw-bold">{{ numberWithCommas(
                                        products.reduce((partialSum: number, a) => partialSum + (a.qty * a.price), 0)
                                    ) }}</strong>
                                </div>
                                <div class="action-bottom">
                                    <a v-on:click="onOrderClick" class="btn btn-lg btn-danger rounded-pill w-100">Đặt
                                        hàng</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>
