import Repository from "@/lib/https/repository"
import { buildUrlWithQueryOptions } from "../utils/stringUtils"
import type { IChangeFortuneParam, IPhoneNumberAccordingToCriteriaReq } from "@/models/https/changeFortuneRequests"
import type { IChangeFortuneRes, ISimAccordingToCriteriaRes } from "@/models/https/changeFortuneResponse"

export const searchWithPhoneNumberUrl = "/api/trang_chu/chart_detail_by_sim_by_cus"
export const getPhoneNumberAccordingToCriterialUrl = "api/trang_chu/sim_tieu_chi"
export const searchWithLisencePlateUrl = "/api/trang_chu/chart_detail_by_bien_so_xe_cus"
export const searchWithBankAccountNumberUrl = "/api/trang_chu/chart_detail_by_so_tk_by_cus"
export const searchWithHouseNumberUrl = "/api/trang_chu/chart_detail_by_so_nha_by_cus"

export default class ChangeFortuneRepository extends Repository {

  searchWithPhoneNumber =  async (data:IChangeFortuneParam) => {
    const url = buildUrlWithQueryOptions(searchWithPhoneNumberUrl, data)
    const response = await this.axios.post<IChangeFortuneRes>(url, {});
    if (response.data) return response.data;
    return null
  } 

  getPhoneNumberAccordingToCriteria = async (req: IPhoneNumberAccordingToCriteriaReq) => {
    const response = await this.axios.post<ISimAccordingToCriteriaRes>(getPhoneNumberAccordingToCriterialUrl, req)
    if (response.data) return response.data;
    return null
  } 

  searchWithLisencePlate =  async (data:IChangeFortuneParam) => {
    const url = buildUrlWithQueryOptions(searchWithLisencePlateUrl, data)
    const response = await this.axios.post<IChangeFortuneRes>(url, {});
    if (response.data) return response.data;
    return null
  } 

  searchWithBankAccountNumber =  async (data:IChangeFortuneParam) => {
    const url = buildUrlWithQueryOptions(searchWithBankAccountNumberUrl, data)
    const response = await this.axios.post<IChangeFortuneRes>(url, {});
    if (response.data) return response.data;
    return null
  } 

  searchWithHouseNumber =  async (data:IChangeFortuneParam) => {
    const url = buildUrlWithQueryOptions(searchWithHouseNumberUrl, data)
    const response = await this.axios.post<IChangeFortuneRes>(url, {});
    if (response.data) return response.data;
    return null
  } 
}
