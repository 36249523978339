<style setup>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";

.responsive-video {
    position: relative;
    padding-bottom: 56.25%;
    /* Aspect ratio */
    padding-top: 25px;
    height: 0;
}

.responsive-video1 iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>

<script lang="ts" setup>
import { onBeforeMount, onMounted, ref } from 'vue';

const height = screen.height;
</script>

<template>
    <iframe width="100%" :height="height" src="https://luctrumenhly.com/tin-tc" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen="true">

    </iframe>
</template>