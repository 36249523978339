<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import { RepositoryFactory } from '@/lib/https';
import type {IPerpetualCalendarHistoryRes } from '@/models/PurchaseHistory';
import PageComponent from '@/components/parts/PageComponent.vue';
import ServicePurchaseHistoryTable from './ServicePurchaseTable/ServicePurchaseHistoryTable.vue';
import RequestHistoryRepository from '@/lib/https/requestHistoryRepository';
import type { HistoryPacketServiceData } from './ServicePurchaseTable/ServicePurchaseHistoryTableItem.vue';
import processingIcon from '@/assets/images/icons/ic-status-processing.svg';
import successIcon from '@/assets/images/icons/ic-status-success.svg';
import cancelledIcon from '@/assets/images/icons/ic-status-cancelled.svg';

const { getHistoriesPerpetualCalendarPacket } = RepositoryFactory.getRepository<RequestHistoryRepository>(RequestHistoryRepository);

const TABS = [
    'Tất cả',
    'Không thành công',
    'Hoàn thành',
];

const statusIcon = (status: string) => {
    return {
        0: cancelledIcon,
        1: successIcon,
    }[status] || processingIcon;
};


const currentTab = ref<string>(TABS[0]);

const getStatusFromTab = () => {
    const idx = TABS.indexOf(currentTab.value);
    return idx <= 0 ? '' : idx - 1;
}

const purchaseHistories = ref<HistoryPacketServiceData[]>([]);

const pagingData = ref<{ total_page: number; page_index: number; }>({
    total_page: 0,
    page_index: 0
});

const convertPerpetualPacket = (rs: IPerpetualCalendarHistoryRes) => {
    if (rs?.data?.length) {
        const result: HistoryPacketServiceData[] = rs.data.flatMap(v => {
            const d: HistoryPacketServiceData = {
                OrderId: v.dh_lvn_id,
                Status: v.trang_thai.toString(),
                StatusName: v.trang_thai_ten,
                TextStyle: v.web_text_class,
                PacketName: v.glvn_ten,
                Price: v.tien_cuoi_cung,
                CreateTime: v.thoi_gian_tao,
            }
            return d;
        });
        return result;
    }
    return [];
}

onMounted(() => {
    getDrawNumberHistories('');
});

const setTab = (tab: string) => {
    currentTab.value = tab;
    const idx = getStatusFromTab();
    getDrawNumberHistories(idx?.toString() ?? null);
};

const onIndexChange = (index: number) => {
    const idx = getStatusFromTab();
    getDrawNumberHistories(idx?.toString() ?? null, index);
};

const getDrawNumberHistories = (idx: string, page_index = 1) =>
    getHistoriesPerpetualCalendarPacket({
        status: idx === '' ? idx : parseInt(idx),
        page_index: page_index,
        page_size: 10,
    }).then(rs => {
        if (rs && rs.result_code === '000') {
            if (rs?.data?.length) {
                pagingData.value = {
                    total_page: rs?.data[0].total_page,
                    page_index: rs?.data[0].page_index
                };
            } else {
                pagingData.value = {
                    total_page: 0,
                    page_index: 0
                };
            }
            purchaseHistories.value = convertPerpetualPacket(rs);
        }
    });

</script>

<template>
    <div>
        <ul class="nav justify-content-center tab-primary" id="myHistory" role="tablist">
            <li class="nav-item" role="presentation" v-for="tab in TABS">
                <button :class="`nav-link ${currentTab == tab ? 'active' : ''}`" @click="setTab(tab)" id="all-tab"
                    data-bs-toggle="tab" type="button" role="tab" :aria-controls="tab" aria-selected="true">
                    {{ tab }}
                </button>
            </li>
        </ul>
        <ServicePurchaseHistoryTable :data="purchaseHistories" :status-icon="statusIcon" />
        <PageComponent v-if="pagingData.total_page > 0" @onIndexChange="onIndexChange"
            :totalPages="pagingData.total_page" />
    </div>
</template>