<style setup scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>

const props = defineProps<{
    type: TitleType,
    icon: string,
    title: string,
    classProps: string,
}>();

</script>

<script lang="ts">

export enum TitleType  {
    Metal= "kim",
    Wood = "moc",
    Water = "thuy",
    Fire = "hoa",
    Earth = "tho"
}


</script>

<template>   
    <div :class="`container-fluid ${classProps ? classProps :''}`">        
        <header>
            <slot name="topSlot"></slot>
        </header>
        <div class="title-group" :class="type">
            <div class="text-start me-auto">
                <img :src="props.icon" class="figure-img rounded" alt="hình">
                <h3 class="title">{{ title }}</h3>
            </div>
            <slot name="botSlot"></slot>
        </div>        
        <hr />
    </div>
</template>