
import vinaIcon from '@/assets/images/img-vina.png';
import viettelIcon from '@/assets/images/img-viettel.png';
import mobiIcon from '@/assets/images/img-mobi.png';

//five element
import metalIcon from '@/assets/images/img-kim.png';
import woodIcon from '@/assets/images/img-moc.png'
import waterIcon from '@/assets/images/img-thuy.png'
import fireIcon from '@/assets/images/img-hoa.png'
import earthIcon from '@/assets/images/img-tho.png'

// '../../../src/assets/images/icons/ic-son.svg',
//   '../../../src/assets/images/icons/ic-daughter.svg',
//   '../../../src/assets/images/icons/ic-money.svg',
//   '../../../src/assets/images/icons/ic-marriage.svg',
//   '../../../src/assets/images/icons/ic-law.svg',
//   '../../../src/assets/images/icons/ic-career.svg',
//   '../../../src/assets/images/icons/ic-father.svg',
//   '../../../src/assets/images/icons/ic-mother.svg',
//   '../../../src/assets/images/icons/ic-healthy.svg',
//   '../../../src/assets/images/icons/ic-sibling.svg'];

//wealths
import moneyIcon from '@/assets/images/icons/ic-money.svg'
import careerIcon from '@/assets/images/icons/ic-career.svg'
import healthyIcon from '@/assets/images/icons/ic-healthy.svg'
import marriageIcon from '@/assets/images/icons/ic-marriage.svg'
import daughterIcon from '@/assets/images/icons/ic-daughter.svg'
import sonIcon from '@/assets/images/icons/ic-son.svg'
import lawIcon from '@/assets/images/icons/ic-law.svg'
import fatherIcon from '@/assets/images/icons/ic-father.svg'
import motherIcon from '@/assets/images/icons/ic-mother.svg'
import siblingIcon from '@/assets/images/icons/ic-sibling.svg'

//shoppings
import iconAddToCart from '@/assets/images/icons/ic-add-to-cart.svg'

export const mobileNetworkIcons = {
  vinaIcon: vinaIcon,
  viettelIcon: viettelIcon,
  mobiIcon: mobiIcon,
}

export const fiveElementIcons = {
  metalIcon: metalIcon,
  woodIcon: woodIcon,
  waterIcon: waterIcon,
  fireIcon: fireIcon,
  earthIcon: earthIcon,
}

export const wealthIcons = {
  moneyIcon: moneyIcon,
  careerIcon: careerIcon,
  healthyIcon: healthyIcon,
  marriageIcon: marriageIcon,
  daughterIcon: daughterIcon,
  sonIcon: sonIcon,
  lawIcon: lawIcon,
  fatherIcon: fatherIcon,
  motherIcon: motherIcon,
  siblingIcon: siblingIcon,
}

export const shoppingIcons = {
  iconAddToCart: iconAddToCart,
}