<script lang="ts" setup>
import store from '@/store';
import type { UserProfileSate } from '@/store/userProfile/state';
import { computed } from 'vue';
import defaultAvatar from '@/assets/images/Avatar.png'
import moment from 'moment';
import router from '@/router';
import { routerPaths } from '@/router/routes';
</script>

<script lang="ts">
const userProfile = computed(() => store.state.userProfile)

function logOut() {
    localStorage.clear();
    store.commit('userProfile/setProfile', null)
    router.push('/')
}
</script>

<template>
    <div class="account-left">
        <div class="card member-card">
            <div class="circle1"></div>
            <div class="circle2"></div>
            <div class="wavy">
                <svg width="280" height="94" viewBox="0 0 280 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_55926_131532)">
                        <path d="M60.6036 7.52478C81.659 4.505 103.914 7.49047 120.58 11.1083C138.458 14.9891 156.412 19.0551 174.676 20.0981C208.379 22.0229 242.069 16.0863 273.084 2.75728L279.5 0L288 94.172H0L2.12695 78.4783C2.37549 76.6443 2.70239 74.8227 3.19614 73.039C6.50632 61.0807 22.12 13.0441 60.6036 7.52478Z" fill="url(#paint0_linear_55926_131532)" fill-opacity="0.24" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_55926_131532" x1="176.5" y1="84.949" x2="-11.726" y2="-37.5539" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                        <clipPath id="clip0_55926_131532">
                            <rect width="280" height="94" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </div>
            <div class="card-body">
                <div class="account-info">
                    <div class="left">
                        <h5 class="name" id="lbl_kh_ten">{{ userProfile.fullName }}</h5>
                        <div class="point">
                            <div class="icon rounded-pill">
                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.3125 9.84375H2.1875C2.01562 9.84375 1.875 10.002 1.875 10.1953V10.8984C1.875 11.0918 2.01562 11.25 2.1875 11.25H10.3125C10.4844 11.25 10.625 11.0918 10.625 10.8984V10.1953C10.625 10.002 10.4844 9.84375 10.3125 9.84375ZM11.5625 2.8125C11.0449 2.8125 10.625 3.28491 10.625 3.86719C10.625 4.02319 10.6563 4.16821 10.7109 4.30225L9.29688 5.25586C8.99609 5.45801 8.60742 5.34375 8.43359 5.00098L6.8418 1.86768C7.05078 1.67432 7.1875 1.38428 7.1875 1.05469C7.1875 0.472412 6.76758 0 6.25 0C5.73242 0 5.3125 0.472412 5.3125 1.05469C5.3125 1.38428 5.44922 1.67432 5.6582 1.86768L4.06641 5.00098C3.89258 5.34375 3.50195 5.45801 3.20312 5.25586L1.79102 4.30225C1.84375 4.17041 1.87695 4.02319 1.87695 3.86719C1.87695 3.28491 1.45703 2.8125 0.939453 2.8125C0.421875 2.8125 0 3.28491 0 3.86719C0 4.44946 0.419922 4.92188 0.9375 4.92188C0.988281 4.92188 1.03906 4.91309 1.08789 4.9043L2.5 9.14062H10L11.4121 4.9043C11.4609 4.91309 11.5117 4.92188 11.5625 4.92188C12.0801 4.92188 12.5 4.44946 12.5 3.86719C12.5 3.28491 12.0801 2.8125 11.5625 2.8125Z" fill="white" />
                                </svg>
                            </div>
                            <strong id="st_point">{{ userProfile.customerPoint }}</strong> <span>Points</span>
                        </div>
                        <RouterLink :to="routerPaths.PacketService" class="btn btn-sm btn-light bg-white text-danger rounded-pill">
                            Mua thêm gói dịch vụ
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="8.30859" cy="7.80859" r="7.30859" fill="white" stroke="#C61914" />
                                <path d="M11.0044 8.16215C11.1997 7.96689 11.1997 7.6503 11.0044 7.45504L7.82245 4.27306C7.62719 4.0778 7.31061 4.0778 7.11535 4.27306C6.92008 4.46832 6.92008 4.7849 7.11535 4.98017L9.94377 7.80859L7.11534 10.637C6.92008 10.8323 6.92008 11.1489 7.11534 11.3441C7.31061 11.5394 7.62719 11.5394 7.82245 11.3441L11.0044 8.16215ZM5.77051 8.30859L10.6509 8.30859L10.6509 7.30859L5.77051 7.30859L5.77051 8.30859Z" fill="#C61914" />
                            </svg>
                        </RouterLink>
                    </div>
                    <div class="right ratio ratio-1x1">
                        <img :src="userProfile.avatar || defaultAvatar" id="img_avatar" alt="mdo" width="32" height="32" class="rounded-4" />
                    </div>
                </div>
                <div class="row account-level">
                    <div class="col">
                        <p class="name">Hạng thành viên</p>
                        <p class="text">{{ userProfile.serviceName }}</p>
                    </div>
                    <div class="col">
                        <p class="name">Lượt xem còn lại</p>
                        <p class="text">{{ userProfile.totalRemainingChartView }}</p>
                    </div>
                    <div class="col">
                        <p class="name">Ngày hết hạn</p>
                        <p class="text">{{ moment(userProfile.expireDate).format('DD/MM/YYYY') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-2 border rounded-2 mt-3 nav-account">
            <ul class="list-group list-group-flush">
                <li class="list-group-item list-group-item-action">
                    <RouterLink class="dropdown-item d-flex align-items-center" :to="routerPaths.UserInfo">
                        <img src="@/assets/images/icons/ic-User.svg" width="24" alt="icon ">
                        <span class="ms-2 me-auto">Thông tin tài
                            khoản</span>
                        <div class="text-end">
                            <img src="@/assets/images/icons/ic-Right.svg" width="14" alt="icon ">
                        </div>
                    </RouterLink>
                </li>
                <li class="list-group-item list-group-item-action">
                    <RouterLink class="dropdown-item d-flex align-items-center" :to="routerPaths.PurchaseHistory">
                        <img src="@/assets/images/icons/ic-History.svg" width="24" alt="icon ">
                        <span class="ms-2 me-auto">Lịch sử mua
                            hàng</span>
                        <div class="text-end">
                            <img src="@/assets/images/icons/ic-Right.svg" width="14" alt="icon ">
                        </div>
                    </RouterLink>
                </li>
                <li class="list-group-item list-group-item-action">
                    <RouterLink class="dropdown-item d-flex align-items-center" :to="routerPaths.PacketService">
                        <img src="@/assets/images/icons/ic-service.svg" width="24" alt="icon ">
                        <span class="ms-2 me-auto">Gói dịch vụ</span>
                        <div class="text-end">
                            <img src="@/assets/images/icons/ic-Right.svg" width="14" alt="icon ">
                        </div>
                    </RouterLink>
                </li>
                <li class="list-group-item list-group-item-action">
                    <a class="dropdown-item d-flex align-items-center" @click="logOut">
                        <img src="//thaysocaivan.com/assets/images/icons/ic-logout.svg" width="24" alt="icon ">
                        <span class="ms-2 me-auto">Đăng xuất</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>