<style setup>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";

.inactive {
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   right: 0;
   bottom: 0;
   top: 0;
   background-color: #dce1e1a6;
}
</style>

<script lang="ts" setup>
export interface DateItemData {
   DateDescription: string,
   InActiveDate: number,
   HightLightDate: number,
   SolarDateSort: string,
   HiddenImageUrl: string,
   DateName: string,
   SolarDateColor: string,
   SolarDate: number,
   LunarDateColor: string,
   LunarDate: string,
   WealthIcon: string,
   WealthColor: string,
   WealthTitle: string,
   WealthPercent: number,
}

const props = defineProps<{
   dateData: DateItemData,
   isSelect: boolean,
}>();


const emits = defineEmits<{
   (event: 'onItemClick', date: string): void
}>();

const getItemCalendar = (date: string) => {
   emits('onItemClick', date);
}
</script>

<template>
   <div class="cell" :class="`${isSelect ? 'active' : ''}`"
      :style="`background-image: url(${dateData.HiddenImageUrl}); cursor: pointer;`"
      v-on:click="getItemCalendar(dateData.SolarDateSort.replace(' ', ''))" title="Nhấp để xem chi tiết">
      <p class="date"><span>Ngày</span> {{ dateData.DateName.replace('Ngày', '') }}</p>
      <p class="des">({{ dateData.DateDescription }})</p>
      <p class="number" :style="{ color: dateData.SolarDateColor }">{{ dateData.SolarDate }}</p>
      <p class="lunar" :style="{ color: dateData.LunarDateColor }">{{ dateData.LunarDate }}</p>
      <div class="wealth">
         <img :src="dateData.WealthIcon" width="12px" alt="coin">
         <span class="name" :style="{ color: dateData.WealthColor }">{{ dateData.WealthTitle }}</span>
         <div :title="dateData.WealthPercent.toString() + '%'" class="progress" role="progressbar"
            :aria-valuenow="dateData.WealthPercent" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar bg-warning"
               :style="{ width: `${dateData.WealthPercent}%`, 'background-color': `${dateData.WealthColor} !important` }">
            </div>
         </div>
      </div>
      <div :class="`${(dateData.InActiveDate === 1) ? 'inactive' : ''}`"></div>
   </div>
</template>