<style setup scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";

.form-select-enhancement {
   padding-left: 3px;
   padding-right: 3px;
   color: white;
   background-color: black;
   text-align: left;
}
</style>

<script lang="ts" setup>
import { PopupController, PoupType } from '@/lib/popup';
import { getDates, getTime, getYears } from '@/lib/utils/common';
import InfomationComponent, { type Infomation } from '../NameMeaning/InfomationComponent.vue';
import { computed, nextTick, onMounted, ref, useTemplateRef } from 'vue';
import ChartComponent, { type chartContenData } from '../NameMeaning/ChartComponent.vue';
import SimTableComponentSlider from '../SimTableComponentSlider.vue';
import GroupTitle, { TitleType } from '../NameMeaning/GroupTitle.vue';
import { ErrorMessage } from '@/lib/utils/ErrorMessage';
import Swal from 'sweetalert2';
import store from '@/store';
import type { GenderAndCity } from '@/store/genderAndCity/state';
import SimChangeFortuneComponent, { type SimChangeFortuneData } from './SimChangeFortuneComponent.vue';
import { RepositoryFactory } from '@/lib/https';
import ChangeFortuneRepository from '@/lib/https/ChangeFortuneRepository';
import type { IChangeFortuneParam, IPhoneNumberAccordingToCriteriaReq } from '@/models/https/changeFortuneRequests';
import type { IChangeFortuneRes } from '@/models/https/changeFortuneResponse';
import PageComponent from '../PageComponent.vue';
import type { UserProfileSate } from '@/store/userProfile/state';

import { wealthIcons } from '@/lib/utils/imageUtils';

export interface FormData {
   Title: string,
   Description: string,
   SpecialInputLabel: string,
   SpecialInputPlaceHolder: string,
   ChangeFortunePlaceHoder: string,
   FormType: FormType,
   SpecialInputId: string,
}

const userProfile = computed(() => store.state.userProfile as UserProfileSate);

const infomation = ref<Infomation>({
   customer: "Nam - Sinh ngày 05/03/2019(29/1/2019 Âm lịch)",
   sexagenaryCycleYear: "KỶ HỢI",
   elementalNature: "BÌNH ĐỊA MỘC",
   zodiacIcon: "https://thaysocaivan.com/assets/images/img-tuoi-hoi.png",
   asianZodiac: "Hợi",
})

const chartRef = ref();
const chartData = ref<chartContenData>({
   fileElementLabels: ["moc", "hoa", "tho", "kim", "thuy"],
   fiveElement: [25, 25, 25, 12.5, 12.5],
   lineChart: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
   name: "Minh",
   placeHolderInput: "Nhập tên cải vận",
   prefixedLabelChartLine: "Biểu đồ tên",
   errorInput: ErrorMessage.InputPhoneNumberChangeFortuneWrong
});

const props = defineProps<{
   formdata: FormData
}>()

const isPhoneNumberForm = () => {
   return props.formdata.FormType === FormType.PhoneNumber;
}

const isLisencePlate = () => {
   return props.formdata.FormType === FormType.LicensePlate;
}

const isBankAccountNumber = () => {
   return props.formdata.FormType === FormType.BankAccountNumber;
}

const isHouseNumber = () => {
   return props.formdata.FormType === FormType.HouseNumber;
}

const { searchWithPhoneNumber,
   searchWithLisencePlate,
   searchWithBankAccountNumber,
   searchWithHouseNumber,
   getPhoneNumberAccordingToCriteria }
   = RepositoryFactory.getRepository<ChangeFortuneRepository>(ChangeFortuneRepository);

const gendersAnCitys = computed(() => store.state.genderAndCity as GenderAndCity);
const genderValues = computed(() => gendersAnCitys.value.genders);
const citys = computed(() => gendersAnCitys.value.citys);

const isShowResult = ref<boolean>(false);

const nameInput = useTemplateRef("txtName");
const specialInput = useTemplateRef("txtSpecial")
const genderInput = useTemplateRef("select_gioi_tinh");
const cityOfBirth = useTemplateRef("select_tinh_sinh");
const cityOfResidence = useTemplateRef("select_tinh_o");
const yearValues = ref(getYears());
const yearRef = useTemplateRef("year");
const monthRef = useTemplateRef("month");
const dateRef = useTemplateRef("day");
const hours = getTime();
const hourRef = useTemplateRef("txt_gio_sinh");
const dates = ref<Array<number>>([]);

const wealthSimDatas = ref<Array<SimChangeFortuneData>>([]);
const totalPageWealth = ref<number>(0);
const totalSimsWealth = ref<number>(0);
const successSimDatas = ref<Array<SimChangeFortuneData>>([]);
const totalPageSuccess = ref<number>(0);
const totalSimsSuccess = ref<number>(0);
const healthSimDatas = ref<Array<SimChangeFortuneData>>([]);
const totalPageHealth = ref<number>(0);
const totalSimsHealth = ref<number>(0);
const marriageSimDatas = ref<Array<SimChangeFortuneData>>([]);
const totalPageMariage = ref<number>(0);
const totalSimsMariage = ref<number>(0);
let phoneNumberAccordant: {
   Wealth: number,
   Success: number,
   Health: number,
   Marriage: number,
} = {
   Wealth: 0,
   Success: 0,
   Health: 0,
   Marriage: 0,
};

const onYearChange = () => {
   if (monthRef.value) {
      const isDisabled = yearRef.value?.value === "" || yearRef.value?.value === "-1";
      (monthRef.value as HTMLSelectElement).disabled = isDisabled;
      if (isDisabled) {
         (monthRef.value as HTMLSelectElement).value = "0";
         (dateRef.value as HTMLSelectElement).value = "0";
         (hourRef.value as HTMLSelectElement).value = "-1";
         (dateRef.value as HTMLSelectElement).disabled = isDisabled;
         (hourRef.value as HTMLSelectElement).disabled = isDisabled;
      }
   }
}

const onMonthChange = () => {
   if (dateRef.value) {
      const isDisabled = monthRef.value?.value === "0";
      (dateRef.value as HTMLSelectElement).disabled = isDisabled;
      if (isDisabled) {
         (dateRef.value as HTMLSelectElement).value = "0";
         (hourRef.value as HTMLSelectElement).value = "-1";
         (hourRef.value as HTMLSelectElement).disabled = isDisabled;
      }
      else {
         dates.value = getDates(parseInt(monthRef.value?.value ?? '0'));
      }
   }
}

const ondateChange = () => {
   if (hourRef.value) {
      const isDisabled = dateRef.value?.value === "-1" || dateRef.value?.value === "0";
      (hourRef.value as HTMLSelectElement).disabled = isDisabled;
      if (isDisabled) {
         (hourRef.value as HTMLSelectElement).value = "-1";
      }
   }
}


const validValues = (specialInputError: string = ErrorMessage.InputPhoneNumberWrong) => {
   const genderValue = genderInput.value?.value ?? "-1";
   const nameValue = nameInput.value?.value ?? "";
   const birthYearValue = yearRef.value?.value ?? "0";
   const specialInputValue = specialInput.value?.value ?? "";
   let error: any = undefined;
   if (!nameValue || nameValue === "") {
      error = {
         icon: "error",
         title: ErrorMessage.InputNameWrong,
      }
   }
   else if (!genderValue || genderValue === "-1") {
      error = {
         icon: "error",
         title: ErrorMessage.InputGenderWrong,
      }
   }
   else if (!birthYearValue || birthYearValue === "0" || birthYearValue === "-1") {
      error = {
         icon: "error",
         title: ErrorMessage.InputBirthYearWrong,
      }
   }
   else if (!specialInputValue || specialInputValue === "") {
      error = {
         icon: "error",
         title: specialInputError,
      }
   }

   if (error) {
      Swal.fire(error);
      return { error: true };
   }

   return {
      error: false,
      genderValue: genderValue,
      nameValue: nameValue,
      birthYearValue: birthYearValue,
      specialInputValue: specialInputValue,
   }
}
const validRemainingView = () => {
    if (userProfile.value.totalRemainingChartView > 0) {    
        return true;
    }
    else {
        PopupController.pushPopup(PoupType.BuyChartViewPopupComponent, { description: null });       
        return false;
    }
}

const convertData = (dataBE: any | undefined) => {

   const uiData: SimChangeFortuneData = {
      FiveElements: dataBE.ngu_hanh_ten,
      Price: dataBE.gia_tien,
      PhoneNumber: dataBE.sim_number,
      MobileNetworkProvider: dataBE.nha_mang
   }
   return uiData;

}

const initListPhoneNumbers = (phoneNumberAccordant: {
   Wealth: number,
   Success: number,
   Health: number,
   Marriage: number,
}) => {
   const param: IPhoneNumberAccordingToCriteriaReq = {
      tieu_chi_ma: 1,
      menh_ma: phoneNumberAccordant.Wealth,
      page: 1,
      gia_tien_sort: 0,
      tieu_chi_sort: 0,
   }
   getPhoneNumberAccordingToCriteria(param).then(rs => {
      if (rs?.result_code === "000") {
         wealthSimDatas.value = rs.data.flatMap(d => convertData(d));
         totalPageWealth.value = rs.data[0].total_page;
         totalSimsWealth.value = rs.data[0].total_rows;
      }
   });

   param.tieu_chi_ma = 2;
   param.menh_ma = phoneNumberAccordant.Success;
   getPhoneNumberAccordingToCriteria(param).then(rs => {
      if (rs?.result_code === "000") {
         successSimDatas.value = rs.data.flatMap(d => convertData(d));
         totalPageSuccess.value = rs.data[0].total_page;
         totalSimsSuccess.value = rs.data[0].total_rows;
      }
   });

   param.tieu_chi_ma = 3;
   param.menh_ma = phoneNumberAccordant.Health;
   getPhoneNumberAccordingToCriteria(param).then(rs => {
      if (rs?.result_code === "000") {
         healthSimDatas.value = rs.data.flatMap(d => convertData(d));
         totalPageHealth.value = rs.data[0].total_page;
         totalSimsHealth.value = rs.data[0].total_rows;
      }
   });

   param.tieu_chi_ma = 4;
   param.menh_ma = phoneNumberAccordant.Marriage;
   getPhoneNumberAccordingToCriteria(param).then(rs => {
      if (rs?.result_code === "000") {
         marriageSimDatas.value = rs.data.flatMap(d => convertData(d));
         totalPageMariage.value = rs.data[0].total_page;
         totalSimsMariage.value = rs.data[0].total_rows;
      }
   })
   resetWealthPage();
   resetSuccessPage();
   resetHealthPage();
   resetMarriagePage();
}

const updateDataInfo = (res: IChangeFortuneRes | null) => {
   infomation.value.customer = res?.data.thong_tin.than_chu ?? "failed!";
   infomation.value.asianZodiac = res?.data.thong_tin.con_giap ?? "failed!";
   infomation.value.elementalNature = res?.data.thong_tin.nap_am ?? "failed!";
   infomation.value.sexagenaryCycleYear = res?.data.thong_tin.nam_can_chi ?? "failed!";
   infomation.value.zodiacIcon = res?.data.thong_tin.anh_dai_dien ?? "failed";
}

const validSigninOrSignUp = () => {
    if (userProfile.value.id >= 0) {
        return true;
    }
    else {
        PopupController.pushPopup(PoupType.SignUpPopupComponent, { description: null });
        return false;
    }
}

const searchByPhoneNumber = () => {
   if(!validSigninOrSignUp()) return;
   if(!validRemainingView()) return;
   const rs = validValues(ErrorMessage.InputPhoneNumberWrong);
   if (rs.error) return;
   const genderValue = rs.genderValue ?? "";
   const nameValue = rs.nameValue ?? "";
   const birthYearValue = rs.birthYearValue ?? "";
   const specialInputValue = rs.specialInputValue ?? "";

   const rqParam: IChangeFortuneParam = {
      ho_ten: nameValue,
      gioi_tinh: parseInt(genderValue),
      ngay_sinh_duong_lich: "",
      month: parseInt(monthRef.value?.value ?? "0"),
      day: parseInt(dateRef.value?.value ?? "0"),
      year: parseInt(birthYearValue),
      sim_cai_van: "",
      sim_cu: specialInputValue,
      gio_sinh: `${hourRef.value?.value ?? "-1"}:00`,
      nha_mang: null,
      thanh_pho_sinh: parseInt(cityOfBirth.value?.value ?? "-1"),
      thanh_pho_o: parseInt(cityOfResidence.value?.value ?? "-1"),
   }

   searchWithPhoneNumber(rqParam).then(rs => {
      if (rs?.result_code === "000") {
         updateDataInfo(rs);

         chartData.value.fiveElement = Object.values(rs?.data.chart_ngu_hanh ?? [])
         const chartShow = rs?.data.chart_line[rs?.data.chart_line.length - 1];
         delete (chartShow as any).loai;
         chartData.value.lineChart = Object.values(chartShow ?? []) as number[];
         chartData.value.prefixedLabelChartLine = "Biểu đồ số";
         chartData.value.name = rqParam.sim_cu;
         chartData.value.errorInput = ErrorMessage.InputPhoneNumberChangeFortuneWrong,

            chartRef.value?.updateChart(chartData.value.lineChart, chartData.value.name);
         chartRef.value?.updateChartFiveElement(chartData.value.fiveElement);
         phoneNumberAccordant = {
            Wealth: rs.data.sim_phu_hop.ngu_hanh_ma_tientai,
            Success: rs.data.sim_phu_hop.ngu_hanh_ma_congdanh,
            Health: rs.data.sim_phu_hop.ngu_hanh_ma_suckhoe,
            Marriage: rs.data.sim_phu_hop.ngu_hanh_ma_honnhan
         };
         //Inits
         initListPhoneNumbers(phoneNumberAccordant);
         isShowResult.value = true;
         nextTick().then(() => {
            chartRef.value?.$el.scrollIntoView({ behavior: "smooth" });
         });
      }
      else {
         const error: any = {
            icon: "error",
            title: rs?.result_msg,
         }
         Swal.fire(error);
      }
   }).catch();
}

const searchByLisencePlate = () => {
   if(!validSigninOrSignUp()) return;
   if(!validRemainingView()) return;
   const rs = validValues(ErrorMessage.InputLisencePlateWrong);
   if (rs.error) return;
   const genderValue = rs.genderValue ?? "";
   const nameValue = rs.nameValue ?? "";
   const birthYearValue = rs.birthYearValue ?? "";

   const rqParam: IChangeFortuneParam = {
      ho_ten: nameValue,
      gioi_tinh: parseInt(genderValue),
      ngay_sinh_duong_lich: "",
      month: parseInt(monthRef.value?.value ?? "0"),
      day: parseInt(dateRef.value?.value ?? "0"),
      year: parseInt(birthYearValue),
      sim_cai_van: "",
      sim_cu: specialInput.value?.value ?? "",
      gio_sinh: `${hourRef.value?.value ?? "-1"}:00`,
      nha_mang: null,
      thanh_pho_sinh: parseInt(cityOfBirth.value?.value ?? "-1"),
      thanh_pho_o: parseInt(cityOfResidence.value?.value ?? "-1"),
   }

   searchWithLisencePlate(rqParam).then(rs => {
      if (rs?.result_code === "000") {
         updateDataInfo(rs);

         chartData.value.fiveElement = Object.values(rs?.data.chart_ngu_hanh ?? [])
         const chartShow = rs?.data.chart_line[rs?.data.chart_line.length - 1];
         delete (chartShow as any).loai;
         chartData.value.lineChart = Object.values(chartShow ?? []) as number[];
         chartData.value.prefixedLabelChartLine = "Biểu đồ số";
         chartData.value.name = rqParam.sim_cu;
         chartData.value.errorInput = ErrorMessage.InputLisencePlateChangeFortuneWrong;

         chartRef.value?.updateChart(chartData.value.lineChart, chartData.value.name);
         chartRef.value?.updateChartFiveElement(chartData.value.fiveElement);
         isShowResult.value = true;
         nextTick().then(() => {
            chartRef.value?.$el.scrollIntoView({ behavior: "smooth" });
         });
      }
      else {
         const error: any = {
            icon: "error",
            title: rs?.result_msg,
         }
         Swal.fire(error);
      }
   }).catch();
}

const searchByBankAccountNumber = () => {
   if(!validSigninOrSignUp()) return;
   if(!validRemainingView()) return;
   const rs = validValues(ErrorMessage.InputBankAccountNumberWrong);
   if (rs.error) return;
   const genderValue = rs.genderValue ?? "";
   const nameValue = rs.nameValue ?? "";
   const birthYearValue = rs.birthYearValue ?? "";

   const rqParam: IChangeFortuneParam = {
      ho_ten: nameValue,
      gioi_tinh: parseInt(genderValue),
      ngay_sinh_duong_lich: "",
      month: parseInt(monthRef.value?.value ?? "0"),
      day: parseInt(dateRef.value?.value ?? "0"),
      year: parseInt(birthYearValue),
      sim_cai_van: "",
      sim_cu: specialInput.value?.value ?? "",
      gio_sinh: `${hourRef.value?.value ?? "-1"}:00`,
      nha_mang: null,
      thanh_pho_sinh: parseInt(cityOfBirth.value?.value ?? "-1"),
      thanh_pho_o: parseInt(cityOfResidence.value?.value ?? "-1"),
   }

   searchWithBankAccountNumber(rqParam).then(rs => {
      if (rs?.result_code === "000") {
         updateDataInfo(rs);

         chartData.value.fiveElement = Object.values(rs?.data.chart_ngu_hanh ?? [])
         const chartShow = rs?.data.chart_line[rs?.data.chart_line.length - 1];
         delete (chartShow as any).loai;
         chartData.value.lineChart = Object.values(chartShow ?? []) as number[];
         chartData.value.prefixedLabelChartLine = "Biểu đồ số";
         chartData.value.name = rqParam.sim_cu;
         chartData.value.errorInput = ErrorMessage.InputBankAccountNumberChangeFortuneWrong,

            chartRef.value?.updateChart(chartData.value.lineChart, chartData.value.name);
         chartRef.value?.updateChartFiveElement(chartData.value.fiveElement);
         isShowResult.value = true;
         nextTick().then(() => {
            chartRef.value?.$el.scrollIntoView({ behavior: "smooth" });
         });
      }
      else {
         const error: any = {
            icon: "error",
            title: rs?.result_msg,
         }
         Swal.fire(error);
      }
   }).catch();
}

const searchByHouseNumber = () => {
   if(!validSigninOrSignUp()) return;
   if(!validRemainingView()) return;
   const rs = validValues(ErrorMessage.InputHouseNumberWrong);
   if (rs.error) return;
   const genderValue = rs.genderValue ?? "";
   const nameValue = rs.nameValue ?? "";
   const birthYearValue = rs.birthYearValue ?? "";

   const rqParam: IChangeFortuneParam = {
      ho_ten: nameValue,
      gioi_tinh: parseInt(genderValue),
      ngay_sinh_duong_lich: "",
      month: parseInt(monthRef.value?.value ?? "0"),
      day: parseInt(dateRef.value?.value ?? "0"),
      year: parseInt(birthYearValue),
      sim_cai_van: "",
      sim_cu: specialInput.value?.value ?? "",
      gio_sinh: `${hourRef.value?.value ?? "-1"}:00`,
      nha_mang: null,
      thanh_pho_sinh: parseInt(cityOfBirth.value?.value ?? "-1"),
      thanh_pho_o: parseInt(cityOfResidence.value?.value ?? "-1"),
   }

   searchWithHouseNumber(rqParam).then(rs => {
      if (rs?.result_code === "000") {
         updateDataInfo(rs);

         chartData.value.fiveElement = Object.values(rs?.data.chart_ngu_hanh ?? [])
         const chartShow = rs?.data.chart_line[rs?.data.chart_line.length - 1];
         delete (chartShow as any).loai;
         chartData.value.lineChart = Object.values(chartShow ?? []) as number[];
         chartData.value.prefixedLabelChartLine = "Biểu đồ số";
         chartData.value.name = rqParam.sim_cu;
         chartData.value.errorInput = ErrorMessage.InputHouseNumberChangeFortuneWrong;

         chartRef.value?.updateChart(chartData.value.lineChart, chartData.value.name);
         chartRef.value?.updateChartFiveElement(chartData.value.fiveElement);
         isShowResult.value = true;
         nextTick().then(() => {
            chartRef.value?.$el.scrollIntoView({ behavior: "smooth" });
         });
      }
      else {
         const error: any = {
            icon: "error",
            title: rs?.result_msg,
         }
         Swal.fire(error);
      }
   }).catch();
}

const search = () => {
   if (isPhoneNumberForm()) {
      searchByPhoneNumber();
   }
   else if (isLisencePlate()) {
      searchByLisencePlate();
   }
   else if (isBankAccountNumber()) {
      searchByBankAccountNumber();
   }
   else if (isHouseNumber()) {
      searchByHouseNumber();
   }
}

const changeFortuneWithPhoneNumber = (input: string) => {
   if(!validRemainingView()) return;
   const rs = validValues(ErrorMessage.InputPhoneNumberWrong);
   if (rs.error) return;
   const genderValue = rs.genderValue ?? "";
   const nameValue = rs.nameValue ?? "";
   const birthYearValue = rs.birthYearValue ?? "";

   const rqParam: IChangeFortuneParam = {
      ho_ten: nameValue,
      gioi_tinh: parseInt(genderValue),
      ngay_sinh_duong_lich: "",
      month: parseInt(monthRef.value?.value ?? "0"),
      day: parseInt(dateRef.value?.value ?? "0"),
      year: parseInt(birthYearValue),
      sim_cai_van: input,
      sim_cu: specialInput.value?.value ?? "",
      gio_sinh: `${hourRef.value?.value ?? "-1"}:00`,
      nha_mang: null,
      thanh_pho_sinh: parseInt(cityOfBirth.value?.value ?? "-1"),
      thanh_pho_o: parseInt(cityOfResidence.value?.value ?? "-1"),
   }

   searchWithPhoneNumber(rqParam).then(res => {
      if (res?.result_code === "000") {

         updateDataInfo(res);

         chartData.value.fiveElement = Object.values(res?.data.chart_ngu_hanh ?? [])
         const chartShow = res?.data.chart_line[0];
         delete (chartShow as any).loai;
         chartData.value.lineChart = Object.values(chartShow ?? []) as number[];
         chartData.value.name = rqParam.sim_cu;
         const charData2 = res?.data.chart_line[1];
         delete (charData2 as any).loai;
         chartRef.value?.updateMultipleChart(chartData.value.lineChart, chartData.value.name, Object.values(charData2 ?? []), input);
         chartRef.value?.updateChartFiveElement(chartData.value.fiveElement);
         chartRef.value?.$el.scrollIntoView({ behavior: "smooth" });
      }
      else {
         const error: any = {
            icon: "error",
            title: res?.result_msg,
         }
         Swal.fire(error);
      }
   });
}

const changeFortuneWithLisencePlate = (input: string) => {
   if(!validRemainingView()) return;
   const rs = validValues(ErrorMessage.InputLisencePlateWrong);
   if (rs.error) return;
   const genderValue = rs.genderValue ?? "";
   const nameValue = rs.nameValue ?? "";
   const birthYearValue = rs.birthYearValue ?? "";

   const rqParam: IChangeFortuneParam = {
      ho_ten: nameValue,
      gioi_tinh: parseInt(genderValue),
      ngay_sinh_duong_lich: "",
      month: parseInt(monthRef.value?.value ?? "0"),
      day: parseInt(dateRef.value?.value ?? "0"),
      year: parseInt(birthYearValue),
      sim_cai_van: input,
      sim_cu: specialInput.value?.value ?? "",
      gio_sinh: `${hourRef.value?.value ?? "-1"}:00`,
      nha_mang: null,
      thanh_pho_sinh: parseInt(cityOfBirth.value?.value ?? "-1"),
      thanh_pho_o: parseInt(cityOfResidence.value?.value ?? "-1"),
   }

   searchWithLisencePlate(rqParam).then(res => {
      if (res?.result_code === "000") {

         updateDataInfo(res);

         chartData.value.fiveElement = Object.values(res?.data.chart_ngu_hanh ?? [])
         const chartShow = res?.data.chart_line[0];
         delete (chartShow as any).loai;
         chartData.value.lineChart = Object.values(chartShow ?? []) as number[];
         chartData.value.name = rqParam.sim_cu;
         const charData2 = res?.data.chart_line[1];
         delete (charData2 as any).loai;
         chartRef.value?.updateMultipleChart(chartData.value.lineChart, chartData.value.name, Object.values(charData2 ?? []), input);
         chartRef.value?.updateChartFiveElement(chartData.value.fiveElement);
         chartRef.value?.$el.scrollIntoView({ behavior: "smooth" });
      }
      else {
         const error: any = {
            icon: "error",
            title: res?.result_msg,
         }
         Swal.fire(error);
      }
   });
}

const changeFortuneWithBankAccountNumber = (input: string) => {
   if(!validRemainingView()) return;
   const rs = validValues(ErrorMessage.InputLisencePlateWrong);
   if (rs.error) return;
   const genderValue = rs.genderValue ?? "";
   const nameValue = rs.nameValue ?? "";
   const birthYearValue = rs.birthYearValue ?? "";

   const rqParam: IChangeFortuneParam = {
      ho_ten: nameValue,
      gioi_tinh: parseInt(genderValue),
      ngay_sinh_duong_lich: "",
      month: parseInt(monthRef.value?.value ?? "0"),
      day: parseInt(dateRef.value?.value ?? "0"),
      year: parseInt(birthYearValue),
      sim_cai_van: input,
      sim_cu: specialInput.value?.value ?? "",
      gio_sinh: `${hourRef.value?.value ?? "-1"}:00`,
      nha_mang: null,
      thanh_pho_sinh: parseInt(cityOfBirth.value?.value ?? "-1"),
      thanh_pho_o: parseInt(cityOfResidence.value?.value ?? "-1"),
   }

   searchWithBankAccountNumber(rqParam).then(res => {
      if (res?.result_code === "000") {

         updateDataInfo(res);

         chartData.value.fiveElement = Object.values(res?.data.chart_ngu_hanh ?? [])
         const chartShow = res?.data.chart_line[0];
         delete (chartShow as any).loai;
         chartData.value.lineChart = Object.values(chartShow ?? []) as number[];
         chartData.value.name = rqParam.sim_cu;
         const charData2 = res?.data.chart_line[1];
         delete (charData2 as any).loai;
         chartRef.value?.updateMultipleChart(chartData.value.lineChart, chartData.value.name, Object.values(charData2 ?? []), input);
         chartRef.value?.updateChartFiveElement(chartData.value.fiveElement);
         chartRef.value?.$el.scrollIntoView({ behavior: "smooth" });
      }
      else {
         const error: any = {
            icon: "error",
            title: res?.result_msg,
         }
         Swal.fire(error);
      }
   });
}

const changeFortuneWithHouseNumber = (input: string) => {
   if(!validRemainingView()) return;
   const rs = validValues(ErrorMessage.InputLisencePlateWrong);
   if (rs.error) return;
   const genderValue = rs.genderValue ?? "";
   const nameValue = rs.nameValue ?? "";
   const birthYearValue = rs.birthYearValue ?? "";

   const rqParam: IChangeFortuneParam = {
      ho_ten: nameValue,
      gioi_tinh: parseInt(genderValue),
      ngay_sinh_duong_lich: "",
      month: parseInt(monthRef.value?.value ?? "0"),
      day: parseInt(dateRef.value?.value ?? "0"),
      year: parseInt(birthYearValue),
      sim_cai_van: input,
      sim_cu: specialInput.value?.value ?? "",
      gio_sinh: `${hourRef.value?.value ?? "-1"}:00`,
      nha_mang: null,
      thanh_pho_sinh: parseInt(cityOfBirth.value?.value ?? "-1"),
      thanh_pho_o: parseInt(cityOfResidence.value?.value ?? "-1"),
   }

   searchWithHouseNumber(rqParam).then(res => {
      if (res?.result_code === "000") {

         updateDataInfo(res);

         chartData.value.fiveElement = Object.values(res?.data.chart_ngu_hanh ?? [])
         const chartShow = res?.data.chart_line[0];
         delete (chartShow as any).loai;
         chartData.value.lineChart = Object.values(chartShow ?? []) as number[];
         chartData.value.name = rqParam.sim_cu;
         const charData2 = res?.data.chart_line[1];
         delete (charData2 as any).loai;
         chartRef.value?.updateMultipleChart(chartData.value.lineChart, chartData.value.name, Object.values(charData2 ?? []), input);
         chartRef.value?.updateChartFiveElement(chartData.value.fiveElement);
         chartRef.value?.$el.scrollIntoView({ behavior: "smooth" });
      }
      else {
         const error: any = {
            icon: "error",
            title: res?.result_msg,
         }
         Swal.fire(error);
      }
   });
}


const changeFortune = (input: string) => {
   if (isPhoneNumberForm()) {
      changeFortuneWithPhoneNumber(input);
   }
   else if (isLisencePlate()) {
      changeFortuneWithLisencePlate(input);
   }
   else if (isBankAccountNumber()) {
      changeFortuneWithBankAccountNumber(input);
   }
   else if(isHouseNumber())
   {
      changeFortuneWithHouseNumber(input);
   }
}

// Phone number funtions
const wealthSelect = ref();
const wealthPriceSelect = ref();

const successSelect = ref();
const successPriceSelect = ref();

const healthSelect = ref();
const healthPriceSelect = ref();

const marriageSelect = ref();
const marriagePriceSelect = ref();

const onWealthPageIndexChange = (index: number) => {
   const param: IPhoneNumberAccordingToCriteriaReq = {
      tieu_chi_ma: 1,
      menh_ma: phoneNumberAccordant.Wealth,
      page: index,
      gia_tien_sort: parseInt(wealthPriceSelect.value.value),
      tieu_chi_sort: parseInt(wealthSelect.value.value),
   }
   getPhoneNumberAccordingToCriteria(param).then(rs => {
      if (rs?.result_code === "000") {
         wealthSimDatas.value = rs.data.flatMap(d => convertData(d));
         totalPageWealth.value = rs.data[0].total_page;
         totalSimsWealth.value = rs.data[0].total_rows;
      }
   });
}

const pageWealth = ref();

const resetWealthPage = () => {
   if (pageHealth.value) {
      pageWealth.value.resetToFirstPage();
   }

}

const pageSuccess = ref();
const resetSuccessPage = () => {
   if (pageSuccess.value) {
      pageSuccess.value.resetToFirstPage();
   }
}

const pageHealth = ref();
const resetHealthPage = () => {
   if (pageHealth.value) {
      pageHealth.value.resetToFirstPage();
   }

}

const pageMarriage = ref();
const resetMarriagePage = () => {
   if (pageMarriage.value) {
      pageMarriage.value.resetToFirstPage();
   }

}

const onSuccessPageIndexChange = (index: number) => {
   const param: IPhoneNumberAccordingToCriteriaReq = {
      tieu_chi_ma: 2,
      menh_ma: phoneNumberAccordant.Success,
      page: index,
      gia_tien_sort: parseInt(successPriceSelect.value.value),
      tieu_chi_sort: parseInt(successSelect.value.value),
   }
   getPhoneNumberAccordingToCriteria(param).then(rs => {
      if (rs?.result_code === "000") {
         successSimDatas.value = rs.data.flatMap(d => convertData(d));
         totalPageSuccess.value = rs.data[0].total_page;
         totalSimsSuccess.value = rs.data[0].total_rows;
      }
   });
}

const onHealthPageIndexChange = (index: number) => {
   const param: IPhoneNumberAccordingToCriteriaReq = {
      tieu_chi_ma: 3,
      menh_ma: phoneNumberAccordant.Health,
      page: index,
      gia_tien_sort: parseInt(healthPriceSelect.value.value),
      tieu_chi_sort: parseInt(healthSelect.value.value),
   }
   getPhoneNumberAccordingToCriteria(param).then(rs => {
      if (rs?.result_code === "000") {
         healthSimDatas.value = rs.data.flatMap(d => convertData(d));
         totalPageHealth.value = rs.data[0].total_page;
         totalSimsHealth.value = rs.data[0].total_rows;
      }
   });
}

const onMarriagePageIndexChange = (index: number) => {
   const param: IPhoneNumberAccordingToCriteriaReq = {
      tieu_chi_ma: 4,
      menh_ma: phoneNumberAccordant.Marriage,
      page: index,
      gia_tien_sort: parseInt(marriagePriceSelect.value.value),
      tieu_chi_sort: parseInt(marriageSelect.value.value),
   }
   getPhoneNumberAccordingToCriteria(param).then(rs => {
      if (rs?.result_code === "000") {
         marriageSimDatas.value = rs.data.flatMap(d => convertData(d));
         totalPageMariage.value = rs.data[0].total_page;
         totalSimsMariage.value = rs.data[0].total_rows;
      }
   });
}

const onSelectWealthChange = () => {
   const param: IPhoneNumberAccordingToCriteriaReq = {
      tieu_chi_ma: 2,
      menh_ma: phoneNumberAccordant.Wealth,
      page: 1,
      gia_tien_sort: parseInt(wealthPriceSelect.value.value),
      tieu_chi_sort: parseInt(wealthSelect.value.value),
   }
   getPhoneNumberAccordingToCriteria(param).then(rs => {
      if (rs?.result_code === "000") {
         wealthSimDatas.value = rs.data.flatMap(d => convertData(d));
         totalPageWealth.value = rs.data[0].total_page;
         totalSimsWealth.value = rs.data[0].total_rows;
         resetWealthPage();
      }
   });
}

const onSelectSuccessChange = () => {
   const param: IPhoneNumberAccordingToCriteriaReq = {
      tieu_chi_ma: 2,
      menh_ma: phoneNumberAccordant.Success,
      page: 1,
      gia_tien_sort: parseInt(successPriceSelect.value.value),
      tieu_chi_sort: parseInt(successSelect.value.value),
   }
   getPhoneNumberAccordingToCriteria(param).then(rs => {
      if (rs?.result_code === "000") {
         successSimDatas.value = rs.data.flatMap(d => convertData(d));
         totalPageSuccess.value = rs.data[0].total_page;
         totalSimsSuccess.value = rs.data[0].total_rows;
         resetSuccessPage();
      }
   });
}

const onSelectHealthChange = () => {
   const param: IPhoneNumberAccordingToCriteriaReq = {
      tieu_chi_ma: 2,
      menh_ma: phoneNumberAccordant.Health,
      page: 1,
      gia_tien_sort: parseInt(healthPriceSelect.value.value),
      tieu_chi_sort: parseInt(healthSelect.value.value),
   }
   getPhoneNumberAccordingToCriteria(param).then(rs => {
      if (rs?.result_code === "000") {
         healthSimDatas.value = rs.data.flatMap(d => convertData(d));
         totalPageHealth.value = rs.data[0].total_page;
         totalSimsHealth.value = rs.data[0].total_rows;
         resetHealthPage();
      }
   });
}

const onSelectMarriageChange = () => {
   const param: IPhoneNumberAccordingToCriteriaReq = {
      tieu_chi_ma: 2,
      menh_ma: phoneNumberAccordant.Marriage,
      page: 1,
      gia_tien_sort: parseInt(marriagePriceSelect.value.value),
      tieu_chi_sort: parseInt(marriageSelect.value.value),
   }
   getPhoneNumberAccordingToCriteria(param).then(rs => {
      if (rs?.result_code === "000") {
         marriageSimDatas.value = rs.data.flatMap(d => convertData(d));
         totalPageMariage.value = rs.data[0].total_page;
         totalSimsMariage.value = rs.data[0].total_rows;
         resetMarriagePage();
      }
   });
}

onMounted(() => {
   chartData.value.placeHolderInput = props.formdata.ChangeFortunePlaceHoder;
});

</script>

<script lang="ts">
export enum FormType {
   PhoneNumber = "PhoneNumber",
   LicensePlate = "LicensePlate",
   BankAccountNumber = "BankAccountNumber",
   HouseNumber = "HouseNumber",
}

</script>

<template>
   <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
      <section class="section-page pt-4 section-changing-fortune linear">
         <div class="container-fluid">
            <h1 class="title-section mb-0 mb-sm-3">{{ formdata.Title }}</h1>
            <div class="row justify-content-center mt-3 mt-sm-0">
               <div class="col-12 col-sm-8 col-xl-6">
                  <div class="alert bg-theme text-white text-center fst-italic py-1 rounded-4 alert-changing-fortune"
                     role="alert">
                     <small>{{ formdata.Description }}</small>
                  </div>
               </div>
            </div>
            <div class="row justify-content-center">
               <div class="col-12 col-sm-9 col-xl-7">
                  <div class="block-lucky wow zoomIn">
                     <p class="shape1"><span class="shape2"></span></p>
                     <p class="shape3"><span class="shape4"></span></p>
                     <p class="shape5"></p>
                     <p class="shape6"></p>
                     <div class="form-change-luck">
                        <div class="row justify-content-center">
                           <div class="col-12 col-md-5">
                              <div class="form-floating">
                                 <input id="txtName" ref="txtName" type="text" class="form-control rounded-pill"
                                    placeholder="name@example.com">
                                 <label for="floatingInput">Họ Tên<b style="color:red;">*</b></label>
                              </div>
                           </div>
                           <div class="col-6 col-md-4">
                              <div class="form-floating">
                                 <input type="text" :id="formdata.SpecialInputId" ref="txtSpecial" class="form-control rounded-pill"
                                    :placeholder="formdata.SpecialInputPlaceHolder">
                                 <label for="floatingInput">{{ formdata.SpecialInputLabel }}<b
                                       style="color:red;">*</b></label>
                              </div>
                           </div>
                           <div class="col-6 col-md-3">
                              <div class="form-floating">
                                 <select ref="select_gioi_tinh" class="form-select rounded-pill"
                                    aria-label="Floating label select example">
                                    <option :value="-1">-Chọn-</option>
                                    <option v-for="e in genderValues" :value="e.id">{{ e.value1 }}
                                    </option>
                                 </select>
                                 <label for="floatingSelect">Giới
                                    tính<b style="color:red;">*</b></label>
                              </div>
                           </div>
                           <div class="col-6 col-md-4">
                              <div class="form-floating">
                                 <select @change="onYearChange" ref="year" required class="form-select rounded-pill">
                                    <option value="-1">-Chọn-</option>
                                    <!-- Example years, you can generate these dynamically -->
                                    <option v-for="e in yearValues" :value="e">{{ e }}</option>
                                 </select>
                                 <label for="floatingInput">Năm sinh<b style="color:red;">*</b></label>
                              </div>
                           </div>
                           <div class="col-6 col-md-4">
                              <div class="form-floating">
                                 <select @change="onMonthChange" ref="month" disabled class="form-select rounded-pill">
                                    <option value="0">-Chọn-</option>
                                    <!-- Example months -->
                                    <option value="1">Tháng 1</option>
                                    <option value="2">Tháng 2</option>
                                    <option value="3">Tháng 3</option>
                                    <option value="4">Tháng 4</option>
                                    <option value="5">Tháng 5</option>
                                    <option value="6">Tháng 6</option>
                                    <option value="7">Tháng 7</option>
                                    <option value="8">Tháng 8</option>
                                    <option value="9">Tháng 9</option>
                                    <option value="10">Tháng 10</option>
                                    <option value="11">Tháng 11</option>
                                    <option value="12">Tháng 12</option>
                                 </select>
                                 <label for="floatingInput">Tháng sinh</label>
                              </div>
                           </div>
                           <div class="col-6 col-md-4">
                              <div class="form-floating">
                                 <select @change="ondateChange" ref="day" disabled class="form-select rounded-pill">
                                    <option value="0">-Chọn-</option>
                                    <option v-for="d in dates" :value="d">{{ d }}</option>
                                 </select>
                                 <label for="floatingInput">Ngày sinh</label>
                              </div>
                           </div>
                           <div class="col-6 col-md-5" style="display: none;">
                              <div class="form-floating">
                                 <div title="Nếu không hiển thị năm vui lòng để lại mặc định zoom của trình duyệt."
                                    class="form-control rounded-pill position-relative">
                                    <input title="Nếu không hiển thị năm vui lòng để lại mặc định zoom của trình duyệt."
                                       id="txt_ngay_sinh" class="datetimepicker border-0" type="text"
                                       placeholder="dd/mm/yyyy">
                                 </div>
                                 <label title="Nếu không hiển thị năm vui lòng để lại mặc định zoom của trình duyệt."
                                    for="floatingInput">Ngày sinh (Dương
                                    lịch)<b style="color:red;">*</b></label>
                              </div>
                           </div>

                           <div class="col-6 col-md-4">
                              <div class="form-floating">
                                 <select ref="txt_gio_sinh" disabled class="form-select rounded-pill">
                                    <option value="-1">-Chọn-</option>
                                    <option v-for="h in hours" :value="h">{{ h }}</option>
                                 </select>
                                 <label for="floatingInput">Giờ sinh</label>
                              </div>
                           </div>
                           <div class="col-6 col-md-4">
                              <div class="form-floating">
                                 <select ref="select_tinh_sinh" class="form-select rounded-pill"
                                    aria-label="Floating label select example">
                                    <option :value="-1">-Chọn-</option>
                                    <option v-for="city in citys" :value="city.id">{{ city.value2 }}
                                    </option>
                                 </select>
                                 <label for="floatingSelect">Nơi sinh</label>
                              </div>
                           </div>
                           <div class="col-6 col-md-4">
                              <div class="form-floating">
                                 <select ref="select_tinh_o" class="form-select rounded-pill"
                                    aria-label="Floating label select example">
                                    <option :value="-1">-Chọn-</option>
                                    <option v-for="city in citys" :value="city.id">{{ city.value2 }}
                                    </option>
                                 </select>
                                 <label for="floatingSelect">Nơi ở</label>
                              </div>
                           </div>
                        </div>
                        <div class="text-center">
                           <button id="btn_tra_cuu" type="button" v-on:click="search"
                              class="btn btn-lg btn-danger mb-3 min-w262 px-5 rounded-pill font-weight-bold ">TRA
                              CỨU NGAY</button>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </div>
         <p class="cloud1 d-none d-md-block"></p>
         <p class="cloud2 d-none d-md-block"></p>
      </section>

      <InfomationComponent v-show="isShowResult" :infomation="infomation" />
      <ChartComponent v-show="isShowResult" @onChangeFortune="changeFortune" :data="chartData" ref="chartRef" />
      <div v-if="isPhoneNumberForm() && isShowResult" id="scrollsChartSection" data-bs-spy="scroll"
         data-bs-target="#navbar-chart-section" data-bs-offset="0" class="scrollspy-example" tabindex="0">
         <div :style="{ 'background-color': '#000', 'padding': '0px 0 30px' }">
            <GroupTitle :type="TitleType.Earth" title="Sim hợp cho Tiền tài" :class-props="''"
               :icon='wealthIcons.moneyIcon' />
            <div class="container-fluid container-scroll">
               <div class="list-phone-horizontal">
                  <div class="row">
                     <div class="col-4"> <select ref="wealthSelect" class="btn btn-sm btn-light form-select-enhancement"
                           id="sort_tientai" style="margin-right: 0.5em;  color: white; background-color: black;"
                           @change="onSelectWealthChange">
                           <option value="0">Tiền tài: Mặc định</option>
                           <option value="1">T.tài: Thấp &rarr; cao</option>
                           <option value="2">T.tài: Cao &rarr; thấp</option>
                        </select>
                        <select ref="wealthPriceSelect" class="btn btn-sm btn-light form-select-enhancement"
                           id="gt_sort_tientai" style="color: white; background-color: black"
                           @change="onSelectWealthChange">
                           <option value="0">Giá tiền: Mặc định</option>
                           <option value="1">G.Tiền: Thấp &rarr; cao</option>
                           <option value="2">G.Tiền: Cao &rarr; thấp</option>
                        </select>
                     </div>
                  </div>
                  <div class="total-number my-3 " :style="{ 'color': 'rgba(255, 255, 255, 0.82)' }">
                     <strong id="total_rows_tientai">Tổng {{ totalSimsWealth }} số điện thoại</strong>
                  </div>
                  <div class="row" id="div_tientai">
                     <SimChangeFortuneComponent @on-change-fortune="changeFortune" v-for="s in wealthSimDatas" :simData="s" />
                  </div>
                  <nav class="mt-2">
                     <PageComponent ref="pageWealth" :totalPages="totalPageWealth"
                        @onIndexChange="onWealthPageIndexChange" />
                  </nav>
               </div>
            </div>

            <GroupTitle :type="TitleType.Fire" title="Sim hợp cho Công danh" :class-props="''"
               :icon='wealthIcons.careerIcon' />
            <div class="container-fluid container-scroll">
               <div class="list-phone-horizontal">
                  <div class="row">
                     <div class="col-4">
                        <select ref="successSelect" class="btn btn-sm btn-light form-select-enhancement"
                           id="sort_congdanh" style="margin-right: 0.5em; color: white;background-color: black;"
                           @change="onSelectSuccessChange">
                           <option value="0">Công danh: Mặc định</option>
                           <option value="1">C.Danh: Thấp &rarr; cao</option>
                           <option value="2">C.Danh: Cao &rarr; thấp</option>
                        </select>

                        <select ref="successPriceSelect" class="btn btn-sm btn-light form-select-enhancement"
                           id="gt_sort_congdanh" style="color: white; background-color: black;"
                           @change="onSelectSuccessChange">
                           <option value="0">Giá tiền: Mặc định</option>
                           <option value="1">G.Tiền: Thấp &rarr; cao</option>
                           <option value="2">G.Tiền: Cao &rarr; thấp</option>
                        </select>
                     </div>
                  </div>
                  <div class="total-number my-3" :style="{ 'color': 'rgba(255, 255, 255, 0.82)' }">
                     <strong id="total_rows_congdanh">Tổng {{ totalSimsSuccess }} số điện thoại</strong>
                  </div>
                  <div class="row" id="div_congdanh">
                     <SimChangeFortuneComponent @on-change-fortune="changeFortune" v-for="s in successSimDatas" :simData="s" />
                  </div>
                  <nav class="mt-2">
                     <PageComponent ref="pageSuccess" :totalPages="totalPageSuccess"
                        @onIndexChange="onSuccessPageIndexChange" />
                  </nav>
               </div>
            </div>

            <GroupTitle :type="TitleType.Wood" title="Sim hợp cho Sức khỏe" :class-props="''"
               :icon='wealthIcons.healthyIcon' />
            <div class="container-fluid container-scroll">
               <div class="list-phone-horizontal">
                  <div class="row">
                     <div class="col-4">
                        <select ref="healthSelect" class="btn btn-sm btn-light form-select-enhancement"
                           id="sort_suckhoe" style="margin-right: 0.5em; color: white;background-color: black;"
                           @change="onSelectHealthChange">
                           <option value="0">Sức khỏe: Mặc định</option>
                           <option value="1">S.Khỏe: Thấp &rarr; cao</option>
                           <option value="2">S.Khỏe: Cao &rarr; thấp</option>
                        </select>

                        <select ref="healthPriceSelect" class="btn btn-sm btn-light form-select-enhancement"
                           id="gt_sort_suckhoe" style="color: white;background-color: black;"
                           @change="onSelectHealthChange">
                           <option value="0">Giá tiền: Mặc định</option>
                           <option value="1">G.Tiền: Thấp &rarr; cao</option>
                           <option value="2">G.Tiền: Cao &rarr; thấp</option>
                        </select>
                     </div>
                  </div>

                  <div class="total-number my-3" :style="{ 'color': 'rgba(255, 255, 255, 0.82)' }">
                     <strong id="total_rows_suckhoe">Tổng {{ totalSimsHealth }} số điện thoại</strong>
                  </div>
                  <div class="row" id="div_suckhoe">
                     <SimChangeFortuneComponent @on-change-fortune="changeFortune" v-for="s in healthSimDatas" :simData="s" />
                  </div>
                  <nav class="mt-2">
                     <PageComponent ref="pageHealth" :totalPages="totalPageHealth"
                        @onIndexChange="onHealthPageIndexChange" />
                  </nav>
               </div>
            </div>

            <GroupTitle :type="TitleType.Water" title="Sim hợp cho Hôn nhân" class-props="''"
               :icon='wealthIcons.marriageIcon' />
            <div class="container-fluid container-scroll">
               <div class="list-phone-horizontal">
                  <div class="row">
                     <div class="col-4">
                        <select ref="marriageSelect" class="btn btn-sm btn-light form-select-enhancement"
                           id="sort_honnhan" style="margin-right: 0.5em; color: white;background-color: black;"
                           @change="onSelectMarriageChange">
                           <option value="0">Hôn Nhân: Mặc định</option>
                           <option value="1">H.Nhân: Thấp &rarr; cao</option>
                           <option value="2">H.Nhân: Cao &rarr; thấp</option>
                        </select>

                        <select ref="marriagePriceSelect" class="btn btn-sm btn-light form-select-enhancement"
                           id="gt_sort_honnhan" style="color: white;background-color: black;"
                           @change="onSelectMarriageChange">
                           <option value="0">Giá tiền: Mặc định</option>
                           <option value="1">G.Tiền: Thấp &rarr; cao</option>
                           <option value="2">G.Tiền: Cao &rarr; thấp</option>
                        </select>
                     </div>
                  </div>
                  <div class="total-number my-3" :style="{ 'color': 'rgba(255, 255, 255, 0.82)' }">
                     <strong id="total_rows_honnhan">Tổng {{ totalSimsMariage }} số điện thoại</strong>
                  </div>
                  <div class="row" id="div_honnhan">
                     <SimChangeFortuneComponent @on-change-fortune="changeFortune" v-for="s in marriageSimDatas" :simData="s" />
                  </div>
                  <nav class="mt-2">
                     <PageComponent ref="pageMarriage" :totalPages="totalPageMariage"
                        @onIndexChange="onMarriagePageIndexChange" />
                  </nav>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>