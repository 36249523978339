import type { MutationTree } from 'vuex';
import { UserProfileSate } from './state';
import type { IUserProfileDto } from '@/models/account';
import { key, storage } from '@/lib/storage';
import moment from 'moment';

const mutations: MutationTree<UserProfileSate> = {
  setProfile(state, profile?: UserProfileSate) {
    if (!profile) profile = new UserProfileSate();
    Object.keys(state).forEach(key => {
      (state as any)[key] = (profile as any)[key];
    });
    state.formatedBirthday = moment(profile?.birthDay).format('YYYY-MM-DD');
    // save token
    const lc = storage.getLocalStorage();
    profile?.accountToken && lc.add(key.userToken, profile.accountToken);
  },

  setProfileFromDto(state, profileDto?: IUserProfileDto) {

    Object.keys(USER_PROFILE_KEYS_TO_DTO).forEach(profileKey => {
      const dtoKey: string = (USER_PROFILE_KEYS_TO_DTO as any)[profileKey];

      (state as any)[profileKey] = (profileDto as any)?.[dtoKey] || (state as any)[profileKey];
    });

    state.formatedBirthday = moment(profileDto?.kh_ngay_sinh).format('YYYY-MM-DD');
    state.totalRemainingNumberDraw = (profileDto?.qs_tong_luot_quay ?? 0) - (profileDto?.qs_luot_quay_t_te ?? 0);    // save token
    if (!profileDto?.luot_xem_con_lai) {
      state.totalRemainingChartView = (profileDto?.tong_luot_xem ?? 0) - (profileDto?.so_luot_da_xem ?? 0)
    }

    if(profileDto && !profileDto?.kh_ten_day_du.isNullOrEmpty())
    {
      const spl = profileDto.kh_ten_day_du.split(' ');
      state.lastName = spl[spl.length - 1];
    }

    const lc = storage.getLocalStorage();
    profileDto?.kh_token && lc.add(key.userToken, profileDto.kh_token);
  },

  setProfileValues(state, values: any) {
    Object.keys(state).forEach(key => {
      if (values.hasOwnProperty(key)) {
        (state as any)[key as keyof typeof state] = values[key as keyof typeof values];
      }
    });

  }
};

export default mutations;

export const USER_PROFILE_KEYS_TO_DTO = {
  id: 'kh_id',
  birthDay: 'kh_ngay_sinh',
  birthTime: 'kh_gio_sinh',
  gmail: 'kh_gmail',
  gender: 'kh_gioi_tinh',
  avatar: 'kh_anh',
  customerPoint: 'kh_diem',
  firstName: 'kh_ho',
  lastName: 'kh_ten',
  middleName: 'kh_ten_dem',
  fullName: 'kh_ten_day_du',
  facebook: 'kh_facebook',
  phoneNumber: 'kh_mobile',
  createDate: 'kh_create_date',
  createBy: 'kh_create_by',
  serviceId: 'goi_dich_vu_ma',
  status: 'trang_thai',
  birthCity: 'kh_thanh_pho_sinh',
  birthDistrict: 'kh_quan_sinh',
  wardOfBirth: 'kh_phuong_sinh',
  placeOfBirth: 'kh_dia_chi_sinh',
  cityOfResidence: 'kh_thanh_pho_o',
  districtOfResidence: 'kh_quan_o',
  wardOfResidence: 'kh_phuong_o',
  placeOfResidence: 'kh_dia_chi',
  totalChartView: 'tong_luot_xem',
  totalNumberOfView: 'so_luot_da_xem',
  totalRemainingChartView: 'luot_xem_con_lai',
  expireDate: 'ngay_het_han',
  expirePerpetualCalendar: 'expried_lvn',
  sumaryOfNumberDraw: 'qs_tong_luot_quay',
  NumberUsedDraw: 'qs_luot_quay_t_te',
  timedNumberDraw: 'qs_thoi_han',
  accountToken: 'kh_token',
  serviceName: 'goi_dich_vu_ten',
};
