import type { Module } from 'vuex'
import mutations from './mutation'
import { UserProfileSate } from './state'
import type { RootState } from '../rootState'
import store from '..'
import type { IUserProfileDto } from '@/models/account'

export const state = new UserProfileSate()

export const userProfile: Module<UserProfileSate, RootState> = {
  namespaced: true,
  state,
  mutations
}

export const commitProfileValues = (values: any) => {
  store.commit('userProfile/setProfileValues', values)
}

export const commitProfileFromDto = (profileDto: IUserProfileDto) => {
  store.commit('userProfile/setProfileFromDto', profileDto)
}