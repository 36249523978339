import type { Module } from "vuex";
import mutations from "./mutation";
import { PopupState } from "./state";
import type { RootState } from "../rootState";

export const state = new PopupState();

const namespaced = true;

export const popup: Module<PopupState, RootState> = {
  namespaced,
  state,
  mutations,
};