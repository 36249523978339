<style setup>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import { computed, onMounted, ref, useTemplateRef } from 'vue';
import type { simItemDto } from '@/models/https/simResponse';
import type { IGetSimReq } from '@/models/https/simRequests';
import SimRepository from '@/lib/https/simRepository';
import { RepositoryFactory } from '@/lib/https';
import PageComponent from '../parts/PageComponent.vue';
import type { AllSearchData, SearchData } from '../parts/SearchComponent.vue';
import { priceSortOptions, WealthSortOptions } from '@/lib/utils/common';
import store from '@/store';
import type { UserProfileSate } from '@/store/userProfile/state';
import SimTableComponent from '../parts/SimTableComponent.vue';
import SearchComponent from '../parts/SearchComponent.vue';

enum NicePhoneNumberType {
   LucQuy = "11",
   NguQuy = "12",
   TuQuy = "1",
   TamHoa = "2",
   TamHoaKep = "7",
   GanhDao = "13",
   TienLen = "10"
}

const { getSims } = RepositoryFactory.getRepository<SimRepository>(
   SimRepository
);

const nicePage = ref();
const userProfile = computed(() => store.state.userProfile as UserProfileSate)
const isShowWealth = computed(() => userProfile.value && userProfile.value.gender > 0 && userProfile.value.birthDay.trim())

const currentNicePhoneType = ref<NicePhoneNumberType>(NicePhoneNumberType.LucQuy);
const allSimDatas = ref<Array<simItemDto>>([]);
const totalPages = ref<number>(0);

const priceOptionsRef = useTemplateRef("priceSort");
const wealthOptionsRef = useTemplateRef("wealthSort");

let dtoCache:SearchData = {
   network: null,
   price: null,
   fiveElement: null,
   allCheck: false,
   firstNumber: '0',
   textSearch: ''
};

const createDefaultRequest = () => {
   const data: IGetSimReq = {
      key_work: "",
      nha_mang_ds: null,
      gia_tien_ma_ds: null,
      ngu_hanh_ma_ds: null,
      tuoi_ma_ds: null,
      sim_loai_ma_ds: "11",
      page_name: "sodep",
      page_index: 1,
      page_size: 18,
      gia_tien_sort: 0,
      cai_van_sort: 0,
      dau_so: undefined
   };
   return data;
}

const createRequestWithCache = (searchData: SearchData | null) =>{
   const rq = createDefaultRequest();
   if (searchData && !searchData.allCheck) {
      rq.key_work = searchData.textSearch;

      if (searchData.network) {
         const value = searchData.network.toString();
         rq.nha_mang_ds = searchData.network.length > 1 ? value : value + ",";
      }

      if(searchData.price)
      {
         const value = searchData.price.toString();
         rq.gia_tien_ma_ds = searchData.price.length > 1 ? value : value + ",";
      } 

      if(searchData.fiveElement)
      {
         const value = searchData.fiveElement.toString();
         rq.ngu_hanh_ma_ds =  searchData.fiveElement.length > 1 ? value : value + ",";
      }      
       
      rq.dau_so = searchData.firstNumber;
      rq.gia_tien_sort = parseInt(priceOptionsRef.value?.value ?? '0');
      rq.cai_van_sort = parseInt(wealthOptionsRef.value?.value ?? '0');
      rq.sim_loai_ma_ds = currentNicePhoneType.value;
   }
   return rq;
}

const fetchNicePhoneNumber = async (page: number) => {
   const rq = createRequestWithCache(dtoCache);
   rq.page_index = page;
   const rp = await getSims(rq);
   allSimDatas.value = rp?.data ?? [];
   totalPages.value = rp?.data[0]?.total_page ?? 0;
}

const searchNicePhoneNumber = async (rq: IGetSimReq) => {
   const rp = await getSims(rq);
   allSimDatas.value = rp?.data ?? [];
   totalPages.value = rp?.data[0]?.total_page ?? 0;
}

const onIndexChange = (index: number) => {
   fetchNicePhoneNumber(index);
}

const onSearch = async (searchData: SearchData | null) => {
   const rq = createRequestWithCache(searchData);
   await searchNicePhoneNumber(rq);
   nicePage.value.resetToFirstPage();
}

const changeDataSearch = (data: AllSearchData) => {
   dtoCache = data.dto;
   onSearch(data.dto);
}

const onChangePriceOption = ()=> {
   onSearch(dtoCache);
}

const onChangeWealthOption = ()=> {

   onSearch(dtoCache);
}

const onChangeNicePhoneType = (type: NicePhoneNumberType)=> {
   currentNicePhoneType.value = type;
   onSearch(dtoCache);
}


onMounted(async () => {
   try {
      await fetchNicePhoneNumber(1);
   } catch (error) {

   }
})

</script>

<template>

   <section class="section-page section-list-search" id="so-dep-list-phone-number">
      <div class="container-fluid container-scroll">
         <ul class="nav nav-fill tab-primary" id="mySim" role="tablist">
            <li class="nav-item">
               <a class="nav-link " :class="currentNicePhoneType === NicePhoneNumberType.LucQuy? 'active':''" id="sim-luc-quy" aria-current="page" href="javascript:void(0)"
                  v-on:click="onChangeNicePhoneType(NicePhoneNumberType.LucQuy)">
                  Lục quý
               </a>
            </li>
            <li class="nav-item">
               <a class="nav-link" :class="currentNicePhoneType === NicePhoneNumberType.NguQuy? 'active':''" id="sim-ngu-quy" aria-current="page" href="javascript:void(0)"
                  v-on:click="onChangeNicePhoneType(NicePhoneNumberType.NguQuy)">
                  Ngũ quý
               </a>
            </li>
            <li class="nav-item">
               <a class="nav-link" :class="currentNicePhoneType === NicePhoneNumberType.TuQuy? 'active':''" id="sim-tu-quy" aria-current="page" href="javascript:void(0)"
               v-on:click="onChangeNicePhoneType(NicePhoneNumberType.TuQuy)">
                  Tứ quý
               </a>
            </li>
            <li class="nav-item">
               <a class="nav-link" :class="currentNicePhoneType === NicePhoneNumberType.TamHoa? 'active':''" id="sim-tam-hoa" aria-current="page" href="javascript:void(0)"
               v-on:click="onChangeNicePhoneType(NicePhoneNumberType.TamHoa)">
                  Tam hoa
               </a>
            </li>
            <li class="nav-item">
               <a class="nav-link" :class="currentNicePhoneType === NicePhoneNumberType.TamHoaKep? 'active':''" id="sim-tam-hoa-kep" aria-current="page" href="javascript:void(0)"
               v-on:click="onChangeNicePhoneType(NicePhoneNumberType.TamHoaKep)">
                  Tam hoa kép
               </a>
            </li>
            <li class="nav-item">
               <a class="nav-link" :class="currentNicePhoneType === NicePhoneNumberType.GanhDao? 'active':''" id="sim-ganh-dao" aria-current="page" href="javascript:void(0)"
               v-on:click="onChangeNicePhoneType(NicePhoneNumberType.GanhDao)">
                  Gánh đảo
               </a>
            </li>
            <li class="nav-item">
               <a class="nav-link" :class="currentNicePhoneType === NicePhoneNumberType.TienLen? 'active':''" id="sim-tien-len" aria-current="page" href="javascript:void(0)"
               v-on:click="onChangeNicePhoneType(NicePhoneNumberType.TienLen)">
                  Tiến lên
               </a>
            </li>
         </ul>
      </div>
      <div class="container-fluid">
         <SearchComponent @onAnyButtonClick="changeDataSearch" :searchData="null" suffix-id="0" textSearch=""
            @onSearch="onSearch" />
         <div class="col-md-4">
            <div class="total-number my-3">
               <strong id="total_rows">Tổng {{ allSimDatas[0]?.total_rows ?? 0 }} số điện thoại</strong>
            </div>
         </div>
         <div style="width: 100%; overflow-x: auto;">
            <table>
               <tr>
                  <td>
                     <select ref="priceSort" class="btn btn-sm btn-light" @change="onChangePriceOption">
                        <option v-for="price in priceSortOptions" :value="price.id">{{ price.name }}</option>
                     </select>
                  </td>
                  <td><select v-if="isShowWealth" ref="wealthSort" class="btn btn-sm btn-light"
                        @change="onChangeWealthOption">
                        <option v-for="wealth in WealthSortOptions" :value="wealth.id">{{ wealth.name }}</option>
                     </select></td>
               </tr>
            </table>
         </div>

         <SimTableComponent :simDatas="allSimDatas" labelElement="Sim mệnh:"/>
         <PageComponent ref="nicePage" @onIndexChange="onIndexChange" :totalPages="totalPages" />
      </div>
   </section>
</template>