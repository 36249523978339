export enum PoupType {
    ChartPopupComponent,
    SignUpPopupComponent,
    BuyChartViewPopupComponent,
    UpdateAccountPopupComponent,
    PopupDetailInterpretation
}

export interface PopupData{

}

export class PopupController{
    public static pushPopup : (type:PoupType, popupData:PopupData) => void;
    public static setPopupData : (popupData:PopupData) => void;
    public static getCurrentPopup : () => any;
}