<template>
   <div :class="typeValue">
      <Teleport defer :to="'#flexContain' + props.simData.PhoneNumber" :disabled="props.type !== 'top'">
         <div :class="flexValue">
            <div class="header-phone">
               <h4 class="item-number">
                  {{ simData.PhoneNumber }}
               </h4>
               <div class="item-img">
                  <img :src="getMobileNetworkProviderIcon(simData.MobileNetworkProvider)" class="img-fluid" alt="hình">
                  <!--src="getIcon(value?.nha_mang)" -->
               </div>
            </div>
            <div class="d-flex-de">
               <p>{{ labelElement }} <span class="life">{{ simData.FiveElements }}</span></p>
               <p>Tiền tài: <span class="life">{{ simData.Wealth }}</span></p>
            </div>
            <p>Giá: <span class="price">{{ numberWithCommas(
               simData?.Price
            ) }}</span></p>
            <div class="group-action">
               <button class="btn btn-light text-primary" data-bs-toggle="modal1" v-on:click="ShowPoup"
                  data-bs-target="#${modal_name}">Xem
                  diễn giải</button>
               <button class="btn btn-danger bg-theme"><img src="@/assets/images/icons/ic-add-to-cart.svg"
                     width="26px" v-on:click="onBuy"></button>
            </div>
         </div>
         <slot name="bottom"></slot>
      </Teleport>
      <div v-if="props.type === 'top'" class="item-hot-phone flex-row-reverse flex-sm-column"
         :id="'flexContain' + props.simData.PhoneNumber">

      </div>
   </div>
</template>

<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
</style>

<script lang="ts" setup>
import { PopupController, PoupType } from "@/lib/popup";
import { numberWithCommas, getMobileNetworkProviderIcon } from "../../lib/utils/common";
import { storage, key } from "@/lib/storage/index";
import Swal from 'sweetalert2';


import RepositoryFactory from "@/lib/https/repositoryFactory"
import { useRouter } from "vue-router"
import SimRepository from "@/lib/https/simRepository";
import type { ISimDetailParam } from "@/models/https/simRequests";
import { addProductToCart, state } from "@/store/cart/index";
import { ProductType, type Product } from "@/store/cart/state";
import { computed, onMounted } from "vue";
import store from "@/store";
import type { UserProfileSate } from "@/store/userProfile/state";

export interface SimData {
   Wealth: String,
   FiveElements: string,
   Price: number,
   PhoneNumber: string,
   MobileNetworkProvider: string
}

const props = defineProps<{
   simData: SimData,
   type: string,
   labelElement: string,
}>()

const typeValue = computed(() => {
   if (props.type === 'slider') {
      return 'col-2';
   }
   else if (props.type === "top") {
      return "col-sm-4";
   }
   else {
      return 'col-6 col-sm-4 col-md-3 col-xl-2';
   }

});

const flexValue = computed(() => {
   if (props.type === "top") {
      return "item-phone-number flex-grow-1";
   }
   else {
      return "item-phone-number";
   }
});

const { getSimDetail } = RepositoryFactory.getRepository<SimRepository>(
   SimRepository
).withRouter(useRouter());

const lc = storage.getLocalStorage();

const userProfile = computed(() => store.state.userProfile as UserProfileSate)

let birthDay = undefined;
let birthYear:number = 0;

onMounted(() => {
   if (userProfile.value.id !== -1) {
      birthDay = new Date(userProfile.value.birthDay);
      birthYear = birthDay.getFullYear();
   }
})


const onChangeYearOrGender = async (year: number, gender: number) => {
   if (userProfile.value.id !== -1) {
      const param: ISimDetailParam = {
         sim: props.simData.PhoneNumber,
         year_chart: year,
         gioi_tinh_chart: gender,
      }
      try {
         const rp = await getSimDetail(param);
         if (rp) {
            delete (rp as any).data.loai;
            if (rp.result_code === "000") {
               PopupController.setPopupData({
                  oldSim: param.sim,
                  currentData: Object.values(rp?.data ?? []),
                  totalRemainingChartView: rp.so_luot_xem_con_lai,
                  birthYear: year,
                  gender: gender === 2 ? 'Nữ' : 'Nam',
                  genderCode: gender,
                  action: onChangeYearOrGender,
               })
            }
         }
      }
      catch {

      }
   }

}

const ShowPoup = async () => {

   if (userProfile) {
      const param: ISimDetailParam = {
         sim: props.simData.PhoneNumber,
         year_chart: 0,
         gioi_tinh_chart: - 1,
      }
      try {
         const rp = await getSimDetail(param);
         if (rp) {
            delete (rp as any).data.loai;
            if (rp.result_code === "000") {
               PopupController.pushPopup(PoupType.ChartPopupComponent, {
                  oldSim: param.sim,
                  currentData: Object.values(rp?.data ?? []),
                  totalRemainingChartView: rp.so_luot_xem_con_lai,
                  birthYear: birthYear,
                  gender: userProfile.value.gender === 2 ? 'Nữ' : 'Nam',
                  genderCode: userProfile.value.gender,
                  action: onChangeYearOrGender,
               });
            }
            else if (rp.result_code === "001") {
               //To do         
               PopupController.pushPopup(PoupType.SignUpPopupComponent, { description: null });
            }
            else if (rp.result_code === "002") {
               //To do
               PopupController.pushPopup(PoupType.BuyChartViewPopupComponent, { description: null });
            }
            else if (rp.result_code === "003") {
               //To do         
               PopupController.pushPopup(PoupType.UpdateAccountPopupComponent, { description: null });
            }
         }

      }
      catch {

      }

   }
   else {
      PopupController.pushPopup(PoupType.SignUpPopupComponent, { description: null });
   }

}

const onBuy = () => {
   const product: Product = {
      productId: props.simData.PhoneNumber,
      price: props.simData.Price,
      name: props.simData.PhoneNumber,
      qty: 1,
      mobileNetworkProvider: props.simData.MobileNetworkProvider,
      icon: getMobileNetworkProviderIcon(props.simData.MobileNetworkProvider),
      type: ProductType.Durable
   }
   addProductToCart(product).then(msg =>{
      const al: any = {
            icon: "success",
            title: msg,
        }
        Swal.fire(al);
   }).catch(err => {
      const al: any = {
            icon: "error",
            title: err,
        }
        Swal.fire(al);
   });
}
</script>
