<template>
   <div class="container-fluid container-scroll">
      <ul class="nav nav-fill" id="myTuoi" role="tablist">
         <li class="nav-item">
            <a :class="currentZodiacSlect === 1 ? 'active' : ''" class="nav-link" id="tuoi-ti" aria-current="page"
               href="javascript:void(0)" v-on:click="onchangeZodiac(1)">
               <div class="ratio ratio-1x1 m-auto">
                  <img src="@/assets/images/img-tuoi-ti.png" alt="img-fluid">
               </div>
               <p>Tý</p>
            </a>
         </li>
         <li class="nav-item">
            <a :class="currentZodiacSlect === 2 ? 'active' : ''" class="nav-link" id="tuoi-suu" aria-current="page"
               href="javascript:void(0)" v-on:click="onchangeZodiac(2)">
               <div class="ratio ratio-1x1 m-auto">
                  <img src="@/assets/images/img-tuoi-suu.png" alt="img-fluid">
               </div>
               <p>Sửu</p>
            </a>
         </li>
         <li class="nav-item">
            <a :class="currentZodiacSlect === 3 ? 'active' : ''" class="nav-link" id="tuoi-dan" aria-current="page"
               href="javascript:void(0)" v-on:click="onchangeZodiac(3)">
               <div class="ratio ratio-1x1 m-auto">
                  <img src="@/assets/images/img-tuoi-dan.png" alt="img-fluid">
               </div>
               <p>Dần</p>
            </a>
         </li>
         <li class="nav-item">
            <a :class="currentZodiacSlect === 4 ? 'active' : ''" class="nav-link" id="tuoi-mao" aria-current="page"
               href="javascript:void(0)" v-on:click="onchangeZodiac(4)">
               <div class="ratio ratio-1x1 m-auto">
                  <img src="@/assets/images/img-tuoi-meo.png" alt="img-fluid">
               </div>
               <p>Mão</p>
            </a>
         </li>
         <li class="nav-item">
            <a :class="currentZodiacSlect === 5 ? 'active' : ''" class="nav-link" id="tuoi-thin" aria-current="page"
               href="javascript:void(0)" v-on:click="onchangeZodiac(5)">
               <div class="ratio ratio-1x1 m-auto">
                  <img src="@/assets/images/img-tuoi-thin.png" alt="img-fluid">
               </div>
               <p>Thìn</p>
            </a>
         </li>
         <li class="nav-item">
            <a :class="currentZodiacSlect === 6 ? 'active' : ''" class="nav-link" id="tuoi-ty" aria-current="page"
               href="javascript:void(0)" v-on:click="onchangeZodiac(6)">
               <div class="ratio ratio-1x1 m-auto">
                  <img src="@/assets/images/img-tuoi-ty.png" alt="img-fluid">
               </div>
               <p>Tỵ</p>
            </a>
         </li>
         <li class="nav-item">
            <a :class="currentZodiacSlect === 7 ? 'active' : ''" class="nav-link" id="tuoi-ngo" aria-current="page"
               href="javascript:void(0)" v-on:click="onchangeZodiac(7)">
               <div class="ratio ratio-1x1 m-auto">
                  <img src="@/assets/images/img-tuoi-ngo.png" alt="img-fluid">
               </div>
               <p>Ngọ</p>
            </a>
         </li>
         <li class="nav-item">
            <a :class="currentZodiacSlect === 8 ? 'active' : ''" class="nav-link" id="tuoi-mui" aria-current="page"
               href="javascript:void(0)" v-on:click="onchangeZodiac(8)">
               <div class="ratio ratio-1x1 m-auto">
                  <img src="@/assets/images/img-tuoi-mui.png" alt="img-fluid">
               </div>
               <p>Mùi</p>
            </a>
         </li>
         <li class="nav-item">
            <a :class="currentZodiacSlect === 9 ? 'active' : ''" class="nav-link" id="tuoi-than" aria-current="page"
               href="javascript:void(0)" v-on:click="onchangeZodiac(9)">
               <div class="ratio ratio-1x1 m-auto">
                  <img src="@/assets/images/img-tuoi-than.png" alt="img-fluid">
               </div>
               <p>Thân</p>
            </a>
         </li>
         <li class="nav-item">
            <a :class="currentZodiacSlect === 10 ? 'active' : ''" class="nav-link" id="tuoi-dau" aria-current="page"
               href="javascript:void(0)" v-on:click="onchangeZodiac(10)">
               <div class="ratio ratio-1x1 m-auto">
                  <img src="@/assets/images/img-tuoi-dau.png" alt="img-fluid">
               </div>
               <p>Dậu</p>
            </a>
         </li>
         <li class="nav-item">
            <a :class="currentZodiacSlect === 11 ? 'active' : ''" class="nav-link" id="tuoi-tuat" aria-current="page"
               href="javascript:void(0)" v-on:click="onchangeZodiac(11)">
               <div class="ratio ratio-1x1 m-auto">
                  <img src="@/assets/images/img-tuoi-tuat.png" alt="img-fluid">
               </div>
               <p>Tuất</p>
            </a>
         </li>
         <li class="nav-item">
            <a :class="currentZodiacSlect === 12 ? 'active' : ''" class="nav-link" id="tuoi-hoi" aria-current="page"
               href="javascript:void(0)" v-on:click="onchangeZodiac(12)">
               <div class="ratio ratio-1x1 m-auto">
                  <img src="@/assets/images/img-tuoi-hoi.png" alt="img-fluid">
               </div>
               <p>Hợi</p>
            </a>
         </li>
      </ul>
   </div>

</template>

<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
</style>

<script lang="ts" setup>
import { computed, ref, useTemplateRef, watch } from 'vue';
const emits = defineEmits<{
   (event: "onChangeZodiac", id: number): void
}>();
const currentZodiacSlect = ref<number>(1);
const onchangeZodiac = (id: number) => {
   currentZodiacSlect.value = id;
   emits("onChangeZodiac", id);
}

const selectZodiac = (id: number) => {
   currentZodiacSlect.value = id;
}

const getValue = () => {
   return currentZodiacSlect.value;
}

defineExpose({
   selectZodiac,
   getValue
});

</script>
