<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
</style>

<script lang="ts" setup>
import { ref } from 'vue';
import PageComponent from '../PageComponent.vue';

export interface NameData {
   Name: string,
   FiveElement: string,
}

const pagingRef = ref();

defineProps<{
   names: Array<NameData>,
   totalPages: number
}>();

const emits = defineEmits<{
   (event: "onIndexChange", index:number) : void
}>();

const onIndexChange = (index: number) => {
   emits("onIndexChange", index);
}

const resetToFirstPage = () =>{
   pagingRef.value.resetToFirstPage();
}

defineExpose({
   resetToFirstPage,
})

</script>


<template>
   <div class="container-fluid">
      <div class="list-phone-horizontal">
         <div class="row">
            <div class="col-2" v-for="n in names">
               <div class="item-phone-number">
                  <div class="header-phone">
                     <h4 class="item-number" style="color: black">
                        {{ n.Name }}
                     </h4>
                  </div>
                  <div class="d-flex-de">
                     <p>Tên mệnh: <span class="life"> {{ n.FiveElement }}</span></p>
                  </div>
                  <div class="group-action">
                     <button class="btn btn-light text-primary" data-bs-toggle="modal1">Cải vận</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <nav class="mt-2" style="text-align: center;">
         <PageComponent :ref=" p => pagingRef = p" :totalPages="totalPages" @onIndexChange="onIndexChange"/>

         <label class="justify-content-center" style="color: white;">Mỗi lượt chuyển trang bạn sẽ cần
            1 lượt xem.</label>
      </nav>
   </div>
</template>