import type { ApplicationPagination, Pagination } from "@/models/https/pagination"
import Repository from "@/lib/https/repository"
import { buildUrlWithQueryOptions } from "@/lib/utils/stringUtils"
import type { IApplication } from "@/models/application"
import router from "@/router"

const applicationUrl = "/applications"

let applicationLogo = "/logo"
let applicationSwitch = "/switch"
let applicationApprove = "/approve"
const exportCsvUrl = applicationUrl + "/export-csv"

export default class ApplicationRepository extends Repository {
  getApplications = async (options: ApplicationPagination) => {
    const url = buildUrlWithQueryOptions(applicationUrl, options)
    const response = await this.axios.get(url)
    if (response) return response.data
    return null
  }

  createApplication = async (data: {
    name?: string
    organizationName?: string
    representativePhoneNumber?: string
    representativeEmail?: string
  }) => {
    const response = await this.axios.post<IApplication>(applicationUrl, JSON.stringify(data))
    if (response) return response
    return null
  }

  getApplicationById = async (applicationId: string, query?: any) => {
    try {
      const url = buildUrlWithQueryOptions(applicationUrl.concat(`/${applicationId}`), query)
      const response = await this.axios.get<IApplication>(url)
      if (response) return response.data
      return null
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        router.push({ name: "NotFound" })
      } else {
        throw error
      }
    }
  }

  //put application
  updateApplication = async (applicationId: string, application: IApplication) => {
    const response = await this.axios.put(applicationUrl.concat(`/${applicationId}`), JSON.stringify(application))
    if (response) return response
    return null
  }

  deleteApplication = async (applicationId: string) => {
    const response = await this.axios.delete(applicationUrl.concat(`/${applicationId}`))
    if (response) return response
    return null
  }

  approveApplication = async (applicationId: string) => {
    const response = await this.axios.post(`${applicationUrl}/${applicationId}${applicationApprove}`)
    if (response.data) return response.data
    return null
  }

  switchApplication = async (applicationId: string) => {
    const response = await this.axios.post(`${applicationUrl}/${applicationId}${applicationSwitch}`)
    if (response.data) return response.data
    return null
  }

  //put logo
  updateLogo = async (applicationId: string, logo: FormData) => {
    const response = await this.axios.put(applicationUrl.concat(`/${applicationId}${applicationLogo}`), logo)
    if (response) return response
    return null
  }
  
  getExportApplications = async (options: ApplicationPagination) => {
    const url = buildUrlWithQueryOptions(exportCsvUrl, options)
    const response = await this.axios.get(url)
    if (response) return response
    return null
  }
}
