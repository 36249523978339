import type { Module } from "vuex";
import mutations from "./mutation";
import { LoaderState } from "./state";
import type { RootState } from "../rootState";

export const state = new LoaderState();

const namespaced = true;

export const loader: Module<LoaderState, RootState> = {
  namespaced,
  state,
  mutations,
};