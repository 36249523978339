<style setup scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import { TitleType } from '../parts/NameMeaning/GroupTitle.vue';
import GroupTitle from '../parts/NameMeaning/GroupTitle.vue';
import SimTableComponentSlider from '../parts/SimTableComponentSlider.vue';
import { onMounted, ref } from 'vue';
import type { simItemDto } from '@/models/https/simResponse';
import type { IGetSimReq } from '@/models/https/simRequests';
import SimRepository from '@/lib/https/simRepository';
import { RepositoryFactory } from '@/lib/https';
import { routerPaths } from '@/router/routes';
import { fiveElementIcons } from '@/lib/utils/imageUtils';


const { getSims } = RepositoryFactory.getRepository<SimRepository>(
   SimRepository
);

const metalSimDatas = ref<Array<simItemDto>>([]);
const woodSimDatas = ref<Array<simItemDto>>([]);
const waterSimDatas = ref<Array<simItemDto>>([]);
const fireSimDatas = ref<Array<simItemDto>>([]);
const earthSimDatas = ref<Array<simItemDto>>([]);
   
const createDefaultRequest = () => {
   const data: IGetSimReq = {
      key_work: "",
      nha_mang_ds: null,
      gia_tien_ma_ds: null,
      ngu_hanh_ma_ds: "1",
      tuoi_ma_ds: null,
      sim_loai_ma_ds: null,
      page_name: "sophongthuy",
      page_index: 1,
      page_size: 18,
      gia_tien_sort: 0,
      cai_van_sort: 0,
      dau_so: undefined
   };
   return data;
}

onMounted(async () => {
   try {
      const rq = createDefaultRequest();

      rq.ngu_hanh_ma_ds = "1";
      const rpMetal = await getSims(rq);
      metalSimDatas.value = rpMetal?.data ?? [];

      rq.ngu_hanh_ma_ds = "2";
      const rpWood = await getSims(rq);
      woodSimDatas.value = rpWood?.data ?? [];

      rq.ngu_hanh_ma_ds = "3";
      const rpWater = await getSims(rq);
      waterSimDatas.value = rpWater?.data ?? [];

      rq.ngu_hanh_ma_ds = "4";
      const rpFire = await getSims(rq);
      fireSimDatas.value = rpFire?.data ?? [];

      rq.ngu_hanh_ma_ds = "5";
      const rpEarth = await getSims(rq);
      earthSimDatas.value = rpEarth?.data ?? [];
   } catch (error) {

   }
})

</script>

<template>
   <section class="py-3 section-feng-shui">
      <div class="container-fluid">
         <div class="row align-items-center">
            <div class="col-12 col-lg-5 col-xl-3">
               <h1 class="title-section  text-xl-start mb-3">CẢI VẬN THEO SỐ NHƯ Ý</h1>
            </div>
            <div class="col-12 col-lg-7 col-xl-9 pe-0">
               <div class="slider-service">
                  <div>
                     <img src="@/assets/images/banner-phong-thuy.png" class="w-100 img-banner" alt="banner" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="section-page section-to-network" id="home-list-phone-number">
      <GroupTitle :type="TitleType.Metal" title="Số mệnh kim" class-props="pt-3 pt-xl-5"
         v-bind:icon='fiveElementIcons.metalIcon'>
         <template #topSlot>
            <img src="@/assets/images/banner-kim.png" class="img-fluid mb-2" alt="banner-kim">
         </template>
         <template #botSlot>
            <div class="text-end pe-1">
               <RouterLink :to="`${routerPaths.DetailPhoneNumber}/?element=1&pageName=sophongthuy&pageSize=36`" class="btn btn-sm btn-outline-danger border-0">Xem
                  thêm</RouterLink>
            </div>
         </template>
      </GroupTitle>
      <SimTableComponentSlider :simDatas="metalSimDatas" labelElement="Sim mệnh:"/>
      
      <GroupTitle :type="TitleType.Wood" title="Số mệnh mộc" class-props="pt-3 pt-xl-5"
         v-bind:icon='fiveElementIcons.woodIcon'>
         <template #topSlot>
            <img src="@/assets/images/banner-moc.png" class="img-fluid mb-2" alt="banner-kim">
         </template>
         <template #botSlot>
            <div class="text-end pe-1">
               <RouterLink :to="`${routerPaths.DetailPhoneNumber}/?element=2&pageName=sophongthuy&pageSize=36`" class="btn btn-sm btn-outline-danger border-0">Xem
                  thêm</RouterLink>
            </div>
         </template>
      </GroupTitle>
      <SimTableComponentSlider :simDatas="woodSimDatas" labelElement="Sim mệnh:"/>

      <GroupTitle :type="TitleType.Water" title="Số mệnh thủy" class-props="pt-3 pt-xl-5"
         v-bind:icon='fiveElementIcons.waterIcon'>
         <template #topSlot>
            <img src="@/assets/images/banner-thuy.png" class="img-fluid mb-2" alt="banner-kim">
         </template>
         <template #botSlot>
            <div class="text-end pe-1">
               <RouterLink :to="`${routerPaths.DetailPhoneNumber}/?element=3&pageName=sophongthuy&pageSize=36`" class="btn btn-sm btn-outline-danger border-0">Xem
                  thêm</RouterLink>
            </div>
         </template>
      </GroupTitle>
      <SimTableComponentSlider :simDatas="waterSimDatas" labelElement="Sim mệnh:"/>

      <GroupTitle :type="TitleType.Fire" title="Số mệnh Hỏa" class-props="pt-3 pt-xl-5"
         v-bind:icon='fiveElementIcons.fireIcon'>
         <template #topSlot>
            <img src="@/assets/images/banner-hoa.png" class="img-fluid mb-2" alt="banner-kim">
         </template>
         <template #botSlot>
            <div class="text-end pe-1">
               <RouterLink :to="`${routerPaths.DetailPhoneNumber}/?element=4&pageName=sophongthuy&pageSize=36`" class="btn btn-sm btn-outline-danger border-0">Xem
                  thêm</RouterLink>
            </div>
         </template>
      </GroupTitle>
      <SimTableComponentSlider :simDatas="fireSimDatas" labelElement="Sim mệnh:"/>

      <GroupTitle :type="TitleType.Earth" title="Số mệnh thổ" class-props="pt-3 pt-xl-5"
         v-bind:icon='fiveElementIcons.earthIcon'>
         <template #topSlot>
            <img src="@/assets/images/banner-tho.png" class="img-fluid mb-2" alt="banner-kim">
         </template>
         <template #botSlot>
            <div class="text-end pe-1">
               <RouterLink :to="`${routerPaths.DetailPhoneNumber}/?element=5&pageName=sophongthuy&pageSize=36`" class="btn btn-sm btn-outline-danger border-0">Xem
                  thêm</RouterLink>
            </div>
         </template>
      </GroupTitle>
      <SimTableComponentSlider :simDatas="earthSimDatas" labelElement="Sim mệnh:"/>
   </section>
</template>