import type { ConditionI } from '@/models/conditions'
import type { BaseQueryParam } from '@/models/https/baseRespones'
import type { Pagination } from '@/models/https/pagination'

export function buildUrlWithQueryOptions(url: string, options: ConditionI | Pagination | BaseQueryParam) {
  const query = new URLSearchParams(options as unknown as Record<string, string>)
  return url + '?' + query
}

// 2. Implement the method
String.prototype.isNullOrEmpty = function (): boolean {
  return !this || this.trim().length === 0;
};