import Repository from '@/lib/https/repository'
import type { AdminPagination, Pagination } from '@/models/https/pagination'
import { buildUrlWithQueryOptions } from '@/lib/utils/stringUtils'
import type { IAdmin } from '@/models/admin'
import { navigate } from '../utils/common'

export const adminUrl = '/admins'
const accountUrl = '/accounts'
const exportCsvUrl = adminUrl + '/export-csv'
const selectAccountUrl = adminUrl + '/select-account'
const inviteUrl = adminUrl + '/invite'
const reinviteUrl = '/re-invite'
const logout = adminUrl + '/logout'
const myProfileUrl = '/my-profile'
const adminAvatarUrl = '/avatar'
const adminEmailUrl = '/email'
const adminNameUrl = '/full-name'

export default class AdminRepository extends Repository {
  logout = async () => {
    const response = await this.axios.post(logout)
    if (response) {
      navigate('Login')
      return response
    }
    return null
  }

  registerAccount = async (formData: FormData) => {
    const response = await this.axios.post(accountUrl, formData)
    if (response) return response
    return null
  }

  selectAccount = async (formData: FormData) => {
    const response = await this.axios.post(selectAccountUrl, formData)
    if (response) return response
    return null
  }

  inviteAdmin = async (data: { fullName: string; email: string; applicationRole: number }) => {
    const response = await this.axios.post(inviteUrl, JSON.stringify(data))
    if (response) return response
    return null
  }

  reinviteAdmin = async (adminId: string) => {
    const response = await this.axios.post(`${adminUrl}/${adminId}${reinviteUrl}`)
    if (response) return response
    return null
  }
  //Admin Operation
  getAdmins = async (options: AdminPagination) => {
    const url = buildUrlWithQueryOptions(adminUrl, options)
    const response = await this.axios.get(url)
    if (response.data) return response.data
    return null
  }

  //get Admin by Id
  getAdminById = async (adminId: string) => {
    const response = await this.axios.get<IAdmin>(adminUrl.concat(`/${adminId}`))
    if (response.data) return response.data
    return null
  }

  //put admin
  updateAdmin = async (admin: IAdmin) => {
    const data = {
      "email": admin.email,
      "applicationRole": +admin.applicationRole
    }
    const response = await this.axios.put(adminUrl.concat(`/${admin.id}`), JSON.stringify(data))
    if (response) return response
    return null
  }

  //post admin
  registerAdmin = async (admin: IAdmin) => {
    const response = await this.axios.post(adminUrl, JSON.stringify(admin))
    if (response) return response
    return null
  }

  //delete admin
  deleteAdmin = async (adminId: string) => {
    const response = await this.axios.delete(adminUrl.concat(`/${adminId}`))
    if (response) return response
    return null
  }

  //exportCsvUrl
  exportCSVAdmin = async (options: Pagination) => {
    const url = buildUrlWithQueryOptions(exportCsvUrl, options)
    const response = await this.axios.get(url)
    if (response) return response.data
    return null
  }

  //my profile
  getMyProfile = async (redirectOnError = true) => {
    const response = await this.axios.get<IAdmin>(adminUrl.concat(myProfileUrl), {
      params: {
        redirectOnError: redirectOnError
      }
    })
    if (response.data) return response.data
    return null
  }

  //formData contains: avatarFile -> file
  updateAdminAvatar = async (adminId: string, formData: FormData) => {
    const response = await this.axios.put(adminUrl.concat(`/${adminId}`, adminAvatarUrl), formData)
    if (response.data) return response.data
    return null
  }
}
