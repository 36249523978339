<style scoped>
.member-card {
    height: 100%;
    background-color: #fff;
    border: none;
}

.member-card-blue {
    background-image: url("@/assets/images/bg-package-blue.svg");
    background-size: cover;
}

.member-card-red {
    background-image: url("@/assets/images/bg-package-red.svg");
    background-size: cover;
}

.member-card-yellow {
    background-image: url("@/assets/images/bg-package-yellow.svg");
    background-size: cover;
}

.member-card-cyan {
    background-image: url("@/assets/images/bg-package-cyan.svg");
    background-size: cover;
}

.calendar-card-red {
    background-image: url("@/assets/images/bg-package-calendar-red.svg");
    background-size: cover;
}

.calendar-card-yellow {
    background-image: url("@/assets/images/bg-package-calendar-yellow.svg");
    background-size: cover;
}

.member-card-bottom {
    position: relative;
    bottom: 0em;
}

.text-color-black {
    color: black !important;
}

.text-color-red {
    color: #c10306 !important;
}
</style>

<script lang="ts" setup>
import { numberWithCommas } from '@/lib/utils/common';

export interface ServiceItemData {
    ServiceId: number,
    SeviceName: string,
    Price: number,
    Expire: number,
    UsageCount: number,
    UsageCountName: string,
    Image: string,
    CreateDate: string,
    ClassStyle: string,
    RealPrice: number,
    DiscountAmmount: number,
    FromDate: string,
    ToDate: string,
    BuyButtonName: string,
}

const props = defineProps<{
    itemData: ServiceItemData,
    textStyle: string,
}>();

const emits = defineEmits<{
    (event: 'onBuyClick', data: {
        ServiceId: number, Price: number, Expire: number, UsageCount: number, Discount: number, RealPrice: number
    }): void
}>();

const onBuy = () => {
    emits('onBuyClick', {
        ServiceId: props.itemData.ServiceId,
        Price: props.itemData.Price,
        Expire: props.itemData.Expire,
        UsageCount: props.itemData.UsageCount,
        Discount: props.itemData.DiscountAmmount,
        RealPrice: props.itemData.RealPrice,
    });
}

</script>

<template>
    <div class="col-6 col-xl-3 mb-3">
        <div :class="itemData.ClassStyle">
            <div class="circle1"></div>
            <div class="circle2"></div>
            <div class="wavy">
                <svg width="280" height="94" viewBox="0 0 280 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_55926_131532)">
                        <path
                            d="M60.6036 7.52478C81.659 4.505 103.914 7.49047 120.58 11.1083C138.458 14.9891 156.412 19.0551 174.676 20.0981C208.379 22.0229 242.069 16.0863 273.084 2.75728L279.5 0L288 94.172H0L2.12695 78.4783C2.37549 76.6443 2.70239 74.8227 3.19614 73.039C6.50632 61.0807 22.12 13.0441 60.6036 7.52478Z"
                            fill="url(#paint0_linear_55926_131532)" fill-opacity="0.24" />
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_55926_131532" x1="176.5" y1="84.949" x2="-11.726"
                            y2="-37.5539" gradientUnits="userSpaceOnUse">
                            <stop stop-color="white" />
                            <stop offset="1" stop-color="white" stop-opacity="0" />
                        </linearGradient>
                        <clipPath id="clip0_55926_131532">
                            <rect width="280" height="94" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </div>
            <div class="card-body px-0">
                <div class="service-info">
                    <h5 class="name mb-3" :class="textStyle">{{ itemData.SeviceName }}</h5>
                    <div class="content" :class="textStyle" v-if="itemData.UsageCount > 0">
                        <small class="title" :class="textStyle">{{ itemData.UsageCountName }}:</small>
                        <p class="text">{{ itemData.UsageCount >= 10000 ? "Không giới hạn" : itemData.UsageCount }}</p>
                    </div>
                    <div class="content" :class="textStyle" v-if="itemData.Expire < 100000">
                        <small class="title" :class="textStyle">Thời gian:</small>
                        <p class="text"> {{ itemData.Expire }} ngày</p>
                    </div>
                    <!-- Discount -->
                    <div class="content" :class="textStyle">
                        <small class="title" style="text-decoration: line-through;">{{ itemData.RealPrice < 0 ? '' :
                            numberWithCommas(itemData.RealPrice) }}</small>
                    </div>
                    <div class="content" :class="textStyle">
                        <small class="title">{{ itemData.DiscountAmmount > 0 ? `${itemData.FromDate} ->
                            ${itemData.ToDate}` : "" }}</small>
                    </div>
                    <h5 class="money mb-3" :class="textStyle">{{ numberWithCommas(itemData.Price) }}đ</h5>

                    <a v-on:click="onBuy"
                        class="member-card-bottom btn btn-sm fw-bold btn-light bg-white text-theme-color px-4 rounded-pill">
                        {{ itemData.BuyButtonName }}
                    </a>
                </div>

            </div>
        </div>
    </div>
</template>