import Repository from "@/lib/https/repository"
import type { AxiosResponse } from "axios"
import { buildUrlWithQueryOptions } from "../utils/stringUtils"
import type { IBuyDrawNumberPacketParam, IBuyPacketServiceParam, IBuyPerpetualCalendarPacketParam, IGetPacketServiceParam } from "@/models/https/packetServiceRequests"
import type { IBuyDestinyAnalysisRes, IBuyDrawNumberPacketRes, IBuyPerpetualCalendarPacketRes, IDestinyAnalysisRes, IDrawNumberPacketRes, IPerpetualCalendarPacketRes } from "@/models/https/packetServiceResponse"

export const getPacketServiceUrl = "/api/masterdata/masterdata_get_dynamic";
export const buyPacketDestinyAnlysisUrl = "/api/donhang/buy_service";
export const buyPacketDrawNumberUrl = "/api/donhang/buy_queso";
export const buyPacketPerpetualCalendarUrl = "/api/donhang/buy_lvn";


export default class PacketServiceRepository extends Repository {

  getPacketDestinyAnalysis =  async (data:IGetPacketServiceParam) => {
    const url = buildUrlWithQueryOptions(getPacketServiceUrl, data)
    const response = await this.axios.post<IDestinyAnalysisRes>(url, {});
    if (response.data) return response.data;
    return null;
  } 

  getPacketDrawNumber =  async (data:IGetPacketServiceParam) => {
    const url = buildUrlWithQueryOptions(getPacketServiceUrl, data)
    const response = await this.axios.post<IDrawNumberPacketRes>(url, {});
    if (response.data) return response.data;
    return null;
  } 

  getPacketPerpetualCalendar =  async (data:IGetPacketServiceParam) => {
    const url = buildUrlWithQueryOptions(getPacketServiceUrl, data)
    const response = await this.axios.post<IPerpetualCalendarPacketRes>(url, {});
    if (response.data) return response.data;
    return null;
  } 

  buyPacketDestinyAnalysis = async (data:IBuyPacketServiceParam) => {
    const url = buildUrlWithQueryOptions(buyPacketDestinyAnlysisUrl, data)
    const response = await this.axios.post<IBuyDestinyAnalysisRes>(url, {});
    if (response.data) return response.data;
    return null;
  } 

  buyPacketDrawNumber = async (data:IBuyDrawNumberPacketParam) => {
    const url = buildUrlWithQueryOptions(buyPacketDrawNumberUrl, data)
    const response = await this.axios.post<IBuyDrawNumberPacketRes>(url, {});
    if (response.data) return response.data;
    return null;
  } 

  buyPacketPerpetualCalendar = async (data:IBuyPerpetualCalendarPacketParam) => {
    const url = buildUrlWithQueryOptions(buyPacketPerpetualCalendarUrl, data)
    const response = await this.axios.post<IBuyPerpetualCalendarPacketRes>(url, {});
    if (response.data) return response.data;
    return null;
  } 
}
