import type { ApplicationState } from "../application/state"


export class GenderAndCity {
  genders = [
    {
      id: 1,
      value1: "Nam",
    },
    {
      id: 2,
      value1: "Nữ",
    }
  ]
  citys = [
    {
      id: 59,
      value2: "Hà Nội"
    },
    {
      id: 22,
      value2: "Hồ Chí Minh City"
    },
    {
      id: 60,
      value2: "Hải Phòng"
    },
    {
      id: 33,
      value2: "Cần Thơ"
    },
    {
      id: 13,
      value2: "Đà Nẵng"
    },
    {
      id: 18,
      value2: "Đồng Nai"
    },
    {
      id: 23,
      value2: "An Giang"
    },
    {
      id: 14,
      value2: "Bà Rịa - Vũng Tàu"
    },
    {
      id: 36,
      value2: "Bắc Giang"
    },
    {
      id: 37,
      value2: "Bắc Kạn"
    },
    {
      id: 24,
      value2: "Bạc Liêu"
    },
    {
      id: 38,
      value2: "Bắc Ninh"
    },
    {
      id: 25,
      value2: "Bến Tre"
    },
    {
      id: 1,
      value2: "Bình Định"
    },
    {
      id: 15,
      value2: "Bình Dương"
    },
    {
      id: 16,
      value2: "Bình Phước"
    },
    {
      id: 17,
      value2: "Bình Thuận"
    },
    {
      id: 26,
      value2: "Cà Mau"
    },
    {
      id: 39,
      value2: "Cao Bằng"
    },
    {
      id: 2,
      value2: "Đak Lak"
    },
    {
      id: 3,
      value2: "Đak Nông"
    },
    {
      id: 40,
      value2: "Điện Biên"
    },
    {
      id: 27,
      value2: "Đồng Tháp"
    },
    {
      id: 4,
      value2: "Gia Lai"
    },
    {
      id: 41,
      value2: "Hà Giang"
    },
    {
      id: 42,
      value2: "Hà Nam"
    },
    {
      id: 43,
      value2: "Hà Tĩnh"
    },
    {
      id: 44,
      value2: "Hải Dương"
    },
    {
      id: 28,
      value2: "Hậu Giang"
    },
    {
      id: 45,
      value2: "Hòa Bình"
    },
    {
      id: 46,
      value2: "Hưng Yên"
    },
    {
      id: 5,
      value2: "Khánh Hòa"
    },
    {
      id: 29,
      value2: "Kiên Giang"
    },
    {
      id: 6,
      value2: "Kon Tum"
    },
    {
      id: 47,
      value2: "Lai Châu"
    },
    {
      id: 19,
      value2: "Lâm Đồng"
    },
    {
      id: 48,
      value2: "Lạng Sơn"
    },
    {
      id: 49,
      value2: "Lào Cai"
    },
    {
      id: 30,
      value2: "Long An"
    },
    {
      id: 50,
      value2: "Nam Định"
    },
    {
      id: 51,
      value2: "Nghệ An"
    },
    {
      id: 52,
      value2: "Ninh Bình"
    },
    {
      id: 20,
      value2: "Ninh Thuận"
    },
    {
      id: 53,
      value2: "Phú Thọ"
    },
    {
      id: 7,
      value2: "Phú Yên"
    },
    {
      id: 8,
      value2: "Quảng Bình"
    },
    {
      id: 9,
      value2: "Quảng Nam"
    },
    {
      id: 10,
      value2: "Quảng Ngãi"
    },
    {
      id: 54,
      value2: "Quảng Ninh"
    },
    {
      id: 11,
      value2: "Quảng Trị"
    },
    {
      id: 31,
      value2: "Sóc Trăng"
    },
    {
      id: 55,
      value2: "Sơn La"
    },
    {
      id: 21,
      value2: "Tây Ninh"
    },
    {
      id: 56,
      value2: "Thái Bình"
    },
    {
      id: 57,
      value2: "Thái Nguyên"
    },
    {
      id: 58,
      value2: "Thanh Hóa"
    },
    {
      id: 12,
      value2: "Thừa Thiên - Huế"
    },
    {
      id: 32,
      value2: "Tiền Giang"
    },
    {
      id: 34,
      value2: "Trà Vinh"
    },
    {
      id: 61,
      value2: "Tuyên Quang"
    },
    {
      id: 35,
      value2: "Vĩnh Long"
    },
    {
      id: 62,
      value2: "Vĩnh Phúc"
    },
    {
      id: 63,
      value2: "Yên Bái"
    }
  ]
}
