import type { ISimPurchaseHistory } from "@/models/PurchaseHistory";
import Repository from "./repository";
import type { BaseResponse } from "@/models/https/baseRespones";
const GET_ORDERS_URL = '/api/donhang/get_order_by_cus';
const GET_SERVICE_ORDERS_URL = '/api/donhang/get_order_dich_vu_by_cus';
const GET_PERPETUAL_CALENDAR_SERVICE_ORDERS_URL = '/api/donhang/get_order_lvn_by_cus';
const GET_DRAW_ORDER_URL = '/api/donhang/get_order_quay_so_by_cus';
export default class EconomyRepository extends Repository {
    getOrders = async (status: string, page_index: number = 1, page_size: number = 10) => {
        const url = `${GET_ORDERS_URL}?status=${status}&page_index=${page_index}&page_size=${page_size}`;
        return await this.axios.post<BaseResponse<ISimPurchaseHistory[]>>(url, { status, page_index, page_size })
            .then(({ data }) => data)
            .catch(err => {
                return err;
            });
    };
}