<style setup scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import { PopupController, PoupType } from '@/lib/popup';
import { getOriginPath } from '@/lib/utils/common';
import FormEnhancementNumber, { FormType, type FormData } from '../parts/LifeEnhancementNumber/FormEnhancementNumber.vue';
import { ref } from 'vue';

const phoneNumberFormData: FormData = {
   Title: "Tìm Sim đúng ý, đổi vận liền tay",
   Description: "Mời Quý khách nhập thông tin và số điện thoại đang dùng để được luận giải về mức độ tốt xấu của số điện thoại so với bản mệnh của mình!",
   SpecialInputLabel: "Số hiện tại",
   ChangeFortunePlaceHoder: "Nhập số cải vận",
   SpecialInputPlaceHolder: "",
   FormType: FormType.PhoneNumber,
   SpecialInputId: "txtPhoneNumber",
};

const licsenPlateFormData: FormData = {
   Title: "Biển số xe theo phong thủy",
   Description: "Mời Quý khách nhập thông tin và biển số xe đang dùng để được luận giải về mức độ tốt xấu của biển số so với bản mệnh của mình!",
   SpecialInputLabel: "Biển số xe",
   ChangeFortunePlaceHoder: "Nhập biển số xe cải vận",
   SpecialInputPlaceHolder: "",
   FormType: FormType.LicensePlate,
   SpecialInputId: "txtLicsenPlate",
};

const bankAccountFormData: FormData = {
   Title: "Số tài khoản theo phong thủy",
   Description: "Mời Quý khách nhập thông tin và số tài khoản đang dùng để được luận giải về mức độ tốt xấu của số tài khoản với bản mệnh của mình!",
   SpecialInputLabel: "Số tài khoản",
   ChangeFortunePlaceHoder: "Nhập số tài khoản cải vận",
   SpecialInputPlaceHolder: "",
   FormType: FormType.BankAccountNumber,
   SpecialInputId: "txtBankAccount",
};

const houseNumberFormData: FormData = {
   Title: "Số nhà theo phong thủy",
   Description: "Mời Quý khách nhập thông tin và số nhà đang dùng để được luận giải về mức độ tốt xấu của số nhà với bản mệnh của mình!",
   SpecialInputLabel: "Số nhà",
   ChangeFortunePlaceHoder: "Nhập số số cải vận",
   SpecialInputPlaceHolder: "",
   FormType: FormType.HouseNumber,
   SpecialInputId: "txtHouseNumber",
};

const currentFormType = ref<FormType>(FormType.PhoneNumber);

const onChangeForm = (type:FormType) =>{
   currentFormType.value = type;
}

</script>

<template>
   <div class="info"></div>
   <section class="section-page section-result-linear pb-0">
      <div class="container-fluid">
         <ul class="nav nav-tabs justify-content-sm-center gap-3 border-bottom-0 pb-1" id="myTab" role="tablist"
            style="overflow-y: hidden;">
            <li class="nav-item" role="presentation">
               <button class="nav-link active" id="tab1-tab" data-bs-toggle="tab"  type="button"
                  role="tab" aria-controls="tab1" aria-selected="true" v-on:click="onChangeForm(FormType.PhoneNumber)">
                  <div class="ratio ratio-1x1 m-auto">
                     <img src="@/assets/images/img-tab-phone.png" alt="img-fluid">
                  </div>
                  <p>Số điện thoại</p>
               </button>
            </li>
            <li class="nav-item" role="presentation">
               <button class="nav-link" id="tab3-tab" data-bs-toggle="tab" data-bs-target="#tab3" type="button"
                  role="tab" aria-controls="tab3" aria-selected="false"  v-on:click="onChangeForm(FormType.LicensePlate)">
                  <div class="ratio ratio-1x1 m-auto">
                     <img src="@/assets/images/img-tab-number-car.png" alt="img-fluid">
                  </div>
                  <p>Biển số xe</p>
               </button>
            </li>
            <li class="nav-item" role="presentation">
               <button class="nav-link" id="tab4-tab" data-bs-toggle="tab" data-bs-target="#tab4" type="button"
                  role="tab" aria-controls="tab4" aria-selected="false"  v-on:click="onChangeForm(FormType.BankAccountNumber)">
                  <div class="ratio ratio-1x1 m-auto">
                     <img src="@/assets/images/img-tab-account-number.png" alt="img-fluid">
                  </div>
                  <p>Số tài khoản</p>
               </button>
            </li>
            <li class="nav-item" role="presentation">
               <button class="nav-link" id="tab5-tab" data-bs-toggle="tab" data-bs-target="#tab5" type="button"
                  role="tab" aria-controls="tab5" aria-selected="false"  v-on:click="onChangeForm(FormType.HouseNumber)">
                  <div class="ratio ratio-1x1 m-auto">
                     <img src="@/assets/images/img-tab-number-house.png" alt="img-fluid">
                  </div>
                  <p>Số nhà</p>
               </button>
            </li>
         </ul>
      </div>
      <div class="tab-content" id="myTabContent">
         <FormEnhancementNumber v-show="currentFormType === FormType.PhoneNumber" :formdata="phoneNumberFormData" />
         <!-- Số điện thoại -->

         <FormEnhancementNumber v-show="currentFormType === FormType.LicensePlate"  :formdata="licsenPlateFormData" />
         <!-- Biến số xe -->

         <FormEnhancementNumber v-show="currentFormType === FormType.BankAccountNumber"  :formdata="bankAccountFormData" />
         <!-- Số tài khoản -->

         <FormEnhancementNumber v-show="currentFormType === FormType.HouseNumber"  :formdata="houseNumberFormData" />
         <!-- Số nhà -->
      </div>
   </section>


</template>