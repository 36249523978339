<script lang="ts" setup>
import "bootstrap/dist/js/bootstrap";
import { computed, onMounted, ref, watch } from "vue";
import store from "@/store";
import type { UserProfileSate } from "@/store/userProfile/state";
import router from "@/router";
import defaultAvatar from '@/assets/images/Avatar.png';
import type { Product } from "@/store/cart/state";
import ProductComponent from "./ProductComponent.vue";
import { routerPaths } from "@/router/routes";

const NAV_ITEMS = [
   {
      title: 'Xem số cải vận',
      to: routerPaths.LifeEnhancementNumber
   },
   {
      title: 'Ý nghĩa họ tên',
      to: routerPaths.NameMeaning
   },
   {
      title: 'Quẻ số',
      to: routerPaths.NumberDraw
   },
   {
      title: 'Số Phong Thuỷ',
      subItems: [
         {
            title: 'Số Phong Thuỷ',
            to: routerPaths.FengShuiPhoneNumber
         },
         {
            title: 'Số Theo Tuổi',
            to: routerPaths.ZodiacAsianPhoneNumber
         },
         {
            title: 'Số Theo Nhà Mạng',
            to: routerPaths.MobileNetworkProviderPhoneNumber
         },
         {
            title: 'Số Đẹp',
            to: routerPaths.NicePhoneNumber
         },
      ]
   },
   {
      title: 'Lịch Vạn Niên',
      to: routerPaths.PerpetutalCalendar
   },
   {
      title: 'Tin Tức',
      to: routerPaths.News
   },
]


</script>
<script lang="ts">

const userProfile = computed(() => store.state.userProfile as UserProfileSate);
const products = computed(() => store.state.shoppingCart.products as Array<Product>);


function logOut() {
   localStorage.clear();
   store.commit('userProfile/setProfile', null);
   router.push('/');
}
</script>

<template>
   <nav class="navbar navbar-expand-lg navbar-dark redBG" style="box-shadow: 0px 2px 10px 0px;">
      <div class="container-fluid">
         <RouterLink class="navbar-brand" :to="routerPaths.Home"><img src="@/assets/images/logoLUCTRUMENHLY.svg"
               width="48" height="48px"></RouterLink>

         <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
         </button>
         <div class="collapse navbar-collapse link-size" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 " id="header_menu">
               <li v-for="navItem in NAV_ITEMS" :class="`nav-item li-padding ${navItem.subItems ? 'dropdown' : ''}`">
                  <RouterLink v-if="navItem.subItems == undefined" class="nav-link nav-item-css" :to="navItem.to">
                     {{ navItem.title }}</RouterLink>
                  <a v-else class="nav-link dropdown-toggle nav-item-css" role="button" data-bs-toggle="dropdown"
                     aria-expanded="false">
                     {{ navItem.title }}
                  </a>
                  <ul v-if="navItem.subItems != undefined" class="dropdown-menu dropdown-menu-end submenu">
                     <li v-for="subItem in navItem.subItems">
                        <RouterLink class="dropdown-item" :to="subItem.to">{{ subItem.title }}</RouterLink>
                     </li>
                  </ul>
               </li>
               <li class="nav-item li-padding d-block d-sm-block d-md-block d-lg-none">
                  <div class="text-end group-account d-flex align-items-center dropdown">
                     <div class="dropdown">
                        <a href="#"
                           class="nav-link nav-item-css dropdown-toggle dropdown-header"
                           data-bs-toggle="dropdown" aria-expanded="false">
                           Gỏ hàng/thanh toán <img src="//thaysocaivan.com/assets/images/icons/ic-shopbag.svg"
                              width="30">
                           <span class="position-absolute translate-middle badge rounded-pill bg-danger"
                              id="id_counter">{{
                                 products.length }}</span>
                        </a>

                        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                           <div class="dropdown-item-custom" style="width: 300px;">
                              <h6 class="border-bottom px-3 pb-2 pt-1 mb-0">GIỎ HÀNG CỦA BẠN</h6>
                              <div class="p-3" id="div_cart">
                                 <ProductComponent v-for="product in products" :product="product" />
                              </div>
                              <div class="text-center bg-light p-2 border-top">
                                 <RouterLink class="btn btn-outline-danger rounded-pill me-2"
                                    :to="routerPaths.ShoppingCart" role="button">Đến giỏ
                                    hàng</RouterLink>
                                 <RouterLink class="btn btn-danger rounded-pill" :to="routerPaths.Payment"
                                    role="button">
                                    Thanh
                                    toán</RouterLink>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </li>
               <li class="nav-item li-padding dropdown d-block d-sm-block d-md-block d-lg-none">
                  <div class="text-end group-account d-flex align-items-center">
                     <!------- account isn't logged in ------->
                     <RouterLink v-if="!userProfile.accountToken" :to="routerPaths.Login"
                        class="nav-link nav-item-css" id="btn-signin">Đăng nhập</RouterLink>
                     <!-- <a href="dang-ky" class="btn btn-light rounded-pill" id="btn-signup">Đăng
                  ký</a> -->
                     <!------- account is logged in ------->

                     <div v-else class="dropdown" id="user-login">
                        <a href="#"
                           class="d-flex align-items-center gap-1 link-light text-decoration-none dropdown-toggle"
                           id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                           <img :src="userProfile.avatar || defaultAvatar" alt="mdo" width="32" height="32"
                              class="rounded-circle">
                           <strong class="h6" style="margin: auto;">{{ userProfile.lastName }} ({{
                              userProfile.totalRemainingChartView }} lượt xem)</strong>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end min-w262 text-small shadow" style="width: 300px;">
                           <li>
                              <RouterLink class="dropdown-item d-flex align-items-center" :to="routerPaths.UserInfo">
                                 <img src="//thaysocaivan.com/assets/images/icons/ic-User.svg" width="24" alt="icon ">
                                 <span class="ms-2 me-auto">Thông tin tài
                                    khoản</span>
                                 <div class="text-end">
                                    <img src="//thaysocaivan.com/assets/images/icons/ic-Right.svg" width="14"
                                       alt="icon ">
                                 </div>
                              </RouterLink>
                           </li>
                           <li>
                              <RouterLink class="dropdown-item d-flex align-items-center"
                                 :to="routerPaths.PurchaseHistory">
                                 <img src="//thaysocaivan.com/assets/images/icons/ic-History.svg" width="24"
                                    alt="icon ">
                                 <span class="ms-2 me-auto">Lịch sử mua
                                    hàng</span>
                                 <div class="text-end">
                                    <img src="//thaysocaivan.com/assets/images/icons/ic-Right.svg" width="14"
                                       alt="icon ">
                                 </div>
                              </RouterLink>
                           </li>
                           <li>
                              <RouterLink class="dropdown-item d-flex align-items-center"
                                 :to="routerPaths.PacketService">
                                 <img src="//thaysocaivan.com/assets/images/icons/ic-service.svg" width="24"
                                    alt="icon ">
                                 <span class="ms-2 me-auto">Gói dịch vụ</span>
                                 <div class="text-end">
                                    <img src="//thaysocaivan.com/assets/images/icons/ic-Right.svg" width="14"
                                       alt="icon ">
                                 </div>
                              </RouterLink>
                           </li>
                           <li>
                              <a class="dropdown-item d-flex align-items-center" @click="logOut">
                                 <img src="//thaysocaivan.com/assets/images/icons/ic-logout.svg" width="24" alt="icon ">
                                 <span class="ms-2 me-auto">Đăng xuất</span>
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </li>
            </ul>
            <div class="text-end group-account d-flex align-items-center">
               <div class="dropdown d-none d-lg-block">
                  <a href="#" class="dropdown-toggle btn btn-outline-danger border-0 position-relative btn-cart me-3"
                     data-bs-toggle="dropdown" aria-expanded="false">
                     <img src="//thaysocaivan.com/assets/images/icons/ic-shopbag.svg" width="30">
                     <span class="position-absolute translate-middle badge rounded-pill bg-danger" id="id_counter">{{
                        products.length }}</span>
                  </a>
                  <div class="dropdown-menu dropdown-menu-end text-small shadow cart" style="width: 300px;">
                     <h6 class="border-bottom px-3 pb-2 pt-1 mb-0">GIỎ HÀNG CỦA BẠN</h6>
                     <div class="p-3" id="div_cart">
                        <ProductComponent v-for="product in products" :product="product" />
                     </div>
                     <div class="text-center bg-light p-2 border-top">
                        <RouterLink class="btn btn-outline-danger rounded-pill me-2" :to="routerPaths.ShoppingCart"
                           role="button">Đến giỏ
                           hàng</RouterLink>
                        <RouterLink class="btn btn-danger rounded-pill" :to="routerPaths.Payment" role="button">Thanh
                           toán</RouterLink>
                     </div>
                  </div>
               </div>
               <!------- account isn't logged in ------->
               <RouterLink v-if="!userProfile.accountToken" :to="routerPaths.Login"
                  class="btn btn-warning rounded-pill me-2 d-none d-lg-block" id="btn-signin">Đăng nhập</RouterLink>
               <!-- <a href="dang-ky" class="btn btn-light rounded-pill" id="btn-signup">Đăng
                  ký</a> -->
               <!------- account is logged in ------->
               <div v-else class="dropdown d-none d-lg-block" id="user-login">
                  <a href="#" class="d-flex align-items-center gap-1 link-light text-decoration-none dropdown-toggle"
                     id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                     <img :src="userProfile.avatar || defaultAvatar" alt="mdo" width="32" height="32"
                        class="rounded-circle">
                     <strong class="h6" style="margin: auto;">{{ userProfile.lastName  }} ({{
                        userProfile.totalRemainingChartView }} lượt xem)</strong>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end min-w262 text-small shadow" style="width: 300px;">
                     <li>
                        <RouterLink class="dropdown-item d-flex align-items-center" :to="routerPaths.UserInfo">
                           <img src="//thaysocaivan.com/assets/images/icons/ic-User.svg" width="24" alt="icon ">
                           <span class="ms-2 me-auto">Thông tin tài
                              khoản</span>
                           <div class="text-end">
                              <img src="//thaysocaivan.com/assets/images/icons/ic-Right.svg" width="14" alt="icon ">
                           </div>
                        </RouterLink>
                     </li>
                     <li>
                        <RouterLink class="dropdown-item d-flex align-items-center" :to="routerPaths.PurchaseHistory">
                           <img src="//thaysocaivan.com/assets/images/icons/ic-History.svg" width="24" alt="icon ">
                           <span class="ms-2 me-auto">Lịch sử mua
                              hàng</span>
                           <div class="text-end">
                              <img src="//thaysocaivan.com/assets/images/icons/ic-Right.svg" width="14" alt="icon ">
                           </div>
                        </RouterLink>
                     </li>
                     <li>
                        <RouterLink class="dropdown-item d-flex align-items-center" :to="routerPaths.PacketService">
                           <img src="//thaysocaivan.com/assets/images/icons/ic-service.svg" width="24" alt="icon ">
                           <span class="ms-2 me-auto">Gói dịch vụ</span>
                           <div class="text-end">
                              <img src="//thaysocaivan.com/assets/images/icons/ic-Right.svg" width="14" alt="icon ">
                           </div>
                        </RouterLink>
                     </li>
                     <li>
                        <a class="dropdown-item d-flex align-items-center" @click="logOut">
                           <img src="//thaysocaivan.com/assets/images/icons/ic-logout.svg" width="24" alt="icon ">
                           <span class="ms-2 me-auto">Đăng xuất</span>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </nav>
</template>

<style scoped>
@import '@/assets/vendors/bootstrap/css/bootstrap.min.css';

.nav-item-css {
   color: #fff;
   font-weight: 500;
   text-transform: uppercase !important;
   padding-left: 0 !important;
   padding-right: 0 !important;
   white-space: nowrap !important;
}

.li-padding {
   padding: 0.0 0.5rem !important;
}


.nav-item-css:hover {
   color: #ffc107 !important;
}

.submenu {
   background: linear-gradient(to bottom, rgb(255, 233, 214) 0%, rgb(255, 255, 255) 23%, rgb(255, 255, 255) 77%, rgb(254, 211, 173) 100%);
}

.cart {
   position: absolute;
   inset: 0px 0px auto auto;
   margin: 0px;
   transform: translate3d(-16px, 0px, 0px);
   width: 230px;
}

.nav-item-css:focus {
   border-bottom: 2px solid #ffc107;
   color: #ffc107 !important;
}

.dropdown-item {
   text-transform: uppercase;
}
.dropdown-header {
   text-align: left
}
</style>