<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script setup lang="ts">
import { numberWithCommas } from '@/lib/utils/common';
import { computed, ref } from 'vue';
import CommonRespository from '@/lib/https/commonRespository';
import { RepositoryFactory } from '@/lib/https';
import Swal from 'sweetalert2'
import { enableLoading } from '../parts/LoadingComponent.vue';

const { createVietQR } = RepositoryFactory.getRepository<CommonRespository>(CommonRespository);

let params = new URLSearchParams(window.location.search);
const amountNumber = parseInt(params.get('tong_tien') ?? '0');
const amount = computed(() => numberWithCommas(amountNumber)); // Lấy giá trị của user_id từ URL
const orderId = computed(() => params.get('dhs_ma'));

let is_mobile = 0;
const successedTitle = ref<string>("Tạo đơn hàng mua sim thành công");
const orderPrefix = ref<string>("TSCVS");
if (params.get('param')) {
    is_mobile = 1;
}
const serviceType = params.get("service_type");
if (serviceType) {
    if (serviceType == "xem_so") {
        orderPrefix.value = "TSCVD"       
    }
    else if (serviceType == "que_so") {
        orderPrefix.value = "TSCVQ";       
    }
    else if (serviceType == "lvn") {
        orderPrefix.value = "TSCVL";
    }
    successedTitle.value = "Tạo đơn hàng nâng cấp thành công.";
}

const onPayClick = () => {
    enableLoading(true);
    createVietQR({
        order_no: orderPrefix.value + orderId.value,
        amount: amountNumber,
        content: orderPrefix.value + orderId.value,
        is_mobile: is_mobile,
    }).then(rs => {
        if (rs?.result_code === '000') {
            document.location.href = rs.data;
        }
        else {
            const error: any = {
                icon: "error",
                title: rs?.result_msg,

            }
            Swal.fire(error);
        }
        enableLoading(false);

    }).catch(err => {
        const error: any = {
            icon: "error",
            title: err.message,
        }
        Swal.fire(error);
        enableLoading(false);
    });

}
</script>

<template>
    <div class="header-page d-none" id="div_header_back" style="display:none !important;">
        <a href="../../" class="ic-back"></a>
        <h1 class="title-page">THANH TOÁN</h1>
    </div>
    <section class="section-page section-page-mobile" id="order-success-section">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-sm-10 c0l-md-8 col-lg-10">
                    <svg width="101" style="display: none;" height="100" viewBox="0 0 101 100" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_55055_34433)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M50.5 93.75C62.1032 93.75 73.2312 89.1406 81.4359 80.9359C89.6406 72.7312 94.25 61.6032 94.25 50C94.25 38.3968 89.6406 27.2688 81.4359 19.0641C73.2312 10.8594 62.1032 6.25 50.5 6.25C38.8968 6.25 27.7688 10.8594 19.5641 19.0641C11.3594 27.2688 6.75 38.3968 6.75 50C6.75 61.6032 11.3594 72.7312 19.5641 80.9359C27.7688 89.1406 38.8968 93.75 50.5 93.75ZM50.5 100C63.7608 100 76.4785 94.7322 85.8553 85.3553C95.2322 75.9785 100.5 63.2608 100.5 50C100.5 36.7392 95.2322 24.0215 85.8553 14.6447C76.4785 5.26784 63.7608 0 50.5 0C37.2392 0 24.5215 5.26784 15.1447 14.6447C5.76784 24.0215 0.5 36.7392 0.5 50C0.5 63.2608 5.76784 75.9785 15.1447 85.3553C24.5215 94.7322 37.2392 100 50.5 100Z"
                                fill="#28A745" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M69.0626 31.0625C69.9373 30.1955 71.1181 29.7075 72.3497 29.7041C73.5813 29.7007 74.7648 30.1822 75.6442 31.0444C76.5237 31.9066 77.0285 33.0803 77.0494 34.3117C77.0704 35.5431 76.6059 36.7334 75.7564 37.625L50.8064 68.8125C50.3776 69.2744 49.86 69.6451 49.2847 69.9023C48.7094 70.1596 48.0881 70.2982 47.458 70.3099C46.8278 70.3216 46.2018 70.2061 45.6174 69.9703C45.0329 69.7345 44.502 69.3832 44.0564 68.9375L27.5251 52.4C27.0646 51.9709 26.6952 51.4534 26.439 50.8784C26.1828 50.3034 26.045 49.6827 26.0339 49.0533C26.0228 48.4239 26.1386 47.7987 26.3744 47.2151C26.6101 46.6314 26.961 46.1012 27.4061 45.6561C27.8513 45.2109 28.3815 44.86 28.9651 44.6243C29.5488 44.3885 30.174 44.2727 30.8034 44.2838C31.4328 44.295 32.0535 44.4327 32.6285 44.6889C33.2035 44.9451 33.721 45.3145 34.1501 45.775L47.2376 58.8563L68.9439 31.2C68.9826 31.1516 69.0244 31.1057 69.0689 31.0625H69.0626Z"
                                fill="#28A745" />
                        </g>
                        <defs>
                            <clipPath id="clip0_55055_34433">
                                <rect width="100" height="100" fill="white" transform="translate(0.5)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div class="d-flex align-items-center mb-3 mb-md-3 gap-1" style="padding-top: 15px;" role="alert">
                        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.4419 14.5196L16.9119 0.746645C16.9683 0.664621 17 0.553374 17 0.437373C17 0.19582 16.8654 0 16.6993 0H14.36C14.2802 0 14.2038 0.0460782 14.1474 0.128095L6.62008 11.0753L2.85195 5.59522C2.79555 5.5132 2.71907 5.46713 2.63932 5.46713H0.300716C0.220967 5.46713 0.144482 5.5132 0.088088 5.59522C-0.0293549 5.76601 -0.0293637 6.04294 0.0880676 6.21375L5.55918 14.1719C5.62962 14.2743 5.70705 14.3604 5.78925 14.4301L5.84711 14.5196C6.2875 15.1601 7.00151 15.1601 7.4419 14.5196Z"
                                fill="#00B578" />
                        </svg>
                        <h1 class="h5 text-start text-success">{{ successedTitle }}</h1>
                    </div>
                    <div class="row flex-column-reverse flex-lg-row">
                        <div class="col-lg-8 col-md-12 " id="div_payment_mom">
                            <h3 class="title-group fs-6">Thanh toán</h3>
                            <div class="d-flex gap-3">
                                <div class="form-check">
                                    <input class="form-check-input" onclick="return show_payment_method('div_vietqr')"
                                        type="radio" name="drone" id="huey" value="huey" checked>
                                    <label class="form-check-label" for="huey">
                                        Thanh toán online
                                    </label>
                                </div>
                                <div class="form-check" style="display: none;">
                                    <input class="form-check-input" onclick="return show_payment_method('div_ck')"
                                        type="radio" name="drone" id="dewey" value="dewey">
                                    <label class="form-check-label" for="dewey">
                                        Chuyển khoản
                                    </label>
                                </div>
                            </div>
                            <div id="div_vietqr">
                                <div class="d-block fst-italic mt-3 text-secondary" role="alert">
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.33333 0C11.3834 0 14.6667 3.28325 14.6667 7.33333C14.6667 11.3834 11.3834 14.6667 7.33333 14.6667C3.28325 14.6667 0 11.3834 0 7.33333C0 3.28325 3.28325 0 7.33333 0ZM7.33333 1C3.83553 1 1 3.83553 1 7.33333C1 10.8311 3.83553 13.6667 7.33333 13.6667C10.8311 13.6667 13.6667 10.8311 13.6667 7.33333C13.6667 3.83553 10.8311 1 7.33333 1ZM11.3133 5.22734L7.11438 9.42606C6.91912 9.62132 6.60254 9.62132 6.40727 9.42606L6.38162 9.39876C6.34518 9.37752 6.31084 9.35128 6.27961 9.32005L3.8538 6.894C3.80173 6.84192 3.80173 6.7575 3.85381 6.70544C3.87881 6.68043 3.91272 6.66639 3.94808 6.66639H4.98498C5.02034 6.66639 5.05426 6.68043 5.07926 6.70544L6.75 8.37605L10.0875 5.03877C10.1125 5.01377 10.1464 4.99972 10.1818 4.99972H11.219C11.2926 4.99972 11.3523 5.05942 11.3523 5.13306C11.3523 5.16842 11.3383 5.20233 11.3133 5.22734Z"
                                            fill="#6C757D" />
                                    </svg>
                                    Vui lòng không đóng ứng dụng hoặc trình duyệt trong quá trình thanh toán.
                                </div>
                                <div class="d-block fst-italic mt-2 text-secondary" role="alert">

                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.33333 0C11.3834 0 14.6667 3.28325 14.6667 7.33333C14.6667 11.3834 11.3834 14.6667 7.33333 14.6667C3.28325 14.6667 0 11.3834 0 7.33333C0 3.28325 3.28325 0 7.33333 0ZM7.33333 1C3.83553 1 1 3.83553 1 7.33333C1 10.8311 3.83553 13.6667 7.33333 13.6667C10.8311 13.6667 13.6667 10.8311 13.6667 7.33333C13.6667 3.83553 10.8311 1 7.33333 1ZM11.3133 5.22734L7.11438 9.42606C6.91912 9.62132 6.60254 9.62132 6.40727 9.42606L6.38162 9.39876C6.34518 9.37752 6.31084 9.35128 6.27961 9.32005L3.8538 6.894C3.80173 6.84192 3.80173 6.7575 3.85381 6.70544C3.87881 6.68043 3.91272 6.66639 3.94808 6.66639H4.98498C5.02034 6.66639 5.05426 6.68043 5.07926 6.70544L6.75 8.37605L10.0875 5.03877C10.1125 5.01377 10.1464 4.99972 10.1818 4.99972H11.219C11.2926 4.99972 11.3523 5.05942 11.3523 5.13306C11.3523 5.16842 11.3383 5.20233 11.3133 5.22734Z"
                                            fill="#6C757D" />
                                    </svg>
                                    Vui lòng thực hiện thanh toán trong vòng 15 phút kể từ thời điểm nhấn
                                    <strong>'Bắt đầu
                                        thanh toán'</strong>.
                                </div>
                                <div class="text-center my-4">
                                    <img src="@/assets/images/vietqr.png" width="140px" class="mb-3">
                                    <br />
                                    <button id="btnUpdate" class="btn btn-lg btn-danger rounded-pill min-w262"
                                        v-on:click="onPayClick">Bắt đầu thanh toán</button>
                                </div>
                                <h4 class="fs-6 mt-4 title-box">Hướng dẫn thanh toán</h4>
                                <div class="accordion my-3" id="accordionFlushExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="flush-headingOne">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                                aria-expanded="false" aria-controls="flush-collapseOne">
                                                Hướng dẫn thanh toán trên laptop
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">
                                                <div class="row guide-payment">
                                                    <div class="col-12">
                                                        <div class="d-flex align-items-center">
                                                            <span class="number-circle">01</span>
                                                            <p class="description">Bấm vào 'Bắt đầu thanh toán' để
                                                                chuyển qua trang
                                                                Vietqr
                                                                tạo mã QR
                                                                thanh
                                                                toán</p>
                                                        </div>
                                                        <img src="@/assets/images/img-payment-web1.png"
                                                            alt="Hướng dẫn thanh toán" class="img-fluid w-100">
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="d-flex align-items-center">
                                                            <span class="number-circle">02</span>
                                                            <p class="description">Dùng điện thoại mở ứng dụng Momo,Zalo
                                                                hoặc các ứng
                                                                dụng
                                                                ngân hàng.</p>
                                                        </div>
                                                        <img src="@/assets/images/img-payment-web2.png"
                                                            alt="Hướng dẫn thanh toán" class="img-fluid w-100">
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="d-flex align-items-center">
                                                            <span class="number-circle">03</span>
                                                            <p class="description">Tìm và sử dụng chức năng Qrcode trên
                                                                ứng dụng rồi
                                                                scan
                                                                mã QR để
                                                                thanh toán.</p>
                                                        </div>
                                                        <img src="@/assets/images/img-payment-web3.png"
                                                            alt="Hướng dẫn thanh toán" class="img-fluid w-100">
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="d-flex align-items-center">
                                                            <span class="number-circle">04</span>
                                                            <p class="description">Thanh toán thành công trả về trang
                                                                thay số cải vận
                                                                thành công.</p>
                                                        </div>
                                                        <img src="@/assets/images/img-payment-web4.png"
                                                            alt="Hướng dẫn thanh toán" class="img-fluid w-100">
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="d-flex align-items-center">
                                                            <span class="number-circle">05</span>
                                                            <p class="description">Vui lòng truy cập <strong>Lịch sử đặt
                                                                    hàng</strong> để kiểm tra trạng thái đơn hàng.</p>
                                                        </div>
                                                        <img src="@/assets/images/img-payment-sim-web5.png"
                                                            alt="Hướng dẫn thanh toán" class="img-fluid w-100">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id="flush-headingTwo">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                                                aria-expanded="false" aria-controls="flush-collapseTwo">
                                                Hướng dẫn thanh toán trên điện thoại
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" class="accordion-collapse collapse"
                                            aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body p-1 p-sm-3">
                                                <div class="guide-payment">
                                                    <div class="row align-items-center">
                                                        <div class="col-6">
                                                            <div class="d-flex">
                                                                <span class="number-circle">01</span>
                                                                <p class="description">Bấm vào 'Bắt đầu thanh toán' để
                                                                    chuyển qua trang
                                                                    Vietqr tạo mã QR
                                                                    thanh
                                                                    toán</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <img src="@/assets/images/img-payment-app1.png"
                                                                alt="Hướng dẫn thanh toán" class="img-fluid w-100">
                                                        </div>
                                                        <div class="col-6">
                                                            <img src="@/assets/images/img-payment-app2.png"
                                                                alt="Hướng dẫn thanh toán" class="img-fluid w-100">
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="d-flex">
                                                                <span class="number-circle">02</span>
                                                                <p class="description">Chụp màn hình điện thoại có chứa
                                                                    mã QRCode VietQr
                                                                    cần thanh toán
                                                                    lưu vào
                                                                    thư viện ảnh trong điện thoại</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="d-flex">
                                                                <span class="number-circle">03</span>
                                                                <p class="description">Mở ứng dụng Momo,Zalo hoặc các
                                                                    ứng dụng ngân
                                                                    hàng.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <img src="@/assets/images/img-payment-web2.png"
                                                                alt="Hướng dẫn thanh toán" class="img-fluid w-100">
                                                        </div>
                                                        <div class="col-6">
                                                            <img src="@/assets/images/img-payment-app4.png"
                                                                alt="Hướng dẫn thanh toán" class="img-fluid w-100">
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="d-flex">
                                                                <span class="number-circle">04</span>
                                                                <p class="description">Tìm và sử dụng chức năng Qrcode
                                                                    trên ứng dụng ở
                                                                    bước
                                                                    3 rồi chọn
                                                                    ảnh đã chụp
                                                                    ở bước 2 để thanh toán.</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="d-flex">
                                                                <span class="number-circle">05</span>
                                                                <p class="description">Thanh toán thành công trả về
                                                                    trang thay số cải
                                                                    vận
                                                                    thành công.</p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <img src="@/assets/images/img-payment-app5.png"
                                                                alt="Hướng dẫn thanh toán" class="img-fluid w-100">
                                                        </div>
                                                        <div class="col-6">
                                                            <img src="@/assets/images/img-payment-sim-app6.png"
                                                                alt="Hướng dẫn thanh toán" class="img-fluid w-100">
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="d-flex">
                                                                <span class="number-circle">06</span>
                                                                <p class="description">Vui lòng truy cập <strong>Lịch sử
                                                                        đặt hàng</strong> để kiểm tra trạng thái đơn
                                                                    hàng.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="div_ck" style="display: none;">
                                <p class="mt-3 mb-2 text-muted fst-italic">
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.33333 0C11.3834 0 14.6667 3.28325 14.6667 7.33333C14.6667 11.3834 11.3834 14.6667 7.33333 14.6667C3.28325 14.6667 0 11.3834 0 7.33333C0 3.28325 3.28325 0 7.33333 0ZM7.33333 1C3.83553 1 1 3.83553 1 7.33333C1 10.8311 3.83553 13.6667 7.33333 13.6667C10.8311 13.6667 13.6667 10.8311 13.6667 7.33333C13.6667 3.83553 10.8311 1 7.33333 1ZM11.3133 5.22734L7.11438 9.42606C6.91912 9.62132 6.60254 9.62132 6.40727 9.42606L6.38162 9.39876C6.34518 9.37752 6.31084 9.35128 6.27961 9.32005L3.8538 6.894C3.80173 6.84192 3.80173 6.7575 3.85381 6.70544C3.87881 6.68043 3.91272 6.66639 3.94808 6.66639H4.98498C5.02034 6.66639 5.05426 6.68043 5.07926 6.70544L6.75 8.37605L10.0875 5.03877C10.1125 5.01377 10.1464 4.99972 10.1818 4.99972H11.219C11.2926 4.99972 11.3523 5.05942 11.3523 5.13306C11.3523 5.16842 11.3383 5.20233 11.3133 5.22734Z"
                                            fill="#6C757D" />
                                    </svg>
                                    <span>Vui lòng chuyển tiền trong vòng 24h theo thông tin
                                        bên
                                        dưới.</span>
                                </p>
                                <div
                                    class="d-flex flex-wrap flex-column-reverse flex-sm-row justify-content-center align-items-center gap-2 bg-light border rounded mb-3">
                                    <div class="p-3">
                                        <img src="@/assets/images/tk_ltml.png" style="width: 200px;">
                                    </div>
                                    <div class="pt-3">
                                        <p class="" style="padding-top: 5px;">Ngân hàng Techcombank</p>
                                        <div class="d-flex align-items-center" style="padding-top: 5px;">
                                            <span>Số TK: </span>
                                            <span class="text-success "> 19030406198021</span>
                                            <img class="cursor-poiter" id="img_s_tk"
                                                src="@/assets/images/icon_copy.png" style="width: 40px"
                                                onclick="copyTextToClipboard1('19030406198021','img_s_tk')"></img>
                                        </div>
                                        <p class="" style="padding-top: 5px;">Chủ TK: Phạm Phương Phong</p>
                                        <p class="" style="padding-top: 5px;">Chi nhánh Quận 1 - TP HCM</p>
                                        <div class="d-flex align-items-center" style="padding-top: 5px;"><span>Nội dung
                                                chuyển
                                                tiền: </span>
                                            <span id="userIdLabel" class="text-success "></span>
                                            <img class="cursor-poiter" id="img_s_nd"
                                                src="@/assets/images/icon_copy.png" width="40"
                                                onclick="copyTextToClipboard1('11','img_s_nd')"></img>
                                        </div>
                                        <!-- <p class="" id="user_money" style="padding-top: 5px;">Số tiền cần chuyển: ??? VND</p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mb-4">
                            <h3 class="title-group fs-6">Đơn hàng sim</h3>
                            <div class="ticket position-sticky top-0">
                                <div class="purchase-section flex-fill flex-vertical">
                                    <ul class="purchase-props">
                                        <!-- <li class="d-flex justify-content-between">
                                 <span>Mã đơn hàng</span>
                                 <strong>Bạc</strong>
                              </li> -->
                                        <li class="d-flex justify-content-between">
                                            <span>Mã đơn hàng</span>
                                            <span class="fw-bold" id="userIdLabel1">{{ orderPrefix }}{{ orderId
                                                }}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="separation-line"></div>
                                <div class="total-section d-flex justify-content-between flex-vertical-center">
                                    <div class="flex-fill flex-vertical">
                                        <div class="total-label f-secondary-color">Số tiền cần thanh toán</div>
                                        <div class="">
                                            <span class="total-payment" id="user_money">{{ amount }}</span>
                                            <small><span class="f-secondary-color">đ</span></small>
                                        </div>
                                    </div>
                                    <i class="ai-coin size-lg"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
