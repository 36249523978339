export default {
  detail: "Detail",
  delete: "Delete",
  downloadJson: "JSON Download",
  required: "Please enter",
  update: "Update",
  downloadCSV: "CSV Download",
  filter: "Filter",
  author: "Author",
  logout: "Logout",
  viewDetails: "View the details",
  deleteButton: "Delete",
  subject: "Subject",
  goToPage: "Go to page",
  edit: "To edit",
  updateDateAndTime: "Updated",
  row: "Row",
  loading: "Loading...",
  status: "Status",
  sub: "Sub",
  icon: "Icon",
  next: "Next",
  name: "Name",
  placeHolderInput: "Please enter text",
  placeHolderDropDown: "Please select",
  filterByDate: "Filter by notification date",
  startDate: "Start date",
  endDate: "End date",
  yearLabel: "Year",
  year: "Year",
  days: "Day",
  monthLabel: "Month",
  month: "Month",
  formatEmail: "The email address format is not correct.",
  filterUpdateDay: "Filter by update date",
  add: "Add",
  empty: "—",
  fieldNotExists: "{field} does not exist",
  RoleOwner: "Owner",
  RoleAdmin: "Admin",
  RoleViewer: "Viewer",
  formatUrl: "The URL is not formatted properly.",
  formatDomain: "The domain URL is not properly formatted.",
  formatUUID: "The UUID is not in the correct format.",
  errorPermission: "You do not have permission to do this",
  welcome: "Welcome to the Demo Blogs",
  introduction: "xxxxxxxx",
  back: "Back",
  msgSuccess: "Successfully processed",
}
