<template>
   <form class="form-search mb-4 mb-sm-0" @submit.prevent="onSearch" :data-gtm-form-interact-id="suffixId">
      <label class="title-box mb-2">Chọn theo tiêu chí</label>
      <div class="group-search d-flex align-items-center gap-2 mb-2">
         <div class="input-group input-group-lg">
            <input ref="txt_key" type="text" class="form-control" placeholder="Nhập số điện thoại..."
               aria-describedby="txt-search" :value="textSearch" v-on:input="onInput">
            <button class="input-group-text bg-light" id="txt-search" v-on:click="onSearch">
               <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M11.7422 10.3439C12.5329 9.2673 13 7.9382 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13C7.93858 13 9.26801 12.5327 10.3448 11.7415L10.3439 11.7422C10.3734 11.7822 10.4062 11.8204 10.4424 11.8566L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L11.8566 10.4424C11.8204 10.4062 11.7822 10.3734 11.7422 10.3439ZM12 6.5C12 9.53757 9.53757 12 6.5 12C3.46243 12 1 9.53757 1 6.5C1 3.46243 3.46243 1 6.5 1C9.53757 1 12 3.46243 12 6.5Z"
                     fill="#212529" />
               </svg>
            </button>
         </div>
         <ul class="nested-list">
            <li>Tìm sim có số 6789 bạn hãy gõ 6789</li>
            <li>Tìm sim có đầu 090 đuôi 8888 hãy gõ 090*8888</li>
            <li>Tìm sim bắt đầu bằng 0914 đuôi bất kỳ, hãy gõ: 0914*</li>
         </ul>
      </div>
      <div class="d-flex flex-wrap gap-2">
         <input @change="onAllCheckChange($event)" type="checkbox" class="btn-check" :ref="el => allCheck = el"
            :id="'btn-check-all' + suffixId" name="btnCheckAll" autocomplete="off">
         <label class="btn btn-sm btn-light" :for="'btn-check-all' + suffixId">Tất cả</label>

         <div :ref="el => (groupNetwork = el)" :id="'option-network'" class="btn-group" role="group"
            aria-label="group1">
            <input @change="onAnyGroupCheckChange($event)" type="radio" class="btn-check" name="btnNetwork"
               :id="'btnViettel' + suffixId" autocomplete="off" value="Viettel">
            <label class="btn btn-sm btn-light" :for="'btnViettel' + suffixId">Viettel</label>

            <input @change="onAnyGroupCheckChange($event)" type="radio" class="btn-check" name="btnNetwork"
               :id="'btnVinaphone' + suffixId" autocomplete="off" value="Vinaphone">
            <label class="btn btn-sm btn-light" :for="'btnVinaphone' + suffixId">Vinaphone</label>

            <input @change="onAnyGroupCheckChange($event)" type="radio" class="btn-check" name="btnNetwork"
               :id="'btnMobifone' + suffixId" autocomplete="off" value="Mobifone">
            <label class="btn btn-sm btn-light" :for="'btnMobifone' + suffixId">Mobifone</label>
         </div>
         <div class="btn-group">
            <!-- #onchange="onChangeDauSo(1)" -->
            <select @change="onAnySelectChange($event)" class="btn btn-sm btn-light" ref="select_first_number">
               <option value="0">Đầu số</option>
               <option value="09">Đầu số 09</option>
               <option value="08">Đầu số 08</option>
               <option value="07">Đầu số 07</option>
               <option value="05">Đầu số 05</option>
               <option value="03">Đầu số 03</option>
            </select>
         </div>
         <div :ref="el => (groupFiveElement = el)" id="option-feng-shui" class="btn-group" role="group"
            aria-label="group2">
            <input @change="onAnyGroupCheckChange($event)" type="radio" class="btn-check" name="btnFengShui"
               :id="'btnKim' + suffixId" autocomplete="off" value="1">
            <label class="btn btn-sm btn-light" :for="'btnKim' + suffixId">Kim</label>

            <input @change="onAnyGroupCheckChange($event)" type="radio" class="btn-check" name="btnFengShui"
               :id="'btnMoc' + suffixId" autocomplete="off" value="2">
            <label class="btn btn-sm btn-light" :for="'btnMoc' + suffixId">Mộc</label>

            <input @change="onAnyGroupCheckChange($event)" type="radio" class="btn-check" name="btnFengShui"
               :id="'btnThuy' + suffixId" autocomplete="off" value="3">
            <label class="btn btn-sm btn-light" :for="'btnThuy' + suffixId">Thủy</label>

            <input @change="onAnyGroupCheckChange($event)" type="radio" class="btn-check" name="btnFengShui"
               :id="'btnHoa' + suffixId" autocomplete="off" value="4">
            <label class="btn btn-sm btn-light" :for="'btnHoa' + suffixId">Hỏa</label>

            <input @change="onAnyGroupCheckChange($event)" type="radio" class="btn-check" name="btnFengShui"
               :id="'btnTho' + suffixId" autocomplete="off" value="5">
            <label class="btn btn-sm btn-light" :for="'btnTho' + suffixId">Thổ</label>
         </div>
         <div :ref="el => (groupPrice = el)" id="option-price" class="group-check" role="group" aria-label="group3">
            <input @change="onAnyGroupCheckChange($event)" type="checkbox" class="btn-check price-button-margin"
               name="btnMoney" :id="'btn-check-duoi_500' + suffixId" autocomplete="off" value="7">
            <label class="btn btn-sm btn-light price-button-margin" :for="'btn-check-duoi_500' + suffixId">Dưới
               500k</label>
            <input @change="onAnyGroupCheckChange($event)" type="checkbox" class="btn-check" name="btnMoney"
               :id="'btn-check-500-1tr' + suffixId" autocomplete="off" value="1">
            <label class="btn btn-sm btn-light price-button-margin" :for="'btn-check-500-1tr' + suffixId">Giá 500k - 1
               triệu</label>

            <input @change="onAnyGroupCheckChange($event)" type="checkbox" class="btn-check" name="btnMoney"
               :id="'btn-check-1-3tr' + suffixId" autocomplete="off" value="2">
            <label class="btn btn-sm btn-light price-button-margin" :for="'btn-check-1-3tr' + suffixId">Giá 1 - 3
               triệu</label>

            <input @change="onAnyGroupCheckChange($event)" type="checkbox" class="btn-check" name="btnMoney"
               :id="'btn-check-3-5tr' + suffixId" autocomplete="off" value="3">
            <label class="btn btn-sm btn-light price-button-margin" :for="'btn-check-3-5tr' + suffixId">Giá 3 - 5
               triệu</label>

            <input @change="onAnyGroupCheckChange($event)" type="checkbox" class="btn-check" name="btnMoney"
               :id="'btn-check-5-10tr' + suffixId" autocomplete="off" value="4">
            <label class="btn btn-sm btn-light price-button-margin" :for="'btn-check-5-10tr' + suffixId">Giá 5 - 10
               triệu</label>

            <input @change="onAnyGroupCheckChange($event)" type="checkbox" class="btn-check" name="btnMoney"
               :id="'btn-check-10tr' + suffixId" autocomplete="off" value="5">
            <label class="btn btn-sm btn-light price-button-margin" :for="'btn-check-10tr' + suffixId">Trên 10
               triệu</label>

            <input @change="onAnyGroupCheckChange($event)" type="checkbox" class="btn-check" name="btnMoney"
               :id="'btn-check-20tr' + suffixId" autocomplete="off" value="6">
            <label class="btn btn-sm btn-light price-button-margin" :for="'btn-check-20tr' + suffixId">Trên 20
               triệu</label>
         </div>
      </div>
   </form>


</template>

<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
</style>

<script lang="ts" setup>
import { computed, ref, useTemplateRef, watch } from 'vue';
import Swal from 'sweetalert2';

export interface SearchUiData {
   network: Array<boolean>,
   fiveElement: Array<boolean>,
   price: Array<boolean>,
   allCheck: boolean,
   select: string,
}

export interface SearchData {
   network: Array<string> | null,
   fiveElement: Array<string> | null,
   price: Array<string> | null,
   allCheck: boolean,
   firstNumber: string,
   textSearch: string,
}

export interface AllSearchData {
   ui: SearchUiData,
   dto: SearchData,
}

const testPhoneRegex = (input: string): boolean => {
   const regex: RegExp = /^(?=\d{0,12}(\*\d*)?$)(\d{1,12}(\*\d*)?|)$/;
   return regex.test(input);
}

const inputText = useTemplateRef('txt_key');

const props = defineProps<{
   textSearch: string,
   suffixId: string
   searchData: SearchUiData | null,
}>();

const emits = defineEmits<{
   (event: 'onSearch', searchData: SearchData): void,
   (event: 'onInputSearch', text: string): void,
   (event: 'onAnyButtonClick', data: AllSearchData): void,
}>()

const searchDataChange = computed(() => props.searchData);

watch(searchDataChange, (newData, previous) => {
   if (newData) {
      CheckedWithData(groupNetwork.value.children, newData.network);
      CheckedWithData(groupFiveElement.value.children, newData.fiveElement);
      CheckedWithData(groupPrice.value.children, newData.price);
      (allCheck.value as HTMLInputElement).checked = newData.allCheck;
      const select: HTMLSelectElement = selectFistNumber.value as HTMLSelectElement;
      select.value = newData.select;
   }
});

const onSearch = () => {
   const input = inputText.value?.value ?? "";
   if (!testPhoneRegex(input)) {
      let errorMessage = undefined;
      if (!(/^[\d*]+$/).test(input)) {
         errorMessage = 'Chỉ được sử dụng số và `*`.';
      } else if (input.length > 12) {
         errorMessage = 'Tối đa 12 số.';
      } else if ((input.match(/\*/g) || []).length > 1) {
         errorMessage = 'Chỉ một `*` được phép.';
      }

      if (errorMessage) {
         Swal.fire({
            icon: "error",
            title: errorMessage,
         });
      }
      return;
   }
   emits("onSearch", getDataChecks());
}

const onInput = () => {
   emits('onInputSearch', inputText.value?.value ?? "");
}
const groupNetwork = ref();
const groupFiveElement = ref();
const groupPrice = ref();
const allCheck = ref();
const selectFistNumber = useTemplateRef('select_first_number');

const getDataGroupAny = (children: HTMLCollection) => {
   const data = [];
   for (let i = 0; i < children.length; i++) {
      if (children[i] instanceof HTMLInputElement) {
         const input = children[i] as HTMLInputElement;
         data.push(input.checked);
      }
   }
   return data;
}

const getValueDataGroupAny = (children: HTMLCollection) => {
   const data = [];
   for (let i = 0; i < children.length; i++) {
      if (children[i] instanceof HTMLInputElement) {
         const input = children[i] as HTMLInputElement;
         if (input.checked) {
            data.push(input.value);
         }
      }
   }
   if (data.length <= 0) return null;
   return data;
}

const getDataUiChecks = () => {
   const network = getDataGroupAny(groupNetwork.value.children);
   const fiveElement = getDataGroupAny(groupFiveElement.value.children);
   const price = getDataGroupAny(groupPrice.value.children);

   const data: SearchUiData =
   {
      network: network,
      fiveElement: fiveElement,
      price: price,
      select: (selectFistNumber.value as HTMLSelectElement).value,
      allCheck: (allCheck.value as HTMLInputElement).checked,
   }
   return data;
}

const getDataChecks = () => {
   const network = getValueDataGroupAny(groupNetwork.value.children);
   const fiveElement = getValueDataGroupAny(groupFiveElement.value.children);
   const price = getValueDataGroupAny(groupPrice.value.children);

   const data: SearchData =
   {
      network: network,
      fiveElement: fiveElement,
      price: price,
      firstNumber: (selectFistNumber.value as HTMLSelectElement).value,
      allCheck: (allCheck.value as HTMLInputElement).checked,
      textSearch: inputText.value?.value ?? "",
   }
   return data;
}


const setChecked = (element: Element, isChecked: boolean) => {
   if (element instanceof HTMLInputElement) {
      const elementInput = element as HTMLInputElement;
      elementInput.checked = isChecked;
      return true;
   }
   return false;
}

const Checked = (children: HTMLCollection, isChecked: boolean) => {
   for (let i = 0; i < children.length; i++) {
      setChecked(children[i], isChecked);
   }
}

const CheckedWithData = (children: HTMLCollection, data: Array<boolean>) => {
   let j = 0;
   for (let i = 0; i < children.length; i++) {
      if (setChecked(children[i], data[j])) {
         j++;
      }
   }
}


const onAllCheckChange = (event: Event) => {
   const target = event?.target as HTMLInputElement;
   if (target?.checked) {
      Checked(groupNetwork.value.children, false);
      Checked(groupFiveElement.value.children, false);
      Checked(groupPrice.value.children, false);
      const select: HTMLSelectElement = selectFistNumber.value as HTMLSelectElement;
      select.value = '0';
   }
   const dt: AllSearchData = {
      ui: getDataUiChecks(),
      dto: getDataChecks(),
   }
   emits('onAnyButtonClick', dt);
}

const onAnyGroupCheckChange = (event: Event) => {
   const target = event?.target as HTMLInputElement;
   if (target?.checked) {
      (allCheck.value as HTMLInputElement).checked = false;
   }
   const dt: AllSearchData = {
      ui: getDataUiChecks(),
      dto: getDataChecks(),
   }
   emits('onAnyButtonClick', dt);
}

const onAnySelectChange = (event: Event) => {
   const target = event?.target as HTMLSelectElement;
   if (target?.value != '0') {
      (allCheck.value as HTMLInputElement).checked = false;
   }
   const dt: AllSearchData = {
      ui: getDataUiChecks(),
      dto: getDataChecks(),
   }
   emits('onAnyButtonClick', dt);
}

</script>
