<template>
   <div class="col-2">
      <div class="item-phone-number">
         <div class="header-phone">
            <h4 class="item-number">
               {{ simData.PhoneNumber }}
            </h4>
            <div class="item-img">
               <img :src="getMobileNetworkProviderIcon(simData.MobileNetworkProvider)" class="img-fluid" alt="hình">
               <!--src="getIcon(value?.nha_mang)" -->
            </div>
         </div>
         <div class="d-flex-de">
            <p>Sim mệnh: <span class="life">{{ simData.FiveElements }}</span></p>
         </div>
         <p>Giá: <span class="price">{{ numberWithCommas(
            simData?.Price
               ) }}</span></p>
         <div class="group-action">
            <button class="btn btn-light text-primary" data-bs-toggle="modal1" v-on:click="onClickChangeFortune"
               data-bs-target="#${modal_name}">Cải vận</button>
            <button class="btn btn-danger bg-theme"><img :src="shoppingIcons.iconAddToCart" width="26px"
               v-on:click="onBuy"  ></button>
         </div>
      </div>
   </div>
</template>

<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
</style>

<script lang="ts" setup>
import { PopupController, PoupType } from "@/lib/popup";
import { numberWithCommas, getMobileNetworkProviderIcon} from "@/lib/utils/common"
import { shoppingIcons } from "@/lib/utils/imageUtils";
import Swal from 'sweetalert2';

import { addProductToCart, state } from "@/store/cart/index";
import { ProductType, type Product } from "@/store/cart/state";
import { computed } from "vue";
import store from "@/store";
import type { UserProfileSate } from "@/store/userProfile/state";

export interface SimChangeFortuneData {
   FiveElements: string,
   Price: number,
   PhoneNumber: string,
   MobileNetworkProvider: string
}

const props = defineProps<{
   simData: SimChangeFortuneData
}>()



const userProfile = computed(() => store.state.userProfile as UserProfileSate)

let birthDay = undefined;
let birthYear = undefined;

if (userProfile.value.id !== -1) {
   birthDay = new Date(userProfile.value.birthDay);
   birthYear = birthDay.getFullYear();
}

const onBuy= () =>{
   const product:Product = {
      productId: props.simData.PhoneNumber,
      price: props.simData.Price,
      name:  props.simData.PhoneNumber,
      qty: 1, 
      mobileNetworkProvider: props.simData.MobileNetworkProvider,
      icon: getMobileNetworkProviderIcon(props.simData.MobileNetworkProvider),
      type: ProductType.Durable
   }
   addProductToCart(product).then(msg =>{
      const al: any = {
            icon: "success",
            title: msg,
        }
        Swal.fire(al);
   }).catch(err => {
      const al: any = {
            icon: "error",
            title: err,
        }
        Swal.fire(al);
   });;
}

const onClickChangeFortune = () =>{
   emits("onChangeFortune", props.simData.PhoneNumber);
}

const emits = defineEmits<{
   (event: "onChangeFortune", phoneNumber:string):void
}>()
</script>
