import type { ApplicationState } from "../application/state"

export enum ProductType
{
    Durable = "Durable",
    Consumable = "Consumable",
    Subscription = "Subscription",
}

export interface Product {
  productId: string,
  name: string,
  price: number,
  qty: number,
  icon: string,
  mobileNetworkProvider: string,
  type: ProductType
}

export class ShoppingCart {
  products:Array<Product> = []
}
