<template>
   <nav v-if="props.totalPages > 0" class="mt-2 mb-4">
      <ul class="pagination justify-content-center" id="div_paging">
         <li class="page-item"><a class="page-link" v-on:click="onPrevious">Trước</a></li>
         <!-- <li class="page-item active"><a class="page-link" href="#">1</a></li> -->
         <li v-for="index in pages" :class="activePage == index ? 'page-item active' : 'page-item'"><a class="page-link"
               v-on:click="changeIndex(index)">{{ index }}</a>
         </li>

         <li class="page-item"><a class="page-link" v-on:click="onNext">Sau</a></li>
      </ul>
   </nav>
</template>

<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
</style>
<script lang="ts" setup>
import { ref, watchEffect } from 'vue';

const emit = defineEmits<{
   (event: 'onIndexChange', id: number): void;
}>();

const props = defineProps<{
   totalPages: number,
}>();
const activePage = ref(1);
const pages = ref<Array<number>>();

watchEffect(() => {
   // works for reactivity tracking
   if (props.totalPages > 0) { // if total page  = 1
      const previousPages = activePage.value - 2; // -1
      const nextPages = activePage.value + 2; // 3
      let startPage = previousPages > 0 ? previousPages : 1; // 1

      startPage = (nextPages > props.totalPages) ?
         (startPage - (nextPages - props.totalPages)) : // 1 - (3 - 1) = -1 (failed)
         startPage;

      let loopCount = startPage + 4;
      if (props.totalPages < 5) {
         startPage = 1;
         loopCount = props.totalPages;
      }
      const arr = [];
      for (let i = startPage; i <= loopCount; i++) {
         arr.push(i);
      }
      pages.value = arr;
   }

});

const changeIndex = (index: number) => {
   if (activePage.value == index) return;
   activePage.value = index;
   emit('onIndexChange', index);
}

const resetToFirstPage = () => {
   activePage.value = 1;
}

const onNext = () => {
   if (activePage.value < props.totalPages ) {
      activePage.value = activePage.value + 1;
      emit('onIndexChange', activePage.value);
   }
}

const onPrevious = () => {
   if (activePage.value > 1) {
      activePage.value = activePage.value - 1;
      emit('onIndexChange', activePage.value);
   }
}

defineExpose({
   resetToFirstPage,
});

</script>
