<style setup scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import { createChartExplain, createChartFiveElement, createLabelSecond } from '@/lib/utils/common';
import { computed, onBeforeMount, onMounted, useTemplateRef } from 'vue';
import Swal from 'sweetalert2'
import { ErrorMessage } from '@/lib/utils/ErrorMessage';
import store from '@/store';
import type { UserProfileSate } from '@/store/userProfile/state';
import type { Chart, ChartTypeRegistry } from 'chart.js';

const userProfile = computed(() => store.state.userProfile as UserProfileSate);

export interface chartContenData {
    fileElementLabels: Array<string>,
    fiveElement: Array<number>,
    lineChart: Array<number>,
    name: string,
    placeHolderInput: string,
    prefixedLabelChartLine: string,
    errorInput: string,
}

const canvasRef = useTemplateRef("chart-explain");
const canvasfiveElementRef = useTemplateRef("chart-quantity");

const inputRef = useTemplateRef("txtMobile_Ct");

const props = defineProps<{
    data: chartContenData
}>();

const emits = defineEmits<{
    (event: "onChangeFortune", value: string): void
}>()


const cv = canvasRef.value as HTMLCanvasElement;
const cvf = canvasfiveElementRef.value as HTMLCanvasElement;

let chart: Chart<keyof ChartTypeRegistry, number[] | undefined, string>| undefined = undefined;
let chartFiveElement: Chart<keyof ChartTypeRegistry, number[] | undefined, string>| undefined = undefined;

onMounted(()=>{
    if(!chart){
        chart = createChartExplain(props.data.name, props.data.lineChart, cv?.getContext("2d"));
    }

    if(!chartFiveElement)
    {
        chartFiveElement = createChartFiveElement(props.data.fiveElement, cvf?.getContext("2d"));
    }
});

const updateChart = (data: number[], label: string) => {

    if (chart) {
        chart.data.datasets[0].data = data;
        chart.data.datasets[0].label = label;
        const lb2 = createLabelSecond(true);
        const option = chart.data.datasets[1] as any;
        option.borderColor = lb2.borderColor;
        option.backgroundColor = lb2.backgroundColor;
        option.fill = lb2.fill;
        option.tension = lb2.tension;
        option.pointBackgroundColor = lb2.pointBackgroundColor;
        option.pointBorderWidth = lb2.pointBorderWidth;
        chart.data.datasets[1] = option;
        chart.data.datasets[1].data = [];
        chart.update()
    }
    else {
        const canvas = canvasRef.value as HTMLCanvasElement;
        chart = createChartExplain(props.data.name, data, canvas?.getContext("2d"));
    }
}

const updateChartFiveElement = (data: number[]) => {
    if (chartFiveElement) {
        chartFiveElement.data.datasets[0].data = data;
        chartFiveElement.update()
    }
    else {
        const canvas = canvasfiveElementRef.value as HTMLCanvasElement;
        chartFiveElement = createChartFiveElement(data, canvas?.getContext("2d"), "#ba8d11", props.data.fileElementLabels);
    }
}

const updateMultipleChart = (data1: number[], label1: string, data2: number[], label2: string) => {

    if (chart) {
        chart.data.datasets[0].data = data1;
        chart.data.datasets[0].label = label1;
        chart.data.datasets[1].data = data2;
        chart.data.datasets[1].label = label2;
        const lb2 = createLabelSecond(false);
        const option = chart.data.datasets[1] as any;
        option.borderColor = lb2.borderColor;
        option.backgroundColor = lb2.backgroundColor;
        option.fill = lb2.fill;
        option.tension = lb2.tension;
        option.pointBackgroundColor = lb2.pointBackgroundColor;
        option.pointBorderWidth = lb2.pointBorderWidth;
        chart.data.datasets[1] = option;
        chart.update();
    }
    else {
        const canvas = canvasRef.value as HTMLCanvasElement;
        chart = createChartExplain(props.data.name, data1, canvas?.getContext("2d"));
    }
}

const onChangeFortuneClick = () => {
    const inputValue = inputRef.value?.value ?? "";
    if (!inputValue || inputValue === "") {
        Swal.fire({
            icon: "error",
            title: props.data.errorInput,
        });
    }
    else {
        emits("onChangeFortune", inputRef.value?.value ?? "")
    }
}

defineExpose({
    updateChart,
    updateChartFiveElement,
    updateMultipleChart
})

onBeforeMount(()=>{
    if(chart){
        chart.destroy();
    }

    if(chartFiveElement)
    {
        chartFiveElement.destroy();
    }
});

</script>

<template>
    <div id="scrollsChartSection" data-bs-spy="scroll" data-bs-target="#navbar-chart-section" data-bs-offset="0"
        class="scrollspy-example" tabindex="0">
        <div class="group-chart" id="id_chart">
            <!-- <div class="group-chart" id="id_chart"> -->
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-8 col-md-6 col-lg-4">
                        <h4 class="header-dashboard">Tỉ lệ ngũ hành lúc sinh ra F0</h4>
                        <div class="row justify-content-center">
                            <div class="col-12 pt-3">
                                <canvas ref="chart-quantity" height="400" width="400"></canvas>
                                <div class="d-flex new-number mb-5 mb-lg-0">
                                    <input type="text" ref="txtMobile_Ct" class="form-control"
                                        :placeholder="data.placeHolderInput">
                                    <button v-on:click="onChangeFortuneClick"
                                        class="btn border btn-lg btn-danger text-nowrap" for="floatingInput">Cải
                                        vận</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8" id="scrollsChartSection_line">
                        <h4 class="header-dashboard" id="lb_title_chart">{{ data.prefixedLabelChartLine }} {{ data.name
                            }}</h4>
                        <div class="row justify-content-center">
                            <div class="col-12 position-relative pt-3">
                                <canvas ref="chart-explain" height="400" width="800"></canvas>
                            </div>
                            <div class="col-12 col-sm-10">
                                <div class="alert bg-theme text-white text-center fst-italic py-1 rounded-4 alert-changing-fortune"
                                    role="alert">
                                    <small id="sm_so_luot_xem_con_lai">Quý khách còn {{ userProfile.totalRemainingChartView }} lượt xem.</small>
                                    <small> Quý
                                        khách có thể click vào <a style="font-weight: bold;cursor: pointer;"
                                            onclick="register_service()">đây</a> để mua thêm.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>