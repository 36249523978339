<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import {getPassAndFeatureYears } from '@/lib/utils/common';
import { computed, onMounted, ref, useTemplateRef } from 'vue';

export interface PersonalData {
   FullName: string,
   Gender: number,
   BirthYear: number,
   phoneNumber: string,
   hoaGiapIcon: string,
}

export interface ActionData {
   FullName: string,
   Gender: number,
   BirthYear: number,
}

const genderRef = ref();
const nameRef = ref();
const birthYearRef = ref();


const years = computed(() => getPassAndFeatureYears());

defineProps<{
   personalInfo: PersonalData | undefined;
}>()

const emits = defineEmits<{
   (event: "onReviewBadOrGood", actionData: ActionData): void
}>()

const onClickBadOrGood = () => {
   emits("onReviewBadOrGood", {
      FullName: nameRef.value.value,
      BirthYear: birthYearRef.value.value,
      Gender: genderRef.value?.value,
   });
}

const getCurrentValue = () => {
   return {
      FullName: nameRef.value.value,
      BirthYear: birthYearRef.value.value,
      Gender: genderRef.value?.value,
   };
}

defineExpose({
   getCurrentValue,
});

</script>

<template>
   <main>
      <div class="d-flex justify-content-center mt-3 mt-lg-5" style="margin-top:0px !important;">

      </div>
      <div class="d-flex justify-content-center mt-3 mt-lg-5" style="margin-top:5px !important">
         <div class="calendar-today" id="option_4">
            <div class="calendar-header">
               <h2 class="title-perpetual">THÔNG TIN CÁ NHÂN</h2>
            </div>
            <div class="today-content">
               <div class="row">
                  <div class="col-sm-6 pe-sm-0">
                     <div class="content-left content-info h-100">
                        <div class="item-info">
                           <span>Tên</span>
                        </div>
                        <div class="item-info">
                           <input type="text" :value="personalInfo?.FullName" ref="nameRef">
                        </div>
                        <div class="item-info">
                           <span>Giới tính</span>
                        </div>
                        <div class="item-info">
                           <!-- <span id="sex_4"></span> -->
                           <select ref="genderRef" style="width: 100%;height: 40px;text-align: center;"
                              :value="personalInfo?.Gender">
                              <option value="1">Nam</option>
                              <option value="2">Nữ</option>
                           </select>
                        </div>
                        <div class="item-info">
                           <span>Năm sinh</span>
                        </div>
                        <div class="item-info">
                           <!-- <span id="year_4"></span> -->
                           <select ref="birthYearRef" style="width: 100%;height: 40px;text-align: center;"
                              :value="personalInfo?.BirthYear">
                              <option v-for="year in years" :value="year">{{ year }}</option>
                           </select>
                        </div>
                        <div class="item-info">
                           <span>Số ĐT</span>
                        </div>
                        <div class="item-info">
                           <input type="input" id="phone_4" :value="personalInfo?.phoneNumber ?? ''">
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-6 ps-sm-0">
                     <div class="bg-img ratio ratio-4x3 h-100" style="background-color: #fb0503;">
                        <img id="img_avartar" :src="personalInfo?.hoaGiapIcon" class="object-contain" width="100%">
                     </div>
                  </div>
               </div>
               <div class="row" id="div_view_pt">
                  <div class="d-flex justify-content-center mt-3 mt-lg-5 ">
                     <button type="button" class="btn btn-light px-5 rounded-pill fw-bold wow zoomIn"
                        style="background-color: #D9D9D9;" v-on:click="onClickBadOrGood">Xem giờ tốt
                        xấu</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </main>

</template>