export default {
  usageLog: "Usage Log",
  when: "Date and time",
  targetType: "Target type",
  target: "Target",
  operation: "Operation",
  notFound: "No results found",
  filterNoDataLog: "There were no usage logs that matched the filter conditions.",

  addAccount: "Add Account",
  updateAccount: "Update Account",
  updateAccountName: "Update Account Name",
  deleteAccount: "Delete Account",
  exportAccount: "CSV Export (Accounts)"
}
