import type { Module } from 'vuex'
import mutations from './mutation'
import { GenderAndCity} from './state'
import type { RootState } from '../rootState'
import store from '..'

export const state = new GenderAndCity()

const namespaced = true;

export const genderAndCity: Module<GenderAndCity, RootState> = {
  namespaced,
  state,
  mutations,
}

export const commitGender = (genders: Array<{id: number, value1:string}>) => {
  store.commit("genderAndCity/updateGenders", genders)
}

export const commitCitys = (citys: Array<{id: number, value2:string}>) => {
  store.commit("genderAndCity/updateCitys", citys)
}