
import type { RouteRecordRaw } from 'vue-router'
import Home from '../components/pages/Home.vue'
import Login from '@/components/pages/Login.vue'
import ConfirmOTP from '@/components/pages/ConfirmOTP.vue'
import UserInfo from '@/components/pages/UserAccount/UserInfo.vue'
import NumberDraw from '@/components/pages/NumberDraw.vue'
import PurchaseHistory from '@/components/pages/UserAccount/PurchaseHistory/PurchaseHistory.vue';
import NameMeaning from '@/components/pages/NameMeaning.vue'
import LifeEnhancementNumber from '@/components/pages/LifeEnhancementNumber.vue'
import FengShuiPhoneNumber from '@/components/pages/FengShuiPhoneNumber.vue'
import ZodiacAsianPhoneNumber from '@/components/pages/ZodiacAsianPhoneNumber.vue'
import MobileNetworkProviderPhoneNumber from '@/components/pages/MobileNetworkProviderPhoneNumber.vue'
import NicePhoneNumber from '@/components/pages/NicePhoneNumber.vue'
import DetailPhoneNumber from '@/components/pages/DetailPhoneNumber.vue'
import News from '@/components/pages/News.vue'
import PerpetutalCalendar from '@/components/pages/PerpetutalCalendar.vue'
import ShoppingCart from '@/components/pages/ShoppingCart.vue'
import Payment from '@/components/pages/Payment.vue'
import PaymentSuccessed from '@/components/pages/PaymentSuccessed.vue'
import PacketService from '@/components/pages/PacketService.vue'

export const routerPaths = {
  Home: '/',
  Login: '/login',
  ConfirmOTP: '/confirm-OTP',
  UserInfo: '/account',
  NumberDraw: '/number-draw',
  PurchaseHistory: '/purchase-history',
  NameMeaning:'/name-meaning',
  LifeEnhancementNumber: '/life-enhancement-number',
  FengShuiPhoneNumber: '/feng-shui-phone-number',
  ZodiacAsianPhoneNumber: '/zodiac-asian-phone-number',
  MobileNetworkProviderPhoneNumber: '/mobile-network-provider-phone-number',
  NicePhoneNumber: '/nice-phone-number',
  DetailPhoneNumber: '/detail-phone-number',
  News: '/news',
  PerpetutalCalendar: '/perpetual-calender',
  ShoppingCart: '/shopping-cart',
  Payment: '/payment', 
  PaymentSuccessed: '/payment-successed',
  PacketService: '/packet-service'
}

export const routes: RouteRecordRaw[] = [
  { path: routerPaths.Home, component: Home },
  { path: routerPaths.Login, component: Login },
  { path: routerPaths.ConfirmOTP, component: ConfirmOTP },
  { path: routerPaths.UserInfo, component: UserInfo },
  { path: routerPaths.NumberDraw, component: NumberDraw },
  { path: routerPaths.PurchaseHistory, component: PurchaseHistory },
  { path: routerPaths.NameMeaning, component: NameMeaning },
  { path: routerPaths.LifeEnhancementNumber, component: LifeEnhancementNumber },
  { path: routerPaths.FengShuiPhoneNumber, component: FengShuiPhoneNumber },
  { path: routerPaths.ZodiacAsianPhoneNumber, component: ZodiacAsianPhoneNumber },
  { path: routerPaths.MobileNetworkProviderPhoneNumber, component: MobileNetworkProviderPhoneNumber },
  { path: routerPaths.NicePhoneNumber, component: NicePhoneNumber },
  { path: routerPaths.DetailPhoneNumber, component: DetailPhoneNumber },
  { path: routerPaths.News, component: News },
  { path: routerPaths.PerpetutalCalendar, component: PerpetutalCalendar },
  { path: routerPaths.ShoppingCart, component: ShoppingCart },
  { path: routerPaths.Payment, component: Payment },
  { path: routerPaths.PaymentSuccessed, component: PaymentSuccessed },
  { path: routerPaths.PacketService, component: PacketService },
];

