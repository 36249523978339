<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import { PopupController, PoupType } from '@/lib/popup';
import { getCitys, getDates, getDefaultGender, getTime, getYears} from '@/lib/utils/common';
import store from '@/store';
import type { GenderAndCity } from '@/store/genderAndCity/state';
import { computed, nextTick, ref, useTemplateRef } from 'vue';
import InfomationComponent, { type Infomation } from '../parts/NameMeaning/InfomationComponent.vue';
import ChartComponent, { type chartContenData } from '../parts/NameMeaning/ChartComponent.vue';
import GroupTitle, { TitleType } from '../parts/NameMeaning/GroupTitle.vue';
import ListNameComponent, { type NameData } from '../parts/NameMeaning/ListNameComponent.vue';
import NameMeaningRepository from '@/lib/https/nameMeaningRepository';
import { RepositoryFactory } from '@/lib/https';
import type { INameAccordingToCriteriaReq, INameMeaningParam } from '@/models/https/nameMeaningRequests';
import InfomationV2Component, { type InfomationV2 } from '../parts/NameMeaning/InfomationV2Component.vue';
import Swal from 'sweetalert2';
import { ErrorMessage } from '@/lib/utils/ErrorMessage';
import type { INameMeaningRes } from '@/models/https/nameMeaningResponse';
import type { UserProfileSate } from '@/store/userProfile/state';
import { wealthIcons } from '@/lib/utils/imageUtils';


const userProfile = computed(() => store.state.userProfile as UserProfileSate)

const { getNameMeaning, getNameAccordingToCriteria } = RepositoryFactory.getRepository<NameMeaningRepository>(NameMeaningRepository);

const nameInput = useTemplateRef("txtName_firstname");
const genderInput = useTemplateRef("select_gioi_tinh_firstname");
const cityOfBirth = useTemplateRef("select_tinh_sinh_firstname");
const cityOfResidence = useTemplateRef("select_tinh_o_firstname");
const yearValues = ref(getYears());
const yearRef = useTemplateRef("year_firstname");
const monthRef = useTemplateRef("month_firstname");
const dateRef = useTemplateRef("day_firstname");
const hours = getTime();
const hourRef = useTemplateRef("txt_gio_sinh_firstname");
const dates = ref<Array<number>>([]);

const gendersAnCitys = computed(() => store.state.genderAndCity as GenderAndCity);
const genderValues = computed(() => gendersAnCitys.value.genders);
const citys = computed(() => gendersAnCitys.value.citys);
const wealthData = ref<Array<NameData>>([]);
const reputation = ref<Array<NameData>>([]);
const marriage = ref<Array<NameData>>([]);
const health = ref<Array<NameData>>([]);
const isShowResult = ref<boolean>(false);
const isShowMiddleName1V2 = ref<boolean>(false);
const isShowMiddleName2V2 = ref<boolean>(false);
const isShowLastNameV2 = ref<boolean>(false);

const totalPages = ref<Array<number>>([0, 0, 0, 0]);
const totalNames = ref<Array<number>>([0, 0, 0, 0]);

const wealthListRef = ref();
const reputationListRef = ref();
const marriageListRef = ref();
const healthListRef = ref();
const selectWealth = ref();
const selectReputation = ref();
const selectHealth = ref();
const selectMarriage = ref();

const chartRef = ref();
const chartV2Ref = ref();
const chartMiddleName1V2Ref = ref();
const chartMiddleName2V2Ref = ref();
const chartLastNameV2Ref = ref();
const chartData = ref<chartContenData>({
    fileElementLabels: ["moc", "hoa", "tho", "kim", "thuy"],
    fiveElement: [25, 25, 25, 12.5, 12.5],
    lineChart: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    name: "Minh",
    placeHolderInput: "Nhập tên cải vận",
    prefixedLabelChartLine: "Biểu đồ tên",
    errorInput: ErrorMessage.InputNameChangeFortuneWrong,
});

const infomation = ref<Infomation>({
    customer: "Nam - Sinh ngày 05/03/2019(29/1/2019 Âm lịch)",
    sexagenaryCycleYear: "KỶ HỢI",
    elementalNature: "BÌNH ĐỊA MỘC",
    zodiacIcon: "https://thaysocaivan.com/assets/images/img-tuoi-hoi.png",
    asianZodiac: "Hợi",
})

const infomationV2 = ref<InfomationV2>({
    fileElementLabels: ["hoa", "moc", "kim", "tho", "thuy"],
    fiveElement: "",
    name: "",
    fiveElementColor: "",
    nameMeaningVietNam: "",
    nameImage: "",
    namingTurorial: [],
    tenNameVietNam: [],
    tenNameEnglish: [],
    fiveElementChart: [],
});

const infomationMiddleName1V2 = ref<InfomationV2>({
    fileElementLabels: ["hoa", "moc", "kim", "tho", "thuy"],
    fiveElement: "",
    name: "",
    fiveElementColor: "",
    nameMeaningVietNam: "",
    nameImage: "",
    namingTurorial: [],
    tenNameVietNam: [],
    tenNameEnglish: [],
    fiveElementChart: [],
});

const infomationMiddleName2V2 = ref<InfomationV2>({
    fileElementLabels: ["hoa", "moc", "kim", "tho", "thuy"],
    fiveElement: "",
    name: "",
    fiveElementColor: "",
    nameMeaningVietNam: "",
    nameImage: "",
    namingTurorial: [],
    tenNameVietNam: [],
    tenNameEnglish: [],
    fiveElementChart: [],
});

const infomationLastName2V2 = ref<InfomationV2>({
    fileElementLabels: ["hoa", "moc", "kim", "tho", "thuy"],
    fiveElement: "",
    name: "",
    fiveElementColor: "",
    nameMeaningVietNam: "",
    nameImage: "",
    namingTurorial: [],
    tenNameVietNam: [],
    tenNameEnglish: [],
    fiveElementChart: [],
});

const onYearChange = () => {
    if (monthRef.value) {
        const isDisabled = yearRef.value?.value === "" || yearRef.value?.value === "-1";
        (monthRef.value as HTMLSelectElement).disabled = isDisabled;
        if (isDisabled) {
            (monthRef.value as HTMLSelectElement).value = "0";
            (dateRef.value as HTMLSelectElement).value = "0";
            (hourRef.value as HTMLSelectElement).value = "-1";
            (dateRef.value as HTMLSelectElement).disabled = isDisabled;
            (hourRef.value as HTMLSelectElement).disabled = isDisabled;
        }
    }
}

const onMonthChange = () => {
    if (dateRef.value) {
        const isDisabled = monthRef.value?.value === "0";
        (dateRef.value as HTMLSelectElement).disabled = isDisabled;
        if (isDisabled) {
            (dateRef.value as HTMLSelectElement).value = "0";
            (hourRef.value as HTMLSelectElement).value = "-1";
            (hourRef.value as HTMLSelectElement).disabled = isDisabled;
        }
        else {
            dates.value = getDates(parseInt(monthRef.value?.value ?? '0'));
        }
    }
}

const ondateChange = () => {
    if (hourRef.value) {
        const isDisabled = dateRef.value?.value === "-1" || dateRef.value?.value === "0";
        (hourRef.value as HTMLSelectElement).disabled = isDisabled;
        if (isDisabled) {
            (hourRef.value as HTMLSelectElement).value = "-1";
        }
    }
}


const initNameIdeaGroups = (nameIdeas: {
    wealth: number,
    reputation: number,
    marriage: number,
    health: number,
    fiveElementWealth: number,
    fiveElementReputation: number,
    fiveElementMarriage: number,
    fiveElementHealth: number,
}) => {
    const rqParam: INameAccordingToCriteriaReq = {
        tieu_chi_ma: nameIdeas.wealth,
        menh_ma: nameIdeas.fiveElementWealth,
        page: 1,
        gia_tien_sort: 0,
        tieu_chi_sort: 0,
    }
    getNameAccordingToCriteria(rqParam)
        .then(res => {
            if (res?.data) {
                const arr = res.data.flatMap(d => { return { Name: d.ten, FiveElement: d.ngu_hanh_ten }; });
                wealthData.value = arr;
                totalPages.value[0] = res.data[0].total_page;
                totalNames.value[0] = res.data[0].total_rows;
            }
        });

    rqParam.tieu_chi_ma = nameIdeas.reputation;
    rqParam.menh_ma = nameIdeas.fiveElementReputation;
    getNameAccordingToCriteria(rqParam)
        .then(res => {
            if (res?.data) {
                const arr = res.data.flatMap(d => { return { Name: d.ten, FiveElement: d.ngu_hanh_ten }; });
                reputation.value = arr;
                totalPages.value[1] = res.data[0].total_page;
                totalNames.value[1] = res.data[0].total_rows;
            }
        });

    rqParam.tieu_chi_ma = nameIdeas.marriage;
    rqParam.menh_ma = nameIdeas.fiveElementMarriage;
    getNameAccordingToCriteria(rqParam)
        .then(res => {
            if (res?.data) {
                const arr = res.data.flatMap(d => { return { Name: d.ten, FiveElement: d.ngu_hanh_ten }; });
                marriage.value = arr;
                totalPages.value[2] = res.data[0].total_page;
                totalNames.value[2] = res.data[0].total_rows;
            }
        });

    rqParam.tieu_chi_ma = nameIdeas.health;
    rqParam.menh_ma = nameIdeas.fiveElementHealth;
    getNameAccordingToCriteria(rqParam)
        .then(res => {
            if (res?.data) {
                const arr = res.data.flatMap(d => { return { Name: d.ten, FiveElement: d.ngu_hanh_ten }; });
                health.value = arr;
                totalPages.value[3] = res.data[0].total_page;
                totalNames.value[3] = res.data[0].total_rows;
            }
        });

}

let nameIdeas = {
    fiveElementWealth: 0,
    fiveElementReputation: 0,
    fiveElementMarriage: 0,
    fiveElementHealth: 0,
    wealth: 1,
    reputation: 2,
    marriage: 3,
    health: 4
};

const validRemainingView = () => {
    if (userProfile.value.totalRemainingChartView > 0) {
        return true;
    }
    else {
        PopupController.pushPopup(PoupType.BuyChartViewPopupComponent, { description: null });
        return false;
    }
}

const validSigninOrSignUp = () => {
    if (userProfile.value.id >= 0) {
        return true;
    }
    else {
        PopupController.pushPopup(PoupType.SignUpPopupComponent, { description: null });
        return false;
    }
}

const validValues = () => {
    const genderValue = genderInput.value?.value ?? "-1";
    const nameValue = nameInput.value?.value ?? "";
    const birthYearValue = yearRef.value?.value ?? "0";
    let error: any = undefined;
    if (!nameValue || nameValue === "") {
        error = {
            icon: "error",
            title: ErrorMessage.InputNameWrong,
        }
    }
    else if (!genderValue || genderValue === "-1") {
        error = {
            icon: "error",
            title: ErrorMessage.InputGenderWrong,
        }
    }
    else if (!birthYearValue || birthYearValue === "0" || birthYearValue === "-1") {
        error = {
            icon: "error",
            title: ErrorMessage.InputBirthYearWrong,
        }
    }

    if (error) {
        Swal.fire(error);
        return { error: true };
    }

    return {
        error: false,
        genderValue: genderValue,
        nameValue: nameValue,
        birthYearValue: birthYearValue,
    }
}

const updateDataInfo = (res: INameMeaningRes | null) => {
    infomation.value.customer = res?.data.thong_tin.than_chu ?? "failed!";
    infomation.value.asianZodiac = res?.data.thong_tin.con_giap ?? "failed!";
    infomation.value.elementalNature = res?.data.thong_tin.nap_am ?? "failed!";
    infomation.value.sexagenaryCycleYear = res?.data.thong_tin.nam_can_chi ?? "failed!";
    infomation.value.zodiacIcon = res?.data.thong_tin.anh_dai_dien ?? "failed";
}


const updateDataInfoV2 = (res: INameMeaningRes | null) => {
    infomationV2.value.fiveElement = res?.data.info_v2[0].ngu_hanh ?? "failed";
    infomationV2.value.name = res?.data.info_v2[0].ten_danh_cho ?? "failed";
    infomationV2.value.fiveElementColor = res?.data.info_v2[0].ngu_hanh_mau ?? "";
    infomationV2.value.nameMeaningVietNam = res?.data.info_v2[0].y_nghia_tieng_viet ?? "failed";
    infomationV2.value.nameImage = res?.data.info_v2[0].image ?? "failed";
    infomationV2.value.namingTurorial = res?.data.info_v2[0].huong_dan_dat_ten?.flatMap(e => e.huong_dan_dat_ten) ?? [];
    infomationV2.value.tenNameVietNam = res?.data.info_v2[0].muoi_ten_tieng_viet?.flatMap(e => e.ten_tieng_viet_hop) ?? [];
    infomationV2.value.tenNameEnglish = res?.data.info_v2[0].muoi_ten_tieng_anh?.flatMap(e => e.ten_tieng_anh_hop) ?? [];
}

const updateDataMildeNameAndLastName = (res: INameMeaningRes | null) => {
    //middle name 1        
    if (Boolean(res?.data.info_v2_ten_lot1)) {
        isShowMiddleName1V2.value = true;
        infomationMiddleName1V2.value.fiveElement = res?.data.info_v2_ten_lot1[0]?.ngu_hanh ?? "failed";
        infomationMiddleName1V2.value.name = res?.data.info_v2_ten_lot1[0]?.ten_danh_cho ?? "failed";
        infomationMiddleName1V2.value.fiveElementColor = res?.data.info_v2_ten_lot1[0]?.ngu_hanh_mau ?? "";
        infomationMiddleName1V2.value.nameMeaningVietNam = res?.data.info_v2_ten_lot1[0]?.y_nghia_tieng_viet ?? "failed";
        infomationMiddleName1V2.value.nameImage = res?.data.info_v2_ten_lot1[0]?.image ?? "failed";
        infomationMiddleName1V2.value.namingTurorial = res?.data.info_v2_ten_lot1[0].huong_dan_dat_ten?.flatMap((e: { huong_dan_dat_ten: any; }) => e.huong_dan_dat_ten) ?? [];
        chartMiddleName1V2Ref.value.updateChartFiveElement(Object.values(res?.data.info_v2_ten_lot1[0].chart_ngu_hanh_ten[0] ?? []))
    }
    else {
        isShowMiddleName1V2.value = false;
    }

    //middle name 2        
    if (Boolean(res?.data.info_v2_ten_lot2)) {
        isShowMiddleName2V2.value = true;
        infomationMiddleName2V2.value.fiveElement = res?.data.info_v2_ten_lot2[0]?.ngu_hanh ?? "failed";
        infomationMiddleName2V2.value.name = res?.data.info_v2_ten_lot2[0]?.ten_danh_cho ?? "failed";
        infomationMiddleName2V2.value.fiveElementColor = res?.data.info_v2_ten_lot2[0]?.ngu_hanh_mau ?? "";
        infomationMiddleName2V2.value.nameMeaningVietNam = res?.data.info_v2_ten_lot2[0]?.y_nghia_tieng_viet ?? "failed";
        infomationMiddleName2V2.value.nameImage = res?.data.info_v2_ten_lot2[0].image ?? "failed";
        infomationMiddleName2V2.value.namingTurorial = res?.data.info_v2_ten_lot2[0].huong_dan_dat_ten.flatMap((e: { huong_dan_dat_ten: any; }) => e.huong_dan_dat_ten) ?? [];
        chartMiddleName2V2Ref.value.updateChartFiveElement(Object.values(res?.data.info_v2_ten_lot2[0].chart_ngu_hanh_ten[0] ?? []))
    }
    else {
        isShowMiddleName2V2.value = false;
    }

    //last name     
    if (Boolean(res?.data.info_v2_ho)) {
        isShowLastNameV2.value = true;
        infomationLastName2V2.value.fiveElement = res?.data.info_v2_ho[0].ngu_hanh ?? "failed";
        infomationLastName2V2.value.name = res?.data.info_v2_ho[0].ten_danh_cho ?? "failed";
        infomationLastName2V2.value.fiveElementColor = res?.data.info_v2_ho[0].ngu_hanh_mau ?? "";
        infomationLastName2V2.value.nameMeaningVietNam = res?.data.info_v2_ho[0].y_nghia_tieng_viet ?? "failed";
        infomationLastName2V2.value.nameImage = res?.data.info_v2_ho[0].image ?? "failed";
        infomationLastName2V2.value.namingTurorial = res?.data.info_v2_ho[0].huong_dan_dat_ten?.flatMap((e: { huong_dan_dat_ten: any; }) => e.huong_dan_dat_ten) ?? [];
        chartLastNameV2Ref.value.updateChartFiveElement(Object.values(res?.data.info_v2_ho[0].chart_ngu_hanh_ten[0] ?? []))
    }
    else {
        isShowLastNameV2.value = false;
    }
}

const onSearchClick = () => {

    if(!validSigninOrSignUp()) return;
    if (!validRemainingView()) return;
    const rs = validValues();
    if (rs.error) return;
    const genderValue = rs.genderValue ?? "";
    const nameValue = rs.nameValue ?? "";
    const birthYearValue = rs.birthYearValue ?? "";

    const rqParam: INameMeaningParam = {
        ho_ten: nameValue,
        gioi_tinh: parseInt(genderValue),
        ngay_sinh_duong_lich: "",
        month: parseInt(monthRef.value?.value ?? "0"),
        day: parseInt(dateRef.value?.value ?? "0"),
        year: parseInt(birthYearValue),
        sim_cai_van: "",
        sim_cu: "",
        gio_sinh: `${hourRef.value?.value ?? "-1"}:00`,
        nha_mang: null,
        thanh_pho_sinh: parseInt(cityOfBirth.value?.value ?? "-1"),
        thanh_pho_o: parseInt(cityOfResidence.value?.value ?? "-1"),
    }

    getNameMeaning(rqParam).then(res => {
        if (res?.result_code === '000') {
            updateDataInfo(res);
            chartData.value.fiveElement = Object.values(res?.data.chart_ngu_hanh ?? [])
            const chartShow = res?.data.chart_line[res?.data.chart_line.length - 1];
            delete (chartShow as any).loai;
            chartData.value.lineChart = Object.values(chartShow ?? [])
            chartData.value.name = rqParam.ho_ten,

                chartRef.value?.updateChart(chartData.value.lineChart, chartData.value.name);
            chartRef.value?.updateChartFiveElement(chartData.value.fiveElement);

            updateDataInfoV2(res);
            chartV2Ref.value.updateChartFiveElement(Object.values(res?.data.info_v2[0].chart_ngu_hanh_ten[0] ?? []));
            nameIdeas = {
                fiveElementWealth: res?.data.ten_phu_hop.ngu_hanh_ma_tientai ?? 0,
                fiveElementReputation: res?.data.ten_phu_hop.ngu_hanh_ma_congdanh ?? 0,
                fiveElementMarriage: res?.data.ten_phu_hop.ngu_hanh_ma_honnhan ?? 0,
                fiveElementHealth: res?.data.ten_phu_hop.ngu_hanh_ma_suckhoe ?? 0,
                wealth: 1,
                reputation: 2,
                marriage: 3,
                health: 4
            };
            nextTick().then(() => {
                chartRef.value?.$el.scrollIntoView({ behavior: "smooth" });
            });


            //get idea names
            initNameIdeaGroups(nameIdeas);
            isShowResult.value = true;
            wealthListRef.value.resetToFirstPage();
            reputationListRef.value.resetToFirstPage();
            healthListRef.value.resetToFirstPage();
            marriageListRef.value.resetToFirstPage();

            updateDataMildeNameAndLastName(res);
        }
        else {
            const error: any = {
                icon: "error",
                title: res?.result_msg,
            }
            Swal.fire(error);
        }
    });
}


const onWealthIndexChange = (index: number) => {
    const rqParam: INameAccordingToCriteriaReq = {
        tieu_chi_ma: nameIdeas.wealth,
        menh_ma: nameIdeas.fiveElementWealth,
        page: index,
        gia_tien_sort: 0,
        tieu_chi_sort: selectWealth.value.value ?? 0,
    }
    getNameAccordingToCriteria(rqParam)
        .then(res => {
            if (res?.data) {
                const arr = res.data.flatMap(d => { return { Name: d.ten, FiveElement: d.ngu_hanh_ten }; });
                wealthData.value = arr;
                totalPages.value[0] = res.data[0].total_page;
                totalNames.value[0] = res.data[0].total_rows;
            }
        });
}

const onReputationIndexChange = (index: number) => {
    const rqParam: INameAccordingToCriteriaReq = {
        tieu_chi_ma: nameIdeas.reputation,
        menh_ma: nameIdeas.fiveElementReputation,
        page: index,
        gia_tien_sort: 0,
        tieu_chi_sort: selectReputation.value.value ?? 0,
    }
    getNameAccordingToCriteria(rqParam)
        .then(res => {
            if (res?.data) {
                const arr = res.data.flatMap(d => { return { Name: d.ten, FiveElement: d.ngu_hanh_ten }; });
                reputation.value = arr;
                totalPages.value[0] = res.data[0].total_page;
                totalNames.value[0] = res.data[0].total_rows;
            }
        });
}

const onMarriageIndexChange = (index: number) => {
    const rqParam: INameAccordingToCriteriaReq = {
        tieu_chi_ma: nameIdeas.marriage,
        menh_ma: nameIdeas.fiveElementMarriage,
        page: index,
        gia_tien_sort: 0,
        tieu_chi_sort: selectMarriage.value.value ?? 0,
    }
    getNameAccordingToCriteria(rqParam)
        .then(res => {
            if (res?.data) {
                const arr = res.data.flatMap(d => { return { Name: d.ten, FiveElement: d.ngu_hanh_ten }; });
                marriage.value = arr;
                totalPages.value[0] = res.data[0].total_page;
                totalNames.value[0] = res.data[0].total_rows;
            }
        });
}

const onHealthIndexChange = (index: number) => {
    const rqParam: INameAccordingToCriteriaReq = {
        tieu_chi_ma: nameIdeas.health,
        menh_ma: nameIdeas.fiveElementHealth,
        page: index,
        gia_tien_sort: 0,
        tieu_chi_sort: selectHealth.value.value ?? 0,
    }
    getNameAccordingToCriteria(rqParam)
        .then(res => {
            if (res?.data) {
                const arr = res.data.flatMap(d => { return { Name: d.ten, FiveElement: d.ngu_hanh_ten }; });
                health.value = arr;
                totalPages.value[0] = res.data[0].total_page;
                totalNames.value[0] = res.data[0].total_rows;
            }
        });
}

const onWealthSelectChange = () => {
    const rqParam: INameAccordingToCriteriaReq = {
        tieu_chi_ma: nameIdeas.wealth,
        menh_ma: nameIdeas.fiveElementWealth,
        page: 1,
        gia_tien_sort: 0,
        tieu_chi_sort: selectWealth.value.value ?? 0,
    }
    getNameAccordingToCriteria(rqParam)
        .then(res => {
            if (res?.data) {
                const arr = res.data.flatMap(d => { return { Name: d.ten, FiveElement: d.ngu_hanh_ten }; });
                wealthData.value = arr;
                totalPages.value[0] = res.data[0].total_page;
                totalNames.value[0] = res.data[0].total_rows;
                wealthListRef.value.resetToFirstPage();
            }
        });
}

const onReputationSelectChange = () => {
    const rqParam: INameAccordingToCriteriaReq = {
        tieu_chi_ma: nameIdeas.reputation,
        menh_ma: nameIdeas.fiveElementReputation,
        page: 1,
        gia_tien_sort: 0,
        tieu_chi_sort: selectReputation.value.value ?? 0,
    }
    getNameAccordingToCriteria(rqParam)
        .then(res => {
            if (res?.data) {
                const arr = res.data.flatMap(d => { return { Name: d.ten, FiveElement: d.ngu_hanh_ten }; });
                reputation.value = arr;
                totalPages.value[0] = res.data[0].total_page;
                totalNames.value[0] = res.data[0].total_rows;
                reputationListRef.value.resetToFirstPage();
            }
        });
}

const onMarriageSelectChange = () => {
    const rqParam: INameAccordingToCriteriaReq = {
        tieu_chi_ma: nameIdeas.marriage,
        menh_ma: nameIdeas.fiveElementMarriage,
        page: 1,
        gia_tien_sort: 0,
        tieu_chi_sort: selectMarriage.value.value ?? 0,
    }
    getNameAccordingToCriteria(rqParam)
        .then(res => {
            if (res?.data) {
                const arr = res.data.flatMap(d => { return { Name: d.ten, FiveElement: d.ngu_hanh_ten }; });
                marriage.value = arr;
                totalPages.value[0] = res.data[0].total_page;
                totalNames.value[0] = res.data[0].total_rows;
                marriageListRef.value.resetToFirstPage();
            }
        });
}

const onHealthSelectChange = () => {
    const rqParam: INameAccordingToCriteriaReq = {
        tieu_chi_ma: nameIdeas.health,
        menh_ma: nameIdeas.fiveElementHealth,
        page: 1,
        gia_tien_sort: 0,
        tieu_chi_sort: selectHealth.value.value ?? 0,
    }
    getNameAccordingToCriteria(rqParam)
        .then(res => {
            if (res?.data) {
                const arr = res.data.flatMap(d => { return { Name: d.ten, FiveElement: d.ngu_hanh_ten }; });
                health.value = arr;
                totalPages.value[0] = res.data[0].total_page;
                totalNames.value[0] = res.data[0].total_rows;
                healthListRef.value.resetToFirstPage();
            }
        });
}


const changeFortune = (nameChange: string) => {
    if (!validRemainingView()) return;
    const rs = validValues();
    if (rs.error) return;
    const genderValue = rs.genderValue ?? "";
    const nameValue = rs.nameValue ?? "";
    const birthYearValue = rs.birthYearValue ?? "";

    const rqParam: INameMeaningParam = {
        ho_ten: nameValue,
        gioi_tinh: parseInt(genderValue),
        ngay_sinh_duong_lich: "",
        month: parseInt(monthRef.value?.value ?? "0"),
        day: parseInt(dateRef.value?.value ?? "0"),
        year: parseInt(birthYearValue),
        sim_cai_van: nameChange,
        sim_cu: "",
        gio_sinh: `${hourRef.value?.value ?? "-1"}:00`,
        nha_mang: null,
        thanh_pho_sinh: parseInt(cityOfBirth.value?.value ?? "-1"),
        thanh_pho_o: parseInt(cityOfResidence.value?.value ?? "-1"),
    }

    getNameMeaning(rqParam).then(res => {
        if (res?.result_code === "000") {

            updateDataInfo(res);

            chartData.value.fiveElement = Object.values(res?.data.chart_ngu_hanh ?? [])
            const chartShow = res?.data.chart_line[0];
            delete (chartShow as any).loai;
            chartData.value.lineChart = Object.values(chartShow ?? []);
            chartData.value.name = rqParam.ho_ten;
            const charData2 = res?.data.chart_line[1];
            delete (charData2 as any).loai;
            chartRef.value?.updateMultipleChart(chartData.value.lineChart, chartData.value.name, Object.values(charData2 ?? []), nameChange);
            chartRef.value?.updateChartFiveElement(chartData.value.fiveElement);

            updateDataInfoV2(res);

            chartV2Ref.value.updateChartFiveElement(Object.values(res?.data.info_v2[0].chart_ngu_hanh_ten[0] ?? []));
            updateDataMildeNameAndLastName(res);
        }
        else {
            const error: any = {
                icon: "error",
                title: res?.result_msg,
            }
            Swal.fire(error);
        }
    });
}

</script>

<template>
    <div class="section-page section-result-linear pb-0">
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                <section class="section-page pt-4 section-changing-fortune linear">
                    <div class="container-fluid">
                        <h1 class="title-section mb-0 mb-sm-3">Tìm Tên đúng ý, <br> đổi vận liền tay</h1>
                        <div class="row justify-content-center mt-3 mt-sm-0">
                            <div class="col-12 col-sm-8 col-xl-6">
                                <div class="alert bg-theme text-white text-center fst-italic py-1 rounded-4 alert-changing-fortune"
                                    role="alert">
                                    <small>Mời Quý khách nhập thông tin và họ tên đang dùng để được luận giải về mức độ
                                        tốt xấu của họ tên so với bản mệnh của mình!</small>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 col-sm-9 col-xl-7">
                                <div class="block-lucky wow zoomIn">
                                    <p class="shape1"><span class="shape2"></span></p>
                                    <p class="shape3"><span class="shape4"></span></p>
                                    <p class="shape5"></p>
                                    <p class="shape6"></p>
                                    <div class="form-change-luck">
                                        <div class="row justify-content-center">
                                            <div class="col-12 col-md-5">
                                                <div class="form-floating">
                                                    <input id="txtName" ref="txtName_firstname" type="text"
                                                        class="form-control rounded-pill"
                                                        placeholder="name@example.com">
                                                    <label for="floatingInput">Họ Tên<b style="color:red;">*</b></label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4" style="display: none;">
                                                <div class="form-floating">
                                                    <input type="text" id="txtMobile_firstname"
                                                        class="form-control rounded-pill" placeholder="0988 000 000">
                                                    <label for="floatingInput">Số hiện tại<b
                                                            style="color:red;">*</b></label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-3">
                                                <div class="form-floating">
                                                    <select ref="select_gioi_tinh_firstname"
                                                        class="form-select rounded-pill"
                                                        aria-label="Floating label select example">
                                                        <option :value="-1">-Chọn-</option>
                                                        <option v-for="e in genderValues" :value="e.id">{{ e.value1 }}
                                                        </option>
                                                    </select>
                                                    <label for="floatingSelect">Giới
                                                        tính<b style="color:red;">*</b></label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4">
                                                <div class="form-floating">
                                                    <select @change="onYearChange" ref="year_firstname" required
                                                        class="form-select rounded-pill">
                                                        <option value="-1">-Chọn-</option>
                                                        <!-- Example years, you can generate these dynamically -->
                                                        <option v-for="e in yearValues" :value="e">{{ e }}</option>
                                                    </select>
                                                    <label for="floatingInput">Năm sinh<b
                                                            style="color:red;">*</b></label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4">
                                                <div class="form-floating">
                                                    <select @change="onMonthChange" ref="month_firstname" disabled
                                                        class="form-select rounded-pill">
                                                        <option value="0">-Chọn-</option>
                                                        <!-- Example months -->
                                                        <option value="1">Tháng 1</option>
                                                        <option value="2">Tháng 2</option>
                                                        <option value="3">Tháng 3</option>
                                                        <option value="4">Tháng 4</option>
                                                        <option value="5">Tháng 5</option>
                                                        <option value="6">Tháng 6</option>
                                                        <option value="7">Tháng 7</option>
                                                        <option value="8">Tháng 8</option>
                                                        <option value="9">Tháng 9</option>
                                                        <option value="10">Tháng 10</option>
                                                        <option value="11">Tháng 11</option>
                                                        <option value="12">Tháng 12</option>
                                                    </select>
                                                    <label for="floatingInput">Tháng sinh</label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4">
                                                <div class="form-floating">
                                                    <select @change="ondateChange" ref="day_firstname" disabled
                                                        class="form-select rounded-pill">
                                                        <option value="0">-Chọn-</option>
                                                        <option v-for="d in dates" :value="d">{{ d }}</option>
                                                        <!-- Placeholder for days, actual options generated by JavaScript -->
                                                    </select>
                                                    <label for="floatingInput">Ngày sinh</label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-5" style="display: none;">
                                                <div class="form-floating">
                                                    <div title="Nếu không hiển thị năm vui lòng để lại mặc định zoom của trình duyệt."
                                                        class="form-control rounded-pill position-relative">
                                                        <input
                                                            title="Nếu không hiển thị năm vui lòng để lại mặc định zoom của trình duyệt."
                                                            id="txt_ngay_sinh_firstname" class="datetimepicker border-0"
                                                            type="text" placeholder="dd/mm/yyyy">
                                                    </div>
                                                    <label
                                                        title="Nếu không hiển thị năm vui lòng để lại mặc định zoom của trình duyệt."
                                                        for="floatingInput">Ngày sinh (Dương
                                                        lịch)<b style="color:red;">*</b></label>
                                                </div>
                                            </div>

                                            <div class="col-6 col-md-4">
                                                <div class="form-floating">
                                                    <select ref="txt_gio_sinh_firstname" disabled
                                                        class="form-select rounded-pill">
                                                        <option value="-1">-Chọn-</option>
                                                        <option v-for="h in hours" :value="h">{{ h }}</option>
                                                        <!-- Placeholder for days, actual options generated by JavaScript -->
                                                    </select>
                                                    <label for="floatingInput">Giờ sinh</label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4">
                                                <div class="form-floating">
                                                    <select ref="select_tinh_sinh_firstname"
                                                        class="form-select rounded-pill"
                                                        aria-label="Floating label select example">
                                                        <option :value="-1">-Chọn-</option>
                                                        <option v-for="city in citys" :value="city.id">{{ city.value2 }}
                                                        </option>
                                                    </select>
                                                    <label for="floatingSelect">Nơi sinh</label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-md-4">
                                                <div class="form-floating">
                                                    <select ref="select_tinh_o_firstname"
                                                        class="form-select rounded-pill"
                                                        aria-label="Floating label select example">
                                                        <option :value="-1">-Chọn-</option>
                                                        <option v-for="city in citys" :value="city.id">{{ city.value2 }}
                                                        </option>
                                                    </select>
                                                    <label for="floatingSelect">Nơi ở</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button id="btn_tra_cuu" type="button" v-on:click="onSearchClick"
                                                class="btn btn-lg btn-danger mb-3 min-w262 px-5 rounded-pill font-weight-bold ">TRA
                                                CỨU NGAY</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <p class="cloud1 d-none d-md-block"></p>
                    <p class="cloud2 d-none d-md-block"></p>
                </section>

                <InfomationComponent v-show="isShowResult" :infomation="infomation" />


                <ChartComponent @onChangeFortune="changeFortune" v-show="isShowResult" :data="chartData"
                    ref="chartRef" />

                <InfomationV2Component label-prefix="Tên" :show-footer="true" :show-header="true" v-show="isShowResult"
                    :infomation="infomationV2" ref="chartV2Ref" />

                <div v-show="isShowResult" class="group-chart" id="id_box_ten">
                    <GroupTitle :type="TitleType.Earth" title="Tên hợp cho Tiền tài" class-props="pt-3 pt-xl-5"
                        :icon='wealthIcons.moneyIcon' />
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-2">
                                <select :ref="r => selectWealth = r" class="btn btn-sm btn-light" id="sort_tientai"
                                    style="color: white;background-color: black;text-align: left"
                                    @change="onWealthSelectChange">
                                    <option value="0">Tiền tài: Mặc định</option>
                                    <option value="1">T.tài: Thấp dến cao</option>
                                    <option value="2">T.tài: Cao xuống thấp</option>
                                </select>
                            </div>
                        </div>
                        <div class="total-number my-3">
                            <strong id="total_rows_tientai">{{ `Tổng ${totalNames[0]} Tên` }}</strong>
                        </div>

                    </div>

                    <ListNameComponent :ref="r => wealthListRef = r" @onIndexChange="onWealthIndexChange"
                        :totalPages="totalPages[0]" :names="wealthData" />

                    <GroupTitle :type="TitleType.Fire" title="Tên hợp cho Công danh" class-props="pt-3 pt-xl-5"
                        :icon='wealthIcons.careerIcon' />
                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-2">
                                <select :ref="r => selectReputation = r" class="btn btn-sm btn-light" id="sort_congdanh"
                                    style="color: white;background-color: black;text-align: left"
                                    @change="onReputationSelectChange">
                                    <option value="0">Công danh: Mặc định</option>
                                    <option value="1">C.Danh: Thấp dến cao</option>
                                    <option value="2">C.Danh: Cao xuống thấp</option>
                                </select>
                            </div>
                        </div>
                        <div class="total-number my-3">
                            <strong id="total_rows_congdanh">{{ `Tổng ${totalNames[1]} Tên` }}</strong>
                        </div>

                    </div>
                    <ListNameComponent @onIndexChange="onReputationIndexChange" :ref="r => reputationListRef = r"
                        :totalPages="totalPages[1]" :names="reputation" />

                    <GroupTitle :type="TitleType.Wood" title="Tên hợp cho Sức khỏe" class-props="pt-3 pt-xl-5"
                        :icon='wealthIcons.healthyIcon' />
                    <div class="container-fluid container-scroll">
                        <div class="row">
                            <div class="col-2">
                                <select :ref="r => selectHealth = r" class="btn btn-sm btn-light" id="sort_suckhoe"
                                    style="color: white;background-color: black;text-align: left"
                                    @change="onHealthSelectChange">
                                    <option value="0">Sức khỏe: Mặc định</option>
                                    <option value="1">S.Khỏe: Thấp dến cao</option>
                                    <option value="2">S.Khỏe: Cao xuống thấp</option>
                                </select>
                            </div>
                        </div>
                        <div class="total-number my-3">
                            <strong id="total_rows_suckhoe">>{{ `Tổng ${totalNames[3]} Tên` }}</strong>
                        </div>
                    </div>
                    <ListNameComponent @onIndexChange="onHealthIndexChange" :ref="r => healthListRef = r"
                        :totalPages="totalPages[3]" :names="health" />

                    <GroupTitle :type="TitleType.Water" title="Tên hợp cho Hôn nhân" class-props="pt-3 pt-xl-5"
                        :icon='wealthIcons.marriageIcon' />
                    <div class="container-fluid container-scroll">

                        <div class="row">
                            <div class="col-2">
                                <select :ref="r => selectMarriage = r" class="btn btn-sm btn-light" id="sort_honnhan"
                                    style="color: white;background-color: black;text-align: left"
                                    @change="onMarriageSelectChange">
                                    <option value="0">Hôn Nhân: Mặc định</option>
                                    <option value="1">H.Nhân: Thấp đến cao</option>
                                    <option value="2">H.Nhân: Cao xuống thấp</option>
                                </select>
                            </div>
                        </div>
                        <div class="total-number my-3">
                            <strong id="total_rows_honnhan">{{ `Tổng ${totalNames[2]} Tên` }}</strong>
                        </div>
                    </div>
                    <ListNameComponent @onIndexChange="onMarriageIndexChange" :ref="r => marriageListRef = r"
                        :totalPages="totalPages[2]" :names="marriage" />
                </div>

                <InfomationV2Component label-prefix="Tên lót 1" :show-footer="false" :show-header="false"
                    v-show="isShowMiddleName1V2" :infomation="infomationMiddleName1V2" ref="chartMiddleName1V2Ref" />

                <InfomationV2Component label-prefix="Tên lót 2" :show-footer="false" :show-header="false"
                    v-show="isShowMiddleName2V2" :infomation="infomationMiddleName2V2" ref="chartMiddleName2V2Ref" />

                <InfomationV2Component label-prefix="Họ" :show-footer="false" :show-header="false"
                    v-show="isShowLastNameV2" :infomation="infomationLastName2V2" ref="chartLastNameV2Ref" />

            </div>
        </div>
    </div>
</template>