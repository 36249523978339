<template>
   <div class="container-fluid">
      <div :class="titleGroup">
         <div class="text-start me-auto">
            <img :src="imageSrc" class="figure-img rounded" alt="hình">
            <h3 class="title">{{titleText}}</h3>
         </div>
         <div class="text-end pe-1">
            <RouterLink :to="link" class="btn btn-sm btn-outline-danger border-0">Xem
               thêm</RouterLink>
         </div>
      </div>
   </div>
</template>

<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
</style>

<script lang="ts" setup>
import { ref } from 'vue';
defineProps<{
   titleGroup: string,
   imageSrc: string,
   link: string,
   titleText: string,
}>()

</script>

<script lang="ts">
const isShowLoading = ref<boolean>(false);

export const enableLoading = (isShow: boolean) => {
   isShowLoading.value = isShow;
}

</script>