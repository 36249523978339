import type { IDropdownItem } from "@/models/dropdownItem"
import router from "@/router"
import moment from "moment"
import "moment-timezone"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Chart, CategoryScale } from 'chart.js/auto'
import { mobileNetworkIcons, wealthIcons } from "./imageUtils"
Chart.register(CategoryScale);

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
const nameRegex = /([0-9])|([-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#])/
const passwordRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(.{8,})$/
const domainRegex = /^https?:\/\/[^\s/$.?#].[^\s/:]*(:\d+)?\/?$/
const uuidRegex = /\b[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}\b/

const locale = import.meta.env.VITE_APP_I18N_LOCALE

export const applicationRoles: Array<IDropdownItem> = [
  { value: "1", text: "RoleOwner" },
  { value: "2", text: "RoleAdmin" },
  { value: "3", text: "RoleViewer" },
]

export async function navigate(name: string) {
  await router.push({ name: name })
}

//20mb -> Byte
export const maxSizeImage = 20 * 1024 * 1024

export const convertDateTimeToNumber = (date: Date, time: string, isEndDay: boolean, isEndSecond: boolean) => {
  if (!date) return 0
  const dateTmp = new Date(date)
  const t = time == "" ? ["00", "00"] : time.split(":")
  dateTmp.setHours(parseInt(t[0]))
  dateTmp.setMinutes(parseInt(t[1]))
  isEndDay || isEndSecond ? dateTmp.setSeconds(59) : dateTmp.setSeconds(0)
  isEndDay ? dateTmp.setMilliseconds(999) : dateTmp.setMilliseconds(0)
  return dateTmp.getTime()
}

export const convertLastDateTimeToNumber = (date: Date) => {
  if (!date) return 0
  const dateTmp = new Date(date)
  dateTmp.setHours(23, 59, 59)
  return Math.floor(dateTmp.getTime() / 1000)
}

export const convertDateToNumber = (date: Date) => {
  if (!date) return 0
  const dateTmp = new Date(date)
  return Math.floor(dateTmp.getTime() / 1000)
}

export const convertDateFormatToDate = (date: string, format: string, time?: string) => {
  if (!date) return
  let dateString = date.replace(/\D/g, "-")

  if (format === "YYYY年MM月DD日") {
    dateString = dateString.slice(0, -1)
  }
  const parsedDate = new Date(dateString)
  if (time == "startOfDay") {
    // Set the time to 0:0:0:0
    parsedDate.setHours(0, 0, 0, 0)
  }
  if (time == "endOfDay") {
    // Set the time to 23:59:59.999
    parsedDate.setHours(23, 59, 59, 999)
  }
  return parsedDate
}

export const getFullName = (firstName: string, lastName: string): string => {
  if (!lastName && !firstName) return "—"
  else if (!lastName) return firstName
  else if (!firstName) return lastName
  else return lastName + " " + firstName
}

export const getFullAddress = (prefecture: string, city: string, address: string) => {
  if ((!prefecture && !city && !address) || (prefecture === "—" && city === "—" && address === "—")) return "—"
  return `${prefecture}${city}${address}`
}

export const convertTimestampToDate = (timestamp: number) => new Date(timestamp * 1000)

export const formatDateTime = (date: Date, format: string): string => {
  if (date?.toString() == "Invalid Date" || !date) return ""
  const timeDifference = moment.tz.guess()
  return moment(date).tz(timeDifference).format(format) || ""
}

export const isEmail = (value: string) => {
  if (!emailRegex.test(value) || value.includes("--")) {
    return false
  }
  return true
}

export const isValidName = (value: string) => {
  if (nameRegex.test(value)) {
    return false
  }
  return true
}

export const isValidPassword = (value: string) => {
  return passwordRegex.test(value)
}

export const isEmptyObject = (object: any): boolean => {
  for (const key in object) return false
  return true
}

export const isValidDomain = (value: string) => {
  return domainRegex.test(value)
}

export const isValidMultileDomain = (multiline: string) => {
  const normalizedString = multiline.replace(/[\s;|,]+/g, ' ').trim()

  const potentialStrings = normalizedString.split(' ');
  for (let i = 0; i < potentialStrings.length; i++) {
    if (!domainRegex.test(potentialStrings[i])) {
      return false
    }
  }

  return true
}

export const isValidMultileUUID = (multiline: string) => {
  const normalizedString = multiline.replace(/[\s;|,/]+/g, ' ').trim()

  const potentialStrings = normalizedString.split(' ');
  for (let i = 0; i < potentialStrings.length; i++) {
    if (!uuidRegex.test(potentialStrings[i])) {
      return false
    }
  }

  return true
}

export const dateFormatKey = {
  dateMonthYear: locale == "ja" ? "YYYY年MM月DD日" : "YYYY/MM/DD",
  dateAndTime: locale == "ja" ? "YYYY年MM月DD日HH:mm" : "YYYY/MM/DD HH:mm",
  dateAndTimeDetail: locale == "ja" ? "YYYY年MM月DD日HH:mm:ss" : "YYYY/MM/DD HH:mm:ss",
  yearAndMonth: locale == "ja" ? "YYYY年MM月" : "YYYY/MM",
  month: locale == "ja" ? "MM月" : "MM",
  dateAndTimeFormat: "YYYYMMDDHHmm",
  dateMonthYearFormat: "YYYY-MM-DD",
}

export const resizeImage = async (file: File) => {
  const img = new Image()
  img.src = URL.createObjectURL(file)

  return new Promise((resolve) => {
    const result = {
      fileUpload: null,
      base64: "",
    }
    img.onload = async () => {
      const outputCanvas: HTMLCanvasElement = document.createElement("canvas")
      const outputCanvasContext = outputCanvas.getContext("2d")
      const constantSize = 256

      if (img.height >= img.width) {
        outputCanvas.width = constantSize
        outputCanvas.height = constantSize
        outputCanvasContext?.drawImage(
          img,
          0,
          Math.floor((img.height - img.width) / 2),
          img.width,
          img.width,
          0,
          0,
          constantSize,
          constantSize
        )
      } else {
        outputCanvas.width = constantSize
        outputCanvas.height = constantSize
        outputCanvasContext?.drawImage(
          img,
          Math.floor((img.width - img.height) / 2),
          0,
          img.height,
          img.height,
          0,
          0,
          constantSize,
          constantSize
        )
      }

      outputCanvas.toBlob((blob) => {
        result.fileUpload = blob as any
        result.base64 = outputCanvas.toDataURL()
        resolve(result)
      }, file.type)
    }
  })
}

export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error || (error && (error as any).message)) {
    return (error as any).message
  }
  return String(error)
}

export const convertTime = (totalSeconds: number) => {
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = Math.floor(totalSeconds % 60)
  if (minutes) {
    return minutes + "分" + seconds + "秒"
  } else {
    return seconds + "秒"
  }
}

export function exportCSV(fileContent: any, filename: string): void {
  const blob = new Blob(["\ufeff" + fileContent], {
    type: "text/csv; charset=utf-8",
  })
  const link = document.createElement("a")
  link.href = URL.createObjectURL(blob)
  link.download = filename
  link.click()
  URL.revokeObjectURL(link.href)
}

export function exportJSON(jsonContent: any, filename: string): void {
  const jsonString = JSON.stringify(jsonContent, null, 2);
  const blob = new Blob([jsonString], { type: "application/json" });
  const link = document.createElement("a")
  link.href = URL.createObjectURL(blob)
  link.download = filename
  link.click()
  URL.revokeObjectURL(link.href)
}

export function setNameCSV(applicationName: string): string {
  const currentDateTime = new Date()
  const dateTime = formatDateTime(currentDateTime, dateFormatKey.dateAndTimeFormat)
  let csvName = ""
  csvName = "_" + applicationName + "_" + dateTime + ".csv"

  return csvName
}

export function setNameCustomIdCSV() {
  const currentDateTime = new Date()
  const dateTime = formatDateTime(currentDateTime, dateFormatKey.dateAndTimeFormat)
  let csvName = ""
  csvName = dateTime + ".csv"
  return csvName
}

export const parseDateTime = (hours: string, date: Date): string => {
  if (!date) return ""
  if (hours != "") {
    const time = hours.split(":")
    date.setHours(parseInt(time[0]))
    date.setMinutes(parseInt(time[1] || "00"))
  }
  const hourseFormat = moment(date).format()

  return hourseFormat
}

export const getFilePathFromUrl = (urlFileInCloudStorage: string) => {
  if (urlFileInCloudStorage) return import.meta.env.VITE_APP_CLOUD_URL + urlFileInCloudStorage
}

export const numberWithCommas = (x: number): string => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const getMobileNetworkProviderIcon = (mobileNetworkProvider: string): string => {
  let img = '';
  switch (mobileNetworkProvider) {
    case 'Vinaphone': img = mobileNetworkIcons.vinaIcon; break;
    case 'Viettel': img = mobileNetworkIcons.viettelIcon; break;
    case 'Mobifone': img = mobileNetworkIcons.mobiIcon; break;
    default:
      break;
  }
  return img;
}

export const getDefaultGender = () => {
  return [
    {
      id: 1,
      value1: "Nam",
    },
    {
      id: 2,
      value1: "Nữ",
    }
  ]
}

export const getYears = () => {
  const years: number[] = [];
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 100;
  for (let i = currentYear; i >= previousYear; i--) {
    years.push(i);
  }
  return years;
}

export const getPassAndFeatureYears = () => {
  const years: number[] = [];
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 100;
  const nextYear = currentYear + 100;
  for (let i = nextYear; i >= previousYear; i--) {
    years.push(i);
  }
  return years;
}

export const getDates = (month: number) => {
  if (month >= 1 && month <= 12) {
    const daysInMonth = new Date(new Date().getFullYear(), month, 0).getDate();
    const dates: number[] = [];
    for (let i = 1; i <= daysInMonth; i++) {
      dates.push(i);
    }
    return dates;
  }
  return [];
}

export const getTime = () => {
  const hours: number[] = [];
  for (let i = 0; i <= 23; i++) {
    hours.push(i);
  }
  return hours;
}

export const getCitys = () => {
  return [
    {
      id: 59,
      "value2": "Hà Nội"
    },
    {
      id: 22,
      "value2": "Hồ Chí Minh City"
    },
    {
      id: 60,
      "value2": "Hải Phòng"
    },
    {
      id: 33,
      "value2": "Cần Thơ"
    },
    {
      id: 13,
      "value2": "Đà Nẵng"
    },
    {
      id: 18,
      "value2": "Đồng Nai"
    },
    {
      id: 23,
      "value2": "An Giang"
    },
    {
      id: 14,
      "value2": "Bà Rịa - Vũng Tàu"
    },
    {
      id: 36,
      "value2": "Bắc Giang"
    },
    {
      id: 37,
      "value2": "Bắc Kạn"
    },
    {
      id: 24,
      "value2": "Bạc Liêu"
    },
    {
      id: 38,
      "value2": "Bắc Ninh"
    },
    {
      id: 25,
      "value2": "Bến Tre"
    },
    {
      id: 1,
      "value2": "Bình Định"
    },
    {
      id: 15,
      "value2": "Bình Dương"
    },
    {
      id: 16,
      "value2": "Bình Phước"
    },
    {
      id: 17,
      "value2": "Bình Thuận"
    },
    {
      id: 26,
      "value2": "Cà Mau"
    },
    {
      id: 39,
      "value2": "Cao Bằng"
    },
    {
      id: 2,
      "value2": "Đak Lak"
    },
    {
      id: 3,
      "value2": "Đak Nông"
    },
    {
      id: 40,
      "value2": "Điện Biên"
    },
    {
      id: 27,
      "value2": "Đồng Tháp"
    },
    {
      id: 4,
      "value2": "Gia Lai"
    },
    {
      id: 41,
      "value2": "Hà Giang"
    },
    {
      id: 42,
      "value2": "Hà Nam"
    },
    {
      id: 43,
      "value2": "Hà Tĩnh"
    },
    {
      id: 44,
      "value2": "Hải Dương"
    },
    {
      id: 28,
      "value2": "Hậu Giang"
    },
    {
      id: 45,
      "value2": "Hòa Bình"
    },
    {
      id: 46,
      "value2": "Hưng Yên"
    },
    {
      id: 5,
      "value2": "Khánh Hòa"
    },
    {
      id: 29,
      "value2": "Kiên Giang"
    },
    {
      id: 6,
      "value2": "Kon Tum"
    },
    {
      id: 47,
      "value2": "Lai Châu"
    },
    {
      id: 19,
      "value2": "Lâm Đồng"
    },
    {
      id: 48,
      "value2": "Lạng Sơn"
    },
    {
      id: 49,
      "value2": "Lào Cai"
    },
    {
      id: 30,
      "value2": "Long An"
    },
    {
      id: 50,
      "value2": "Nam Định"
    },
    {
      id: 51,
      "value2": "Nghệ An"
    },
    {
      id: 52,
      "value2": "Ninh Bình"
    },
    {
      id: 20,
      "value2": "Ninh Thuận"
    },
    {
      id: 53,
      "value2": "Phú Thọ"
    },
    {
      id: 7,
      "value2": "Phú Yên"
    },
    {
      id: 8,
      "value2": "Quảng Bình"
    },
    {
      id: 9,
      "value2": "Quảng Nam"
    },
    {
      id: 10,
      "value2": "Quảng Ngãi"
    },
    {
      id: 54,
      "value2": "Quảng Ninh"
    },
    {
      id: 11,
      "value2": "Quảng Trị"
    },
    {
      id: 31,
      "value2": "Sóc Trăng"
    },
    {
      id: 55,
      "value2": "Sơn La"
    },
    {
      id: 21,
      "value2": "Tây Ninh"
    },
    {
      id: 56,
      "value2": "Thái Bình"
    },
    {
      id: 57,
      "value2": "Thái Nguyên"
    },
    {
      id: 58,
      "value2": "Thanh Hóa"
    },
    {
      id: 12,
      "value2": "Thừa Thiên - Huế"
    },
    {
      id: 32,
      "value2": "Tiền Giang"
    },
    {
      id: 34,
      "value2": "Trà Vinh"
    },
    {
      id: 61,
      "value2": "Tuyên Quang"
    },
    {
      id: 35,
      "value2": "Vĩnh Long"
    },
    {
      id: 62,
      "value2": "Vĩnh Phúc"
    },
    {
      id: 63,
      "value2": "Yên Bái"
    }
  ]
}

export const images = [wealthIcons.sonIcon,
  wealthIcons.daughterIcon,
  wealthIcons.moneyIcon,
  wealthIcons.marriageIcon,
  wealthIcons.lawIcon,
  wealthIcons.careerIcon,
  wealthIcons.fatherIcon,
  wealthIcons.motherIcon,
  wealthIcons.healthyIcon,
  wealthIcons.siblingIcon];

export enum AsianZodiac {
  RatZodiac = "Tý",
  OxZodiac = "Sửu",
  TigerZodiac = "Dần",
  CatZodiac = "Mẹo",
  DragonZodiac = "Thìn",
  SnakeZodiac = "Tỵ",
  HosreZodiac = "Ngọ",
  GoatZodiac = "Mùi",
  MonkeyZodiac = "Thân",
  RoosterZodiac = "Dậu",
  DogZodiac = "Tuất",
  PigZodiac = "Hợi",
}

const fiveElementColors: any = {
  kim: "#F5B000",
  moc: "#077D0F",
  thuy: "#0053CE",
  hoa: "#C61914",
  tho: "#4C2802",
}

const fiveElementNames: any = {
  kim: "KIM",
  moc: "MỘC",
  thuy: "THỦY",
  hoa: "HỎA",
  tho: "THỔ",
}

export const priceSortOptions = [
  { id: 0, name: "Giá Tiền" },
  { id: 1, name: "Tiền: Thấp dến cao" },
  { id: 2, name: "Tiền: Cao xuống thấp" },
];

export const WealthSortOptions = [
  { id: 0, name: "Cải vận" },
  { id: 1, name: "Tiền tài: Thấp dến cao" },
  { id: 2, name: "Tiền tài: Cao xuống thấp" },
  { id: 3, name: "Công Danh: Thấp dến cao" },
  { id: 4, name: "Công Danh: Cao xuống thấp" },
  { id: 5, name: "Hôn Nhân: Thấp dến cao" },
  { id: 6, name: "Hôn Nhân: Cao xuống thấp" },
  { id: 7, name: "Sức Khỏe: Thấp dến cao" },
  { id: 8, name: "Sức Khỏe: Cao xuống thấp" },
];


const getFiveElementColor = (labels: Array<string>) => {
  const colors = [];
  for (const l of labels) {
    colors.push(fiveElementColors[l]);
  }
  return colors;
}

const getFiveElementNames = (labels: Array<string>) => {
  const colors = [];
  for (const l of labels) {
    colors.push(fiveElementNames[l]);
  }
  return colors;
}

const imageMap = new Map();


export function createChartExplain(chartLabel: string, chartData: Array<number>, ctx: CanvasRenderingContext2D | null, options: any = null) {

  try {
    if (!ctx) return;
    const chartView = new Chart(ctx, {
      plugins: [ChartDataLabels, {
        afterDraw: chart => {
          const ctx = chart.ctx;
          const xAxis = chart.scales.x;
          const yAxis = chart.scales.y;
          xAxis.ticks.forEach((value, index) => {
            const x = xAxis.getPixelForTick(index);
            let image = undefined
            const src = images[index];
            if (imageMap.has(src)) {
              image = imageMap.get(src);
            }
            else {
              image = new Image();
              image.src = src;
              imageMap.set(src, image);
            }

            ctx.drawImage(image, x - 11, yAxis.bottom + 10, 20, 20);
          });
        }
      }],
      type: 'line',
      data: {
        labels: ["CON TRAI", "CON GÁI", "TIỀN TÀI", "HÔN NHÂN", "PHÁP LUẬT", "CÔNG DANH", "CHA", "MẸ", "SỨC KHỎE", "ANH EM"],
        datasets: [
          {
            label: chartLabel,
            data: chartData,//[10, -19, 69, 84, -23, -40, -13, -24, -70, -80],
            borderColor: options ? options.borderColor : "rgba(198,25,20, 1)",
            backgroundColor: options ? options.backgroundColor : "rgba(198,25,20, 0.3)",
            fill: 'start',
            tension: 0.5,
            pointBackgroundColor: options ? options.pointBackgroundColor : "#C61914",
            pointBorderWidth: 1.5,
            datalabels: {
              labels: {
                index: {
                  align: 'end',
                  anchor: 'end',
                  color: options ? options.labelColor : "#ba8d11",
                  font: {
                    weight: 'bold',
                    size: 13
                  },
                  formatter: function (value, context) {
                    return value + '%';
                  },
                  padding: 1,
                },
              }
            }
          },
          {
            label: "",
            data: undefined,
            borderColor: undefined,
            backgroundColor: undefined,
            fill: undefined,
            tension: 0,
            pointBackgroundColor: undefined,
            pointBorderWidth: 0,
            datalabels: {
              labels: {
                index: {
                  align: 'end',
                  anchor: 'end',
                  color: "#0b6dff",
                  font: {
                    weight: 'bold',
                    size: 13
                  },
                  formatter: function (value, context) {
                    return value + '%';
                  },
                  padding: 1,
                },
              }
            }
          }
        ]
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          filler: {
            propagate: true
          },
          legend: {
            display: options ? options.enableLabel : true,
            position: 'bottom',
            labels: {
              padding: 10,
              usePointStyle: true,
              boxWidth: 5,
              boxHeight: 5,
              color: "#ffff00",
              font: {
                family: "Roboto",
                size: 15,
                weight: 'bold',
              }
            }
          },
        },
        layout: {
          padding: {
            top: 30,  // Thêm khoảng cách 50px ở trên cùng desktop
            bottom: options ? options.paddingBottom : 0,
            left: 8,
            //right: 16,
          }
        },
        scales: {
          x: {
            grid: {
              borderColor: 'grey',
              tickColor: 'grey',
              color: 'rgba(255, 255, 255, 0.07)'
            },
            ticks: {
              padding: 30,
              display: true,
              font: (context) => {
                const avgSize = Math.round((context.chart.height + context.chart.width) / 4.5);
                let size = Math.round(avgSize / 32);
                size = size > 12 ? 12 : size;
                return {
                  family: "Roboto",
                  size: size,
                  weight: 500,

                }
              },
              color: options ? options.labelColor : "#29a64b",
              minRotation: 0,
              maxRotation: 0,
              autoSkip: false,
            }
          },
          y: {
            grid: {
              borderColor: 'grey',
              tickColor: 'grey',
              color: 'rgba(255, 255, 255, 0.07)',
            },
            ticks: {
              font: {
                family: "Roboto",
                size: 12,
                weight: 500,
                color: "#000000",
              }
            },
          }
        },
      }
    });
    return chartView;
  } catch (error) {
    throw error
  }
}

export const createChartFiveElement = (chartData: Array<number>, ctx: CanvasRenderingContext2D | null, colorLabel: string = "#ba8d11", labels: Array<string> = ["kim", "moc", "thuy", "hoa", "tho"]) => {
  try {
    if (!ctx) return;
    const chart = new Chart(ctx, {
      plugins: [ChartDataLabels],
      type: 'doughnut',
      data: {
        labels: getFiveElementNames(labels),
        datasets: [{
          backgroundColor: getFiveElementColor(labels),
          hoverBorderColor: 'white',
          borderWidth: 0,
          data: chartData,//[25, 25, 25, 12.5, 12.5],
          datalabels: {
            labels: {
              index: {
                align: 'end',
                anchor: 'end',
                color: colorLabel,
                font: {
                  weight: 'bold',
                  size: 14
                },
                formatter: function (value, context) {
                  let datasets = context.chart.data.datasets;
                  let percentage: string = '0%';
                  if (datasets.indexOf(context.dataset) === datasets.length - 1) {
                    const sum: number = datasets[0].data.reduce((a, b) => a + b, 0);
                    percentage = Math.round((value / sum) * 100) + '%';
                    // return context.chart.data.labels[context.dataIndex] + '\n    ' + percentage;
                    return percentage;
                  } else {
                    return percentage;
                  }
                  // return context.chart.data.labels[context.dataIndex]; //display labels
                },
                padding: 2,
              },
            }
          }
        }]
      },

      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              padding: 19,
              usePointStyle: true,
              boxWidth: 5,
              boxHeight: 5,
              font: {
                family: "Roboto",
                size: 14,
                weight: 500,
                color: "#000000",
              }
            }
          }
        },
        aspectRatio: 1,
        layout: {
          padding: 32,
        },
      },
    });
    return chart;
  } catch (error) {
  }
}

export const createCalendarInterpretionChart = (chartData: Array<number>, ctx: CanvasRenderingContext2D | null, labels: string[], borderColor: string, backgroundColor: string) => {
  try {
    if (!ctx) return;
    return new Chart(ctx, {
      plugins: [ChartDataLabels],
      type: 'line',
      data: {
        labels: labels, // Thời gian, ví dụ: ["23h-1h", "1h-2h", "2h-3h", ...]
        datasets: [{
          label: 'Chỉ số', // Nhãn biểu đồ, ví dụ: "Tiền tài so với giờ"
          data: chartData, // Dữ liệu phần trăm
          borderColor: borderColor,
          backgroundColor: backgroundColor,
          fill: 'start',
          tension: 0.5,
          pointBackgroundColor: borderColor,
          pointBorderWidth: 1.5,
          datalabels: {
            labels: {
              index: {
                align: 'end',
                anchor: 'end',
                color: borderColor,
                font: {
                  weight: 'bold',
                  size: 13
                },
                formatter: function (value) {
                  return value + '%';
                },
                padding: 6,
              }
            }
          }
        }]
      },
      options: {
        responsive: true,
        plugins: {
          filler: {
            propagate: true
          },
          legend: {
            display: false,
            position: 'top',
            labels: {
              usePointStyle: true,
              boxWidth: 0,
              color: "#ffff00",
              font: {
                family: "Roboto",
                size: 20,
                weight: 'bold',
              },
              padding: 20, // Tăng khoảng cách giữa nhãn và biểu đồ
            }
          },
          datalabels: {
            color: borderColor,
            font: {
              weight: 'bold'
            }
          }
        },
        layout: {
          padding: {
            top: 36,
            bottom: 20,
            left: 20,
            right: 20,
          }
        },
        scales: {
          x: {
            grid: {
              borderColor: 'grey',
              tickColor: 'grey',
              color: 'rgba(255, 255, 255, 0.07)'
            },
            ticks: {
              font: {
                family: "Roboto",
                size: 12,
                weight: 500,
              },
              color: borderColor, // Sử dụng borderColor cho nhãn thời gian
              padding: 0 // Thay đổi giá trị để tăng khoảng cách giữa các nhãn và trục y
            }
          },
          y: {
            grid: {
              borderColor: 'grey',
              tickColor: 'grey',
              color: 'rgba(255, 255, 255, 0.07)'
            },
            ticks: {
              font: {
                family: "Roboto",
                size: 12,
                weight: 500,
              },
              padding: 0 //Thay đổi giá trị để tăng khoảng cách giữa các nhãn và trục x
            }
          }
        }
      }
    });
  }
  catch (err) {

    throw err;
  }
}

export const getOriginPath = () => {
  return window.location.origin;
}


export const createLabelSecond = (isEmpty: boolean) => {
  if (isEmpty) return {
    borderColor: undefined,
    backgroundColor: undefined,
    fill: undefined,
    tension: 0,
    pointBackgroundColor: undefined,
    pointBorderWidth: 0,
  };

  const label = {
    borderColor: "rgba(0,83,206, 1)",
    backgroundColor: "rgba(0,83,206, 0.3)",
    fill: 'start',
    tension: 0.5,
    pointBackgroundColor: "#004ab9",
    pointBorderWidth: 1.5,
  }
  return label;
}

export const getZodiacAsianWithIndex = (zodiacId: number) => {

  const zodiacs = Object.values(AsianZodiac);
  return zodiacs[zodiacId - 1];
}