export default {
  btnRegisterLogin: "xxxx",
  setAccountName: "Set your account name. Account name cannot be changed later.",
  register: "Register",
  accountCreated: "Set your account name. You cannot change your account name later.",
  next: "Next",
  selectAccount: "Please select the account to log in.",
  createNewAccount: "Create a new account",
  here: "here",
  smartPOSTCorporation: "SmartPOST Co., Ltd.",
  setGoCorporation: "SetGo Co., Ltd.",
  accountName: "Account name",
  login: "Login",
  selectAnAccount: "Select an account",
  registeringAccount:"Registering an account",
}
