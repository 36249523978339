<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, useTemplateRef } from 'vue';
import Calendar from '../parts/PerpetutalCalender/Calendar.vue';
import PersonalInformation, { type ActionData, type PersonalData } from '../parts/PerpetutalCalender/PersonalInformation.vue';
import Interpretation, { type CalendarInterPretionData, type InDateData } from '../parts/PerpetutalCalender/Interpretation.vue';
import { RepositoryFactory } from '@/lib/https';
import PerpetutalCalendarRepository from '@/lib/https/PerpetutalCalendarRepository';
import type { DateItemData } from '../parts/PerpetutalCalender/DateCalendarItem.vue';
import type { ICalendarDto, ICalendarInterpretionDto, IDetailCalendarDto } from '@/models/https/perpetutalCalendarResponse';
import store from '@/store';
import type { UserProfileSate } from '@/store/userProfile/state';
import type { ICalendarInterpretionParam, IDateInterpretionParam, IGetDetailPerpetutalCalendarParam } from '@/models/https/perpetualCalendarRequests';
import DateCalendarDetail, { type DetailDateData } from '../parts/PerpetutalCalender/DateCalendarDetail.vue';
import moment from 'moment';
import Swal from 'sweetalert2';

const userProfile = computed(() => store.state.userProfile as UserProfileSate)
const { checkPerpetutalCalendar, getPerpetutalCalendar, getDetailPerpetutalCalendar, getIndateInterpretion, getCalendarInterpretion } = RepositoryFactory.getRepository<PerpetutalCalendarRepository>(PerpetutalCalendarRepository);
const isShowCalendar = ref<boolean>(false);
const isChangeStatusCalendar = ref<boolean>(false);
const status = ref<string>("");
const dates = ref<Array<DateItemData>>([]);
const titleCalendar = ref<string>("");
const detailDateData = ref<DetailDateData>();
const personalInfo = ref<PersonalData>();
const interpretionInDateData = ref<InDateData>();
const interpretionData = ref<CalendarInterPretionData>();
const interpretationRef = useTemplateRef("interpretionCalendar");

const convertInterpretionInDateData = (detail: IDetailCalendarDto) => {
   const dt: InDateData = {
      name: "",
      lineChartInDate: Object.values(detail?.ti_le_10_duong_trong_ngay[0] ?? []),
   }
   return dt;
}

const getColorChartTitle = (chartId: number) => {
   switch (chartId) {
      case 3://tiền
         return "#ffff00";
      case 2://công danh
         return "#ff0000";
      case 1://sức khẻo
         return "#6AE344";
      case 4:// hôn nhân
         return "#E53BF2";
      default:
         return "#ffff00";
   }
}


const convertInterpretionData = (detail: ICalendarInterpretionDto, charId: number) => {
   const dt: CalendarInterPretionData = {
      lineChartInterpretion: Object.values(detail?.chart_theo_gio[0] ?? []),
      titleChartInterpretion: detail.title_chart,
      interpretion: detail.luan_giai,
      favorableReview: detail.nhan_xet_tot,
      unfavorableReview: detail.nhan_xet_xau,
      colorChartInterpretion: getColorChartTitle(charId),
      chartId: charId,
   }
   return dt;
}

const convertDataDetail = (detail: IDetailCalendarDto) => {
   const dt: DetailDateData = {
      Weekdays: detail.thu,
      SolarDate: detail.ngay_duong,
      SolarMonth: detail.thang_duong,
      SolarYear: detail.nam_duong,
      LunaDate: detail.ngay_am,
      LunaMonth: detail.thang_am,
      LunaYear: detail.nam_duong,
      DateName: detail.ngay_ten,
      MonthName: detail.thang_ten,
      YearName: detail.nam_ten,
      ImageChartUrl: detail.image_chart,
   }
   return dt;
}

const converDataPersonalInfo = (detail: IDetailCalendarDto) => {
   const ps: PersonalData = {
      FullName: detail.ten,
      Gender: (detail.gioi_tinh == "Nam" ? 1 : 2),
      BirthYear: detail.nam_sinh,
      phoneNumber: detail.so_dien_thoai,
      hoaGiapIcon: detail.image_avartar_user,
   }
   return ps;
}

const convertCalendarData = (data: ICalendarDto) => {
   const it: DateItemData = {
      DateDescription: data.ngay_mo_ta,
      InActiveDate: data.ngay_inactive,
      HightLightDate: data.ngay_highlight,
      SolarDateSort: data.ngay_duong_sort,
      HiddenImageUrl: data.anh_an,
      DateName: data.ngay_ten,
      SolarDateColor: data.ngay_duong_mau,
      SolarDate: data.ngay_duong,
      LunarDateColor: data.ngay_am_mau,
      LunarDate: data.ngay_am,
      WealthIcon: data.icon_tien,
      WealthColor: data.tien_mau,
      WealthTitle: data.title_tien,
      WealthPercent: data.percent_tien,
   }
   return it;
}

const createDefaultRequest = () => {
   const year = new Date(userProfile.value.birthDay);

   return {
      gioi_tinh: userProfile.value.gender,
      mang: 0,
      nam_nguoi_dung: year.getFullYear(),
      nam_lich_van_nien: new Date().getFullYear(),
      thang_lich_van_nien: new Date().getMonth() + 1,
      sort_danh_muc: 0,
      sort_tot_xau_hoang_dao: 0,
      sort_ngay_tot_xau: 0,
      thu_lich: 0,
      name: ""
   };
}

const createDefautDetailParam = () => {
   const year = new Date(userProfile.value.birthDay);
   const pr: IGetDetailPerpetutalCalendarParam = {
      date: moment.utc(new Date()).format("yyyy-MM-DD"),
      nam_sinh: year.getFullYear().toString(),
      gioi_tinh: userProfile.value.gender
   }
   return pr;
}

const createInterpretionParam = (name: string, tab: number, year: string, gender: number, date: string) => {
   const param: ICalendarInterpretionParam = {
      tab: tab,
      date: date,
      nam_sinh: year,
      gioi_tinh: gender,
      name: name,
   }
   return param;
}

const createInDateParam = (name: string, year: string, gender: number, date: string) => {
   const param: IDateInterpretionParam = {
      date: date,
      nam_sinh: year,
      gioi_tinh: gender,
      name: name,
   }
   return param;
}

const getDetail = () => {
   const pr = createDefautDetailParam();
   getDetailPerpetutalCalendar(pr).then(d => {
      if (d?.result_code === "000") {
         detailDateData.value = convertDataDetail(d.data[0]);
         personalInfo.value = converDataPersonalInfo(d.data[0]);
         interpretionInDateData.value = convertInterpretionInDateData(d.data[0]);
      }
   });
}

const check = () => {
   checkPerpetutalCalendar().then(res => {
      if (res?.data.gioi_tinh != 0 && res?.data.nam_sinh != 0) {
         isShowCalendar.value = true;
         const rq = createDefaultRequest();
         //rq.mang = res?.data ?? 0;
         getPerpetutalCalendar(rq).then(p => {
            if (p?.result_code === "000") {
               dates.value = p.data.flatMap(d => { return convertCalendarData(d); })
               titleCalendar.value = p.data[0].title_calenda;
            }
         });

         getDetail();

      }
      else {
         isShowCalendar.value = false;
      }

      if (res?.data.lvn == 1) {
         isChangeStatusCalendar.value = true;
      }
      else {
         isChangeStatusCalendar.value = false;
      }
      status.value = res?.result_msg ?? '';
   });
}

const isViewBadOrGoodRef = ref<boolean>();

let isViewBadOrGood = false;
const persionalInfo = ref();
let cacheDate: string | undefined = undefined;
let cacheTab: number = 3;
const detailCalendar = ref();


const onCalendarClick = (date: string) => {
   cacheDate = date;
   if (isViewBadOrGood) {
      onReviewBadOrGoodClick(persionalInfo.value.getCurrentValue());     
   }
   else {
      const pr = createDefautDetailParam();
      pr.date = date;
      getDetailPerpetutalCalendar(pr).then(d => {
         if (d?.result_code === "000") {
            detailDateData.value = convertDataDetail(d.data[0]);
            //personalInfo.value = converDataPersonalInfo(d.data[0]);
            interpretionInDateData.value = convertInterpretionInDateData(d.data[0]);
            detailCalendar.value?.$el.scrollIntoView({ behavior: "smooth" });
         }
      });
     
   }
}

const onReviewBadOrGoodClick = (actionData: ActionData) => {
   isViewBadOrGood = true;
   const date = cacheDate ?? moment.utc(new Date()).format("yyyy-MM-DD");
   const param = createInDateParam(actionData.FullName, actionData.BirthYear.toString(), actionData.Gender, date);
   getIndateInterpretion(param).then(async d => {
      if (d?.result_code === "000") {
         detailDateData.value = convertDataDetail(d.data[0]);
         //personalInfo.value = converDataPersonalInfo(d.data[0]);
         interpretionInDateData.value = convertInterpretionInDateData(d.data[0]);
         isViewBadOrGoodRef.value = true;
         await nextTick();
         interpretationRef.value?.$el.scrollIntoView({ behavior: "smooth" });
      }
      else {
         if (d?.result_msg) {

            const error: any = {
               icon: "error",
               title: d.result_msg,
            }
            Swal.fire(error);
         }
      }
   })

   const pr2 = createInterpretionParam(actionData.FullName, cacheTab, actionData.BirthYear.toString(), actionData.Gender, date);
   getCalendarInterpretion(pr2).then(r => {
      if (r?.result_code === "000") {
         interpretionData.value = convertInterpretionData(r.data[0], pr2.tab);
      }
      else {
         if (r?.result_msg) {

            const error: any = {
               icon: "error",
               title: r.result_msg,
            }
            Swal.fire(error);
         }
      }
   });
}

const onChangeTab = (chartId: number) => {
   cacheTab = chartId;
   if (isViewBadOrGood) {
      const date = cacheDate ?? moment.utc(new Date()).format("yyyy-MM-DD");
      const infoValues = persionalInfo.value.getCurrentValue();
      const pr2 = createInterpretionParam(infoValues.FullName, cacheTab, infoValues.BirthYear.toString(), infoValues.Gender, date);
      getCalendarInterpretion(pr2).then(r => {
         if (r?.result_code === "000") {
            interpretionData.value = convertInterpretionData(r.data[0], pr2.tab);
         }
      });
   }

}

onMounted(() => {
   check();
})

</script>

<template>

   <section class="section-page section-changing-fortune perpetual-calendar linear">
      <div class="container-fluid">
         <h1 class="title-section mb-0 mb-sm-3">LỊCH VẠN NIÊN MỖI NGÀY</h1>
         <div class="list-group mt-4">
            <div id="div_status"
               class="alert bg-theme text-white text-center fst-italic py-1 rounded-4 alert-changing-fortune"
               :style="isChangeStatusCalendar ? { 'background-color': '#2f2fca' } : {}" role="alert">
               <p id="lvn_status">{{ status }}</p>
            </div>
         </div>

         <div v-show="!isShowCalendar" class="list-group mt-4" id="div_gioi_tinh_nam_sinh">
            <div class="list-option">
               <div class="item-option">
                  <img src="@/assets/images/male.svg" alt="male">
                  <input type="radio" class="btn-check" name="options-gender" id="male" autocomplete="off" value="1"
                     onchange="getBySex_Mang('none');">
                  <label class="btn" for="male"><strong>Nam</strong></label>
               </div>
               <div class="item-option">
                  <img src="@/assets/images/female.svg" alt="female">
                  <input type="radio" class="btn-check" name="options-gender" id="female" autocomplete="off" value="2"
                     onchange="getBySex_Mang('none');">
                  <label class="btn" for="female"><strong>Nữ</strong></label>
               </div>
            </div>
            <div class="list-option list-five-elements">
               <div class="item-option">
                  <img src="@/assets/images/kim.svg" alt="metal">
                  <input type="radio" class="btn-check" name="options-element" id="metal" autocomplete="off" value="1"
                     onchange="getBySex_Mang('kim')">
                  <label class="btn" for="metal"><strong>Kim</strong></label>
               </div>
               <div class="item-option">
                  <img src="@/assets/images/moc.svg" alt="wood">
                  <input type="radio" class="btn-check" name="options-element" id="wood" autocomplete="off" value="2"
                     onchange="getBySex_Mang('moc')">
                  <label class="btn" for="wood"><strong>Mộc</strong></label>
               </div>
               <div class="item-option">
                  <img src="@/assets/images/thuy.svg" alt="water">
                  <input type="radio" class="btn-check" name="options-element" id="water" autocomplete="off"
                     onchange="getBySex_Mang('thuy')" value="3">
                  <label class="btn" for="water"><strong>Thuỷ</strong></label>
               </div>
               <div class="item-option">
                  <img src="@/assets/images/hoa.svg" alt="fire">
                  <input type="radio" class="btn-check" name="options-element" id="fire" autocomplete="off" value="4"
                     onchange="getBySex_Mang('hoa')">
                  <label class="btn" for="fire"><strong>Hoả</strong></label>
               </div>
               <div class="item-option">
                  <img src="@/assets/images/tho.svg" alt="earth">
                  <input type="radio" class="btn-check" name="options-element" id="earth" autocomplete="off" value="5"
                     onchange="getBySex_Mang('tho')">
                  <label class="btn" for="earth"><strong>Thổ</strong></label>
               </div>
            </div>
         </div>
         <div v-show="!isShowCalendar" class="detail-five-elements mt-3 mt-lg-5" id="div_gioi_tinh_nam_sinh1">
            <div class="d-flex justify-content-center">
               <div class="img-element" id="icon_title">
               </div>
            </div>
            <!-- class name changes color: metal, wood, water, fire, earth -->
            <div class="list-year metal" style="display: none;" id="option_1">
               <div class="d-flex justify-content-center">
                  <h2 class="title-element mb-4 mb-lg-5"><span class="text" id="name_mang">mệnh kim</span></h2>
               </div>
               <div class="table-year" id="table_year_calendar">
                  <!-- bind data -->
               </div>
            </div>
         </div>
      </div>
      <div class="container-fluid px-0">
         <Calendar @on-item-click="onCalendarClick" :title="titleCalendar" :dateItems="dates" v-if="isShowCalendar" />

         <DateCalendarDetail ref="detailCalendar" :detailData="detailDateData" v-if="isShowCalendar" />
         <PersonalInformation ref="persionalInfo" @onReviewBadOrGood="onReviewBadOrGoodClick"
            :personalInfo="personalInfo" v-if="isShowCalendar" />

         <div class="row flex-column-reverse flex-lg-row row justify-content-center">
            <div class="col-lg-8 col-md-12 ">
               <div class="accordion my-3" id="accordionFlushExample">
                  <div class="accordion-item">
                     <h2 class="accordion-header" id="flush-headingOne">
                        <button class="accordion-button collapsed" style="color: red;font-style: italic;" type="button"
                           data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false"
                           aria-controls="flush-collapseOne">
                           Hướng dẫn xem lịch vạn niên của chính mình một cách chính xác nhất.
                        </button>
                     </h2>
                     <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                           <div class="row guide-payment">
                              <div class="col-12">
                                 <div class="d-flex align-items-center">
                                    <span class="number-circle">01</span>
                                    <p class="description">Bấm vào Đăng nhập -> Nhập số điện thoại và OTP để truy cập hệ
                                       thống.</p>
                                 </div>
                                 <img src="@/assets/images/lvn/login1.png" alt="Hướng dẫn thanh toán"
                                    class="img-fluid w-100">
                              </div>
                              <div class="col-12">
                                 <div class="d-flex align-items-center">
                                    <span class="number-circle">02</span>
                                    <p class="description">Cập nhật thông tin cá nhân của mình. Ít nhất ngày sinh và
                                       giới tính để được kết quả chính xác nhất.</p>
                                 </div>
                                 <img src="@/assets/images/lvn/login2.png" alt="Hướng dẫn thanh toán"
                                    class="img-fluid w-100">
                              </div>
                              <div class="col-12">
                                 <div class="d-flex align-items-center">
                                    <span class="number-circle">03</span>
                                    <p class="description">Mua gói lịch vạn niên theo nhu cầu.</p>
                                 </div>
                                 <img src="@/assets/images/lvn/login3.png" alt="Hướng dẫn thanh toán"
                                    class="img-fluid w-100">
                              </div>
                              <div class="col-12">
                                 <div class="d-flex align-items-center">
                                    <span class="number-circle">04</span>
                                    <p class="description">Thời gian xem toàn bộ thông tin lịch vạn niên của bạn hiển
                                       thị
                                       thành công.</p>
                                 </div>
                                 <img src="@/assets/images/lvn/login4.png" alt="Hướng dẫn thanh toán"
                                    class="img-fluid w-100">
                              </div>
                              <div class="col-12">
                                 <div class="d-flex align-items-center">
                                    <span class="number-circle">05</span>
                                    <p class="description">Xem các ngày tốt xấu hoặc các giờ tốt cho lĩnh vực tiền tài,
                                       công danh, sức khỏe, hôn nhân.</p>
                                 </div>
                                 <img src="@/assets/images/lvn/login5.png" alt="Hướng dẫn thanh toán"
                                    class="img-fluid w-100">
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <Interpretation ref="interpretionCalendar" v-if="isViewBadOrGoodRef" @change-tab="onChangeTab" :dataInDate="interpretionInDateData"
            :interpretion-data="interpretionData" />
      </div>
   </section>
</template>