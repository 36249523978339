import Vuex from 'vuex'
import type { StoreOptions } from 'vuex'
import type { RootState } from './rootState'
import { popup } from './popup'
import { loader } from './loader'
import { notification } from './notification'
import { application } from './application'
import { userProfile } from './userProfile'
import { shoppingCart } from './cart'

import type { IAdmin } from '@/models/admin'
import { createGenericModule } from './generic/genericModule'
import { genderAndCity } from './genderAndCity'

const store: StoreOptions<RootState> = {
  modules: {
    popup,
    loader,
    notification,
    application,
    userProfile,
    shoppingCart,
    genderAndCity,
    adminsRegister: createGenericModule<IAdmin>(),
    adminsInvite: createGenericModule<IAdmin>(),
  }
}

export default new Vuex.Store<RootState>(store)
