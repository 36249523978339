<style setup scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import SimTableComponentSlider from '../parts/SimTableComponentSlider.vue';
import SimTableHeaderComponent from '../parts/SimTableHeaderComponent.vue';
import {onMounted, ref } from 'vue';
import type { simItemDto } from '@/models/https/simResponse';
import type { IGetSimReq } from '@/models/https/simRequests';
import SimRepository from '@/lib/https/simRepository';
import { RepositoryFactory } from '@/lib/https';
import SimComponent from '../parts/SimComponent.vue';
import { convertData } from '../parts/SimTableComponent.vue';
import { routerPaths } from '@/router/routes';
import { mobileNetworkIcons } from '@/lib/utils/imageUtils';

const { getSims } = RepositoryFactory.getRepository<SimRepository>(
   SimRepository
);

const vinaSimDatas = ref<Array<simItemDto>>([]);

const viettelSimDatas = ref<Array<simItemDto>>([]);

const mobiSimDatas = ref<Array<simItemDto>>([]);

const topSimDatas = ref<Array<simItemDto>>([]);

const createDefaultRequest = () => {
   const data: IGetSimReq = {
      key_work: "",
      nha_mang_ds: null,
      gia_tien_ma_ds: null,
      ngu_hanh_ma_ds: null,
      tuoi_ma_ds: null,
      sim_loai_ma_ds: null,
      page_name: "sotheonhamang",
      page_index: 1,
      page_size: 18,
      gia_tien_sort: 0,
      cai_van_sort: 0,
      dau_so: undefined
   };
   return data;
}

const fetchSimsWithMobileNetworkProvider = async () => {
   const rq = createDefaultRequest();
   topSimDatas.value = [];
   rq.nha_mang_ds = "Vinaphone";
   const rpVina = await getSims(rq);
   vinaSimDatas.value = rpVina?.data ?? [];
   if (vinaSimDatas.value.length > 0) {
      topSimDatas.value.push(vinaSimDatas.value[0]);
   }

   rq.nha_mang_ds = "Viettel";
   const rpViettel = await getSims(rq);
   viettelSimDatas.value = rpViettel?.data ?? [];
   if (viettelSimDatas.value.length > 0) {
      topSimDatas.value.push(viettelSimDatas.value[0]);
   }

   rq.nha_mang_ds = "Mobifone";
   const rpMobi = await getSims(rq);
   mobiSimDatas.value = rpMobi?.data ?? [];
   if (mobiSimDatas.value.length > 0) {
      topSimDatas.value.push(mobiSimDatas.value[0]);
   }
}

onMounted(async () => {
   try {
      await fetchSimsWithMobileNetworkProvider();
   } catch (error) {

   }
})

</script>

<template>

   <section class="py-3 section-feng-shui">
      <div class="container-fluid">
         <div class="row align-items-center">
            <div class="col-12 col-lg-5 col-xl-3">
               <h1 class="title-section text-xl-start mb-3">Số theo nhà mạng</h1>
            </div>
            <div class="col-12 col-lg-7 col-xl-9 pe-0">
               <div class="slider-service">
                  <div>
                     <img src="@/assets/images/banner-nha-mang.png" class="w-100 img-banner" alt="banner" />
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>

   <section class="section-page section-to-network pt-4" id="list-phone-number">

      <div class="container-fluid">
         <div class="top-so-hot">
            <div class="row align-items-center">
               <div class="col-lg-4">
                  <h2 class="title-hot-phone mb-3">Top số HOT theo nhà mạng</h2>
               </div>
               <div class="col-lg-8">
                  <div class="row flex-grow-1" id="div_top">
                     <SimComponent type="top" v-for="item in topSimDatas" :simData="convertData(item)" labelElement="Sim mệnh:">  
                        <template #bottom>
                           <h3>{{ item.nha_mang }}</h3>
                        </template>
                     </SimComponent>                
                  </div>
               </div>
            </div>

         </div>
      </div>

      <SimTableHeaderComponent titleGroup="title-group vina" :imageSrc="mobileNetworkIcons.vinaIcon"
         :link="`${routerPaths.DetailPhoneNumber}/?pageName=sotheonhamang&pageSize=36&provider=Vinaphone`" titleText="Số Vinaphone" />
      <SimTableComponentSlider :simDatas="vinaSimDatas" labelElement="Sim mệnh:"/>

      <SimTableHeaderComponent titleGroup="title-group viettel" :imageSrc="mobileNetworkIcons.viettelIcon"
      :link="`${routerPaths.DetailPhoneNumber}/?pageName=sotheonhamang&pageSize=36&provider=Viettel`" titleText="Số Viettel" />
      <SimTableComponentSlider :simDatas="viettelSimDatas" labelElement="Sim mệnh:"/>

      <SimTableHeaderComponent titleGroup="title-group mobi" :imageSrc="mobileNetworkIcons.mobiIcon"
      :link="`${routerPaths.DetailPhoneNumber}/?pageName=sotheonhamang&pageSize=36&provider=Mobifone`" titleText="Số Mobi" />
      <SimTableComponentSlider :simDatas="mobiSimDatas" labelElement="Sim mệnh:"/>
   </section>

</template>