<template>
   <div class="row list-phones justify-content-center" id="navbar-chart-section">
      <SimComponent type="" v-for="item in simDatas" :simData="convertData(item)" :labelElement="labelElement" />
   </div>

   <div v-if="simDatas.length <= 0" class="row list-phones justify-content-center" id="navbar-chart-section">
      <div class="empty-block border">
         <img src="@/assets/images/icons/ic-EmptyPictures.svg" width="216px" alt="EmptyPictures">
         <p class="text-uppercase fw-bold fs-5">KHÔNG TÌM THẤY SIM</p>
         <p class="fw-light text-secondary fs-6">Vui lòng thử lại số khác</p>
      </div>
   </div>

</template>

<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
</style>

<script lang="ts" setup>
import type { simItemDto } from "@/models/https/simResponse";
import SimComponent, { type SimData } from "./SimComponent.vue"
defineProps<{
   simDatas: Array<simItemDto>,
   labelElement: string,
}>()

</script>

<script lang="ts">

export const convertData = (dataBE: simItemDto | undefined) => {

   let uiData: SimData = {
      Wealth: "undefined",
      FiveElements: "undefined",
      Price: 0,
      PhoneNumber: "undefined",
      MobileNetworkProvider: "undefined"
   };

   if (dataBE != undefined) {
      uiData = {
         Wealth: dataBE.tien_tai,
         FiveElements: dataBE.ngu_hanh_ten,
         Price: dataBE.gia_tien,
         PhoneNumber: dataBE?.sim_number,
         MobileNetworkProvider: dataBE.nha_mang
      }
   }

   return uiData;

}

</script>
