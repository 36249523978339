import authClient from './auth-client'
import admin from './admin'
import requestHistory from './request-history'
import usageLog from './usage-log'
import profile from './profile'
import common from './common'
import application from './application'
import login from './login'
import sidebar from './sidebar'

export default {
  authClient,
  admin,
  requestHistory,
  usageLog,
  profile,
  common,
  application,
  login,
  sidebar
}
