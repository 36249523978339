
export const apiCitiesRouter = {
    getCitiesUrl: "/api/masterdata/masterdata_get_dynamic?master_type=province",
    baseUrl: "/api/masterdata/masterdata_get_dynamic",
    getGenderUrl: "/api/masterdata/masterdata_get_dynamic?master_type=gioitinh",
}

export const apiAccountRouter = {
    accountUrl : "/accounts",
    getOtpUrl : '/api/khach_hang/send_otp',
    confirmOtpUrl : '/api/khach_hang/login_with_otp',
    accountUpdateProfileUrl : '/api/khach_hang/update_info',
}