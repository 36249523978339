<style setup scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import { createChartFiveElement } from '@/lib/utils/common';
import { computed, useTemplateRef } from 'vue';

export interface InfomationV2 {
    name: string,
    fiveElementColor: string,
    fiveElement: string,
    nameMeaningVietNam: string,
    nameImage: string,
    namingTurorial: Array<string>,
    tenNameVietNam: Array<string>,
    tenNameEnglish: Array<string>,
    fiveElementChart: Array<number>,
    fileElementLabels: Array<string>,
}

const props = defineProps<{
    infomation: InfomationV2,
    showHeader: boolean,
    showFooter: boolean,
    labelPrefix: string,
}>();

const startFiveNameVietNam = computed(() => props.infomation.tenNameVietNam.slice(0, 5));
const lastFiveNameVietNam = computed(() => props.infomation.tenNameVietNam.slice(5, 10))

const startFiveNameEng = computed(() => props.infomation.tenNameEnglish.slice(0, 5));
const lastFiveNameEng = computed(() => props.infomation.tenNameEnglish.slice(5, 10))

const canvasfiveElementRef = useTemplateRef("chart-info-v2");
const cvf = canvasfiveElementRef.value as HTMLCanvasElement;
let chartFiveElement = createChartFiveElement(props.infomation.fiveElementChart, cvf?.getContext("2d"), "Black", props.infomation.fileElementLabels);

const getNamingTutorial = () => {
    if (props.infomation.namingTurorial) {
        let str = ""
        for (const d of props.infomation.namingTurorial) {
            str = str + d + "<br>"
        }
        return str;
    }
    return "";
}

const updateChartFiveElement = (data: number[]) => {
    if(chartFiveElement){
        chartFiveElement.data.datasets[0].data = data;
        chartFiveElement.update()
    }
    else
    {
        const canvas = canvasfiveElementRef.value as HTMLCanvasElement;
        chartFiveElement = createChartFiveElement(data, canvas?.getContext("2d"), "Black", props.infomation.fileElementLabels);
    }
}

defineExpose({
    updateChartFiveElement
})

</script>

<template>
    <section class="section-page">
        <div class="container-fluid" id="div_info_v2">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10 col-xxl-9">
                    <div v-show="showHeader" class="header-name">
                        <h3 class="title-group-name" :style="{'-webkit-text-stroke': '1px' + ' #fff'}"><span class="text1">TÊN HAY VẬN TỐT</span></h3>
                    </div>
                    <div class="block-name">
                        <div class="w-50">
                            <div class="block-name-column fw-bold">
                                {{ labelPrefix }}: <label id="info_name">{{ infomation?.name ?? "failed!" }}</label>
                            </div>
                            <div class="block-name-column p-3  h-100">
                                <div class="ratio ratio-1x1">
                                    <img :src="infomation.nameImage" alt="image name"
                                        class="object-contain" id="img_name">
                                </div>
                            </div>
                        </div>
                        <div class="w-50">
                            <div class="block-name-column fw-bold">
                                Y
                            </div>
                            <div class="block-name-column h-100">
                                <canvas ref="chart-info-v2" height="400" width="400"></canvas>
                            </div>
                        </div>
                    </div>
                    <div class="block-name border-top border-dark">
                        <div class="block-name-column fw-bold bg-dark text-white">
                            Ngũ hành tương ứng
                        </div>
                        <div class="block-name-column fw-bold text-white" id="div_ngu_hanh"
                            :style="{ 'background-color': infomation?.fiveElementColor }">
                            <label id="info_nguhanh" class="fst-italic"> {{ infomation?.fiveElement }}</label>
                        </div>
                    </div>
                    <div class="block-name border border-dark">
                        <div class="block-name-column">
                            <div class="row justify-content-center text-center w-100">
                                <div class="col-12 col-md-10 col-lg-8">
                                    <div class="name-meaning">
                                        <h3 class="title-block">Hướng dẫn đặt tên</h3>
                                        <p class="content-name-meaning" id="div_hd_dat_ten"
                                            v-html="getNamingTutorial()">
                                        </p>
                                    </div>
                                    <div class="name-meaning pt-0">
                                        <h3 class="title-block">Ý nghĩa Tiếng Việt</h3>
                                        <p class="content-name-meaning" id="div_y_nghia_tv">
                                            {{ infomation.nameMeaningVietNam }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  v-show="showFooter" class="block-name border-bottom border-dark">
                        <div class="w-50">
                            <div class="block-name-column fw-bold bg-theme text-white">
                                10 tên Tiếng Việt
                            </div>
                            <div class="block-name-column p-3">
                                <div class="text-name" id="div_10_vn">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-6 text-center">
                                            <ul>
                                                <li v-for="e in startFiveNameVietNam">{{ e }}</li>                                              
                                            </ul>
                                        </div>
                                        <div class="col-md-6 col-sm-6 text-center">
                                            <ul>
                                                <li v-for="e in lastFiveNameVietNam">{{ e }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-50">
                            <div class="block-name-column fw-bold bg-theme text-white">
                                10 tên Tiếng Anh
                            </div>
                            <div class="block-name-column p-3">
                                <div class="text-name" id="div_10_eng">
                                    <div class="row">
                                        <div class="col-md-6 col-sm-6 text-center">
                                            <ul>
                                                <li v-for="e in startFiveNameEng">{{ e }}</li>                                              
                                            </ul>
                                        </div>
                                        <div class="col-md-6 col-sm-6 text-center">
                                            <ul>
                                                <li v-for="e in lastFiveNameEng">{{ e }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>