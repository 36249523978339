import Repository from "@/lib/https/repository"
import { buildUrlWithQueryOptions } from "../utils/stringUtils"
import type { ICalendarInterpretionParam, IDateInterpretionParam, IGetDetailPerpetutalCalendarParam, IGetPerpetutalCalendarReq } from "@/models/https/perpetualCalendarRequests"
import type { ICalendarInterpretionRes, ICheckPerpetutalCalendarRes, IGetDetailPerpetutalCalendarRes, IGetPerpetutalCalendarRes } from "@/models/https/perpetutalCalendarResponse"

export const checkPerpetutalCalendarUrl = "/api/lich_van_nien/init_lvn"
export const getPerpetutalCalendarUrl = "/api/lich_van_nien/calenda"
export const getDetailPerpetutalCalendarUrl = "/api/lich_van_nien/calenda_detail"
export const getCalendarInterpretionUrl = "/api/lich_van_nien/calenda_detail_by_tab"
export const getInDateInterpretionUrl = "/api/lich_van_nien/calenda_detail_license"


export default class PerpetutalCalendarRepository extends Repository {

  checkPerpetutalCalendar =  async () => {
    const response = await this.axios.post<ICheckPerpetutalCalendarRes>(checkPerpetutalCalendarUrl, {});
    if (response.data) return response.data;
    return null
  } 

  getPerpetutalCalendar = async (req: IGetPerpetutalCalendarReq) => {
    const response = await this.axios.post<IGetPerpetutalCalendarRes>(getPerpetutalCalendarUrl, req);
    if (response.data) return response.data;
    return null
  } 

  getDetailPerpetutalCalendar = async (param: IGetDetailPerpetutalCalendarParam) => {
    const url = buildUrlWithQueryOptions(getDetailPerpetutalCalendarUrl, param);
    const response = await this.axios.post<IGetDetailPerpetutalCalendarRes>(url, {});
    if (response.data) return response.data;
    return null
  } 

  getCalendarInterpretion = async (param: ICalendarInterpretionParam) => {
    const url = buildUrlWithQueryOptions(getCalendarInterpretionUrl, param);
    const response = await this.axios.post<ICalendarInterpretionRes>(url, {});
    if (response.data) return response.data;
    return null
  } 

  getIndateInterpretion = async (param: IDateInterpretionParam) => {
    const url = buildUrlWithQueryOptions(getInDateInterpretionUrl, param);
    const response = await this.axios.post<IGetDetailPerpetutalCalendarRes>(url, {});
    if (response.data) return response.data;
    return null
  } 
}
