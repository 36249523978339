export default class LocalStorageWorker {
	private static localStorage = new LocalStorageWorker();
	static getLocalStorage = () => LocalStorageWorker.localStorage;

	localStorageSupported: boolean;

	constructor() {
		this.localStorageSupported =
			typeof window.localStorage !== "undefined" &&
			window.localStorage !== null;
	}

	add(key: string, value: any) {
		if (!this.localStorageSupported) return false;
		localStorage.setItem(key, value);
		return true;
	}

	get(key: string): any {
		if (!this.localStorageSupported) return null;
		return localStorage.getItem(key);
	}

	remove(key: string) {
		if (!this.localStorageSupported) return false;
		localStorage.removeItem(key);
		return true;
	}

	getWithType<T>(key: string)
	{
		if(!this.localStorageSupported) return null;
		const value = localStorage.getItem(key);
		if(value !== null && Boolean(value))
		{
			const objectValue: T = JSON.parse(value);
			return objectValue;
		}
		return null;		
	}

	clear() {
		if (!this.localStorageSupported) return false;
		localStorage.clear();
		return true;
	}
}

