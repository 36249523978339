<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import { RepositoryFactory } from '@/lib/https';
import type { IDestinyAnalysisHistoryDto, IDestinyAnalysisHistoryRes, ISimPurchaseHistory } from '@/models/PurchaseHistory';
import PageComponent from '@/components/parts/PageComponent.vue';
import ServicePurchaseHistoryTable from './ServicePurchaseTable/ServicePurchaseHistoryTable.vue';
import RequestHistoryRepository from '@/lib/https/requestHistoryRepository';
import type { HistoryPacketServiceData } from './ServicePurchaseTable/ServicePurchaseHistoryTableItem.vue';
import processingIcon from '@/assets/images/icons/ic-status-processing.svg';
import successIcon from '@/assets/images/icons/ic-status-success.svg';
import cancelledIcon from '@/assets/images/icons/ic-status-cancelled.svg';

const { getHistoriesDestinyAnalysis } = RepositoryFactory.getRepository<RequestHistoryRepository>(RequestHistoryRepository);

const TABS = [
    'Tất cả',
    'Đang xử lý',
    'Hoàn thành',
    'Hủy bỏ',
];

const currentTab = ref<string>(TABS[0]);
const purchaseHistories = ref<HistoryPacketServiceData[]>([]);

const pagingData = ref<{ total_page: number; page_index: number; }>({
    total_page: 0,
    page_index: 0
});

const statusIcon = (status: string) => {
    return {
        1: processingIcon,
        2: successIcon,
        3: cancelledIcon,
    }[status] || processingIcon;
};

const convertDestinyAnalysic = (rs: IDestinyAnalysisHistoryRes) => {
    if (rs?.data?.length) {
        const result: HistoryPacketServiceData[] = rs.data.flatMap(v => {
            const d: HistoryPacketServiceData = {
                OrderId: v.dhdv_ma,
                Status: v.dhdv_trang_thai,
                StatusName: v.dhdv_trang_thai_ten,
                TextStyle: v.web_text_class,
                PacketName: v.goi_dich_vu_ten,
                Price: v.tong_tien_cuoi_cung,
                CreateTime: v.thoi_gian_tao,
            }
            return d;
        });
        return result;
    }
    return [];
}

onMounted(() => {
    getDestinyAnalysisHistories('0');
});

const setTab = (tab: string) => {
    currentTab.value = tab;
    const idx = TABS.indexOf(tab);
    getDestinyAnalysisHistories(idx.toString());
};

const onIndexChange = (index: number) => {
    const idx = TABS.indexOf(currentTab.value);
    getDestinyAnalysisHistories(idx.toString(), index);
};

const getDestinyAnalysisHistories = (idx: string, page_index = 1) =>
    getHistoriesDestinyAnalysis({
        status: parseInt(idx),
        page_index: page_index,
        page_size: 10,
    }).then(rs => {
        if (rs && rs.result_code === '000') {
            if (rs?.data?.length) {
                pagingData.value = {
                    total_page: rs?.data[0].total_page,
                    page_index: rs?.data[0].page_index
                };
            } else {
                pagingData.value = {
                    total_page: 0,
                    page_index: 0
                };
            }
            purchaseHistories.value = convertDestinyAnalysic(rs);
        }       
    });

</script>

<template>
    <div>
        <ul class="nav justify-content-center tab-primary" id="myHistory" role="tablist">
            <li class="nav-item" role="presentation" v-for="tab in TABS">
                <button :class="`nav-link ${currentTab == tab ? 'active' : ''}`" @click="setTab(tab)" id="all-tab"
                    data-bs-toggle="tab" type="button" role="tab" :aria-controls="tab" aria-selected="true">
                    {{ tab }}
                </button>
            </li>
        </ul>
        <ServicePurchaseHistoryTable :data="purchaseHistories" :status-icon="statusIcon"/>
        <PageComponent v-if="pagingData.total_page > 0" @onIndexChange="onIndexChange"
            :totalPages="pagingData.total_page" />
    </div>
</template>