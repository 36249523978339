import Repository from "@/lib/https/repository"
import type { BaseResponse, IVietQRParam } from "@/models/https/baseRespones";
import { buildUrlWithQueryOptions } from "../utils/stringUtils";

export const USER_PROFILE_URL = "/vpr/api/create_payment"

export default class CommonRespository extends Repository {
  createVietQR = async (data:IVietQRParam) => {
    const url = buildUrlWithQueryOptions(USER_PROFILE_URL, data)
    const response = await this.axios.post<BaseResponse<string>>(url, {});
    if (response.data) return response.data;
    return null
  }
}