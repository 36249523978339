import Repository from "@/lib/https/repository"
import type { IAccountResponse } from "@/models/account"
import type { IGetSimReq, ISimDetailParam } from "@/models/https/simRequests"
import type { IGetSimRes, ISimDetailRes } from "@/models/https/simResponse"
import type { AxiosResponse } from "axios"
import { buildUrlWithQueryOptions } from "../utils/stringUtils"

export const getSimUrl = "/api/trang_chu/sim_get_dynamic"
export const getSimDetailUrl = "/api/trang_chu/chart_detail_by_sim"


export default class SimRepository extends Repository {
  getSims = async (data:IGetSimReq) => {
    const response = await this.axios.post<IGetSimRes>(getSimUrl, data)
    if (response.data) return response.data;
    return null
  } 

  getSimDetail =  async (data:ISimDetailParam) => {
    const url = buildUrlWithQueryOptions(getSimDetailUrl, data)
    const response = await this.axios.post<ISimDetailRes>(url, {});
    if (response.data) return response.data;
    return null;
  } 
}
