<style setup scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";

.header-page {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3rem;
}

.header-page .ic-back {
    display: none;
    width: 48px;
    height: 48px;
    background: url(@/assets/images/wheel/ic-back.svg) no-repeat;
}

.header-page .title-page {
    position: relative;
    font-size: clamp(16px, 2vw, 26px);
    font-weight: 600;
    margin-bottom: 0;
}

.title-group-name {
    position: relative;
    display: inline-block;
}

.title-group-name .text1 {
    background: rgb(246, 206, 113);
    background: -moz-linear-gradient(left, rgb(246, 206, 113) 0%, rgb(238, 117, 56) 25%, rgb(246, 206, 113) 50%, rgb(238, 117, 56) 75%, rgb(246, 206, 113) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgb(246, 206, 113)), color-stop(25%, rgb(238, 117, 56)), color-stop(50%, rgb(246, 206, 113)), color-stop(75%, rgb(238, 117, 56)), color-stop(100%, rgb(246, 206, 113)));
    background: -webkit-linear-gradient(left, rgb(246, 206, 113) 0%, rgb(238, 117, 56) 25%, rgb(246, 206, 113) 50%, rgb(238, 117, 56) 75%, rgb(246, 206, 113) 100%);
    background: -o-linear-gradient(left, rgb(246, 206, 113) 0%, rgb(238, 117, 56) 25%, rgb(246, 206, 113) 50%, rgb(238, 117, 56) 75%, rgb(246, 206, 113) 100%);
    background: -ms-linear-gradient(left, rgb(246, 206, 113) 0%, rgb(238, 117, 56) 25%, rgb(246, 206, 113) 50%, rgb(238, 117, 56) 75%, rgb(246, 206, 113) 100%);
    background: linear-gradient(to right, rgb(246, 206, 113) 0%, rgb(238, 117, 56) 25%, rgb(246, 206, 113) 50%, rgb(238, 117, 56) 75%, rgb(246, 206, 113) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f6ce71", endColorstr="#f6ce71", GradientType=1);
    font-family: "UTM Davida";
    font-size: clamp(24px, 5vw, 48px);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    position: relative;
    z-index: 3;
}

.title-group-name .text2 {
    color: rgb(246, 206, 113);
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    font-family: "UTM Davida";
    z-index: 0;
    font-size: clamp(24px, 5vw, 48px);
    text-shadow: 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
    /* To show the white border clearly */
    z-index: 2;
}

.title-group-name .text3 {
    content: attr(data-text);
    position: absolute;
    top: 4px;
    left: -4px;
    z-index: 1;
    font-family: "UTM Davida";
    font-size: clamp(24px, 5vw, 48px);
    color: #231f20;
}

.title-group-name.h1 .text1,
.title-group-name.h1 .text2,
.title-group-name.h1 .text3 {
    font-size: clamp(32px, 5vw, 64px);
}

.title-section2 {
    position: relative;
    font-family: "UTM Davida";
    font-size: clamp(24px, 5vw, 56px);
    margin-bottom: 0;
}

.title-box {
    position: relative;
    font-size: clamp(32px, 5vw, 56px);
    text-align: center;
}

.title-box::after {
    content: "";
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: url(@/assets/images/wheel/title-box.svg) no-repeat;
    background-size: contain;
    background-position: center;
}

.title-box .text1 {
    padding: clamp(1.5rem, 5vw, 2.5rem);
    display: block;
    z-index: 2;
    position: relative;
}

.title-box2 {
    position: relative;
    font-size: clamp(18px, 5vw, 32px);
    text-align: center;
    color: #FFEECC;
    margin: 2rem auto;
}

.title-box2 .text1 {
    position: relative;
    z-index: 2;
    padding: clamp(0.5rem, 5vw, 1.5rem) clamp(3.5rem, 23vw, 9.5rem);
    display: inline-flex;
    background: url(@/assets/images/wheel/title-box-red.svg) no-repeat;
    background-size: contain;
    background-position: center;
    justify-content: center;
}

.title-box2 .text1::after {
    content: "";
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.section-page-wheel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 3rem 0;
}

.section-page-wheel::after {
    content: "";
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: -2;
    background: url(@/assets/images/wheel/wheel-bg.png) no-repeat;
    background-size: cover;
    background-position: center;
}

.section-page-wheel .dragon::before {
    content: "";
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: url(@/assets/images/wheel/dragon-left.svg) no-repeat;
    background-size: contain;
    background-position: top left;
}

.section-page-wheel .dragon::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0%;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: url(@/assets/images/wheel/dragon-right.svg) no-repeat;
    background-size: contain;
    background-position: bottom right;
}

.section-page-wheel .wheel-block {
    position: relative;
    max-width: 500px;
    max-height: 500px;
    width: 30%;
    min-width: 340px;
    min-height: 340px;
    display: flex;
    align-items: center;
}

.section-page-wheel .wheel-block .arrow {
    bottom: -9px;
    left: 50%;
    width: 28px;
    height: 34px;
    position: absolute;
    z-index: 1;
    transform: translateX(-50%);
}

.section-page-wheel .wheel {
    position: relative;
}

.section-page-wheel .wheel .ring {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.section-page-wheel .wheel .ring img {
    width: 100%;
}

.btn-wheel {
    background-color: #FFECC6;
    color: #C61914;
    font-weight: bold;
    font-size: 24px;
    padding: 4px;
    min-width: 262px;
}

.btn-wheel:hover {
    background: rgb(229, 167, 61);
    background: -moz-linear-gradient(left, rgb(229, 167, 61) 0%, rgb(249, 230, 163) 50%, rgb(229, 167, 61) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgb(229, 167, 61)), color-stop(50%, rgb(249, 230, 163)), color-stop(100%, rgb(229, 167, 61)));
    background: -webkit-linear-gradient(left, rgb(229, 167, 61) 0%, rgb(249, 230, 163) 50%, rgb(229, 167, 61) 100%);
    background: -o-linear-gradient(left, rgb(229, 167, 61) 0%, rgb(249, 230, 163) 50%, rgb(229, 167, 61) 100%);
    background: -ms-linear-gradient(left, rgb(229, 167, 61) 0%, rgb(249, 230, 163) 50%, rgb(229, 167, 61) 100%);
    background: linear-gradient(to right, rgb(229, 167, 61) 0%, rgb(249, 230, 163) 50%, rgb(229, 167, 61) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e5a73d", endColorstr="#e5a73d", GradientType=1);
}

.btn-wheel:focus {
    border-color: transparent;
}

.interpretation-box {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;
}

.interpretation-box .item-interpretation {
    position: relative;
    max-width: 454px;
    width: 50%;
    flex: 0 50%;
}

.interpretation-box .item-interpretation img {
    width: 100%;
}

.interpretation-box .item-interpretation .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.interpretation-box .item-interpretation .content .year {
    position: relative;
    font-family: "UTM Davida";
    font-size: clamp(12px, 5vw, 24px);
    text-align: center;
    margin: 1rem auto 0;
}

.interpretation-box .item-interpretation .content .year span {
    position: relative;
    z-index: 2;
    padding: clamp(0rem, 5vw, 0.25rem) clamp(1.5rem, 23vw, 8.5rem);
    display: inline-flex;
    background: url(@/assets/images/wheel/interpretation-year.svg) no-repeat;
    background-size: cover;
    background-position: center;
    justify-content: center;
    max-width: 100%;
}

.interpretation-box .item-interpretation .content .year span::after {
    content: "";
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.interpretation-box .item-interpretation .content .number {
    font-size: clamp(18px, 5vw, 32px);
    color: #fff;
    text-align: center;
    font-weight: bold;
    margin-top: clamp(0rem, 5vw, 1rem);
}

.interpretation-box .item-interpretation .content .number span {
    position: relative;
    z-index: 2;
    padding: clamp(0rem, 5vw, 1rem) clamp(1.5rem, 23vw, 8.5rem);
    display: inline-flex;
    background: url(@/assets/images/wheel/interpretation-lantern.svg) no-repeat;
    background-size: contain;
    background-position: center;
    justify-content: center;
}

.interpretation-box .item-interpretation .content .inter-info {
    padding: clamp(0rem, 5vw, 1rem) 1rem;
}

.interpretation-box .item-interpretation .content table {
    color: #fff;
    font-size: 14px;
    vertical-align: middle;
    font-weight: 500;
    margin-bottom: 1rem;
    margin-top: 3rem;
}

.interpretation-box .item-interpretation .content table td {
    padding: 0.15rem 0.5rem;
}

.interpretation-box .item-interpretation .content .mean {
    margin-top: 54%;
    font-size: 18px;
    padding: 1rem 2rem;
}

.interpretation-box .item-interpretation .content .mean .name {
    font-size: 22px;
    color: #C61914;
}

@media screen and (min-width: 767px) and (max-width: 1023px) and (orientation: portrait) {
    .interpretation-box .item-interpretation .content .mean {
        padding: 0rem 1.5rem;
        font-size: 14px;
    }

    .interpretation-box .item-interpretation .content .mean .name {
        font-size: 18px;
    }
}

@media screen and (max-width: 767px) {
    .interpretation-box {
        flex-direction: column;
        align-items: center;
    }

    .interpretation-box .item-interpretation {
        width: 100%;
        flex: 0 100%;
    }

    .interpretation-box .item-interpretation .content table {
        font-size: 14px;
    }

    .interpretation-box .item-interpretation .content .mean {
        padding: 0rem 1.5rem;
        font-size: 14px;
    }

    .interpretation-box .item-interpretation .content .mean .name {
        font-size: 18px;
    }
}

@media screen and (max-width: 400px) {
    .interpretation-box .item-interpretation .content .mean {
        padding: 0rem 1rem;
        font-size: 12px;
    }

    .interpretation-box .item-interpretation .content .mean .name {
        font-size: 16px;
    }
}

.modal.show {
    display: block;
}

.modalDetailInterpretation .modal-header {
    position: relative;
    padding: 1rem 1rem;
    background-color: #C61914;
}

.modalDetailInterpretation .modal-header::after {
    content: "";
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: url(@/assets/images/wheel/interpretation-header-bg.svg) no-repeat;
    background-size: cover;
    background-position: center;
}

.modalDetailInterpretation .modal-header .title-section2,
.modalDetailInterpretation .modal-header .btn-close {
    position: relative;
    z-index: 2;
    color: #fff;
}

.modalDetailInterpretation .modal-content {
    position: relative;
    background-color: #FFEEDE;
}

.modalDetailInterpretation .modal-body {
    background: url(@/assets/images/wheel/bg-cloud-dragon.svg) no-repeat;
    background-size: cover;
    background-position: top;
    padding: 2rem clamp(0.5rem, 4vw, 8rem);
    font-size: 22px;
    text-align: justify;
}

.modalDetailInterpretation .modal-body ol li {
    list-style: inherit;
}

.modalDetailInterpretation .modal-body .content-interpretation {
    position: relative;
    z-index: 1;
}

.modalDetailInterpretation .modal-body .btn-wheel {
    background: rgb(229, 167, 61);
    background: -moz-linear-gradient(left, rgb(229, 167, 61) 0%, rgb(249, 230, 163) 50%, rgb(229, 167, 61) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgb(229, 167, 61)), color-stop(50%, rgb(249, 230, 163)), color-stop(100%, rgb(229, 167, 61)));
    background: -webkit-linear-gradient(left, rgb(229, 167, 61) 0%, rgb(249, 230, 163) 50%, rgb(229, 167, 61) 100%);
    background: -o-linear-gradient(left, rgb(229, 167, 61) 0%, rgb(249, 230, 163) 50%, rgb(229, 167, 61) 100%);
    background: -ms-linear-gradient(left, rgb(229, 167, 61) 0%, rgb(249, 230, 163) 50%, rgb(229, 167, 61) 100%);
    background: linear-gradient(to right, rgb(229, 167, 61) 0%, rgb(249, 230, 163) 50%, rgb(229, 167, 61) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e5a73d", endColorstr="#e5a73d", GradientType=1);
    color: #C61914;
}

.modalDetailInterpretation .modal-body .des-interpretation.hide {
    height: 0;
    overflow: hidden;
    transition: all 500ms;
}

.modalDetailInterpretation .modal-body .des-interpretation.show {
    height: initial;
}

@media screen and (max-width: 767px) {
    .modalDetailInterpretation .modal-header {
        padding: 1rem 1rem;
    }

    .modalDetailInterpretation .modal-body {
        font-size: 14px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1025px) and (orientation: portrait) {
    .section-page-wheel {
        min-height: calc(100vh - 200px);
    }

    .section-page-wheel .wheel-block {
        width: 62%;
    }
}

@media screen and (min-width: 1200px) and (orientation: landscape) {
    .section-page-wheel {
        min-height: calc(100vh - 200px);
    }
}

@media screen and (max-width: 768px) {
    .mobile-page .header-page {
        position: relative;
        left: 0;
        top: 0;
        max-width: 100vw;
        width: 100%;
        z-index: 10;
        display: flex !important;
        height: 60px;
        padding-top: 0rem;
        background-color: #C61914;
        justify-content: space-between;
        color: #fff;
    }

    .mobile-page .header-page .ic-back {
        display: block;
    }

    .mobile-page .header-page h1 {
        margin-right: auto;
    }

    .mobile-page .header-page.hide {
        display: none !important;
    }

    .mobile-page .header-page.show {
        display: flex !important;
    }

    .section-page-wheel {
        min-height: calc(100vh - 59px);
    }
}

@media screen and (max-width: 340px) {
    .section-page-wheel .wheel-block {
        min-width: 280px;
        min-height: 280px;
    }
}
</style>

<script lang="ts" setup>
import { PopupController, PoupType } from '@/lib/popup';
import type { IGetNumberDrawRes } from '@/models/https/numDrawResponse';
import { computed, nextTick, ref, useTemplateRef } from 'vue';
import { type DetailInterpretationPopupData } from '../popups/PopupDetailInterpretation.vue';
import RepositoryFactory from "@/lib/https/repositoryFactory"
import DrawNumberRepository from '@/lib/https/drawNumberRepository';
import { useRouter } from 'vue-router';
import store from '@/store';
import type { UserProfileSate } from '@/store/userProfile/state';

const isPlayDrawing = ref<boolean>(false);
const userProfile = computed(() => store.state.userProfile as UserProfileSate)

const { drawNumber } = RepositoryFactory.getRepository<DrawNumberRepository>(DrawNumberRepository).withRouter(useRouter());

const audioRef = useTemplateRef("audio1");

const imageRef1 = useTemplateRef("img_1_que");
const imageRef2 = useTemplateRef("img_2_ngoai_quai");
const imageRef3 = useTemplateRef("img_3_noi_quai");
const imageRef4 = useTemplateRef("img_4_tien_thien");
const imageRef5 = useTemplateRef("img_5_thai_cuc");

const rotateImages = async (element: HTMLElement, numberRotate: number, angle: number, timeRotate: number, timeDelayRotate: number,
    direction: string, callback: () => void) => {
    HTMLImageElement
    setTimeout(() => {
        let totalDegrees = 360 * numberRotate + angle;
        if (direction === 'counterclockwise') {
            totalDegrees = -totalDegrees;
        }

        element.style.transitionDuration = `${timeRotate}s`;
        element.style.transform = `rotate(${totalDegrees}deg)`;
        setTimeout(() => {
            if (callback) {
                callback();
            }
        }, timeRotate * 1000);


    }, timeDelayRotate * 1000);
}

const clearRotate = () => {
    if (imageRef1.value) {
        imageRef1.value.style.transitionDuration = "";
        imageRef1.value.style.transform = "";
    }

    if (imageRef2.value) {
        imageRef2.value.style.transitionDuration = "";
        imageRef2.value.style.transform = "";
    }

    if (imageRef3.value) {
        imageRef3.value.style.transitionDuration = "";
        imageRef3.value.style.transform = "";
    }

    if (imageRef4.value) {
        imageRef4.value.style.transitionDuration = "";
        imageRef4.value.style.transform = "";
    }

    if (imageRef5.value) {
        imageRef5.value.style.transitionDuration = "";
        imageRef5.value.style.transform = "";
    }
}

const playDrawNumber = async (rp: IGetNumberDrawRes) => {
    if (!isPlayDrawing.value) {

    }
    var dataNumberDraw = rp.data[0];
    await nextTick();
    if (audioRef.value) {
        audioRef.value.play();
    }
    if (imageRef1.value) {
        rotateImages(imageRef1.value, 13, dataNumberDraw.que_do, 13, 0, "clockwise", async function () {
            // $("#modalDetailInterpretation").modal("show");
            // isBusying = false;
            const popupData: DetailInterpretationPopupData = {
                description: null,
                drawNumber: dataNumberDraw.que_so,
                drawNumberIcon: dataNumberDraw.que_icon,
                drawNumberName: dataNumberDraw.que_mo_ta,
                outerTrigram: dataNumberDraw.ngoai_quai_ten,
                innerTrigram: dataNumberDraw.noi_quai_ten,
                drawNumberImage: dataNumberDraw.que_image,
                imageMeaning: dataNumberDraw.y_nghia_buc_tranh,
                symbol: dataNumberDraw.hinh_tuong,
                judgment: dataNumberDraw.thoat_tu,
                lineStatement: dataNumberDraw.hao_tu,
                interpretation: dataNumberDraw.luan_giai,
            }
            PopupController.pushPopup(PoupType.PopupDetailInterpretation, popupData);
            await nextTick();
            isPlayDrawing.value = false;
        });

    }

    if (imageRef2.value) {
        rotateImages(imageRef2.value, 11, dataNumberDraw.ngoai_quai_do, 11, 0, "counterclockwise", () => { });
    }

    if (imageRef3.value) {
        rotateImages(imageRef3.value, 9, dataNumberDraw.noi_quai_do, 9, 0, "clockwise", () => { });
    }

    if (imageRef4.value) {
        rotateImages(imageRef4.value, 7, dataNumberDraw.ngoai_quai_do, 7, 0, "counterclockwise", () => { });
    }

    if (imageRef5.value) {
        rotateImages(imageRef5.value, 13, 0, 13, 0, "clockwise", () => { });
    }

}

const play = async () => {
    if (!isPlayDrawing.value) {
        try {
            isPlayDrawing.value = true;
            clearRotate();
            const rp: IGetNumberDrawRes = await drawNumber();

            if (rp.result_code === '000') {
                playDrawNumber(rp);
            }
            else if (rp.result_code === '001') {
                PopupController.pushPopup(PoupType.SignUpPopupComponent, { description: null });
            }
            else if (rp.result_code === '002') {
                PopupController.pushPopup(PoupType.BuyChartViewPopupComponent, { description: "Số lượt xem quẻ số đã hết. Bạn vui lòng mua thêm số lượt quay quẻ số." });
            }
        } catch {
            isPlayDrawing.value = false;
        }
    }


}

</script>

<template>
    <div class="info"></div>
    <div class="header-page d-none">
        <a onclick="on_back()" class="ic-back"></a>
        <h1 class="title-page">Vòng quay dịch lý</h1>
    </div>
    <section class="section-page-wheel">
        <div class="dragon"></div>
        <div class="position-relative">
            <h2 class="title-group-name h1 mb-4">
                <span class="text1">Vòng quay dịch lý</span>
                <span class="text2">Vòng quay dịch lý</span>
                <span class="text3">Vòng quay dịch lý</span>
            </h2>
            <div class="text-white text-center" role="alert">
                <small id="sm_luot_quay">Bạn còn {{ userProfile?.totalRemainingNumberDraw ?? 0 }} lượt quay</small>
            </div>
        </div>
        <div class="wheel-block">
            <div class="wheel ratio ratio-1x1">
                <div class="ring ring1">
                    <img alt="Thái cực" ref="img_5_thai_cuc" src="@/assets/images/wheel/wheel_5.svg">
                </div>
                <div class="ring ring2">
                    <img alt="tiên thiên" ref="img_4_tien_thien" src="@/assets/images/wheel/wheel_4.svg">
                </div>
                <div class="ring ring3">
                    <img alt="Nội Quái" ref="img_3_noi_quai" src="@/assets/images/wheel/wheel_3.svg">
                </div>
                <div class="ring ring4">
                    <img alt="Ngoại Quái" ref="img_2_ngoai_quai" src="@/assets/images/wheel/wheel_2.svg">
                </div>
                <div class="ring ring5">
                    <img alt="64 quẻ" ref="img_1_que" src="@/assets/images/wheel/wheel_1.svg">
                </div>
            </div>
            <div class="ring arrow">
                <img alt="arrow" class="arrow" src="@/assets/images/wheel/arrow-bottom.svg">
            </div>
        </div>
        <button :disabled="isPlayDrawing" type="button" style="z-index: 9;"
            class="btn btn-wheel mt-5 rounded-pill font-weight-bold wow zoomIn" v-on:click="play">Bắt
            đầu</button>
        <button type="button" id="btn_quay_it" style="z-index: 9;display: none;"
            class="btn btn-wheel mt-5 rounded-pill font-weight-bold wow zoomIn" onclick="quay_it()">Bắt
            đầu IT</button>
        <audio ref="audio1" style="width: 100px;z-index:-999999999999999; height: 0;">
            <source src="@/assets/images/wheel/trungthuong.mp3" type="audio/mpeg">
        </audio>
    </section>   
</template>