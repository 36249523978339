export interface Pagination {
  page?: number
  pageSize?: number
  totalRecords?: number
  isSimpleList?: boolean
  orderBy?: string
  canAdd?: boolean
  keyword?: string
  canExport?: boolean
  canUpdate?: boolean
  canDelete?: boolean
}

export interface AuthClientPagination extends Pagination {
  environment?: string
  clientName?: string
  clientId?: string
  scope?: string
  startUpdatedAt?: number
  endUpdatedAt?: number
  sortDir?: string
  sortBy?: string
  canExport?: boolean
  canAdd?: boolean
  canUpdate?: boolean
  canDelete?: boolean
}

export interface RequestHistoryPagination extends Pagination {
  clientId?: string
  type?: string
  status?: string
  sub?: string
  startUpdatedAt?: number
  endUpdatedAt?: number
}
export interface AdminPagination extends Pagination {
  name?: string
  email?: string
  role?: string
  status?: number
  fullName?: string
}
export interface ActivityPagination extends Pagination {
  startCreatedAt?: number
  endCreatedAt?: number
  fullName?: string
  actionType?: number
  resourceId?: string
  actionDetailType?: number
}

export interface ApplicationPagination extends Pagination {
  applicationType?: number
  name?: string
  createdBy?: string
  organizationName?: string
  representativeEmail?: string
  startCreatedAt?: number
  endCreatedAt?: number
  startUpdatedAt?: number
  endUpdatedAt?: number
}

export const PAGE_SIZE_DEFAULT = 20
