import type { Module } from 'vuex'
import mutations from './mutation'
import { ShoppingCart, type Product } from './state'
import type { RootState } from '../rootState'
import actions from './actions'
import store from '..'

export enum AddProductResult
{
  ExitsProductError = "Sản phẩm đã tồn tại trong giỏ hàng!",
  AddProductSuccessed = "Đã thêm sản phẩm vào giỏ hàng!"
}

export const state = new ShoppingCart()

const namespaced = true;

export const shoppingCart: Module<ShoppingCart, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
}

export const addProductToCart = (product:Product) => {
  return store.dispatch("shoppingCart/UpdateProducts", product);
}

export const commitRemoveProductToCart = (productId:string) => {
  store.commit("shoppingCart/removeProductToCart", productId)
}