<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script setup lang="ts">
import store from '@/store';
import type { Product } from '@/store/cart/state';
import { computed } from 'vue';
import { numberWithCommas } from "../../lib/utils/common"
import { commitRemoveProductToCart } from '@/store/cart';
import Swal from 'sweetalert2';
import { routerPaths } from '@/router/routes';

const products = computed(() => store.state.shoppingCart.products as Array<Product>);

const removeProduct = (productId: string) => {
    Swal.fire({
        title: "Bạn có chắc chắn?",
        text: "Bạn muốn xóa sản phẩm này ra khỏi giỏ hàng!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
    }).then(() => {
        commitRemoveProductToCart(productId);
    });
    
}

</script>

<template>
    <div class="header-page">
        <a href="../" class="ic-back"></a>
        <h1 class="title-page text-center">GIỎ HÀNG</h1>
    </div>
    <section class="section-page section-page-mobile" id="shopping-cart-section">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-sm-10 c0l-md-8 col-lg-6" id="id_table_cart">
                    <div v-if="!products || products.length <= 0" class="empty-block border">
                        <img src="@/assets/images/icons/ic-EmptyCart.svg" width="216px" alt="EmptyPictures">
                        <p class="text-uppercase fw-bold fs-5">CHƯA CÓ SẢN PHẨM</p>
                        <p class="fw-light text-secondary fs-6">Giỏ hàng của bạn còn trống</p>
                    </div>
                    <table v-if="products && products.length > 0" class="table table-hover table-cart">
                        <thead>
                            <tr>
                                <th scope="col">Sản phẩm</th>
                                <th scope="col" class="text-end">Giá</th>
                                <th scope="col" class="text-center" width="50px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in products">
                                <td>
                                    <img :src="product.icon" width="32px" class="img-fluid" alt="hình">
                                    <strong class="ms-1">{{ product.name }}</strong>
                                </td>
                                <td class="text-end">{{ product.qty }}x {{ numberWithCommas(
                                    product.price
                                ) }}</td>
                                <td class="text-center"><button type="button" class="btn-close"
                                        v-on:click="removeProduct(product.productId)"></button></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="3" class="">
                                    <div class="row-total py-2 mt-3">
                                        <span class="me-2">Tổng tiền tạm
                                            tính</span>
                                        <strong class="text-danger text-end cart-total">{{ numberWithCommas(
                                            products.reduce((partialSum: number, a) => partialSum + (a.qty * a.price), 0)
                                            )}}</strong>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    <div class=" d-flex align-items-center justify-content-between">
                        <RouterLink to="../" class="btn text-link text-primary h6 btn-back">
                            <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.51568 0.940976L4.57568 0.000976562L0.575684 4.00098L4.57568 8.00098L5.51568 7.06098L2.46235 4.00098L5.51568 0.940976Z"
                                    fill="#0D6EFD" />
                            </svg>
                            <span class="ms-1">Tiếp tục mua hàng</span>
                        </RouterLink>
                        <div v-if="products && products.length > 0" class="action-bottom">
                            <RouterLink :to="routerPaths.Payment" class="btn btn-lg btn-danger rounded-pill min-w262">Thanh
                                toán</RouterLink>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>
