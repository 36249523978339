<template>
  <div :class="isShow ? 'black-cover' : ''"></div>
  <component @onClose="closePoup" :isShow="isShow" :is="currentPopupComponent" :popupData="popupData"></component>
</template>

<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";

.black-cover {
  background-color: #0000007d;
  width: 100%;
  height: 100%;
  position: fixed;
  display: block;
  bottom: 0px;
}
</style>

<script lang="ts" setup>
import { ref, useTemplateRef } from 'vue';
</script>

<script lang="ts">
import ChartPopupComponent from './ChartPopupComponent.vue';
import SignUpPopupComponent from './SignUpPopupComponent.vue';
import BuyChartViewPopupComponent from './BuyChartViewPopupComponent.vue'
import UpdateAccountPopupComponent from './UpdateAccountPopupComponent.vue'
import PopupDetailInterpretation from './PopupDetailInterpretation.vue';
import { PoupType, type PopupData } from '@/lib/popup';


const isShow = ref<boolean>(false);
const currentPopupComponent = ref<any>(undefined)
const popupData = ref<PopupData>();
export default {
  methods: {
    switchComponent(type: PoupType, data: PopupData) {
      isShow.value = false;
      setTimeout(() => {
        switch (type) {
          case PoupType.ChartPopupComponent:
            currentPopupComponent.value = ChartPopupComponent;
            break;
          case PoupType.SignUpPopupComponent:
            currentPopupComponent.value = SignUpPopupComponent;
            break;
          case PoupType.BuyChartViewPopupComponent:
            currentPopupComponent.value = BuyChartViewPopupComponent;
            break;
          case PoupType.UpdateAccountPopupComponent:
            currentPopupComponent.value = UpdateAccountPopupComponent;
            break;
          case PoupType.PopupDetailInterpretation:
            currentPopupComponent.value = PopupDetailInterpretation;
            break;
        }
        popupData.value = data;
        setTimeout(() => {
          isShow.value = true;
          document.body.style.overflow = 'hidden';
        }, 9)
      }, 9)
    },

    closePoup() {
      isShow.value = false;
      document.body.style.overflow = 'auto';
      setTimeout(() => {
        currentPopupComponent.value = null;
      }, 330)
    },

    setData(data: PopupData) {
      popupData.value = data;
    },

    getCurrentPopup() {
      return currentPopupComponent.value;
    }
  },
};


</script>