import { isEmail, isValidName, isValidPassword, isValidMultileDomain, isValidMultileUUID } from "@/lib/utils/common"
import { defineRule } from "vee-validate"

defineRule("required", (value: string | any[]) => {
  if (!value || !value.length) {
    return "error_required"
  }
  return true
})
defineRule("email", (value: string) => {
  if (!isEmail(value)) {
    return "error_format_email"
  }

  return true
})
defineRule("name", (value: string) => {
  if (!isValidName(value)) {
    return "error_format_name"
  }
  return true
})
defineRule("password", (value: string) => {
  if (!isValidPassword(value)) {
    return "error_format_password"
  }
  return true
})

defineRule("url", (value: string) => {
  const urlRegex = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/gi
  if (!urlRegex.test(value)) {
    return "error_format_url"
  }
  return true
})

defineRule("multiline-domain", (multiline: string) => {
  if (!isValidMultileDomain(multiline)) {
    return "error_format_domain"
  }
  return true
})

defineRule("multiline-url", (multiline: string) => {
  const urlRegex = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/gi
  const normalizedString = multiline.replace(/[\s;|,]+/g, ' ').trim()

  const potentialStrings = normalizedString.split(' ');
  for(let i = 0; i < potentialStrings.length; i++) {
    if (!urlRegex.test(potentialStrings[i])) {
      return "error_format_multiline_url"
    } 
  }

  return true
})

defineRule("multiline-uuid", (multiline: string) => {
  if (!isValidMultileUUID(multiline)) {
    return "error_format_uuid"
  }
  return true
})