<template>
   <div :class="isShow ? 'show' : ''" class="modal fade modalExplain" id="modalExplain" data-bs-backdrop="static"
      data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticExplain" aria-modal="true" role="dialog"
      style="display: block;">
      <div class="modal-dialog modal-xl modal-dialog-centered">
         <div class="modal-content">
            <div class="modal-header border-0">
               <h5 class="modal-title">{{ getTitle() }}</h5>
               <button v-on:click="onCloseClick" type="button" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close"></button>
            </div>
            <div class="modal-body">
               <div class="row">
                  <div class="col-3 col-sm-6" style="display: inline-flex;">
                     <select :value="popupData.birthYear" class="btn btn-sm btn-light" :onchange="onChangeYearOrGender"
                        ref="year_copy" id="year_copy" style="color: white;background-color: black;text-align: left;">
                        <option v-for="item in years" :value="item">{{ item }}</option>
                     </select>
                     <select :value="popupData.genderCode" class="btn btn-sm btn-light" :onchange="onChangeYearOrGender"
                        ref="select_gioi_tinh_copy" id="select_gioi_tinh_copy"
                        style="color: white;background-color: black;text-align: left;margin-left: 10px;">
                        <option value="1">Nam</option>
                        <option value="2">Nữ</option>
                     </select>
                  </div>
               </div>
               <div class="row justify-content-center">
                  <div class="col-12 position-relative">
                     <canvas id="modal-chart-explain-vue" height="480" width="1588"></canvas>
                  </div>
                  <div class="col-12 col-sm-8 col-xl-6">
                     <div class="alert bg-theme text-white text-center fst-italic py-1 rounded-4 alert-changing-fortune"
                        role="alert">
                        <small id="sm_so_luot_xem_con_lai">Quý khách còn {{ popupData.totalRemainingChartView }} lượt
                           xem.</small> <small> Quý khách có thể
                           click vào <a style="font-weight: bold;cursor: pointer;" onclick="register_service()">đây</a>
                           để mua thêm.</small>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
</style>

<script lang="ts" setup>
import { PopupController, PoupType, type PopupData } from '@/lib/popup';
import { createChartExplain, getYears } from '@/lib/utils/common';
import type { ISimDetailParam } from '@/models/https/simRequests';
import store from '@/store';
import type { UserProfileSate } from '@/store/userProfile/state';
import { Chart, CategoryScale, type ChartTypeRegistry } from 'chart.js/auto'
import { computed, onMounted, onUnmounted, ref, useTemplateRef, watch, watchEffect } from 'vue';
const userProfile = computed(() => store.state.userProfile as UserProfileSate);

interface ChartPopupData extends PopupData {
   oldSim: string,
   currentData: Array<number>,
   totalRemainingChartView: number,
   gender: string,
   genderCode: string,
   birthYear: string,
   action: (year: number, genderCode: number) => void
};

let chartView: Chart<keyof ChartTypeRegistry, number[] | undefined, string> | undefined = undefined;

const props = defineProps<{
   isShow: boolean
   popupData: ChartPopupData,
}>();


const getTitle = () => {
   if (Boolean(props.popupData.gender) && Boolean(props.popupData.birthYear)) {
      return `Biểu đồ cho số ${props.popupData.oldSim} cho ${props.popupData.birthYear} ${props.popupData.gender}`
   }
   return "Biểu đồ luận giải...";
}

watchEffect(() => {

   setTimeout(() => {
      const canvas = document.getElementById('modal-chart-explain-vue') as HTMLCanvasElement;
      const ctx: CanvasRenderingContext2D | null = canvas?.getContext("2d");
      chartView = createChartExplain(props.popupData.oldSim, props.popupData.currentData, ctx);
   }, 10);

});

const pd = computed(() => props.popupData);

watch(pd, (newData, previous) => {
   updateChart(newData.currentData);
});

const emit = defineEmits<{
   (event: 'onClose'): void
}>();

const onCloseClick = () => {
   emit('onClose');
}

const years: number[] = getYears();

const yearSelect = useTemplateRef('year_copy');
const genderSelect = useTemplateRef('select_gioi_tinh_copy');

const onChangeYearOrGender = () => {
   props.popupData.action(parseInt(yearSelect.value?.value ?? props.popupData.birthYear),
      parseInt(genderSelect.value?.value ?? "-1"));
}

const updateChart = (data: number[]) => {
   if (chartView) {
      chartView.data.datasets[0].data = data;
      chartView.update()
   }

}

onUnmounted(() => {
   if (chartView) {
      chartView.destroy();
   }
});

</script>
