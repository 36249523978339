<template>
   <div :class="isShow ? 'show' : ''" class="modal fade" id="modalUpgradeYourAccount" data-bs-backdrop="static"
      data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticUpgradeYourAccount" style="display: block;"
      aria-modal="true" role="dialog">
      <div class="modal-dialog modal-lg modal-dialog-centered">
         <div class="modal-content">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="modal-body">
               <div class="content d-flex align-items-center">
                  <div>
                     <!-- <h5 class="title">{{ popupData.name }}</h5> -->
                     <h5 class="title">THAY SỐ CẢI VẬN</h5>
                     <p id="p_message">{{getDesc(popupData.description)}}</p>
                  </div>
                  <img src="@/assets/images/logoLUCTRUMENHLY.svg" width="50px" alt="Logo LỤC TRỤ MỆNH LÝ"
                     class="logo" />
               </div>
               <div
                  class="btn-group d-flex align-items-center justify-content-center flex-column flex-sm-row gap-3 mt-4">
                  <button v-on:click="onCloseClick" type="button" class="btn btn-lg btn-outline-warning rounded-pill">Để
                     sau</button>
                  <RouterLink v-on:click="onCloseClick" :to="routerPaths.PacketService" id="a_message" class="btn btn-lg btn-warning rounded-pill">Mua</RouterLink>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
</style>

<script lang="ts" setup>
import type { PopupData } from '@/lib/popup';
import { routerPaths } from '@/router/routes';
import { RouterLink } from 'vue-router';



interface BuyPopupData extends PopupData {
   description: string,
}

const getDesc = (desc: string): string => {
   if (Boolean(desc)) {
      return desc;
   }
   return "Số lượt xem đã hết. Vui lòng mua thêm số lượt xem.";
}

defineProps<{
   isShow: boolean,
   popupData: BuyPopupData
}>();

const emit = defineEmits<{
   (event: 'onClose'): void
}>();

const onCloseClick = () => {
   emit('onClose');
}
</script>