export class PopupState {
  InformationPopup = false
  SuccessfulPopup = false
  TextFieldPopup = false
  DropdownPopup = false
  CreateApplicationPopup = false
  EditApplicationName = false
  EditOrganizationName = false
  EditRepresentativeEmail = false
  EditRepresentativePhoneNumber = false
  DeleteApplication = false
  SuccessUpdateApplication = false
  SuccessDeleteApplication = false
  SelectItemPopup = false
  AdminPopup = false
}
