import type { IUserProfileDto } from "@/models/account";
import { USER_PROFILE_KEYS_TO_DTO } from "@/store/userProfile/mutation";
import { UserProfileSate } from "@/store/userProfile/state";

export const userProfileToDTO = (userProfile: UserProfileSate): IUserProfileDto => {
    if (!userProfile) userProfile = new UserProfileSate();

    var dtoProfile: IUserProfileDto = {
        kh_id: 0,
        kh_ho: "",
        kh_ten: "",
        kh_ten_dem: "",
        kh_ten_day_du: "",
        kh_ngay_sinh: "",
        kh_gio_sinh: "",
        kh_gioi_tinh: 0,
        kh_mobile: "",
        kh_gmail: "",
        kh_facebook: "",
        kh_create_date: "",
        kh_create_by: "",
        goi_dich_vu_ma: 0,
        goi_dich_vu_ten: "",
        trang_thai: "",
        kh_token: "",
        kh_thanh_pho_sinh: 0,
        kh_quan_sinh: 0,
        kh_phuong_sinh: 0,
        kh_dia_chi_sinh: "",
        kh_thanh_pho_o: 0,
        kh_quan_o: 0,
        kh_phuong_o: 0,
        kh_dia_chi: "",
        tong_luot_xem: 0,
        so_luot_da_xem: 0,
        luot_xem_con_lai: 0,
        ngay_het_han: "",
        ngay_het_han_vn: "",
        kh_diem: 0,
        kh_anh: "",
        expried_lvn: "",
        qs_tong_luot_quay: 0,
        qs_luot_quay_t_te: 0,
        qs_thoi_han: undefined
    };
    Object.keys(USER_PROFILE_KEYS_TO_DTO).forEach(profileKey => {
        const dtoKey: string = (USER_PROFILE_KEYS_TO_DTO as any)[profileKey];

        (dtoProfile as any)[dtoKey] = (userProfile as any)[profileKey];
    });
    return dtoProfile;
};