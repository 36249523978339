<script lang="ts" setup>
import store from '@/store';
import defaultAvatar from '@/assets/images/Avatar.png';
import { RepositoryFactory } from '@/lib/https';
import AccountRepository from '@/lib/https/accountRepository';
import moment from 'moment';
import { userProfileToDTO } from '@/common/utils';
import MasterDataRepository from '@/lib/https/masterDataRepository';
import { ref } from 'vue';
import { type IDistrict, type ITown, type IProvince } from '@/models/masterData';
import AccountLeftPanel from '@/components/parts/UserAccount/AccountLeftPanel.vue';
import UserProfileRepository from '@/lib/https/userProfileRepository';
import { commitProfileFromDto } from '@/store/userProfile';
import Swal from 'sweetalert2';
</script>

<script lang="ts">
const { updateAccountProfile } = RepositoryFactory.getRepository<AccountRepository>(AccountRepository);
const { getProvices, getDistricts, getTowns } = RepositoryFactory.getRepository<MasterDataRepository>(MasterDataRepository);
const { getUserProfile } = RepositoryFactory.getRepository<UserProfileRepository>(UserProfileRepository);
const provices = ref<IProvince[]>([]);
const districts = ref<IDistrict[]>([]);
const towns = ref<ITown[]>([]);

const birthDistricts = ref<IDistrict[]>([]);
const birthTowns = ref<ITown[]>([]);

export default {
    data() {
        return {
            form: store.state.userProfile,
        };
    },
    mounted() {
        getProvices().then(data => {
            provices.value = data;
        });
        getDistricts(this.form.cityOfResidence).then(data => {
            districts.value = data;
            if (this.form.birthCity == this.form.cityOfResidence) {
                birthDistricts.value = data;
            } else {
                getDistricts(this.form.birthCity).then(data => {
                    birthDistricts.value = data;
                });
            }
        });
        getTowns(this.form.districtOfResidence).then(data => {
            towns.value = data;
            if (this.form.birthDistrict == this.form.districtOfResidence) {
                birthTowns.value = data;
            } else {
                getTowns(this.form.birthDistrict).then(data => {
                    birthTowns.value = data;
                });
            }
        });
    },
    computed: {
        cityOfResidence() {
            return this.form.cityOfResidence;
        },
        districtOfResidence() {
            return this.form.districtOfResidence;
        },
        birthCity() {
            return this.form.birthCity;
        },
        birthDistrict() {
            return this.form.birthDistrict;
        }
    },
    watch: {
        cityOfResidence(newVal, _oldVal) {
            if (_oldVal == 0) return;
            if (newVal == this.birthCity && birthDistricts.value.length) {
                districts.value = birthDistricts.value;
            } else {
                getDistricts(newVal).then(data => {
                    districts.value = data;
                });
            }
        },
        districtOfResidence(newVal, _oldVal) {
            if (_oldVal == 0) return;
            if (newVal == this.birthDistrict && birthTowns.value.length) {
                towns.value = birthTowns.value;
            } else {
                getTowns(newVal).then(data => {
                    towns.value = data;
                });
            }
        },
        birthCity(newVal, _oldVal) {
            if (_oldVal == 0) return;
            if (newVal == this.cityOfResidence && districts.value.length) {
                birthDistricts.value = districts.value;
            } else {
                getDistricts(newVal).then(data => {
                    birthDistricts.value = data;
                });
            }
        },
        birthDistrict(newVal, _oldVal) {
            if (_oldVal == 0) return;
            if (newVal == this.districtOfResidence && towns.value.length) {
                birthTowns.value = towns.value;
            } else {
                getTowns(newVal).then(data => {
                    birthTowns.value = data;
                });
            }
        }
    },
    methods: {
        async onSubmit() {
            var body = userProfileToDTO(this.form);
            body.kh_ngay_sinh = moment(this.form.formatedBirthday).format('YYYY-MM-DDT00:00:00');
            body.qs_thoi_han = null;
            const res = await updateAccountProfile(body);
            if (res?.result_code == '000') {
                const al: any = {
                    icon: "success",
                    title: "Cập nhật thành công",
                }
                Swal.fire(al);
                getUserProfile().then(userProfile => {
                    if (userProfile) {
                        commitProfileFromDto(userProfile);
                    }
                });
            }
            else {
                const error: any = {
                    icon: "error",
                    title: res?.result_msg,
                }
                Swal.fire(error);
            }
        },
    }
};

// const myDate = '2034-09-22'
// console.log(myDate)
</script>

<template>
    <section class="section-page section-page-mobile section-page-account bg-light" id="account-section">
        <div class="container-fluid container-scroll">
            <div class="row">
                <div class="col-4 d-none d-lg-block block">
                    <AccountLeftPanel />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                    <div class="px-3 px-sm-0">
                        <h2 class="title-page mb-3 text-center">THÔNG TIN TÀI KHOẢN</h2>
                        <h5 class="title-page mb-3 text-center"
                            style="font-size: 14px; font-weight: normal; font-style: italic; color: red;">
                            Vui lòng cập nhật ít thông tin ngày sinh, giới tính để có thể sử dụng các dịch vụ xem số,
                            quẻ số, lịch vạn niên được chính xác nhất.
                        </h5>
                        <form class="card mb-3" @submit.prevent="onSubmit">
                            <div class="p-3">
                                <div class="row">
                                    <div class="col-lg-4 col-xl-3 m-auto">
                                        <div class="upload-wrapper m-auto">
                                            <div class="avatar ratio ratio-1x1">
                                                <img :src="form.avatar || defaultAvatar" id="img_avatar_info" alt="lgo"
                                                    width="150px" height="150px"
                                                    class="expert-img img-fluid object-cover">
                                            </div>
                                            <!-- <span class="btn-close rounded-pill" title="delete"></span>
                                 <div class="upload-avatar">
                                    <div class="upload-button">Chọn ảnh</div>
                                    <input class="file-upload" type="file" accept="image/*" />
                                 </div> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-xl-9">
                                        <div class="row mt-4">
                                            <div class="col-6 mb-3">
                                                <label for="nameInput" class="form-label">Họ tên</label>
                                                <input type="text" class="form-control" v-model="form.fullName">
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label for="phoneInput" class="form-label">Số điện thoại</label>
                                                <input type="phone" class="form-control" id="phoneInput"
                                                    v-model="form.phoneNumber" placeholder="">
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label for="nameInput" class="form-label">Ngày sinh</label>
                                                <div class="form-control position-relative mt-1">
                                                    <input class="border-0" type="date"
                                                        v-model.date="form.formatedBirthday">
                                                    <!-- YYYY-MM-DD -->
                                                </div>
                                            </div>
                                            <div class="col-6 mb-3">
                                                <label for="phoneInput" class="form-label w-100">Giới tính</label>
                                                <div id="option-gender" class="btn-group border" role="group"
                                                    aria-label="group1">
                                                    <input type="radio" class="btn-check" value="1" name="btnGender"
                                                        id="btnMale" :checked="form.gender == 1">
                                                    <label class="btn btn-sm btn-light" for="btnMale">Nam</label>

                                                    <input type="radio" class="btn-check" value="2" name="btnGender"
                                                        id="btnFemale" :checked="form.gender == 2">
                                                    <label class="btn btn-sm btn-light" for="btnFemale">Nữ</label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="bg-light p-3">
                                <h5 for="" class="h6">Địa chỉ nơi ở</h5>
                            </div>
                            <div class="p-3">
                                <div class="row ali">
                                    <div class="col-sm-6 col-xl-3 mb-3">
                                        <label for="province" class="form-label">Tỉnh/TP</label>
                                        <select class="form-select" v-model="form.cityOfResidence">
                                            <option v-for="province in provices" :value="province.id">
                                                {{ province.value2 }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 col-xl-3 mb-3">
                                        <label for="city" class="form-label">Quận/Huyện</label>
                                        <select class="form-select" v-model="form.districtOfResidence">
                                            <option v-for="district in districts" :value="district.value1">
                                                {{ district.value2 }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 col-xl-3 mb-3">
                                        <label for="city" class="form-label">Phường/Xã</label>
                                        <select class="form-select" v-model="form.wardOfResidence">
                                            <option v-for="town in towns" :value="town.value1">
                                                {{ town.value2 }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 col-xl-3 mb-3">
                                        <label for="locationInput" class="form-label">Địa chỉ</label>
                                        <textarea class="form-control" rows="1" placeholder="vd: 8 Lý Chính Thắng"
                                            v-model="form.placeOfResidence"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-light p-3">
                                <h5 for="" class="h6">Địa chỉ nơi sinh</h5>
                            </div>
                            <div class="p-3">
                                <div class="row ali">
                                    <div class="col-sm-6 col-xl-3 mb-3">
                                        <label for="province" class="form-label">Tỉnh/TP</label>
                                        <select class="form-select" v-model="form.birthCity">
                                            <option v-for="province in provices" :value="province.id">
                                                {{ province.value2 }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 col-xl-3 mb-3">
                                        <label for="city" class="form-label">Quận/Huyện</label>
                                        <select class="form-select" v-model="form.birthDistrict">
                                            <option v-for="district in birthDistricts" :value="district.value1">
                                                {{ district.value2 }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 col-xl-3 mb-3">
                                        <label for="city" class="form-label">Phường/Xã</label>
                                        <select class="form-select" v-model="form.wardOfBirth">
                                            <option v-for="town in birthTowns" :value="town.value1">
                                                {{ town.value2 }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 col-xl-3 mb-3">
                                        <label for="locationInput-sinh" class="form-label">Địa chỉ</label>
                                        <textarea class="form-control" rows="1" placeholder="vd: 8 Lý Chính Thắng"
                                            v-model="form.placeOfBirth"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="action-bottom text-center mb-3">
                                <button id="btnUpdate" class="btn btn-lg btn-danger rounded-pill min-w262">CẬP
                                    NHẬT</button>
                            </div>
                            <div class="bg-light p-3">
                                <div class="row">
                                    <div class="col-lg-10 col-xl-8 col-xxl-6">
                                        <div class="d-flex flex-column flex-sm-row">
                                            <h5 for="" class="h6 pe-3 mb-3">Liên kết tài khoản</h5>
                                            <div class="flex-fill">
                                                <div class="d-flex align-items-center justify-content-between mb-2">
                                                    <div class="d-flex align-items-center">
                                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M2.3335 14.0653C2.3335 19.8656 6.54613 24.6888 12.0557 25.6668V17.2406H9.13905V14.0002H12.0557V11.4072C12.0557 8.49058 13.935 6.87086 16.5931 6.87086C17.435 6.87086 18.3431 7.00016 19.185 7.12947V10.1113H17.6946C16.2684 10.1113 15.9446 10.8239 15.9446 11.732V14.0002H19.0557L18.5375 17.2406H15.9446V25.6668C21.4542 24.6888 25.6668 19.8666 25.6668 14.0653C25.6668 7.61266 20.4168 2.3335 14.0002 2.3335C7.5835 2.3335 2.3335 7.61266 2.3335 14.0653Z"
                                                                fill="#0E70E3" />
                                                        </svg>
                                                        <span class="ps-1">Facebook</span>
                                                    </div>
                                                    <span class="ps-3" id="facebook_id">{{ form.facebook }} </span>
                                                </div>
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M25.4399 11.7152H24.5002V11.6668H14.0002V16.3335H20.5936C19.6317 19.0501 17.0469 21.0002 14.0002 21.0002C10.1344 21.0002 7.00016 17.8659 7.00016 14.0002C7.00016 10.1344 10.1344 7.00016 14.0002 7.00016C15.7846 7.00016 17.408 7.67333 18.6441 8.77291L21.944 5.473C19.8603 3.53108 17.0732 2.3335 14.0002 2.3335C7.55725 2.3335 2.3335 7.55725 2.3335 14.0002C2.3335 20.4431 7.55725 25.6668 14.0002 25.6668C20.4431 25.6668 25.6668 20.4431 25.6668 14.0002C25.6668 13.2179 25.5863 12.4543 25.4399 11.7152Z"
                                                                fill="#FFBB0D" />
                                                            <path
                                                                d="M3.67871 8.56991L7.51179 11.381C8.54896 8.81316 11.0608 7.00016 14.0002 7.00016C15.7846 7.00016 17.408 7.67333 18.6441 8.77291L21.944 5.473C19.8604 3.53108 17.0732 2.3335 14.0002 2.3335C9.51904 2.3335 5.63288 4.86341 3.67871 8.56991Z"
                                                                fill="#FF3D00" />
                                                            <path
                                                                d="M14 25.6666C17.0135 25.6666 19.7517 24.5133 21.8219 22.6379L18.2111 19.5824C17.0004 20.5031 15.521 21.0011 14 20.9999C10.9655 20.9999 8.38894 19.065 7.41827 16.3647L3.61377 19.296C5.5446 23.0742 9.46577 25.6666 14 25.6666Z"
                                                                fill="#4CAF50" />
                                                            <path
                                                                d="M25.4397 11.7149H24.5V11.6665H14V16.3332H20.5934C20.1333 17.6261 19.3045 18.7559 18.2093 19.5829L18.2111 19.5818L21.8219 22.6373C21.5664 22.8694 25.6667 19.8332 25.6667 13.9998C25.6667 13.2176 25.5862 12.454 25.4397 11.7149Z"
                                                                fill="#1976D2" />
                                                        </svg>
                                                        <span class="ps-1">Google</span>
                                                    </div>
                                                    <span class="ps-3" id="google_id">{{ form.gmail }}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>