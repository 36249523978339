export class UserProfileSate {
  id = -1
  birthDay = ''
  birthTime = ''
  gmail = ''
  gender = 0
  avatar = ''
  customerPoint = 0
  firstName = ''
  lastName = ''
  middleName = ''
  fullName = ''
  facebook = ''
  phoneNumber = ''
  createDate = ''
  createBy = '' 
  serviceId = 0
  status = ''
  birthCity = 0
  birthDistrict = 0
  wardOfBirth = 0
  placeOfBirth = ''
  cityOfResidence = 0
  districtOfResidence = 0
  wardOfResidence = 0
  placeOfResidence = ''
  totalChartView = 0
  totalNumberOfView = 0
  totalRemainingChartView = 0
  expireDate = ""
  expirePerpetualCalendar = ""
  sumaryOfNumberDraw = 0
  NumberUsedDraw = 0
  timedNumberDraw = 0
  totalRemainingNumberDraw = 0
  accountToken = ''
  serviceName = ''
  formatedBirthday = ''
}
