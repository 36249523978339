import type { MutationTree } from "vuex";
import type { LoaderState } from "./state";
const mutations: MutationTree<LoaderState> = {
	showLoader(state) {
		state.isShow = true
	},
	hideLoader(state) {
		state.isShow = false
	}
};

export default mutations;
