<template>
   <div :class="isShow ? 'show' : ''" class="modal fade modalDetailInterpretation" id="modalDetailInterpretation"
      data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticDetailInterpretation"
      style="display: block;" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-fullscreen-xl-down modal-xl">
         <div class="modal-content">
            <div class="modal-header">
               <h2 class="title-section2 text-white m-auto">
                  <span class="text1 ">LUẬN GIẢI QUẺ DỊCH</span>
               </h2>
               <button v-on:click="onCloseClick" type="button" class="btn-close ms-0"
                  style="background-color: white !important;opacity: 1 !important;" data-bs-dismiss="modal"
                  aria-label="Close"></button>
            </div>
            <div class="modal-body">
               <div class="content-interpretation">
                  <h3 class="title-box text-white m-auto">
                     <span class="text1" id="id_que_so">{{ popupData.drawNumber }}</span>
                  </h3>
                  <div class="interpretation-box">
                     <div class="item-interpretation -image">
                        <img src="@/assets/images/wheel/interpretation-image.svg" alt="interpretation">
                        <div class="content">
                           <div class="year"> <span>2024</span></div>
                           <div class="number"><span></span></div>
                           <div class="inter-info">
                              <div class="px-lg-3">
                                 <table class="table table-bordered">
                                    <tbody>
                                       <tr>
                                          <td style="width: 20%; text-align: center;"><span class="number"
                                                id="id_que_so_1">{{ popupData.drawNumber }}</span>
                                          </td>
                                          <td style="text-align: center;">
                                             <img id="id_icon" style="width: 63px;height: 64px;"
                                                :src="popupData.drawNumberIcon" class="img-fluid" alt="interpretation">
                                          </td>
                                          <td>
                                             <span id="id_ten">{{ popupData.drawNumberName }}</span><br>
                                             <span id="id_ngoai_quai">Ngoại quái: {{ popupData.outerTrigram }}</span><br>
                                             <span id="id_noi_quai">Nội quái: {{ popupData.innerTrigram }}</span>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>

                              <div class="bg-white p-3">
                                 <img id="id_que_image" :src="popupData.drawNumberImage" alt="que">
                              </div>

                           </div>

                        </div>
                     </div>
                     <div class="item-interpretation interpretation-text">
                        <img src="@/assets/images/wheel/interpretation-text.svg" alt="interpretation">
                        <div class="content">
                           <div class="year"> <span>2024</span></div>
                           <div class="inter-info">
                              <div class="mean" id="div_y_nghia" style="overflow-y: scroll;">
                                 <h4 class="name">Ý nghĩa bức tranh</h4>
                                 <ol id="id_y_nghia_buc_tranh">                             
                                    <li v-for="m in popupData.imageMeaning">{{ m.y_nghia }}</li>
                                 </ol>
                                 <h4 class="name">Hình và tượng</h4>
                                 <label id="id_hinh">{{ popupData.symbol ? popupData.symbol[0].hinh_tuong : "" }}</label> <br>
                                 <label id="id_tuong">>{{ popupData.symbol ? popupData.symbol[1].hinh_tuong : "" }}</label>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
                  <div class="text-center my-3">
                     <button type="button" id="btn_luan_giai"
                        class="btn btn-wheel mt-3 rounded-pill font-weight-bold wow zoomIn" onclick="LuanGiai()">LUẬN
                        GIẢI</button>
                  </div>
                  <div class="des-interpretation">
                     <h4 class="title-box2">
                        <span class="text1 ">THOÁN TỪ</span>
                     </h4>
                     <div id="id_thoat_tu" v-html="popupData.judgment">
                     </div>
                     <h4 class="title-box2">
                        <span class="text1 ">HÀO TỪ</span>
                     </h4>
                     <div id="id_hao_tu" v-html="popupData.lineStatement ">
                     </div>
                     <h4 class="title-box2">
                        <span class="text1 ">LUẬN GIẢI</span>
                     </h4>
                     <div id="id_luan_giai" v-html="popupData.interpretation">
                     </div>
                  </div>

               </div>

            </div>
         </div>
      </div>
   </div>
</template>

<style scoped>
@import "@/assets/css/wheel.css";
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
</style>

<script lang="ts" setup>
import type { PopupData } from '@/lib/popup';
import type { ImageMeaning, Symbol} from '@/models/https/numDrawResponse';



export interface DetailInterpretationPopupData extends PopupData {
   description: string | null,
   drawNumber: string,
   drawNumberIcon: string,
   drawNumberName: string,
   outerTrigram: string,
   innerTrigram: string,
   drawNumberImage: string,
   imageMeaning: ImageMeaning[],
   symbol: Symbol[],
   judgment: string,
   lineStatement: string,
   interpretation: string
}


defineProps<{
   isShow: boolean,
   popupData: DetailInterpretationPopupData
}>();

const emit = defineEmits<{
   (event: 'onClose'): void
}>();

const onCloseClick = () => {
   emit('onClose');
}
</script>