<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import { createCalendarInterpretionChart, createChartExplain} from '@/lib/utils/common';
import type { Chart, ChartTypeRegistry } from 'chart.js';
import { computed, onBeforeUnmount, onMounted, ref, useTemplateRef, watch } from 'vue';
export interface InDateData {
   lineChartInDate: Array<number>,
   name: string,
}

export interface CalendarInterPretionData {
   lineChartInterpretion: Array<number>,
   titleChartInterpretion: string,
   interpretion: string,
   favorableReview: string,
   unfavorableReview: string,
   colorChartInterpretion: string,
   chartId: number,
}


const getBoderBackgroundByChart = (chartId: number) => {
   let item = { borderColor: '', backgroundColor: '' };

   switch (chartId) {
      case 3://tiền
         item = { borderColor: '#FFFD54', backgroundColor: '#4D4C13' };
         break;
      case 2://công danh
         item = { borderColor: '#ff0000', backgroundColor: '#3F1F1F' };
         break;
      case 1://sức khẻo
         item = { borderColor: '#6AE344', backgroundColor: '#1E4B10' };
         break;
      case 4:// hôn nhân
         item = { borderColor: '#E53BF2', backgroundColor: '#460B4A' };
         break;
      default:
         break;
   }
   return item;
}


const canvasRef = useTemplateRef("chart-explain");
let chart: Chart<keyof ChartTypeRegistry, number[] | undefined, string> | undefined = undefined;

const canvasInterpretionRef = useTemplateRef("chart-interpretion");
let chartInterpretion: Chart<keyof ChartTypeRegistry, number[] | undefined, string> | undefined = undefined;

const props = defineProps<{
   dataInDate: InDateData | undefined,
   interpretionData: CalendarInterPretionData | undefined
}>();

const updateChartInDate = (data: number[], label: string) => {

   if (chart) {
      chart.data.datasets[0].data = data;
      chart.data.datasets[0].label = label;
      chart.update()
   }
}

const updateChartInterpretion = (data: number[], chartId: number) => {

   if (chartInterpretion) {
      const bd = getBoderBackgroundByChart(chartId);
      const option = chartInterpretion.data.datasets[0] as any;
      option.data = data;
      option.borderColor = bd.borderColor;
      option.pointBackgroundColor = bd.borderColor;
      option.datalabels.labels.index.color = bd.borderColor
      option.backgroundColor = bd.backgroundColor;
      const option2 = chartInterpretion.options as any;
      option2.plugins.datalabels.color = bd.borderColor;
      option2.scales.x.ticks.color = bd.borderColor;

      chartInterpretion.update();
   }
}

watch([() => props.dataInDate, () => props.interpretionData], ([newFirstProp, newSecondProp], [oldFirstProp, oldSecondProp]) => {
   if (newFirstProp) {
      updateChartInDate(newFirstProp.lineChartInDate, newFirstProp.name);
   }
   if (newSecondProp) {
      updateChartInterpretion(newSecondProp.lineChartInterpretion, newSecondProp.chartId);
   }
});

onMounted(() => {
   if (!chart) {
      const cv = canvasRef.value as HTMLCanvasElement;
      chart = createChartExplain(props.dataInDate?.name ?? "", props.dataInDate?.lineChartInDate ?? [], cv?.getContext("2d"), {
         borderColor: "#FFFD54",
         backgroundColor: "#4D4C13",
         labelColor: "#FFFD54",
         pointBackgroundColor: "#FFFD54",
         enableLabel: false,
         paddingBottom: -30,
      });
   }

   if (!chartInterpretion) {
      const cv = canvasInterpretionRef.value as HTMLCanvasElement;
      const labels = ["23h-1h", "1h-3h", "3h-5h", "5h-7h", "7h-9h", "9h-11h", "11h-13h", "13h-15h", "15h-17h", "17h-19h", "19h-21h", "21h-23h"];
      chartInterpretion = createCalendarInterpretionChart(props.interpretionData?.lineChartInterpretion ?? [], cv?.getContext("2d"), labels, "#FFFD54", "#4D4C13");
   }
});

onBeforeUnmount(() => {
   if (chart) {
      chart.destroy();
   }
   
   if(chartInterpretion)
   {
      chartInterpretion.destroy();
   }
})

const emits = defineEmits<{
   (event: "changeTab", chartId: number): void
}>();

const changeTab = (chartId: number) => {
   emits("changeTab", chartId);
}

</script>

<template>
   <main>
      <div class="d-flex justify-content-center mt-3 mt-lg-5" id="div_detail_1">
         <h2 class="title-perpetual mb-0 mb-sm-3 text-dark" id="option_5">tỉ lệ 10 đường
            trong ngày</h2>
      </div>

      <div class="d-flex justify-content-center pb-5" id="div_detail_2">
         <div class="calendar-today bg-black ps-2 pe-4" id="option_6">
            <div class=" pb-5 position-relative ps-3 pe-sm-3">
               <canvas ref="chart-explain" height="550" width="800"></canvas>
            </div>
         </div>
      </div>
      <div class="d-flex justify-content-center mt-3 mt-lg-5 " id="div_detail_3">
         <div class="calendar-tab" id="option_8">
            <ul class="nav nav-pills mb-3 gap-1 p-0 justify-content-center" id="pills-tab" role="tablist">
               <li class="nav-item" role="presentation">
                  <button class="btn btn-danger rounded-pill active" id="pills-wealth-tab" data-bs-toggle="pill"
                     data-bs-target="#pills-wealth" type="button" role="tab" aria-controls="pills-wealth"
                     aria-selected="true" v-on:click="changeTab(3)">Tiền Tài</button>
               </li>
               <li class="nav-item" role="presentation">
                  <button class="btn btn-danger rounded-pill" id="pills-Reputation-tab" data-bs-toggle="pill"
                     data-bs-target="#pills-wealth" type="button" role="tab" aria-controls="pills-Reputation"
                     aria-selected="false" v-on:click="changeTab(2)">Công Danh</button>
               </li>
               <li class="nav-item" role="presentation">
                  <button class="btn btn-danger rounded-pill" id="pills-Health-tab" data-bs-toggle="pill"
                     data-bs-target="#pills-wealth" type="button" role="tab" aria-controls="pills-Health"
                     aria-selected="false" v-on:click="changeTab(1)">Sức Khoẻ</button>
               </li>
               <li class="nav-item" role="presentation">
                  <button class="btn btn-danger rounded-pill" id="pills-Marriage-tab" data-bs-toggle="pill"
                     data-bs-target="#pills-wealth" type="button" role="tab" aria-controls="pills-Marriage"
                     aria-selected="false" v-on:click="changeTab(4)">Hôn Nhân</button>
               </li>
            </ul>

            <div class="tab-content" id="pills-tabContent">
               <div class="tab-pane fade show active" id="pills-wealth" role="tabpanel"
                  aria-labelledby="pills-wealth-tab" tabindex="0">
                  <div class="row">
                     <div class="col-sm-6 pe-sm-0">
                        <div class="calendar-today bg-black h-100 d-flex align-items-center flex-column">
                           <p class="title h3">Luận Giải</p>
                           <h3 class="my-4" :style="{ color: interpretionData?.colorChartInterpretion }">{{
                              interpretionData?.titleChartInterpretion
                              }}
                           </h3>
                           <canvas ref="chart-interpretion" height="500" width="800"></canvas>
                        </div>
                     </div>
                     <div class="col-sm-6 ps-sm-0">
                        <div class="content-explanation h-100">
                           <h3 class="title">Luận Giải</h3>
                           <p class="des p-3" id="luangiai_tt">
                              {{ interpretionData?.interpretion }}
                           </p>
                           <h3 class="title">Nhận Xét</h3>
                           <div class="p-3">
                              <p class="des fw-bold pb-3 text-success" id="nhanxet_tot_tt"> {{
                                 interpretionData?.favorableReview }}</p>
                              <p class="des fw-bold text-danger" id="nhanxet_xau_tt"> {{
                                 interpretionData?.unfavorableReview }}</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      </div>
   </main>
</template>