import {createI18n} from "vue-i18n";
import locales from "@/locales";

export default createI18n({
  locale: import.meta.env.VITE_APP_I18N_LOCALE, 
  legacy: false, 
  globalInjection: true,
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE,
  messages: locales,
});
