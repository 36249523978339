<template>
   <div class="item-cart">
      <img :src="product.icon" class="img-fluid" alt="hình" style='width:30px;'>
      <strong class="me-auto ms-2">{{product.name}}</strong>
      <div class="text-end">
         <small class="text-danger fw-bold">{{ numberWithCommas(
            product.price
            )}}</small>
         <button class="btn btn-close" v-on:click="removeProduct"></button>
      </div>
   </div>

</template>

<style scoped>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
</style>

<script lang="ts" setup>
import type { Product } from '@/store/cart/state';
import { numberWithCommas } from "../../lib/utils/common"
import { commitRemoveProductToCart } from '@/store/cart';


const props = defineProps<{
   product: Product
}>();

const removeProduct = () => {
   commitRemoveProductToCart(props.product.productId);
}

</script>
