import type { Module } from "vuex";
import mutations from "./mutation";
import type { RootState } from "../rootState";
import { ApplicationState } from "./state";

export const state = new ApplicationState();

const namespaced = true;

export const application: Module<ApplicationState, RootState> = {
  namespaced,
  state,
  mutations,
};
