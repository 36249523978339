<style setup>
@import "https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
@import 'vue3-carousel/dist/carousel.css';
</style>
<script lang="ts" setup>
import NavBar from './parts/NavBar.vue';
import LoadingComponent from './parts/LoadingComponent.vue';
import FooterComponent from './parts/FooterComponent.vue';
import PopupRoot from './popups/PopupRoot.vue';
import { Carousel, Slide } from 'vue3-carousel';
import UserProfileRepository from '@/lib/https/userProfileRepository';
import { RepositoryFactory } from '@/lib/https';
import store from '@/store';
import { PopupController } from '@/lib/popup';
import CitiesRepositiory from '@/lib/https/citiesRepository';
import { onMounted } from 'vue';
import ScrollUpButton from './parts/ScrollUpButton.vue';
// import router from '@/router';
// import { computed } from 'vue';
</script>
<script lang="ts">

const { getUserProfile } = RepositoryFactory.getRepository<UserProfileRepository>(
   UserProfileRepository
);

const { getCities, getGenders } = RepositoryFactory.getRepository<CitiesRepositiory>(
   CitiesRepositiory
);

function GetUserProfile() {
   getUserProfile().then(userProfile => {
      if (userProfile) {
         store.commit('userProfile/setProfileFromDto', userProfile);
      }
   });

}
GetUserProfile();
getCities();
getGenders();
const emptyFunc = () => { }
PopupController.pushPopup = PopupRoot.methods?.switchComponent ?? emptyFunc;
PopupController.setPopupData = PopupRoot.methods?.setData ?? emptyFunc;
PopupController.getCurrentPopup = PopupRoot.methods?.getCurrentPopup ?? emptyFunc;

</script>
<template>
   <LoadingComponent />
   <header id="header">
      <div class="header-top bg-theme-dark">
         <div>
            <Carousel :wrap-around="true" :autoplay="5000" :loop="true">
               <Slide :key="0">
                  <img src="@/assets/images/banner-top.png" width="100%">
               </Slide>
               <slide :key="1">
                  <img src="@/assets/images/banner-top2.png" width="100%">
               </Slide>
            </Carousel>
         </div>
      </div>
      <NavBar />
   </header>
   <main>
      <RouterView />
   </main>
   <FooterComponent />
   <PopupRoot />
   <ScrollUpButton />
</template>
