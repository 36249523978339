<style setup>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import { computed, onMounted, ref, useTemplateRef } from 'vue';
import type { simItemDto } from '@/models/https/simResponse';
import type { IGetSimReq } from '@/models/https/simRequests';
import SimRepository from '@/lib/https/simRepository';
import { RepositoryFactory } from '@/lib/https';
import PageComponent from '../parts/PageComponent.vue';
import type { AllSearchData, SearchData, SearchUiData } from '../parts/SearchComponent.vue';
import { AsianZodiac, priceSortOptions, WealthSortOptions } from '@/lib/utils/common';
import store from '@/store';
import type { UserProfileSate } from '@/store/userProfile/state';
import SimTableComponent from '../parts/SimTableComponent.vue';
import SearchComponent from '../parts/SearchComponent.vue';
import { useRoute } from 'vue-router';
import SelectZodiacComponent from '../parts/SelectZodiacComponent.vue';
import { enableLoading } from '../parts/LoadingComponent.vue';
const route = useRoute()

const { getSims } = RepositoryFactory.getRepository<SimRepository>(
   SimRepository
);
const hasZodiacSelect = ref<boolean>();
const zodiacSelect = ref();
const detailPage = ref();
const searchUiData = ref<SearchUiData | null>(null);
const userProfile = computed(() => store.state.userProfile as UserProfileSate)
const isShowWealth = computed(() => userProfile.value && userProfile.value.gender > 0 && userProfile.value.birthDay.trim())

const allSimDatas = ref<Array<simItemDto>>([]);
const totalPages = ref<number>(0);

const priceOptionsRef = useTemplateRef("priceSort");
const wealthOptionsRef = useTemplateRef("wealthSort");
let cachePayLoad: any = undefined;

let dtoCache: SearchData = {
   network: null,
   price: null,
   fiveElement: null,
   allCheck: false,
   firstNumber: '0',
   textSearch: ''
};

const createDefaultRequest = () => {
   const data: IGetSimReq = {
      key_work: "",
      nha_mang_ds: null,
      gia_tien_ma_ds: null,
      ngu_hanh_ma_ds: null,
      tuoi_ma_ds: null,
      sim_loai_ma_ds: null,
      page_name: "sodep",
      page_index: 1,
      page_size: 18,
      gia_tien_sort: 0,
      cai_van_sort: 0,
      dau_so: undefined
   };
   return data;
}

const createRequestWithCache = (searchData: SearchData | null) => {
   const rq = createDefaultRequest();
   if (hasZodiacSelect.value) {
      rq.tuoi_ma_ds = zodiacSelect.value.getValue();
   }

   if (searchData && !searchData.allCheck) {
      rq.key_work = searchData.textSearch;

      if (searchData.network) {
         const value = searchData.network.toString();
         rq.nha_mang_ds = searchData.network.length > 1 ? value : value + ",";
      }

      if (searchData.price) {
         const value = searchData.price.toString();
         rq.gia_tien_ma_ds = searchData.price.length > 1 ? value : value + ",";
      }

      if (searchData.fiveElement) {
         if (hasZodiacSelect.value) {
            const value = searchData.fiveElement.toString();
            rq.ngu_hanh_ma_ds = searchData.fiveElement.length > 1 ? value : value;
         }
         else {
            const value = searchData.fiveElement.toString();
            rq.ngu_hanh_ma_ds = searchData.fiveElement.length > 1 ? value : value + ",";
         }

      }

      rq.dau_so = searchData.firstNumber;
      rq.gia_tien_sort = parseInt(priceOptionsRef.value?.value ?? '0');
      rq.cai_van_sort = parseInt(wealthOptionsRef.value?.value ?? '0');
   }
   return rq;
}

const fetchPhoneNumber = async (page: number) => {
   const rq = createRequestWithCache(dtoCache);
   rq.page_name = cachePayLoad.pageName;
   rq.page_size = cachePayLoad.pageSize;
   rq.page_index = page;
   const rp = await getSims(rq);
   allSimDatas.value = rp?.data ?? [];
   totalPages.value = rp?.data[0]?.total_page ?? 0;
}

const fetchPhoneNumberPayload = async (payload: {
   fiveElement: string | null,
   pageName: string | null,
   pageSize: number,
   mobileNetworkProvider: string | null,
   asianZodiac: string | null,
}) => {
   cachePayLoad = payload;
   const rq = createDefaultRequest();
   if (payload.asianZodiac) {
      rq.ngu_hanh_ma_ds = payload.fiveElement ? payload.fiveElement : null;
   }
   else {
      rq.ngu_hanh_ma_ds = payload.fiveElement ? payload.fiveElement + "," : null;
   }

   rq.page_name = payload.pageName;
   rq.page_size = payload.pageSize;
   rq.nha_mang_ds = payload.mobileNetworkProvider ? payload.mobileNetworkProvider + "," : null;
   rq.tuoi_ma_ds = payload.asianZodiac;
   const rp = await getSims(rq);
   allSimDatas.value = rp?.data ?? [];
   totalPages.value = rp?.data[0]?.total_page ?? 0;
}

const searchPhoneNumber = async (rq: IGetSimReq) => {
   const rp = await getSims(rq);
   allSimDatas.value = rp?.data ?? [];
   totalPages.value = rp?.data[0]?.total_page ?? 0;
}

const onIndexChange = (index: number) => {
   fetchPhoneNumber(index);
}

const onSearch = async (searchData: SearchData | null) => {
   enableLoading(true);
   const rq = createRequestWithCache(searchData);
   rq.page_name = cachePayLoad.pageName;
   rq.page_size = cachePayLoad.pageSize;
   try{
      await searchPhoneNumber(rq);
   }
   catch{

   }
   
   detailPage.value.resetToFirstPage();
   enableLoading(false);
}

const changeDataSearch = (data: AllSearchData) => {
   dtoCache = data.dto;
   onSearch(data.dto);
}

const onChangePriceOption = () => {
   onSearch(dtoCache);
}

const onChangeWealthOption = () => {

   onSearch(dtoCache);
}

const providerIndex = [
   'Viettel',
   'Vinaphone',
   'Mobifone'
]
const arrElement = [false, false, false, false, false];
const arrProvider = [false, false, false];
const emtyArr = [false, false, false, false, false, false, false];

onMounted(async () => {
   try {
      const elm = route.query.element as string;
      const index = parseInt(route.query.element as string ?? '1');
    
      if (route.query.element) {
         arrElement[index - 1] = true;
      }
      let pr = route.query.provider as string;
      if (route.query.provider) {
         arrProvider[providerIndex.indexOf(pr ?? 'Viettel')] = true;
      }
      dtoCache = {
         network: pr ? [pr] : null,
         fiveElement: elm ? [elm] : null,
         price: null,
         allCheck: false,
         firstNumber: '0',
         textSearch: ''
      }
      searchUiData.value = {
         network: arrProvider,
         fiveElement: arrElement,
         price: emtyArr,
         allCheck: false,
         select: "0",
      };

      if (route.query.zodiac) {
         hasZodiacSelect.value = true;
         zodiacSelect.value.selectZodiac(parseInt(route.query.zodiac as string));
      }
      enableLoading(true);
      await fetchPhoneNumberPayload({
         fiveElement: route.query.element as any ?? null,
         pageName: route.query.pageName as any ?? "home",
         pageSize: parseInt(route.query.pageSize as any ?? '36'),
         mobileNetworkProvider: route.query.provider as any ?? null,
         asianZodiac: route.query.zodiac as any ?? null
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      enableLoading(false);
   } catch (error) {
      enableLoading(false);
   }
})

const onchangeZodiac = async (id: number) => {
   onSearch(dtoCache);
}


</script>

<template>

   <section class="section-page section-list-search" id="so-dep-list-phone-number">
      <SelectZodiacComponent v-show="hasZodiacSelect" ref="zodiacSelect" @onChangeZodiac="onchangeZodiac" />

      <div class="container-fluid">
         <SearchComponent @onAnyButtonClick="changeDataSearch" :searchData="searchUiData" suffix-id="0" textSearch=""
            @onSearch="onSearch" />
         <div class="col-md-4">
            <div class="total-number my-3">
               <strong id="total_rows">Tổng {{ allSimDatas[0]?.total_rows ?? 0 }} số điện thoại</strong>
            </div>
         </div>
         <div style="width: 100%; overflow-x: auto;">
            <table>
               <tr>
                  <td>
                     <select ref="priceSort" class="btn btn-sm btn-light" @change="onChangePriceOption">
                        <option v-for="price in priceSortOptions" :value="price.id">{{ price.name }}</option>
                     </select>
                  </td>
                  <td><select v-if="isShowWealth" ref="wealthSort" class="btn btn-sm btn-light"
                        @change="onChangeWealthOption">
                        <option v-for="wealth in WealthSortOptions" :value="wealth.id">{{ wealth.name }}</option>
                     </select></td>
               </tr>
            </table>
         </div>

         <SimTableComponent :simDatas="allSimDatas" labelElement="Sim mệnh:"/>
         <PageComponent ref="detailPage" @onIndexChange="onIndexChange" :totalPages="totalPages" />
      </div>
   </section>
</template>