<script lang="ts" setup>
import processingIcon from '@/assets/images/icons/ic-status-processing.svg';
import orderedIcon from '@/assets/images/icons/ic-status-ordered.svg';
import successIcon from '@/assets/images/icons/ic-status-success.svg';
import cancelledIcon from '@/assets/images/icons/ic-status-cancelled.svg';
import refusedIcon from '@/assets/images/icons/ic-status-refused.svg';

import type { ISimPurchaseHistory } from '@/models/PurchaseHistory';
import { numberWithCommas } from '@/lib/utils/common';
import SimPurchaseHistoryTableListSimItem from './SimPurchaseHistoryTableListSimItem.vue';


defineProps<{ data: ISimPurchaseHistory; }>();

const statusIcon = (status: string) => {
    return {
        1: processingIcon,
        2: orderedIcon,
        3: successIcon,
        4: cancelledIcon,
        5: refusedIcon
    }[status] || processingIcon;
};

</script>

<template>
    <div class="item-history" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${data.dhs_ma}`" aria-expanded="false" aria-controls="collapse1">
        <div class="content">
            <div class="d-flex group1">
                <div class="idCode me-auto text-start"><span class="text-show-mobile">ID:</span><span id="code"></span>{{ data.dhs_ma }}</div>
                <div class="text-nowrap d-flex align-items-center justify-content-center status" id="status">
                    <img :src="statusIcon(data.dhsim_trang_thai)" width="16px" class="img-fluid" alt="hình icon">
                    <span :class="data.web_text_class">{{ data.dhsim_trang_thai_ten }}</span>
                </div>
            </div>
            <div class="number">
                <span class="text-show-mobile">Số lượng:</span><span id="number">{{ data.so_luong }}</span>
            </div>
            <div class="d-flex group2">
                <div class="money" id="">
                    {{ numberWithCommas(data.tong_tien_cuoi_cung) }}
                </div>
                <div class="time text-muted dropdown-toggle ms-auto" id="">
                    {{ data.thoi_gian_tao }}
                </div>
            </div>
        </div>
    </div>
    <div class="collapse" :id="`collapse${data.dhs_ma}`">
        <div class="card card-body history-detail">
            <div class="row my-3">
                <div class="col-lg-8">
                    <h4 class="title-box mb-2">THÔNG TIN NGƯỜI NHẬN</h4>
                    <p><span class="text-muted pe-1">Người nhận:</span><span id="nameLabel{data.dhs_ma}">{{ data.kh_ho_ten }} - {{ data.kh_mobile }}</span></p>
                    <p><span class="text-muted pe-1">Địa chỉ:</span><span id="locationLabel{data.dhs_ma}">{{ data.kh_dia_chi }}</span></p>
                    <p><span class="text-muted pe-1">Ghi chú:</span><span id="noteLabel{data.dhs_ma}">{{ data.comment }}</span></p>
                </div>
                <div class="col-lg-4">
                    <h4 class="title-box mb-2">HÌNH THƯC THANH TOÁN</h4>
                    <p>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_55943_44530)">
                                <path d="M9.9987 9.41699C9.22515 9.41699 8.48328 9.72428 7.9363 10.2713C7.38932 10.8182 7.08203 11.5601 7.08203 12.3337C7.08203 13.1072 7.38932 13.8491 7.9363 14.3961C8.48328 14.943 9.22515 15.2503 9.9987 15.2503C10.7722 15.2503 11.5141 14.943 12.0611 14.3961C12.6081 13.8491 12.9154 13.1072 12.9154 12.3337C12.9154 11.5601 12.6081 10.8182 12.0611 10.2713C11.5141 9.72428 10.7722 9.41699 9.9987 9.41699ZM8.7487 12.3337C8.7487 12.0021 8.88039 11.6842 9.11481 11.4498C9.34923 11.2154 9.66718 11.0837 9.9987 11.0837C10.3302 11.0837 10.6482 11.2154 10.8826 11.4498C11.117 11.6842 11.2487 12.0021 11.2487 12.3337C11.2487 12.6652 11.117 12.9831 10.8826 13.2175C10.6482 13.452 10.3302 13.5837 9.9987 13.5837C9.66718 13.5837 9.34923 13.452 9.11481 13.2175C8.88039 12.9831 8.7487 12.6652 8.7487 12.3337Z" fill="#6C757D" />
                                <path d="M14.605 3.263L11.9558 -0.451172L2.215 7.33049L1.675 7.32466V7.33299H1.25V17.333H18.75V7.33299H17.9483L16.3533 2.66716L14.605 3.263ZM16.1875 7.33299H7.83083L14.055 5.21133L15.3233 4.80549L16.1875 7.33299ZM12.9583 3.82466L6.53333 6.01466L11.6217 1.94966L12.9583 3.82466ZM2.91667 14.1405V10.5238C3.26825 10.3992 3.58759 10.1978 3.85141 9.93408C4.11524 9.67041 4.31689 9.35117 4.44167 8.99966H15.5583C15.6831 9.3513 15.8847 9.67067 16.1485 9.93449C16.4123 10.1983 16.7317 10.3999 17.0833 10.5247V14.1413C16.7317 14.2661 16.4123 14.4677 16.1485 14.7315C15.8847 14.9953 15.6831 15.3147 15.5583 15.6663H4.44333C4.31849 15.3144 4.11664 14.9948 3.85252 14.7308C3.5884 14.4668 3.26867 14.2651 2.91667 14.1405Z" fill="#6C757D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_55943_44530">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>{{ data.loai_thah_toan }}</span>
                    </p>
                </div>
            </div>
            <h4 class="title-box">THÔNG TIN SẢN PHẨM</h4>
            <SimPurchaseHistoryTableListSimItem v-for="detail in data.details" :detail="detail" />
            <div class="row mt-3">
                <div class="col-md-10 col-lg-6 col-xl-5 ms-auto">
                    <p class="d-flex justify-content-between"><span class="text-muted pe-1">Tạm
                            tính:</span><span id="tempoary">{{ numberWithCommas(data.tong_tien) }}</span></p>
                    <p class="d-flex justify-content-between"><span class="text-muted pe-1">Phụ
                            phí:</span><span id="surcharge">{{ numberWithCommas(data.phu_phi) }}</span></p>
                    <p class="d-flex justify-content-between"><span class="text-muted pe-1">Giảm
                            giá:</span><span id="discount">{{ numberWithCommas(data.giam_gia) }}</span></p>
                    <p class="d-flex justify-content-between"><span class="text-muted pe-1">Tổng
                            tiền:</span><span id="totalAmount">{{ numberWithCommas(data.tong_tien_cuoi_cung) }}</span></p>
                    <p class="d-flex justify-content-between"><span class="text-muted pe-1">Số tiền đã
                            thanh toán:</span><span class="text-danger fw-bold h5" id="amountPaid">{{ numberWithCommas(data.tien_da_thanh_toan) }}</span></p>
                </div>
            </div>
        </div>
    </div>
</template>