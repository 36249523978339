<style setup>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import AccountLeftPanel from '../parts/UserAccount/AccountLeftPanel.vue';
import { RepositoryFactory } from '@/lib/https';
import PacketServiceRepository from '@/lib/https/packetServiceRepository';
import PacketItem, { type ServiceItemData } from '../parts/PacketService/PacketItem.vue';
import { ref } from 'vue';
import type { DestinyAnalysisDto, DrawNumberPacketDto, IDestinyAnalysisRes, IDrawNumberPacketRes, IPerpetualCalendarPacketRes, PerpetualCalendarPacketDto } from '@/models/https/packetServiceResponse';
import Swal from 'sweetalert2';
import router from '@/router';
import { routerPaths } from '@/router/routes';

const {
    getPacketDestinyAnalysis,
    getPacketDrawNumber,
    getPacketPerpetualCalendar,
    buyPacketDestinyAnalysis,
    buyPacketDrawNumber,
    buyPacketPerpetualCalendar,
} = RepositoryFactory.getRepository<PacketServiceRepository>(PacketServiceRepository);

const styleNumberDrawMap = new Map<number, string>([
    [1, "card member-card member-card-blue"],
    [2, "card member-card member-card-red"],
    [3, "card member-card member-card-yellow"],
    [4, "card member-card member-card-cyan"],
]);

const stylePerpetualCalendarMap = new Map<number, string>([
    [1, "card member-card calendar-card-red"],
    [2, "card member-card calendar-card-red"],
    [3, "card member-card calendar-card-yellow"],
    [4, "card member-card calendar-card-red"],
]);

const styleTextDrawNumberMap = new Map<number, string>([
    [1, ""],
    [2, ""],
    [3, "text-color-black"],
    [4, ""],
]);

const styleTextPerpetualCalendarMap = new Map<number, string>([
    [1, ""],
    [2, ""],
    [3, "text-color-red"],
    [4, ""],
])

const convertDestinyAnalysic = (data: IDestinyAnalysisRes) => {
    if (data) {
        const arr: Array<ServiceItemData> = data.data.flatMap((d: DestinyAnalysisDto) => {
            const item: ServiceItemData = {
                ServiceId: d.goi_dich_vu_ma,
                SeviceName: d.goi_dich_vu_ten,
                Price: d.gia_tien,
                Expire: d.thoi_han,
                UsageCount: d.so_luot_xem,
                UsageCountName: 'Số lần xem',
                Image: d.hinh,
                CreateDate: d.create_date,
                ClassStyle: d.web_class_card,
                RealPrice: d.gia_tien_old,
                DiscountAmmount: d.discount_amount,
                FromDate: d.from_date,
                ToDate: d.to_date,
                BuyButtonName: "Nâng Cấp Ngay",
            };
            return item;
        })
        return arr;
    }
    return [];
}

const convertDrawNumberPacket = (data: IDrawNumberPacketRes) => {
    if (data) {
        const arr: Array<ServiceItemData> = data.data.flatMap((d: DrawNumberPacketDto) => {
            const item: ServiceItemData = {
                ServiceId: d.gqs_id,
                SeviceName: d.gqs_ten,
                Price: d.gqs_tien,
                Expire: d.gqs_thoi_gian,
                UsageCount: d.gqs_luot_quay,
                UsageCountName: 'Số lần quay',
                Image: '',
                CreateDate: '',
                ClassStyle: styleNumberDrawMap.get(d.gqs_id) ?? '',
                RealPrice: d.gqs_tien_old ?? -1,
                DiscountAmmount: d.discount_amount,
                FromDate: d.from_date ?? '',
                ToDate: d.to_date ?? '',
                BuyButtonName: "Mua Ngay",
            };
            return item;
        })
        return arr;
    }
    return [];
}

const convertPerpetualCalendarPacket = (data: IPerpetualCalendarPacketRes) => {
    if (data) {
        const arr: Array<ServiceItemData> = data.data.flatMap((d: PerpetualCalendarPacketDto) => {
            const item: ServiceItemData = {
                ServiceId: d.glvn_id,
                SeviceName: d.glvn_ten,
                Price: d.glvn_tien,
                Expire: d.glvn_thoi_han,
                UsageCount: -1,
                UsageCountName: 'Số lần xem',
                Image: '',
                CreateDate: '',
                ClassStyle: stylePerpetualCalendarMap.get(d.glvn_id) ?? '',
                RealPrice: d.glvn_tien_old ?? -1,
                DiscountAmmount: d.discount_amount,
                FromDate: d.from_date ?? '',
                ToDate: d.to_date ?? '',
                BuyButtonName: "Mua Ngay",
            };
            return item;
        })
        return arr;
    }
    return [];
}

const destinyAnalysic = ref<Array<ServiceItemData>>([]);
const drawNumberpacket = ref<Array<ServiceItemData>>([]);
const perpetualCalendarPacket = ref<Array<ServiceItemData>>([]);

getPacketDestinyAnalysis({ master_type: 'goidichvu' }).then(rs => {
    if (rs?.result_code === "000") {
        destinyAnalysic.value = convertDestinyAnalysic(rs);
    }
    else {
        const error: any = {
            icon: "error",
            title: rs?.result_msg,
        }
        Swal.fire(error);
    }
});

getPacketDrawNumber({ master_type: 'goi_que_so' }).then(rs => {
    if (rs?.result_code === "000") {
        drawNumberpacket.value = convertDrawNumberPacket(rs);
    }
    else {
        const error: any = {
            icon: "error",
            title: rs?.result_msg,
        }
        Swal.fire(error);
    }
});

getPacketPerpetualCalendar({ master_type: 'goilvn' }).then(rs => {
    if (rs?.result_code === "000") {
        perpetualCalendarPacket.value = convertPerpetualCalendarPacket(rs);
    }
    else {
        const error: any = {
            icon: "error",
            title: rs?.result_msg,
        }
        Swal.fire(error);
    }
});

const onBuyDestinyAnalysis = (event: {
    ServiceId: number, Price: number, Expire: number, UsageCount: number, Discount: number
}) => {
    buyPacketDestinyAnalysis({
        goi_dich_vu_ma: event.ServiceId,
        so_luot_xem: event.UsageCount,
        thoi_han: event.Expire,
        gia_tien: event.Price,
        giam_gia: event.Discount
    }).then(rs => {
        if (rs?.result_code === "000") {
            router.replace(`${routerPaths.PaymentSuccessed}/?service_type=xem_so&dhs_ma=${rs.data.dhdv_ma}&tong_tien=${event.Price}`);
        }
        else {
            const error: any = {
                icon: "error",
                title: rs?.result_msg,
            }
            Swal.fire(error);
        }
    }).catch(err => {
        const error: any = {
            icon: "error",
            title: err.message,
        }
        Swal.fire(error);
    });
}

const onBuyDrawNumberPacket = (event: {
    ServiceId: number, Price: number, Expire: number, UsageCount: number, Discount: number, RealPrice: number
}) => {
    buyPacketDrawNumber({
        gqs_id: event.ServiceId,
        gqs_thoi_gian: event.Expire,
        gqs_luot_quay: event.UsageCount,
        gqs_tien_old: event.RealPrice,
        giam_gia: event.Discount,
        gqs_tien_final: event.Price,
    }).then(rs => {
        if (rs?.result_code === "000") {
            router.replace(`${routerPaths.PaymentSuccessed}/?service_type=que_so&dhs_ma=${rs.data.dhqs_ma}&tong_tien=${event.Price}`);
        }
        else {
            const error: any = {
                icon: "error",
                title: rs?.result_msg,
            }
            Swal.fire(error);
        }
    }).catch(err => {
        const error: any = {
            icon: "error",
            title: err.message,
        }
        Swal.fire(error);
    });
}

const onBuyPerpetualCalendarPacket = (event: {
    ServiceId: number, Price: number, Expire: number, UsageCount: number, Discount: number, RealPrice: number
}) => {
    buyPacketPerpetualCalendar({
        glvn_id: event.ServiceId,
        glvn_thoi_han: event.Expire,
        glvn_tien_old: event.RealPrice < 0 ? null : event.RealPrice,
        giam_gia: event.Discount,
        glvn_tien_final: event.Price,
    }).then(rs => {
        if (rs?.result_code === "000") {
            router.replace(`${routerPaths.PaymentSuccessed}/?service_type=lvn&dhs_ma=${rs.data.dh_lvn_id}&tong_tien=${event.Price}`);
        }
        else {
            const error: any = {
                icon: "error",
                title: rs?.result_msg,
            }
            Swal.fire(error);
        }
    }).catch(err => {
        const error: any = {
            icon: "error",
            title: err.message,
        }
        Swal.fire(error);
    });
}

</script>

<template>
    <section class="section-page section-page-mobile section-page-account bg-light" id="account-section">
        <div class="container-fluid container-scroll">
            <div class="row">
                <div class="col-4 d-none d-lg-block block">
                    <AccountLeftPanel />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                    <h3 class=" title-perpetual orange text-center">Danh sách các gói dịch vụ</h3>
                    <br />
                    <h2 class="title-page d-block mb-3 text-center">GÓI XEM MỆNH LÝ</h2>
                    <div class="row justify-content-center mb-3">
                        <div class="col-xxl-8">
                            <div class="service-guide gap-3">
                                <div class="d-inline-flex align-items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM17.9101 8.5H16.3543C16.3013 8.5 16.2504 8.52107 16.2129 8.55857L10.6961 14.0746L8.17958 11.5582C8.14207 11.5207 8.0912 11.4996 8.03816 11.4996H6.4827C6.42967 11.4996 6.37881 11.5207 6.34131 11.5582C6.26319 11.6363 6.26316 11.7629 6.34125 11.841C6.42917 11.929 6.5171 12.0169 6.60502 12.1049C6.62887 12.1288 6.65325 12.1532 6.67813 12.1781L6.83305 12.3334C7.15375 12.655 7.53643 13.0404 7.92601 13.4333L8.21862 13.7286C9.12764 14.6461 9.99919 15.5287 10.1333 15.6643L10.1505 15.6816C10.4434 15.9745 10.9183 15.9745 11.2112 15.6816L18.0516 8.84142C18.0891 8.80392 18.1101 8.75304 18.1101 8.7C18.1101 8.58954 18.0206 8.5 17.9101 8.5Z"
                                            fill="#C61914" />
                                    </svg>
                                    <span class="ps-2 ">Xem phong thủy sim</span>
                                </div>
                                <div class="d-inline-flex align-items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM17.9101 8.5H16.3543C16.3013 8.5 16.2504 8.52107 16.2129 8.55857L10.6961 14.0746L8.17958 11.5582C8.14207 11.5207 8.0912 11.4996 8.03816 11.4996H6.4827C6.42967 11.4996 6.37881 11.5207 6.34131 11.5582C6.26319 11.6363 6.26316 11.7629 6.34125 11.841C6.42917 11.929 6.5171 12.0169 6.60502 12.1049C6.62887 12.1288 6.65325 12.1532 6.67813 12.1781L6.83305 12.3334C7.15375 12.655 7.53643 13.0404 7.92601 13.4333L8.21862 13.7286C9.12764 14.6461 9.99919 15.5287 10.1333 15.6643L10.1505 15.6816C10.4434 15.9745 10.9183 15.9745 11.2112 15.6816L18.0516 8.84142C18.0891 8.80392 18.1101 8.75304 18.1101 8.7C18.1101 8.58954 18.0206 8.5 17.9101 8.5Z"
                                            fill="#C61914" />
                                    </svg>
                                    <span class="ps-2 ">Xem sim hợp tuổi</span>
                                </div>
                                <div class="d-inline-flex align-items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM17.9101 8.5H16.3543C16.3013 8.5 16.2504 8.52107 16.2129 8.55857L10.6961 14.0746L8.17958 11.5582C8.14207 11.5207 8.0912 11.4996 8.03816 11.4996H6.4827C6.42967 11.4996 6.37881 11.5207 6.34131 11.5582C6.26319 11.6363 6.26316 11.7629 6.34125 11.841C6.42917 11.929 6.5171 12.0169 6.60502 12.1049C6.62887 12.1288 6.65325 12.1532 6.67813 12.1781L6.83305 12.3334C7.15375 12.655 7.53643 13.0404 7.92601 13.4333L8.21862 13.7286C9.12764 14.6461 9.99919 15.5287 10.1333 15.6643L10.1505 15.6816C10.4434 15.9745 10.9183 15.9745 11.2112 15.6816L18.0516 8.84142C18.0891 8.80392 18.1101 8.75304 18.1101 8.7C18.1101 8.58954 18.0206 8.5 17.9101 8.5Z"
                                            fill="#C61914" />
                                    </svg>
                                    <span class="ps-2 ">Xem sim hợp mệnh</span>
                                </div>
                                <div class="d-inline-flex align-items-center">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1ZM17.9101 8.5H16.3543C16.3013 8.5 16.2504 8.52107 16.2129 8.55857L10.6961 14.0746L8.17958 11.5582C8.14207 11.5207 8.0912 11.4996 8.03816 11.4996H6.4827C6.42967 11.4996 6.37881 11.5207 6.34131 11.5582C6.26319 11.6363 6.26316 11.7629 6.34125 11.841C6.42917 11.929 6.5171 12.0169 6.60502 12.1049C6.62887 12.1288 6.65325 12.1532 6.67813 12.1781L6.83305 12.3334C7.15375 12.655 7.53643 13.0404 7.92601 13.4333L8.21862 13.7286C9.12764 14.6461 9.99919 15.5287 10.1333 15.6643L10.1505 15.6816C10.4434 15.9745 10.9183 15.9745 11.2112 15.6816L18.0516 8.84142C18.0891 8.80392 18.1101 8.75304 18.1101 8.7C18.1101 8.58954 18.0206 8.5 17.9101 8.5Z"
                                            fill="#C61914" />
                                    </svg>
                                    <span class="ps-2 ">Sim hợp ngày/tháng/năm sinh, nơi sinh, nơi ở</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <PacketItem v-for="it in destinyAnalysic" :item-data="it" text-style=""
                            @on-buy-click="onBuyDestinyAnalysis" />
                    </div>

                    <br />
                    <h2 class="title-page d-block mb-3 text-center">GÓI GIEO QUẺ DỊCH LÝ</h2>
                    <div class="row">
                        <PacketItem v-for="it in drawNumberpacket" :item-data="it"
                            :text-style="styleTextDrawNumberMap.get(it.ServiceId) ?? ''"
                            @onBuyClick="onBuyDrawNumberPacket" />
                    </div>

                    <br />
                    <h2 class="title-page d-block mb-3 text-center">GÓI XEM LỊCH VẠN NIÊN</h2>
                    <div class="row">
                        <PacketItem v-for="it in perpetualCalendarPacket" :item-data="it"
                            :text-style="styleTextPerpetualCalendarMap.get(it.ServiceId) ?? ''" @onBuyClick="onBuyPerpetualCalendarPacket" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>