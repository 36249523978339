<style setup>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import {getPassAndFeatureYears} from '@/lib/utils/common';
import { computed, ref } from 'vue';
import DateCalendarItem, { type DateItemData } from './DateCalendarItem.vue';

const years = computed(() => getPassAndFeatureYears());
const currentSelect = ref<string>('default');
defineProps<{
   dateItems: Array<DateItemData>,
   title: string,
}>();

const emits = defineEmits<{
   (event: "onItemClick", date:string):void
}>();

const onItemClick = (date: string) =>{
   currentSelect.value = date;
   emits("onItemClick", date);
}


</script>

<template>
   <div class="d-flex justify-content-center mt-3 mt-lg-5">
      <div class="calendar" id="option_2">
         <div class="calendar-header">
            <h2 class="title-calendar mb-4 mb-lg-4" id="title_calendar">{{ title }}</h2>
            <div class="options">
               <select class="form-select" id="choose_month" :value="new Date().getMonth() + 1" onchange="getCalendar(0)">
                  <option value="0" selected>Tháng</option>
                  <option value="1">Tháng 1</option>
                  <option value="2">Tháng 2</option>
                  <option value="3">Tháng 3</option>
                  <option value="4">Tháng 4</option>
                  <option value="5">Tháng 5</option>
                  <option value="6">Tháng 6</option>
                  <option value="7">Tháng 7</option>
                  <option value="8">Tháng 8</option>
                  <option value="9">Tháng 9</option>
                  <option value="10">Tháng 10</option>
                  <option value="11">Tháng 11</option>
                  <option value="12">Tháng 12</option>
               </select>
               <select class="form-select" id="choose_year" :value="new Date().getFullYear()" onchange="getCalendar(0)">
                  <option value="0" selected>Năm</option>
                  <option v-for="year in years" :year="0" selected>{{ year }}</option>
               </select>
               <select class="form-select" id="choose_linhvuc" onchange="getCalendar(0)">
                  <option value="0" selected>Lĩnh vực</option>
                  <option value="1">Tiền tài</option>
                  <option value="2">Công danh</option>
                  <option value="3">Sức khoẻ</option>
                  <option value="4">Hôn nhân</option>
               </select>
               <select class="form-select" id="choose_day_hd" onchange="getCalendar(0)">
                  <option value="0" selected>Hoàng Đạo</option>
                  <option value="1">3 ngày tốt nhất</option>
                  <option value="2">5 ngày tốt nhất</option>
                  <option value="3">7 ngày tốt nhất</option>
                  <option value="4">3 ngày xấu nhất</option>
                  <option value="5">5 ngày xấu nhất</option>
                  <option value="6">7 ngày xấu nhất</option>
               </select>
               <select class="form-select" style="display: none;" id="choose_day_good_bad" onchange="getCalendar(0)">
                  <option value="0" selected>Ngày Tốt/Xấu</option>
                  <option value="1">Ngày tốt</option>
                  <option value="2">Ngày xấu</option>
               </select>
            </div>
         </div>
         <div class="calendar-content" id="table_calendar">
            <div class="table-calendar">
               <div class="cell">
                  <p class="week">Thứ Hai</p>
               </div>
               <div class="cell">
                  <p class="week">Thứ Ba</p>
               </div>
               <div class="cell">
                  <p class="week">Thứ Tư</p>
               </div>
               <div class="cell">
                  <p class="week">Thứ Năm</p>
               </div>
               <div class="cell">
                  <p class="week">Thứ Sáu</p>
               </div>
               <div class="cell">
                  <p class="week">Thứ Bảy</p>
               </div>
               <div class="cell">
                  <p class="week">Chủ Nhật</p>
               </div>

               <DateCalendarItem :is-select="currentSelect === 'default' ? (l.HightLightDate === 1) : l.SolarDateSort.startsWith(currentSelect)" v-for="l in dateItems" :date-data="l" @on-item-click="onItemClick"/>
            </div>
           
         </div>
      </div>
   </div>
</template>