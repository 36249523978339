import { commitProfileValues } from "@/store/userProfile";
import { commitCitys, commitGender } from "@/store/genderAndCity";
import { apiCitiesRouter } from "./apiRouter";

class InterceptorEvents {
  private listeners: Array<(data: any) => void> = [];

  addListener(callback: (data: string) => void) {
      this.listeners.push(callback);
  }

  removeListener(callback: (data: string) => void) {
      this.listeners = this.listeners.filter(listener => listener !== callback);
  }

  invoke(data: any) {
      this.listeners.forEach(listener => listener(data));
  }
}

export const interceptorEvents = new InterceptorEvents();

const commitRemainingViewChart = (response:any) => {
  const data = response.data;
  if (data) {
    const values: any = {};
    if(data.so_luot_quay_con_lai)
    {
      values.totalRemainingNumberDraw = data.so_luot_quay_con_lai;
    }
    
    if( data.so_luot_xem_con_lai)
    {
      values.totalRemainingChartView = data.so_luot_xem_con_lai;
    }

    commitProfileValues(values);
  }
}

const commitCities = (response:any) => {
  const data = response.data;
  const config = response.config;
  if (data && data.result_code === "000") {
    const url:string = config.url as string;
    if(url.includes(apiCitiesRouter.getCitiesUrl))
    {
        commitCitys(data.data);
    }
  }
}
// 
const commitGenders = (response:any) => {
  const data = response.data;
  const config = response.config;
  if (data && data.result_code === "000") {
    const url:string = config.url as string;
    if(url.includes(apiCitiesRouter.getGenderUrl))
    {
      commitGender(data.data);
    }
  }
}



interceptorEvents.addListener(commitRemainingViewChart);
interceptorEvents.addListener(commitCities);
interceptorEvents.addListener(commitGenders);


