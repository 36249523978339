import Repository from "@/lib/https/repository"
import { apiAccountRouter } from "../utils/apiRouter";


export default class AccountRepository extends Repository {
  sendOTP = async (mobile: string) => {
    return await this.axios.post(`${apiAccountRouter.getOtpUrl}?mobile=${mobile}`, { mobile })
      .then(({ data }) => data)
      .catch(err => {
        return err;
      })

  }
  confirmOTP = async (mobile: string, otp: string) => {
    return await this.axios.post(`${apiAccountRouter.confirmOtpUrl}?mobile=${mobile}&otp=${otp}`, { mobile, otp })
      .then(({ data }) => data)
      .catch(err => {
        return err;
      })
  }
  updateAccountProfile = async (profile: any) => {
    return this.axios.post(apiAccountRouter.accountUpdateProfileUrl, profile)
      .then(({ data }) => data)
      .catch(err => {
        return err;
      })
  }
}
