<style setup>
@import "@/assets/vendors/bootstrap/css/bootstrap.min.css";
@import "https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css";
@import "@/assets/vendors/animate/animate.css";
@import "@/assets/css/style.css";
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import SimRepository from "@/lib/https/simRepository"
import RepositoryFactory from "@/lib/https/repositoryFactory"
import { computed, onMounted, ref, useTemplateRef } from "vue"
import { useRouter } from "vue-router"
import SimComponent, { type SimData } from "../parts/SimComponent.vue"
import SimTableComponent from "../parts/SimTableComponent.vue"
import SimTableComponentSlider from "../parts/SimTableComponentSlider.vue"
import PageComponent from "../parts/PageComponent.vue"
import type { IGetSimReq } from "@/models/https/simRequests"
import type { IGetSimRes, simItemDto } from "@/models/https/simResponse"
import { enableLoading } from '../parts/LoadingComponent.vue';
import SimTableHeaderComponent from "../parts/SimTableHeaderComponent.vue"
import SearchComponent, { type SearchUiData, type SearchData, type AllSearchData } from "../parts/SearchComponent.vue"
import store from "@/store"
import type { UserProfileSate } from "@/store/userProfile/state"
import { priceSortOptions, WealthSortOptions } from "@/lib/utils/common"
import { routerPaths } from "@/router/routes"
import { mobileNetworkIcons } from "@/lib/utils/imageUtils"
//images


const { getSims } = RepositoryFactory.getRepository<SimRepository>(
   SimRepository
).withRouter(useRouter());

const searchCopy = useTemplateRef("searchCopy");
const homePage = ref();

const allSimDatas = ref<Array<simItemDto>>([]);
const totalPages = ref<number>(0);

const vinaSimDatas = ref<Array<simItemDto>>([]);

const viettelSimDatas = ref<Array<simItemDto>>([]);

const mobiSimDatas = ref<Array<simItemDto>>([]);
const search1 = ref<SearchUiData | null>(null);
const search2 = ref<SearchUiData | null>(null);
const priceOptionsRef = useTemplateRef("priceSort");
const wealthOptionsRef = useTemplateRef("wealthSort");

const userProfile = computed(() => store.state.userProfile as UserProfileSate)

const isShowWealth = computed(() => userProfile.value && userProfile.value.gender > 0 && userProfile.value.birthDay.trim())


let dtoCache:SearchData = {
   network: null,
   price: null,
   fiveElement: null,
   allCheck: false,
   firstNumber: '0',
   textSearch: ''
};


const createDefaultRequest = () => {
   const data: IGetSimReq = {
      key_work: "",
      nha_mang_ds: null,
      gia_tien_ma_ds: null,
      ngu_hanh_ma_ds: null,
      tuoi_ma_ds: null,
      sim_loai_ma_ds: null,
      page_name: "trangchu",
      page_index: 1,
      page_size: 18,
      gia_tien_sort: 0,
      cai_van_sort: 0,
      dau_so: "0"
   };
   return data;
}

const createRequestWithCache = (searchData: SearchData | null) =>{
   const rq = createDefaultRequest();
   if (searchData && !searchData.allCheck) {
      rq.key_work = searchData.textSearch;

      if (searchData.network) {
         const value = searchData.network.toString();
         rq.nha_mang_ds = searchData.network.length > 1 ? value : value + ",";
      }

      if(searchData.price)
      {
         const value = searchData.price.toString();
         rq.gia_tien_ma_ds = searchData.price.length > 1 ? value : value + ",";
      } 

      if(searchData.fiveElement)
      {
         const value = searchData.fiveElement.toString();
         rq.ngu_hanh_ma_ds =  searchData.fiveElement.length > 1 ? value : value + ",";
      }      
       
      rq.dau_so = searchData.firstNumber;
      rq.gia_tien_sort = parseInt(priceOptionsRef.value?.value ?? '0');
      rq.cai_van_sort = parseInt(wealthOptionsRef.value?.value ?? '0');
   }
   return rq;
}

const changeDataSearch1 = (data: AllSearchData) => {
   search2.value = data.ui;
   dtoCache = data.dto;
   onSearch(data.dto);
}

const changeDataSearch2 = (data: AllSearchData) => {
   search1.value = data.ui;
   dtoCache = data.dto;
   onSearch(data.dto);
}

onMounted(async () => {
   try {
      const rq = createDefaultRequest();
      const rp = await getSims(rq);
      allSimDatas.value = rp?.data ?? [];
      totalPages.value = rp?.data[0]?.total_page ?? 0;

      rq.nha_mang_ds = "Vinaphone";
      const rpVina = await getSims(rq);
      vinaSimDatas.value = rpVina?.data ?? [];

      rq.nha_mang_ds = "Viettel";
      const rpViettel = await getSims(rq);
      viettelSimDatas.value = rpViettel?.data ?? [];

      rq.nha_mang_ds = "Mobifone";
      const rpMobi = await getSims(rq);
      mobiSimDatas.value = rpMobi?.data ?? [];
   } catch (error) {

   }
})


const getSimUI = async (data: IGetSimReq) => {
   try {
      enableLoading(true);
      const rp = await getSims(data);
      allSimDatas.value = rp?.data ?? [];
      totalPages.value = rp?.data[0]?.total_page ?? 0;
      enableLoading(false);
   } catch (error) {
      enableLoading(false);
   }
}

const onIndexChange = (index: number) => {
   const rq = createRequestWithCache(dtoCache);
   rq.page_index = index;
   getSimUI(rq);
}

const searchText = ref<string>("");
const searchCopyText = ref<string>("");

const onSearch = async (searchData: SearchData | null) => {

   const rq = createRequestWithCache(searchData);   

   await getSimUI(rq);
   homePage.value.resetToFirstPage();
   searchCopy.value?.$el.scrollIntoView({ behavior: "smooth" })
}


const onTextSearchChange = (txt: string) => {
   searchCopyText.value = txt;
}

const onTextSearchCopyChange = (txt: string) => {
   searchText.value = txt;
}

const onChangePriceOption = ()=> {
   onSearch(dtoCache);
}

const onChangeWealthOption = ()=> {

   onSearch(dtoCache);
}

</script>


<script lang="ts" scope>

</script>
<template>

   <section class="section-page section-list-search pb-0">
      <div class="container-fluid">
         <SearchComponent @onAnyButtonClick="changeDataSearch1" :searchData="search1" suffix-id=""
            @onInputSearch="onTextSearchChange" :textSearch="searchText" @onSearch="onSearch" />
      </div>
   </section>

   <section class="hero-banner">
      <div class="container-fluid">
         <div class="content-block">
            <div class="row justify-content-between">
               <div class="col-12 col-md-5 col-lg-4 text-center pe-md-0">
                  <div
                     class="content-left d-flex align-items-center justify-content-center flex-column py-5 py-md-3 h-100">
                     <div class="position-relative">
                        <h1 class="title-group-name h1">
                           <span class="text1">LỤC TRỤ <br> MỆNH LÝ</span>
                           <span class="text2">LỤC TRỤ <br> MỆNH LÝ</span>
                           <span class="text3">LỤC TRỤ <br> MỆNH LÝ</span>
                        </h1>
                        <br>
                        <RouterLink :to="routerPaths.News"
                           class="btn btn-lg btn-light my-3 min-w262 rounded-pill font-weight-bold wow zoomIn delay-2s">Tìm
                           hiểu thêm</RouterLink>
                        <p>Khuyến mãi trong hôm nay*</p>
                     </div>
                     <div class="line"></div>
                  </div>
               </div>
               <div class="col-12 col-md-7 col-lg-8 ps-md-0">
                  <div class="group-link p-4 pb-2 h-100">
                     <div class="row">
                        <div class="col-sm-6 mb-3">
                           <RouterLink class="item-link" :to="routerPaths.LifeEnhancementNumber">
                              <img src="@/assets/images/hero1.png" class="img-fluid" alt="Thay số cải vận">
                           </RouterLink>
                        </div>
                        <div class="col-sm-6 mb-3">
                           <RouterLink class="item-link" :to="routerPaths.NameMeaning">
                              <img src="@/assets/images/hero2.png" class="img-fluid" alt="Ý nghĩa họ tên">
                           </RouterLink>
                        </div>
                        <div class="col-sm-6 mb-3">
                           <RouterLink class="item-link" to="tin-tuc/">
                              <img src="@/assets/images/hero3.png" class="img-fluid" alt="Lịch cải vận">
                           </RouterLink>
                        </div>
                        <div class="col-sm-6 mb-3">
                           <RouterLink class="item-link" to="tin-tuc/">
                              <img src="@/assets/images/hero4.png" class="img-fluid" alt="Xem mệnh lý">
                           </RouterLink>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
   <section class="section-page section-feng-shui">
      <div class="container-fluid">
         <h2 class="title-section text-center">CẢI VẬN THEO SỐ NHƯ Ý</h2>
         <div class="row align-items-center mt-3">
            <div class="col-12 col-lg-7 col-xl-9 pe-0">
               <div>
                  <img src="@/assets/images/banner-phong-thuy.png" class="w-100 img-banner" alt="banner" />
               </div>
            </div>
            <div class="col-12 col-lg-5 col-xl-3 text-center">
               <div class="group-life">
                  <RouterLink class="item-life" :to="`${routerPaths.DetailPhoneNumber}/?element=1&pageName=sophongthuy&pageSize=36`">
                     <img src="@/assets/images/img-kim.png" class="figure-img" alt="hình">
                     <p class="figure-caption">Kim</p>
                  </RouterLink>
                  <RouterLink class="item-life" :to="`${routerPaths.DetailPhoneNumber}/?element=2&pageName=sophongthuy&pageSize=36`">
                     <img src="@/assets/images/img-moc.png" class="figure-img" alt="hình">
                     <p class="figure-caption">Mộc</p>
                  </RouterLink>
                  <RouterLink class="item-life" :to="`${routerPaths.DetailPhoneNumber}/?element=3&pageName=sophongthuy&pageSize=36`">
                     <img src="@/assets/images/img-thuy.png" class="figure-img" alt="hình">
                     <p class="figure-caption">Thủy</p>
                  </RouterLink>
                  <RouterLink class="item-life" :to="`${routerPaths.DetailPhoneNumber}/?element=4&pageName=sophongthuy&pageSize=36`">
                     <img src="@/assets/images/img-hoa.png" class="figure-img" alt="hình">
                     <p class="figure-caption">Hỏa</p>
                  </RouterLink>
                  <RouterLink class="item-life" :to="`${routerPaths.DetailPhoneNumber}/?element=5&pageName=sophongthuy&pageSize=36`">
                     <img src="@/assets/images/img-tho.png" class="figure-img" alt="hình">
                     <p class="figure-caption">Thổ</p>
                  </RouterLink>
               </div>
               <RouterLink :to="routerPaths.FengShuiPhoneNumber"
                  class="btn btn-lg btn-danger my-3 min-w262 rounded-pill font-weight-bold wow zoomIn delay-2s">Tìm
                  hiểu thêm</RouterLink>
            </div>
         </div>
      </div>
   </section>

   <section class="section-page section-list-search pb-0">
      <div class="container-fluid">
         <h2 class="title-section mb-3" id="h_kho_sim">Kho Sim của Thay Số cải vận</h2>
         <SearchComponent @onAnyButtonClick="changeDataSearch2" :searchData="search2" suffix-id="0"
            @onInputSearch="onTextSearchCopyChange" :textSearch="searchCopyText" ref="searchCopy"
            @onSearch="onSearch" />
         <div class="col-md-4">
            <div class="total-number my-3">
               <strong id="total_rows">Tổng {{ allSimDatas[0]?.total_rows ?? 0 }} số điện thoại</strong>
            </div>
         </div>
         <div style="width: 100%; overflow-x: auto;">
            <table>
               <tr>
                  <td>
                     <select  ref="priceSort" class="btn btn-sm btn-light" @change="onChangePriceOption">
                        <option v-for="price in priceSortOptions" :value="price.id">{{price.name}}</option>
                     </select>
                  </td>
                  <td><select v-if="isShowWealth" ref="wealthSort" class="btn btn-sm btn-light" @change="onChangeWealthOption">
                     <option v-for="wealth in WealthSortOptions" :value="wealth.id">{{wealth.name}}</option>
                     </select></td>
               </tr>
            </table>
         </div>

         <SimTableComponent :simDatas="allSimDatas" labelElement="Người mệnh:"/>
         <PageComponent ref="homePage" @onIndexChange="onIndexChange" :totalPages="totalPages" />
      </div>
   </section>

   <section class="section-page section-to-network pt-4" id="home-list-phone-number">

      <SimTableHeaderComponent titleGroup="title-group vina" :imageSrc="mobileNetworkIcons.vinaIcon"
         link="so-theo-nha-mang/chi-tiet/?nha_mang=Vinaphone" titleText="Số Vinaphone" />
      <SimTableComponentSlider :simDatas="vinaSimDatas" label-element="Người mệnh:"/>

      <SimTableHeaderComponent titleGroup="title-group viettel" :imageSrc="mobileNetworkIcons.viettelIcon"
         link="so-theo-nha-mang/chi-tiet/?nha_mang=Viettel" titleText="Số Viettel" />
      <SimTableComponentSlider :simDatas="viettelSimDatas" label-element="Người mệnh:"/>

      <SimTableHeaderComponent titleGroup="title-group mobi" :imageSrc="mobileNetworkIcons.mobiIcon"
         link="so-theo-nha-mang/chi-tiet/?nha_mang=Mobifone" titleText="Số Mobi" />
      <SimTableComponentSlider :simDatas="mobiSimDatas" label-element="Người mệnh:"/>
   </section>

   <!-- <ul class="nav-bottom nav nav-pills nav-justified shadow border-top">
      <li class="nav-item">
         <a href="/">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
                  stroke="#49454F" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p class="name">Trang chủ</p>
         </a>
      </li>
      <li class="nav-item">
         <RouterLink :to="routerPaths.PurchaseHistory">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M19 2C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5C2 3.34315 3.34315 2 5 2H19ZM19 3.5H5C4.20116 3.5 3.54817 4.12446 3.50255 4.91186L3.5 5V19C3.5 19.7988 4.12446 20.4518 4.91186 20.4975L5 20.5H19C19.7988 20.5 20.4518 19.8755 20.4975 19.0881L20.5 19V5C20.5 4.20116 19.8755 3.54817 19.0881 3.50255L19 3.5ZM11.8 14.5C11.9105 14.5 12 14.5895 12 14.7V15.8C12 15.9105 11.9105 16 11.8 16H7.2C7.08954 16 7 15.9105 7 15.8V14.7C7 14.5895 7.08954 14.5 7.2 14.5H11.8ZM16.8 11.5C16.9105 11.5 17 11.5895 17 11.7V12.8C17 12.9105 16.9105 13 16.8 13H7.2C7.08954 13 7 12.9105 7 12.8V11.7C7 11.5895 7.08954 11.5 7.2 11.5H16.8ZM16.8 8.5C16.9105 8.5 17 8.58954 17 8.7V9.8C17 9.91046 16.9105 10 16.8 10H7.2C7.08954 10 7 9.91046 7 9.8V8.7C7 8.58954 7.08954 8.5 7.2 8.5H16.8Z"
                  fill="#49454F" />
            </svg>
            <p class="name">Lich sử</p>
         </RouterLink>
      </li>
      <li class="nav-item">
         <a onclick="gotoCarts()" class="position-relative">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M18.875 2C20.5319 2 21.875 3.34315 21.875 5V19C21.875 20.6569 20.5319 22 18.875 22H5.875C4.21815 22 2.875 20.6569 2.875 19V5C2.875 3.34315 4.21815 2 5.875 2H18.875ZM20.375 18H4.375V19C4.375 19.7988 4.99946 20.4518 5.78686 20.4975L5.875 20.5H18.875C19.6738 20.5 20.3268 19.8755 20.3725 19.0881L20.375 19V18ZM18.875 3.5H5.875C5.07616 3.5 4.42317 4.12446 4.37755 4.91186L4.375 5V16.5H20.375V5C20.375 4.20116 19.7505 3.54817 18.9631 3.50255L18.875 3.5ZM10.0667 6C10.1675 6 10.25 6.0825 10.25 6.18333L10.25 7.37499C10.25 8.60178 11.2163 9.61104 12.4421 9.66437L12.5417 9.66666C13.7686 9.66659 14.7778 8.70022 14.8311 7.47446L14.8334 7.375V6.18333C14.8334 6.0825 14.9159 6 15.0167 6H16.025C16.1259 6 16.2084 6.0825 16.2084 6.18333V7.375C16.2084 9.40005 14.5667 11.0417 12.5417 11.0417C10.5166 11.0417 8.875 9.40006 8.875 7.375V6.18333C8.875 6.0825 8.9575 6 9.05833 6H10.0667Z"
                  fill="#6C757D" />
            </svg>
            <span class="position-absolute translate-middle badge rounded-pill bg-danger" id="id_counter_res">3</span>
            <p class="name">Giỏ hàng</p>
         </a>
      </li>
      <li class="nav-item">
         <a href="tai-khoan/">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M12.25 2C15.0056 2 17.2395 4.23388 17.2395 6.9895V9.0105C17.2395 10.8572 16.2362 12.4696 14.745 13.3324L14.7448 13.5L20.2325 16.4329C21.4383 17.0774 22.1302 18.3911 21.9796 19.75C21.8375 21.0309 20.755 22 19.4663 22H5.03372C3.74501 22 2.66247 21.0309 2.52045 19.75C2.36978 18.3911 3.06169 17.0774 4.26751 16.4329L9.75524 13.5L9.75502 13.3324C8.26377 12.4696 7.26049 10.8572 7.26049 9.0105V6.9895C7.26049 4.23388 9.49437 2 12.25 2ZM12.25 3.5C10.3629 3.5 8.82574 4.99789 8.76251 6.86954L8.76049 6.9895V9.0105C8.76049 10.2264 9.38643 11.3318 10.3928 11.9655L10.5062 12.034L11.1542 12.4089C11.2159 12.4446 11.2539 12.5104 11.254 12.5817L11.2563 14.2784C11.2564 14.3523 11.2157 14.4202 11.1506 14.4551L4.97455 17.7559C4.30973 18.1112 3.92824 18.8355 4.01132 19.5847C4.0662 20.0797 4.46639 20.4603 4.95575 20.4971L5.03372 20.5H19.4663C19.9905 20.5 20.4309 20.1057 20.4887 19.5847C20.568 18.8695 20.224 18.1771 19.6143 17.8066L19.5255 17.7559L13.3494 14.4551C13.2843 14.4202 13.2436 14.3523 13.2437 14.2784L13.246 12.5817C13.2461 12.5104 13.2841 12.4446 13.3458 12.4089L13.9938 12.034C15.028 11.4357 15.6909 10.3523 15.7369 9.14512L15.7395 9.0105V6.9895C15.7395 5.0623 14.1772 3.5 12.25 3.5ZM21.8 11.5C21.9105 11.5 22 11.5895 22 11.7V12.8C22 12.9105 21.9105 13 21.8 13H20.2C20.0895 13 20 12.9105 20 12.8V11.7C20 11.5895 20.0895 11.5 20.2 11.5H21.8ZM21.8 8.5C21.9105 8.5 22 8.58954 22 8.7V9.8C22 9.91046 21.9105 10 21.8 10H18.7C18.5895 10 18.5 9.91046 18.5 9.8V8.7C18.5 8.58954 18.5895 8.5 18.7 8.5H21.8ZM21.8 5.5C21.9105 5.5 22 5.58954 22 5.7V6.8C22 6.91046 21.9105 7 21.8 7H18.7C18.5895 7 18.5 6.91046 18.5 6.8V5.7C18.5 5.58954 18.5895 5.5 18.7 5.5H21.8Z"
                  fill="#49454F" />
            </svg>
            <p class="name">Tài khoản</p>
         </a>
      </li>
   </ul>
   <a href="#" class="scrollup">
      <img src="@/assets/images/icons/ic-arrow-up.svg" width="20px" alt="icon arrow to top" />
   </a>
   <div class="info"></div> -->
</template>
