import Repository from "@/lib/https/repository"
import type { AxiosResponse } from "axios"
import { buildUrlWithQueryOptions } from "../utils/stringUtils"
import type { ICreateOrderData, ICreateOrderParam } from "@/models/https/paymentRequests"
import type { ICreateOrderRes } from "@/models/https/paymentResponse"

export const createOrderUrl = "/api/donhang/buy_sim"

export default class PaymentRepository extends Repository {
  createOrder = async (param:ICreateOrderParam, body: Array<ICreateOrderData>) => {
    const url = buildUrlWithQueryOptions(createOrderUrl, param)
    const response = await this.axios.post<ICreateOrderRes>(url, body)
    if (response.data) return response.data;
    return null
  } 
}
