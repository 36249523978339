export default {
  authorizedClient: "Authorized Client",
  registerAuthorizedClient: "Registering an Authorized Client",
  authorizedClientName: "Authorized Client Name",
  authorizedClientId: "Authorization Client ID",
  scope: "Scope",
  clientId: "Client ID",
  authorizedClientDetails: "Authorized Client Details",
  authorizationClientPublicKey: "Authorization Client Public Key",
  tokenAuthenticationMethod: "Token authentication method",
  authorizationClientIcon: "Authorization Client Icon",
  authorizedClientTosURL: "Authorized Client Terms of Use URL",
  authorizedClientPolicy: "Authorized Client Privacy Policy",
  organizationIcon: "Organization Icon",
  authClientRegisterTitle: "Authorized Client Registration",
  register: "Register",
  deleteAuthClient: "Authorized Client Deletion",
  deleteAuthClientDes: "Do you want to delete this authorized client?",
  completeRegister: "Authorized client registration completed",
  completedUpdate: "Authorized client update completed",
  completedDelete: "Authorized client has been deleted",
  authClientEmpty: "The authorization client does not exist.",
  updateAuthClient: "Update Auth Client",
  exportAuthClient: "Export Auth Client",
  numberProtalUrl: "Public personal authentication redirect URL for My Number Portal",
  numberProtalUrlMobile:
    "Public personal authentication redirect URL for My Number Portal for Mobile",
  serialUrl: "Public personal authentication redirect URL for serial",
  serialMobileUrl: "Public personal authentication redirect URL for serial for Mobile",
  redirectUrl: "Redirect URL",
  allowCORS: "CORS to be allowed",
  messageAPI: "SmartPOST Message API target municipalities",
  addAuthClient: "Add auth client",
  environment: "Environment",
  production: "Production",
  dev: "Dev",
  devStable: "DevStable",
  uat: "UAT",
}
