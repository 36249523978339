import Repository from "@/lib/https/repository"
import { buildUrlWithQueryOptions } from "../utils/stringUtils"
import type { INameAccordingToCriteriaReq, INameMeaningParam } from "@/models/https/nameMeaningRequests"
import type { INameAccordingToCriteriaRes, INameMeaningRes } from "@/models/https/nameMeaningResponse"

export const getNameMeaningUrl = "/api/trang_chu/chart_detail_by_ten_by_cus"
export const getNameAccordingToCriterialUrl = "/api/ho_ten/ten_tieu_chi"


export default class NameMeaningRepository extends Repository {

  getNameMeaning =  async (data:INameMeaningParam) => {
    const url = buildUrlWithQueryOptions(getNameMeaningUrl, data)
    const response = await this.axios.post<INameMeaningRes>(url, {});
    if (response.data) return response.data;
    return null
  } 

  getNameAccordingToCriteria = async (req: INameAccordingToCriteriaReq) => {
    const response = await this.axios.post<INameAccordingToCriteriaRes>(getNameAccordingToCriterialUrl, req)
    if (response.data) return response.data;
    return null
  } 
}
