import axios, { type AxiosRequestConfig, type AxiosResponse } from "axios"

const baseDomain: string = import.meta.env.VITE_APP_API_URL as string
const baseDomainNotion: string = import.meta.env.VITE_APP_NOTION_PROXY_API_URL as string
import { key, storage } from "@/lib/storage"
import { interceptorEvents } from "../utils/InterceptorHandler";

function requestHeader(isFormData: boolean) {
  const lc = storage.getLocalStorage();
  let userToken = lc.get(key.userToken);

  if (userToken === undefined || userToken == 'undefined' || userToken === null) {
    userToken = "";
  }

  if (isFormData) {
    return {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": `${import.meta.env.BASE_URL as string}`,
      "kh_token": userToken ?? "",
      "platform_tscv": "Windows",
      "Access-Control-Allow-Credentials": true,
    };
  }

  return {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": `${import.meta.env.BASE_URL as string}`,
    "kh_token": userToken ?? "",
    "platform_tscv": "Windows",
  }
}

const axiosInstance = axios.create({
  baseURL: baseDomain,
  responseType: "json",
  withCredentials: false,
})

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers = config.data instanceof FormData ? requestHeader(true) : requestHeader(false);
    return config;
  },
  (error: any) => Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data) {
      interceptorEvents.invoke(response);
    }
    return response;
  }
  ,
  (error: any) => {
    if (error.response && error.response.status === 401) {
      const localStorage = storage.getLocalStorage()
      localStorage.add(key.redirect_url, window.location.pathname)
      const decodedURL = decodeURIComponent(window.location.search);
      if (decodedURL.includes("error")) {
        const params = new URLSearchParams(decodedURL)
        localStorage.add("error", params.get("error"))
      } else {
        localStorage.remove("error")
      }
      window.location.href = "/login"
    }
    // service unavailable
    else if (error.response && error.response.status === 503) {
      window.location.href = "/maintenance"
      return
    }
    // upgrade required
    else if (error.response && error.response.status === 426) {
      window.location.href = "/conditions"
    } else if (error.response && error.response.status === 404) return Promise.reject(error)
    else return Promise.reject(error.response.data)
  }
)

const axiosInstanceNotion = axios.create({
  baseURL: baseDomainNotion,
  responseType: "json",
  withCredentials: false,
})

export { axiosInstance, axiosInstanceNotion }
