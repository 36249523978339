<style>
@import "@/assets/css/style.css";
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<script lang="ts" setup>
import { ref } from 'vue';

const isShow = ref<boolean>(true)
window.onscroll = () => {
    if (document.body.scrollTop > 100  || document.documentElement.scrollTop > 100) {
        isShow.value = true;
    }
    else {
        isShow.value = false;
    }
}
</script>

<template>
    <Transition>
        <a href="#" class="scrollup" v-if="isShow">
            <img src="@/assets/images/icons/ic-arrow-up.svg" width="20px" alt="icon arrow to top" />
        </a>
    </Transition>
</template>