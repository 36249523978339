import Repository from "@/lib/https/repository"
import type { AxiosResponse } from "axios"
import { buildUrlWithQueryOptions } from "../utils/stringUtils"
import type { IDistrictParam, IWardParam } from "@/models/https/citiesRequests"
import type { ICityOrGenderRes, IDistrictRes, IWardRes } from "@/models/https/citiesResponse"

import { apiCitiesRouter } from "../utils/apiRouter"


export default class CitiesRepositiory extends Repository {
  getCities = async () => {
    const response = await this.axios.post<ICityOrGenderRes>(apiCitiesRouter.getCitiesUrl)
    if (response.data) return response.data;
    return null
  } 

  getGenders = async () => {
    const response = await this.axios.post<ICityOrGenderRes>(apiCitiesRouter.getGenderUrl)
    if (response.data) return response.data;
    return null
  } 

  getDictrics =  async (data:IDistrictParam) => {
    const url = buildUrlWithQueryOptions(apiCitiesRouter.baseUrl, data)
    const response = await this.axios.post<IDistrictRes>(url, {});
    if (response.data) return response.data;
    return null
  } 

  getWards =  async (data:IWardParam) => {
    const url = buildUrlWithQueryOptions(apiCitiesRouter.baseUrl, data)
    const response = await this.axios.post<IWardRes>(url, {});
    if (response.data) return response.data;
    return null
  } 
}
