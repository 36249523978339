import Repository from "@/lib/https/repository"
import type { IUserProfileDto } from "@/models/account";
import type { BaseResponse } from "@/models/https/baseRespones";
import { key, storage } from "../storage";
export const USER_PROFILE_URL = "/api/khach_hang/profile"

export default class UserProfileRepository extends Repository {
  getUserProfile = async () => {
    const lc = storage.getLocalStorage();
    const userToken = lc.get(key.userToken);
    if (!userToken || userToken == undefined) return null;
    const response = await this.axios.post<BaseResponse<[IUserProfileDto]>>(USER_PROFILE_URL)
    return response?.data?.data?.[0];
  }
}