import type { IDistrict, IProvince, ITown } from "@/models/masterData";
import Repository from "./repository";
import { axiosInstance } from "./setting";

const GET_DYNAMIC_DATA_URL = '/api/masterdata/masterdata_get_dynamic';

export function getDynamic(master_type: string, master_child?: string | number) {
    var url = `${GET_DYNAMIC_DATA_URL}?master_type=${master_type}`;
    master_child && (url += `&master_child=${master_child}`);
    return axiosInstance.post(url, { master_type, master_child })
        .then(({ data }) => data)
        .catch(err => {
            return err;
        });
}

export default class MasterDataRepository extends Repository {
    getProvices(): Promise<IProvince[]> {
        return getDynamic('province').then(({ data }) => data);
    }

    getDistricts(provinceId: number): Promise<IDistrict[]> {
        return getDynamic('district', provinceId).then(({ data }) => data);
    }

    getTowns(districtId: number): Promise<ITown[]> {
        return getDynamic('town', districtId).then(({ data }) => data);;
    }
}