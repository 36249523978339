<script setup lang="ts">
import { RepositoryFactory } from '@/lib/https';
import AccountRepository from '@/lib/https/accountRepository';
import router from '@/router';
import { routerPaths } from '@/router/routes';
import store from '@/store';
import { ref } from 'vue';
</script>
<script lang="ts">
const { sendOTP, confirmOTP } = RepositoryFactory.getRepository<AccountRepository>(AccountRepository);

const otpResult = ref<{ msg?: string, code?: string }>({})

export default {
    data() {
        return {
            form: {
                otp: ''
            },
            timerCount: 60
        }
    },
    methods: {
        async submitOTP() {
            const { mobile } = router.currentRoute.value.query
            const otpRes = await confirmOTP(mobile as string, this.form.otp)
            otpResult.value = { msg: otpRes.result_msg, code: otpRes.result_code }
            if (otpRes?.result_code == '000' && otpRes?.result_msg == 'ok') {
                store.commit('userProfile/setProfileFromDto', otpRes.data)
                router.replace(`${routerPaths.UserInfo}`)
            } else {
                alert(otpRes.result_msg)
            }
        },
        async resendOTP() {
            const { mobile } = router.currentRoute.value.query
            const otpRes = await sendOTP(mobile as string);
            otpResult.value = { msg: otpRes.result_msg, code: otpRes.result_code }
            if (otpRes?.result_code == '000' && otpRes?.result_msg == 'ok') {
                this.timerCount = 60;
                this.countDownTimer()
            } else {
                alert(otpRes.result_msg)
            }
        },
        countDownTimer() {
            if (this.timerCount > 0) {
                setTimeout(() => {
                    this.timerCount--;
                    this.countDownTimer()
                }, 1000)
            }
        },
    },
    mounted() {
        this.countDownTimer()
    }
}
</script>
<style setup>
@import '@/assets/css/signin.css';
</style>
<template>
    <main class="main m-0 p-0">
        <div class="container-fluid">
            <div class="row align-items-center justify-content-center m-0 p-0">
                <div class="d-none d-md-flex col-md-6 col-xxl-5 px-0 left">
                    <h2 class="heading">THAY SỐ CẢI VẬN</h2>
                </div>
                <div class="col-md-6 col-xxl-5 right">
                    <div class="signin-block mb-2">
                        <img src="@/assets/images/logo_mompng.png" class="logo mb-3" width="60" alt="logo LỤC TRỤ MỆNH LÝ">
                        <form class="form-signin" @submit.prevent="submitOTP">
                            <h1 class="title-page mt-2">Nhập mã OTP</h1>
                            <div class="my-3">
                                <label for="txtPhone" class="form-label fw-normal">Mã OTP được đã gửi tới số điện thoại: <strong id="st_mobile">{{ router.currentRoute.value.query.mobile }}</strong></label>
                                <input type="text" class="form-control form-control-lg text-center" id="txtOTP" placeholder="OTP" v-model="form.otp">
                            </div>
                            <button v-if="timerCount > 0" class="w-100 btn btn-lg btn-danger rounded-pill">Xác thực mã OTP</button>
                            <div v-if="timerCount <= 0" id="div_otp_error">
                                <div class="alert alert-danger mt-3 d-flex align-items-center" role="alert">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM18.0455 7.4545C17.6061 7.01517 16.8938 7.01517 16.4545 7.4545C16.4439 7.46511 16.4339 7.47635 16.4247 7.48816L11.2161 14.1251L8.07581 10.9848C7.63647 10.5455 6.92416 10.5455 6.48482 10.9848C6.04548 11.4242 6.04548 12.1365 6.48482 12.5758L10.4545 16.5455C10.8938 16.9848 11.6061 16.9848 12.0455 16.5455C12.0553 16.5357 12.0645 16.5254 12.0731 16.5146L18.0615 9.02921C18.4848 8.58872 18.4794 7.88846 18.0455 7.4545Z" fill="#842029" />
                                    </svg>
                                    <div class="ms-2">
                                        OTP lỗi hoặc hết hạn, vui lòng kiểm tra và gửi lại.
                                    </div>
                                </div>
                            </div>
                            <div v-else class="other-block  gap-3 mt-3">
                                <p class="text-muted mb-2">Phiên này sẽ kết thúc sau:
                                    <strong id="st_count_down">{{ timerCount }} giây</strong>.
                                </p>
                                <small v-if="otpResult.code != undefined && otpResult.code != '000'" class="form-text text-danger">
                                    {{ otpResult.msg }}
                                </small>

                            </div>
                            <p class="text-muted mb-2">
                                Bạn chưa nhận được mã ?
                                <a class="text-primary" type="button" @click="resendOTP">Gửi lại mã</a>.
                            </p>
                        </form>
                        <h2 style="text-align: center;color: red;">Công ty cổ phần tiên đoán tương lai MOM (MOM FPT JSC)</h2>
                    </div>

                </div>
            </div>
        </div>
    </main>
</template>