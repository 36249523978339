<script setup lang="ts">
import AccountLeftPanel from '@/components/parts/UserAccount/AccountLeftPanel.vue';
import { ref } from 'vue';
import SimPurchaseHistory from './SimPurchaseHistory.vue';
import ServicePurchaseHistory from './ServicePurchaseHistory.vue';
import DrawServicePurchaseHistory from './DrawServicePurchaseHistory.vue';
import PerpetualCalendarServicePurchaseHistory from './PerpetualCalendarServicePurchaseHistory.vue';

const TABS = [
    "SIM", 'GÓI XEM MỆNH LÝ', 'GÓI GIEO QUẺ DỊCH LÝ', 'GÓI XEM LỊCH VẠN NIÊN'
] as const;

function onChangeTab(idx: number) {
    console.log(idx);
}

const currentTab = ref<string>(TABS[0]);
</script>

<template>
    <div class="row-cols-auto">
        <div class="col-md-12 col-sm-12">
            <div class="tabs">
                <template v-for="tab in TABS">
                    <input type="radio" :id="tab" class="input" :value="tab" v-model="currentTab" />
                    <label :for="tab" class="label">{{ tab }}</label>
                </template>
            </div>
        </div>
    </div>
    <section class="section-page section-page-mobile section-page-account bg-light" id="account-section">
        <div class="container-fluid container-scroll">
            <div class="row">
                <div class="col-4 d-none d-lg-block block">
                    <AccountLeftPanel/>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                    <SimPurchaseHistory v-if="currentTab == TABS[0]" />
                    <ServicePurchaseHistory v-if="currentTab == TABS[1]" />
                    <DrawServicePurchaseHistory v-if="currentTab == TABS[2]" />
                    <PerpetualCalendarServicePurchaseHistory v-if="currentTab == TABS[3]" />
                </div>
            </div>
        </div>
    </section>
</template>

<style>
.tabs {
    margin-top: 10px;
    background-color: #f8f8f8;
    text-align: center;
    /* display: flex;
         flex-wrap: wrap;
         max-width: 700px;
         background: #efefef; */
}

.input {
    position: absolute;
    opacity: 0;
}

.label {
    width: 100%;
    padding: 10px 30px;
    background: #e5e5e5;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    color: #7f7f7f;
    transition: background 0.1s, color 0.1s;
}

.label:hover {
    background: #d8d8d8;
}

.label:active {
    background: #ccc;
}

.input:focus+.label {
    z-index: 1;
    border-bottom: -2px solid red;
}

.input:checked+.label {
    background: #fff;
    color: #000;
    /* border: 1px solid green; */
    border-bottom-color: white;
    position: relative;
    z-index: 1;
}

@media (min-width: 600px) {
    .label {
        width: auto;
    }
}

.panel {
    display: none;
    padding: 20px 30px 30px;
    background: #fff;
    border: 1px solid green;
}

@media (min-width: 600px) {
    .panel {
        order: 99;
    }
}

.input:checked+.label+.panel {
    display: block;
    position: relative;
    top: -1px;
    z-index: 0;
}

@media screen and (max-width: 768px) {
    .row-cols-auto {
        margin-top: 61px;
    }
}

.block{
    width: 326px;
    flex: 0 0 auto;
    position: sticky;
    top: 0;
    height: fit-content;
}
</style>
