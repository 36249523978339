<template>
   <div v-if="isShowLoading" class="tpoloading"></div>
</template>

<style scoped>
@import "@/assets/css/loading.css";
</style>

<script lang="ts" setup>
import { ref } from 'vue';

</script>

<script lang="ts">
const isShowLoading = ref<boolean>(false);

export const enableLoading = (isShow: boolean) =>
{
   isShowLoading.value = isShow;
}

</script>