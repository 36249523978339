export enum ErrorMessage
{
  InputGenderWrong = "Bạn chưa chọn giới tính. Vui lòng chọn giới tính.",
  InputNameWrong = "Bạn chưa nhập họ tên. Vui lòng nhập họ tên.",
  InputBirthYearWrong = "Bạn chưa chọn năm sinh. Vui lòng chọn năm sinh.",
  InputNameChangeFortuneWrong = "Tên cải vận nhập không đúng. Vui lòng nhập lại.",
  InputPhoneNumberChangeFortuneWrong = "Vui lòng nhập sim cải vận!",
  InputLisencePlateChangeFortuneWrong = "Vui lòng nhập biển số xe cải vận!",
  InputBankAccountNumberChangeFortuneWrong = "Vui lòng nhập số tài khoản cải vận!",
  InputHouseNumberChangeFortuneWrong = "Vui lòng nhập số nhà cải vận!",
  InputPhoneNumberWrong = "Bạn chưa nhận sim hiện tại. Vui lòng nhập sim hiện tại.",
  InputLisencePlateWrong = "Bạn chưa nhập biển số xe hiện tại. Vui lòng nhập biển số xe hiện tại.",
  InputBankAccountNumberWrong = "Bạn chưa nhập số tài khoản hiện tại. Vui lòng nhập số tài khoản hiện tại.",
  InputHouseNumberWrong = "Bạn chưa nhập số nhà hiện tại. Vui lòng nhập số nhà hiện tại.",

  //PayMentPage
  InputCitiesWrong = "Bạn chưa nhập Tỉnh/TP!",
  InputDistrictWrong = "Bạn chưa nhập Quận/Huyện!",
  InputWardWrong = "Bạn chưa nhập Phường/Xã!",
  InputAddressWrong = "Bạn chưa nhập địa chỉ!"
}