export default {
  title: "Admin",
  administrator: "Administrator",
  owner: "Owner",
  viewer: "Viewer",
  registered: "Registered",
  inviting: "Inviting",
  primaryOwner: "Primary Owner",
  member: "Member",

  // header table
  name: "Name",
  email: "Email",
  role: "Role",
  lastLogin: "Last Login",

  addingAnAdministrator: "Adding an Administrator",
  adminRegistration: "Admin Registration",
  adminUpdate: "Admin Update",
  adminDelete: "Admin Delete",
  confirmDeleteAdministrator: "Are you sure you want to delete this administrator?",
  adminInviteSuccessful: "Administrator invitation successful",
  adminUpdateSuccessful: "Administrator update successful",

  inviteAdmin: "Invite admin",
  reInviteAdmin: "Re-Invite admin",
  updateAdmin: "Update admin",
  updateAdminAvatar: "Update admin avatar",
  updateAdminEmail: "Update admin email",
  updateAdminApplicationAdminRole: "Update application admin role",
  deleteAdmin: "Delete admin",
  exportAdmin: "Export CSV admin",
  titleReinvite: "Resend invitation email",
  questionReinvite: "Would you like us to resend your invitation to {name}?",
  infoReinvite: "An invitation will be sent to {email}.",
  resend : "Resend",
}
