import type { MutationTree } from 'vuex'
import { storage } from '@/lib/storage'
import { getErrorMessage } from '@/lib/utils/common'
import type { ApplicationState } from './state'
import { AdminRepository, ApplicationRepository, RepositoryFactory } from '@/lib/https'
import store from '..'

const { switchApplication } =
  RepositoryFactory.getRepository<ApplicationRepository>(ApplicationRepository)

const { getMyProfile } = RepositoryFactory.getRepository<AdminRepository>(AdminRepository)

async function handleSwitchApplication(id: string) {
  try {
    await switchApplication(id)
  } catch (error) {
    console.log(getErrorMessage(error))
  }
}
async function handleGetMyProfile() {
  try {
    const myProfile = await getMyProfile()
    store.commit('profile/setProfile', myProfile)
  } catch (error) {
    console.log(getErrorMessage(error))
    return null
  }
}

const mutations: MutationTree<ApplicationState> = {
  async setApplication(state, application: ApplicationState) {
    await handleGetMyProfile()
    state.id = application.id
    state.name = application.name
    state.logo = application.logo
    state.approvedAt = application.approvedAt
    const localStorage = storage.getLocalStorage()
    localStorage.add('selected-application-id', application.id)
  },
  async unsetApplication(state) {
    await handleSwitchApplication('00000000-00000000-00000000-00000000')
    await handleGetMyProfile()
    state.id = ''
    state.name = ''
    state.logo = ''
    state.approvedAt = ''
    const localStorage = storage.getLocalStorage()
    localStorage.remove('selected-application-id')
  }
}

export default mutations
